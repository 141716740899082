import * as React from 'react';
import * as Redux from 'react-redux';
import { Grid, Tab, Tabs } from '@mui/material';
import ListadoModulos from './components/ListadoModulos';
import { getAllModulosAction, setActivoModulosAction } from './ModulosAction';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { SU } from '../../constants/ConfigConst';
import { MANAGER } from '../../constants/enumsConst';
import { CustomTabPanel } from '../subareas/components/FormSubAreas';

const ModulosView = ({ isGlobal }) => {
  const dispatch = Redux.useDispatch();
  const modulosStore = Redux.useSelector((state) => state.modulos);

  const loginStore = Redux.useSelector((state) => state.login);
  const isRoot = loginStore.roles.indexOf(SU) !== -1;
  const [tabs, setTabs] = React.useState(0);

  // if (!isRoot && !isGlobal) {
  const isManager = loginStore.user.userCodes.some(el => el.userType === MANAGER);
  if (isManager) {
    window.location.href = '/dashboard';
  }
  if (!isRoot && isGlobal) {
    window.location.href = '/modulos';
  }

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        {modulosStore.all.length > 0 ? (
          <BotonNuevoComponent
            click={async () => {
              await setActivoModulosAction(dispatch, null);
              await getAllModulosAction(dispatch, modulosStore.params);
            }}
            disabled={modulosStore.loadingGetAll}
            white
            text={modulosStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'}
          />
        ) : null}
      </Grid>
      <Grid item md={12}>
      <Tabs
        style={{ marginBottom: '10px', width: '100%' }}
        value={tabs}
        onChange={(event, newValue) => setTabs(newValue)}
        aria-label="basic tabs example"
      >
        <Tab label="App" style={{flex: 1}} />
        <Tab label="Backoffice" style={{flex: 1}} />
      </Tabs>
        <CustomTabPanel value={tabs} index={0}>
          <ListadoModulos isGlobal={isGlobal} />
        </CustomTabPanel>
        <CustomTabPanel value={tabs} index={1}>
          <ListadoModulos isGlobal={isGlobal} isPanel />
        </CustomTabPanel>
      </Grid>
    </Grid>
  );
};

export default ModulosView;
