import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';

export const documentosApi = `${baseApi}/entity-documents`;

export const getAllDocumentosService = async (params) => {
  const res = await axios.get(documentosApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveDocumentosService = async (id, data) => {
  if (data.file) {
    const formData = new FormData();

    if (data.userTypes?.length) {
      for (const userType of data.userTypes) {
        formData.append('userTypes[]', userType);
      }
      delete data.userTypes;
    }
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.file;
  }

  let res;
  if (id) {
    res = await axios.put(`${documentosApi}/${id}`, data, {
      headers: await token({
        isFormData: !!data.file,
      }),
    });
  } else {
    res = await axios.post(`${documentosApi}`, data, {
      headers: await token({
        isFormData: !!data.file,
      }),
    });
  }
  return res.data;
};

export const getOneDocumentosService = async (id) => {
  const res = await axios.get(`${documentosApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteDocumentosService = async (id) => {
  const res = await axios.delete(`${documentosApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};
