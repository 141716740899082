import { Grid, Paper, Typography, Box } from '@mui/material';
import React from 'react';
import * as ROUTES from '../../router/Routes';
import { Link } from 'react-router-dom';
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessIcon from '@mui/icons-material/Business';
import ChairIcon from '@mui/icons-material/Chair';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import TrimestrePieChartPanelControl from './components/TrimestrePieChartPanelControl';
import GeneroPieChartPanelControl from './components/GeneroPieChartPanelControl';
import MesesBarChartPanelControl from './components/MesesBarChartPanelControl';
import LineChartPanelControl from './components/LineChartPanelControl';
import StackedBarChartPanelControl from './components/StackedBarChartPanelControl';

const PanelControlView = () => {
  const Card = (icon, text, data, to) => (
    <Grid item xs={12} md={3} lg={3}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {icon}
        <Typography variant="body" sx={{ fontWeight: '700' }}>
          {' '}
          {text}{' '}
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Typography variant="h5"> {data} </Typography>
          <Link
            to={to}
            style={{
              color: 'black',
              textDecoration: 'none',
            }}
          >
            <BotonNuevoComponent click={() => {}} morado text="Ver más" />
          </Link>
        </Box>
      </Paper>
    </Grid>
  );

  return (
    <Grid container spacing={1}>
      {Card(<GroupsIcon fontSize="large" />, 'Usuarios', 0, ROUTES.users.to)}
      {Card(
        <BusinessIcon fontSize="large" />,
        'Entidades',
        0,
        ROUTES.entidades.to
      )}
      {Card(<ChairIcon fontSize="large" />, 'Reservas', 0, ROUTES.entidades.to)}
      {Card(
        <PeopleOutlineIcon fontSize="large" />,
        'Invitaciones',
        0,
        ROUTES.entidades.to
      )}
      <Grid item md={6}>
        <TrimestrePieChartPanelControl />
      </Grid>
      <Grid item md={6}>
        <GeneroPieChartPanelControl />
      </Grid>
      <Grid item md={12}>
        <MesesBarChartPanelControl />
      </Grid>
      <Grid item md={12}>
        <LineChartPanelControl />
      </Grid>
      <Grid item md={12}>
        <StackedBarChartPanelControl />
      </Grid>
    </Grid>
  );
};

export default PanelControlView;
