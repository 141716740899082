import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const recibosApi = `${baseApi}/payments`;

export const getAllRecibosService = async (params) => {
  const res = await axios.get(recibosApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveRecibosService = async (id, data) => {

  if (data.voucher) {
    const formData = new FormData();
    Object.keys(data)
      .filter((key) => key !== 'codeIds')
      .forEach((key) => {
      formData.append(key, data[key]);
    });
    if (data.codeIds && data.codeIds.length) {
      data.codeIds.forEach((codeId) => {
        formData.append('codeIds[]', codeId);
      });
    }
    data = formData;
  } else {
    delete data.voucher;
  }

  let res;
  if (id) {
    res = await axios.put(`${recibosApi}/${id}`, data, {
      headers: await token({
        isFormData: !!data.voucher,
      }),
    });
  } else {
    res = await axios.post(`${recibosApi}`, data, {
      headers: await token({
        isFormData: !!data.voucher,
      }),
    });
  }
  return res;
};

export const savePaidRecibosService = async (id, data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  const res = await axios.put(`${recibosApi}/${id}/paid`, formData, {
    headers: await token({
      isFormData: true,
    }),
  });
  return res;
};

export const restartPaidRecibosService = async (id, data) => {
  const res = await axios.put(`${recibosApi}/${id}/restart-paid`, data, {
    headers: await token({}),
  });
  return res;
};

export const deleteRecibosService = async (id) => {
  const res = await axios.delete(`${recibosApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const bulkExcelRecibosService = async (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  const res = await axios.post(`${recibosApi}/bulk`, formData, {
    headers: await token({ isFormData: true }),
  });
  return res;
};
