import React from "react";
import ContainerTemplate from "../../components/template/Container";
import DeliveryView from "./DeliveryView";
import * as ROUTES from "../../router/Routes";

const DeliveryModule = () => {
  return (
    <ContainerTemplate active={ROUTES.delivery} routes={[]}>
      <DeliveryView />
    </ContainerTemplate>
  );
};

export default DeliveryModule;
