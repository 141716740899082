import * as React from 'react';
import * as Redux from 'react-redux';
import { Grid, Paper } from '@mui/material';
import FormAjustes from './components/FormAjustes';

const AjustesModule = () => {
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  return (
    <Grid container>
      <Grid item sx={{ mb: 2 }} md={12} xs={12}>
        <Paper elevation={2}>
          <FormAjustes activo={entidadesStore.activo} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AjustesModule;
