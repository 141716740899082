import { SET_LOGOUT, CLEAR_ALL } from '../../store/Types';

export const GETALL_IOTS = 'GETALL_IOTS';
export const ACTIVO_IOTS = 'ACTIVO_IOTS';
export const LOADING_GETALL_IOTS = 'LOADING_GETALL_IOTS';

const initialState = {
  all: [],
  loadingGetAll: false,
  activo: null,
  params: {},
};

export const iotsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_IOTS:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case ACTIVO_IOTS:
      return {
        ...state,
        activo: action.payload,
      };
    case LOADING_GETALL_IOTS:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case SET_LOGOUT || CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
