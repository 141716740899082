import {
  GETALL_USUARIOS,
  BY_IDENTIFICACION_USUARIOS,
  ACTIVO_USUARIOS,
  LOADING_GETALL_USUARIOS,
  SEARCH_USERS,
  SEARCH_USER_CODES,
} from '../../store/Types';
import {
  getAllUsuariosService,
  getOneUsuariosService,
  byIdentifacionUsuariosService,
  saveUsuariosService,
  unassignUsuariosService,
  updateUsuariosService,
  deleteUsuariosService,
  assingCodeUsuariosService,
  resendEmailConfirmationUsuariosService,
  getSearchUsersService,
  getSearchUserCodesService,
  resendEmailCompleteRegistrationUsuariosService,
} from '../../services';
import { tryCatchHelper } from '../../helpers';

export const getAllUsuariosAction = async (dispatch, params) => {
  try {
    dispatch({ type: LOADING_GETALL_USUARIOS, payload: true });
    const res = await getAllUsuariosService(params);
    dispatch({
      type: GETALL_USUARIOS,
      payload: res.data,
    });
    dispatch({ type: LOADING_GETALL_USUARIOS, payload: false });
    return res.data;
  } catch (e) {
    dispatch({ type: LOADING_GETALL_USUARIOS, payload: false });
    tryCatchHelper(e);
    return false;
  }
};

export const saveUsuariosAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res;
    if (id) {
      res = await updateUsuariosService(id, data);
    } else {
      res = await saveUsuariosService(data);
    }
    if (res.status === 504) {
      addNotification('El tiempo de guardado ha alcanzado el límite', {
        warning: true,
      });
      return;
    }
    addNotification(
      res.message ?? id ? 'Usuario actualizado.' : 'Usuario registrado.'
    );
    await getAllUsuariosAction(dispatch, params);
    dispatch({
      type: BY_IDENTIFICACION_USUARIOS,
      payload: null,
    });
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoUsuariosAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_USUARIOS,
    payload: data,
  });
};

export const getOneUsuariosAction = async (dispatch, id) => {
  try {
    const res = await getOneUsuariosService(id);
    await setActivoUsuariosAction(dispatch, res.data);
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteUsuariosAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  let res = null;
  try {
    res = await deleteUsuariosService(id);
    addNotification(res.data.message ?? 'Usuario eliminado.');
    await setActivoUsuariosAction(dispatch, null);
    await getAllUsuariosAction(dispatch, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const byIdentifacionUsuariosAction = async (
  { dispatch, addNotification },
  { number, entityId }
) => {
  try {
    const res = await byIdentifacionUsuariosService(number, entityId);
    dispatch({
      type: BY_IDENTIFICACION_USUARIOS,
      payload: res.data,
    });
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const assingCodeUsuariosAction = async (
  { dispatch, addNotification },
  userId,
  codeId,
  data,
  params
) => {
  try {
    let res = await assingCodeUsuariosService(userId, codeId, data);
    addNotification(res.data.message ?? 'Usuario asignado al código.');
    dispatch({
      type: BY_IDENTIFICACION_USUARIOS,
      payload: null,
    });
    await getAllUsuariosAction(dispatch, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const unassignUsuariosAction = async (
  { dispatch, addNotification },
  userId,
  codeId,
  params
) => {
  try {
    const res = await unassignUsuariosService(userId, codeId);
    addNotification(res.data.message ?? 'Código del usuario eliminado.');
    await getAllUsuariosAction(dispatch, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const resendEmailConfirmationUsuariosAction = async (
  addNotification,
  userId
) => {
  try {
    const res = await resendEmailConfirmationUsuariosService(userId);
    addNotification(
      res.data.message ?? 'El correo de confirmación fue enviado.'
    );
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};


export const resendEmailCompleteRegistrationUsuariosAction = async (
  addNotification,
  userId
) => {
  try {
    const res = await resendEmailCompleteRegistrationUsuariosService(userId);
    addNotification(
      res.data.message ?? 'El correo para completar el registro fue enviado.'
    );
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const getSearchUsersAction = async (dispatch, entityId, data, userType) => {
  try {
    dispatch({
      type: LOADING_GETALL_USUARIOS,
      payload: true,
    });
    const res = await getSearchUsersService(entityId, data, userType);
    dispatch({
      type: SEARCH_USERS,
      payload: res.data.data,
    });
    dispatch({
      type: LOADING_GETALL_USUARIOS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const getSearchUserCodesAction = async (dispatch, entityId, data) => {
  try {
    dispatch({
      type: LOADING_GETALL_USUARIOS,
      payload: true,
    });
    const res = await getSearchUserCodesService(entityId, data);
    dispatch({
      type: SEARCH_USER_CODES,
      payload: res.data.data,
    });
    dispatch({
      type: LOADING_GETALL_USUARIOS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};
