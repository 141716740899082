import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import * as Redux from 'react-redux';
import { ButtonFile, Modal } from '../../components';
import LoadingComponent from '../../components/Loading';
import { MORADO_COLOR } from '../../constants/ColorsConst';
import * as CONST from '../../constants/FieldsConst';
import { getLabelTypeEntity } from '../../constants/enumsConst';
import { dateformat, isSwitch } from '../../helpers';
import { useNotification } from '../../helpers/notification';
import { PARAMS_RECIBOS } from '../../store/Types';
import FechasPersonalizada from '../accesos/components/FechasPersonalizada';
import { getSearchCodesCodigosAction } from '../codigos/CodigosAction';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import BotonFechas from '../invitados/components/BotonFechas';
import SwitchFechaComponent from '../invitados/components/SwitchFecha';
import { checkModuleAccess } from '../modulos/modules.helpers';
import { getSearchUsersAction } from '../usuarios/UsuariosAction';
import {
  bulkExcelRecibosAction,
  getAllRecibosAction,
  setActivoRecibosAction,
} from './RecibosAction';
import FormRecibos from './components/FormRecibos';
import ListadoRecibos from './components/ListadoRecibos';

let intervalSearch = null;

const RecibosView = () => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const recibosStore = Redux.useSelector((state) => state.recibos);
  const codigosStore = Redux.useSelector((state) => state.codigos);
  const usuariosStore = Redux.useSelector((state) => state.usuarios);
  const loginStore = Redux.useSelector((state) => state.login);

  const isPublic = entidadesStore.activo.isPublic;

  const accessName = 'payment';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [dataCodes, setDataCodes] = React.useState([]);
  const [dataUsers, setDataUsers] = React.useState([]);
  const [loadingSelectCode, setLoadingDataCode] = React.useState(false);
  const [loadingSelectUser, setLoadingDataUser] = React.useState(false);

  const [codeSelect, setCodeSelect] = React.useState(null);
  const [openModalForm, setOpenModalForm] = React.useState(false);

  const [fileExcel, setFileExcel] = React.useState();
  const [loadingExcel, setLoadingExcel] = React.useState(false);
  const [modalExcel, setModalExcel] = React.useState(false);
  const [isPay, setIsPay] = React.useState(false);

  const [selectedItemIndex, setSelectedItemIndex] = React.useState(4);
  const [isRango, setIsRango] = React.useState(false);
  const [isFuturo, setIsFuturo] = React.useState(true);
  const [date, setDate] = React.useState(
    dayjs(dateformat(null, { full: true })).toDate()
  );
  const [dateTo, setDateTo] = React.useState(
    dayjs(dateformat(null, { full: true }))
      .add(30, 'd')
      .toDate()
  );

  React.useEffect(() => {
    if (recibosStore.activo) {
      setOpenModalForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recibosStore.activo]);

  React.useEffect(() => {
    if (!isPublic) {
      delete recibosStore.params.userId;
    } else {
      delete recibosStore.params.codeId;
    }
    dispatch({ type: PARAMS_RECIBOS, payload: recibosStore.params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublic]);

  React.useEffect(() => {
    const payload = {
      ...recibosStore.params,
      entityId: entidadesStore.activo.id,
      codeId: codeSelect?.id,
    };
    dispatch({ type: PARAMS_RECIBOS, payload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeSelect]);

  React.useEffect(() => {
    setDataCodes([]);
    if (codigosStore.searchCodes.length > 0) {
      codigosStore.searchCodes.map((code) => {
        return setDataCodes((old) => [
          ...old,
          {
            [CONST.ID]: code.id,
            [CONST.CODE]: code.code,
          },
        ]);
      });
    }
  }, [codigosStore.searchCodes]);

  React.useEffect(() => {
    setDataUsers([]);
    if (usuariosStore.searchUsers && usuariosStore.searchUsers.length > 0) {
      usuariosStore.searchUsers.map((user) => {
        return setDataUsers((old) => [
          ...old,
          {
            [CONST.ID]: user.id,
            [CONST.NAME]: `${user.profile.firstName} ${user.profile.lastName}`,
          },
        ]);
      });
    }
  }, [usuariosStore.searchUsers]);

  React.useEffect(() => {
    clearTimeout(intervalSearch);
    intervalSearch = setTimeout(async () => {
      if (recibosStore.params.date) {
        await getAllRecibosAction(dispatch, {
          entityId: entidadesStore.activo.id,
          ...recibosStore.params,
        });
      }
    }, 600);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recibosStore.params]);

  React.useEffect(() => {
    dispatch({
      type: PARAMS_RECIBOS,
      payload: {
        ...recibosStore.params,
        perMonth: false,
        date,
        ...(date !== dateTo && { dateTo }),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, dateTo]);

  var delayTimer;
  function doSearch(text) {
    clearTimeout(delayTimer);
    setLoadingDataCode(true);
    delayTimer = setTimeout(async () => {
      await getSearchCodesCodigosAction(
        dispatch,
        entidadesStore.activo.id,
        text
      );
      setLoadingDataCode(false);
    }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
  }

  var delayTimerUser;
  function doSearchUser(text) {
    clearTimeout(delayTimerUser);
    setLoadingDataUser(true);
    delayTimerUser = setTimeout(async () => {
      await getSearchUsersAction(dispatch, entidadesStore.activo.id, text);
      setLoadingDataUser(false);
    }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
  }

  let selectCodeComponent;

  if (!isPublic) {
    selectCodeComponent = (
      <Autocomplete
        freeSolo
        id={'selectCode'}
        disableClearable
        options={dataCodes.map((option) => option.code)}
        size="small"
        loading={loadingSelectCode}
        onChange={(event, newValue) => {
          const code = codigosStore.searchCodes.find(
            (a) => a.code === newValue
          );
          setCodeSelect(code);
        }}
        onInputChange={(event, newInputValue) => {
          if (!newInputValue) {
            setCodeSelect(null);
            // Aquí puedes agregar más lógica si es necesario cuando newInputValue es vacío
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={async (e) => {
              if (e) doSearch(e.target.value);
              delete recibosStore.params.codeId;
              dispatch({
                type: PARAMS_RECIBOS,
                payload: {
                  ...recibosStore.params,
                },
              });
            }}
            label={`Buscar por ${getLabelTypeEntity(
              entidadesStore.entityType.name
            )}`}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: (
                <React.Fragment>
                  {loadingSelectCode && (
                    <CircularProgress color="inherit" size={20} />
                  )}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  } else {
    selectCodeComponent = (
      <Autocomplete
        freeSolo
        id={'selectCode'}
        disableClearable
        options={dataUsers.map((option) => option.name)}
        size="small"
        loading={loadingSelectUser}
        onChange={(event, newValue) => {
          const item = usuariosStore.searchUsers.find(
            (a) => `${a.profile.firstName} ${a.profile.lastName}` === newValue
          );
          dispatch({
            type: PARAMS_RECIBOS,
            payload: {
              ...recibosStore.params,
              userId: item.id,
            },
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => {
              if (e) {
                doSearchUser(e.target.value);
              }
              delete recibosStore.params.userId;
              dispatch({
                type: PARAMS_RECIBOS,
                payload: {
                  ...recibosStore.params,
                },
              });
            }}
            label={`Buscar por usuario`}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: (
                <React.Fragment>
                  {loadingSelectUser ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }

  // const selectMes = (
  //   <FormControl size="small" sx={{ width: '100%' }}>
  //     <InputLabel id="selectMeses">Mes</InputLabel>
  //     <Select
  //       labelId="selectMeses"
  //       size="small"
  //       value={
  //         recibosStore.params.date &&
  //         dateformat(recibosStore.params.date, {
  //           format: 'MM',
  //         })
  //       }
  //       label="Mes"
  //       onChange={(e) => {
  //         setSelectedItemIndex(4);

  //         if (e.target.value === '') {
  //           setDateTo(null);
  //           dispatch({
  //             type: PARAMS_RECIBOS,
  //             payload: {
  //               ...recibosStore.params,
  //               perMonth: true,
  //             },
  //           });
  //           return;
  //         }

  //         const date = `${dateformat(null, { format: 'YYYY' })}-${
  //           e.target.value
  //         }-05 00:00:00`;

  //         dispatch({
  //           type: PARAMS_RECIBOS,
  //           payload: {
  //             ...recibosStore.params,
  //             perMonth: true,
  //             date,
  //             dateTo: null,
  //           },
  //         });
  //       }}
  //       id={CONST.DATE_TO}
  //     >
  //       <MenuItem key={14} value={''}>
  //         Ningúno
  //       </MenuItem>
  //       {mesesArray.map((mes, index) => (
  //         <MenuItem key={index + 1} value={mes.valor}>
  //           {mes.nombre}
  //         </MenuItem>
  //       ))}
  //     </Select>
  //   </FormControl>
  // );

  const filtercomponent = (
    <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          {selectCodeComponent}
        </Grid>

        {/* <Grid item md={isRango ? 2 : 3}>
          {selectMes}
        </Grid> */}
        <Grid item md={isRango ? 3 : 6}>
          <Stack direction="row" alignItems="center">
            <BotonFechas
              futuro={isFuturo}
              selectedItemIndex={selectedItemIndex}
              dateRange={(start, end) => {
                setDate(dateformat(start, { full: true }));
                setDateTo(dateformat(end, { full: true }));
              }}
              setIsRango={(v) => setIsRango(v)}
            />
            {isSwitch(isFuturo, date, dateTo) || isRango ? null : (
              <Box sx={{ ml: 2, mt: 1 }}>
                <SwitchFechaComponent
                  value={isFuturo}
                  onClickLeft={() => setIsFuturo(false)}
                  onClickRigth={() => setIsFuturo(true)}
                  onChange={(e) => setIsFuturo(e.target.checked)}
                />
              </Box>
            )}
          </Stack>
        </Grid>
        {isRango && (
          <FechasPersonalizada
            startDate={date}
            mdStart={2}
            mdEnd={2}
            endDate={dateTo}
            onStart={(value) => {
              if (!value) return;
              setDate(value);
            }}
            onEnd={(value) => {
              if (!value) return;
              setDateTo(value);
            }}
          />
        )}
      </Grid>
    </Paper>
  );

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center">
            <BotonNuevoComponent
              click={async () => {
                await setActivoRecibosAction(dispatch, null);
                setOpenModalForm(true);
              }}
              azul
              mr={2}
            />

            {recibosStore.all.length > 0 && (
              <BotonNuevoComponent
                click={async () => {
                  await setActivoRecibosAction(dispatch, null);
                  dispatch({
                    type: PARAMS_RECIBOS,
                    payload: {
                      ...recibosStore.params,
                      perMonth: true,
                      date: null,
                      dateTo: null,
                    },
                  });
                }}
                white
                disabled={recibosStore.loadingGetAll}
                text={
                  recibosStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'
                }
              />
            )}
          </Stack>
          <Stack direction="row" alignItems="center">
            <BotonNuevoComponent
              click={() => {
                setModalExcel(true);
              }}
              text="SUBIR FORMATO DE EXCEL"
              disabled={loadingExcel}
              azul
              mr={2}
            />
            <BotonNuevoComponent
              click={() => {
                window.location.href =
                  process.env.REACT_APP_API_URL +
                  '/bulk-schemas/payment-schema.xlsx';
              }}
              text="DESCARGAR FORMATO DE EXCEL"
              disabled={loadingExcel}
              cyan
            />
          </Stack>
        </Stack>
      </Grid>
      <Grid item md={12}>
        {filtercomponent}
      </Grid>
      {/* <Grid item md={4}>
        <Paper elevation={4}>
          <FormRecibos />
        </Paper>
      </Grid> */}
      <Grid item md={12}>
        <ListadoRecibos setOpenModalForm={() => setOpenModalForm(true)} />
      </Grid>

      <Modal
        id="modalFormRecibos"
        title={`${recibosStore.activo ? 'Editar' : 'Nuevo'} recibo`}
        open={openModalForm}
        maxWidth={recibosStore.activo || isPay ? 'md' : 'sm'}
        onClose={() => {
          setOpenModalForm(!openModalForm);
          setActivoRecibosAction(dispatch, null);
        }}
      >
        <FormRecibos
          setOpenModalForm={(val) => setOpenModalForm(val)}
          setIsPayView={setIsPay}
        />
      </Modal>

      <Modal
        id="modalFileExcel"
        title={`Recibos masivos`}
        open={modalExcel}
        maxWidth="sm"
        onClose={() => setModalExcel(!modalExcel)}
      >
        <DialogContent>
          <LoadingComponent
            isLoading={loadingExcel}
            text="Procensando archivo excel..."
          />
          <ButtonFile
            id={'archivoexcel'}
            onFile={(file) => setFileExcel(file)}
            file={fileExcel}
            text="Buscar archivo"
            sxc={{ marginTop: '5px' }}
            size="40%"
            name={'excel'}
            onDelete={() => setFileExcel(null)}
            accept=".xlsx"
          />
        </DialogContent>
        <DialogActions>
          <BotonNuevoComponent
            click={async () => {
              if (fileExcel === null || fileExcel === undefined) {
                return;
              }
              setLoadingExcel(true);
              await bulkExcelRecibosAction(
                { dispatch, addNotification },
                {
                  entityId: entidadesStore.activo.id,
                  file: fileExcel,
                }
              );
              setLoadingExcel(false);
              setModalExcel(false);
              setFileExcel(null);
              await getAllRecibosAction(dispatch, {
                entityId: entidadesStore.activo.id,
                ...recibosStore.params,
              });
            }}
            text="SUBIR ARCHIVO"
            morado
          />
          <Button
            sx={{ color: MORADO_COLOR }}
            onClick={() => {
              setLoadingExcel(false);
              setModalExcel(false);
              setFileExcel(null);
            }}
          >
            CANCELAR
          </Button>
        </DialogActions>
      </Modal>
    </Grid>
  );
};

export default RecibosView;
