import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const invitados = `${baseApi}/invitations`;

export const getAllInvitadosService = async (params) => {
  const res = await axios.get(invitados, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveInvitadosService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${invitados}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${invitados}`, data, {
      headers: await token({}),
    });
  }
  return res;
};

export const getOneInvitadosService = async (id) => {
  const res = await axios.get(`${invitados}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteInvitadosService = async (id) => {
  const res = await axios.delete(`${invitados}/${id}`, {
    headers: await token({}),
  });
  return res.data;
};
