import { tryCatchHelper } from '../../helpers';
import {
  GETALL_SUBAREAS,
  LOADING_GETALL_SUBAREAS,
  ACTIVO_SUBAREAS,
} from '../../store/Types';
import {
  deleteSubAreasService,
  getAllSubAreasService,
  getOneSubAreasService,
  saveSubAreasService,
} from './SubAreasService';

export const getAllSubAreasAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_SUBAREAS,
      payload: true,
    });
    const res = await getAllSubAreasService(params);
    dispatch({
      type: GETALL_SUBAREAS,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_SUBAREAS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_SUBAREAS,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const saveSubAreasAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res = await saveSubAreasService(id, data);
    if (id) {
      await setActivoSubAreasAction(dispatch, res);
    }
    addNotification(
      res.message ?? id ? 'La sub área actualizado.' : 'La sub área registrado.'
    );
    await getAllSubAreasAction(dispatch, params);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoSubAreasAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_SUBAREAS,
    payload: data,
  });
};

export const getOneSubAreasAction = async (dispatch, id) => {
  try {
    const res = await getOneSubAreasService(id);
    await setActivoSubAreasAction(dispatch, res.data);
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteSubAreasAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteSubAreasService(id);
    addNotification(res.data.message ?? 'Sub área eliminada.');
    await setActivoSubAreasAction(dispatch, null);
    await getAllSubAreasAction(dispatch, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
