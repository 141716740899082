import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import * as CONST from '../../constants/FieldsConst';
import { getLabelTypeEntity } from '../../constants/enumsConst';
import { dateformat, getDateTimezone, isSwitch } from '../../helpers';
import { getSearchCodesCodigosAction } from '../../store/Actions';
import { PARAMS_ACCESOS } from '../../store/Types';
import BotonFechas from '../invitados/components/BotonFechas';
import SwitchFechaComponent from '../invitados/components/SwitchFecha';
import { checkModuleAccess } from '../modulos/modules.helpers';
import { getAllAccesosAction } from './AccesosAction';
import FechasPersonalizada from './components/FechasPersonalizada';
import ListadoAccesos from './components/ListadoAccesos';

const AccesosView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const codigosStore = Redux.useSelector((state) => state.codigos);
  const accesosStore = Redux.useSelector((state) => state.accesos);
  const loginStore = Redux.useSelector((state) => state.login);

  const accessName = 'accessLogs';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [isRango, setIsRango] = React.useState(false);
  const [dataCodes, setDataCodes] = React.useState([]);
  const [loadingDataCode, setLoadingDataCode] = React.useState(false);
  const [isFuturo, setIsFuturo] = React.useState(true);
  const [startDate, setStartDate] = React.useState(
    dateformat(null, { full: true })
  );
  const [endDate, setEndDate] = React.useState(null);
  const timezone = entidadesStore.activo.country.timezone;

  const [pagination, setPagination] = React.useState({
    page: 1,
    limit: 15,
    total: 0,
    sort: 'entryTime',
    sortDirection: 'desc',
  });

  async function fetchData() {
    await getAllAccesosAction(dispatch, {
      ...accesosStore.params,
      startDate: getDateTimezone(accesosStore.params.startDate, timezone),
      endDate: getDateTimezone(accesosStore.params.endDate, timezone),
    });
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const payload = {
        startDate: getDateTimezone(startDate, timezone),
        endDate: getDateTimezone(endDate, timezone),
        entityId: entidadesStore.activo.id,
        page: pagination.page,
        limit: pagination.limit,
        sort: pagination.sort,
        sortDirection: pagination.sortDirection,
      };
      dispatch({ type: PARAMS_ACCESOS, payload });
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [accesosStore.params]);

  React.useEffect(() => {
    dispatch({
      type: PARAMS_ACCESOS,
      payload: {
        ...accesosStore.params,
        entityId: entidadesStore.activo.id,
        startDate,
        endDate,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  var delayTimer;
  function doSearch(text) {
    clearTimeout(delayTimer);
    setLoadingDataCode(true);
    delayTimer = setTimeout(async () => {
      await getSearchCodesCodigosAction(
        dispatch,
        entidadesStore.activo.id,
        text
      );
      setLoadingDataCode(false);
    }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
  }

  React.useEffect(() => {
    setDataCodes([]);
    if (codigosStore.searchCodes.length > 0) {
      codigosStore.searchCodes.map((code) => {
        return setDataCodes((old) => [
          ...old,
          {
            [CONST.ID]: code.id,
            [CONST.CODE]: code.code,
          },
        ]);
      });
    }
  }, [codigosStore.searchCodes]);

  const selectCode = (
    <Autocomplete
      freeSolo
      id={CONST.CODE}
      disableClearable
      options={dataCodes.map((option) => option.code)}
      size="small"
      loading={loadingDataCode}
      onChange={(event, newValue) => {
        const code = codigosStore.searchCodes.find((a) => a.code === newValue);
        dispatch({
          type: PARAMS_ACCESOS,
          payload: {
            ...accesosStore.params,
            codeId: code.id,
          },
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => doSearch(e.target.value)}
          label={`Buscar por ${getLabelTypeEntity(
            entidadesStore.entityType.name
          )}`}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            endAdornment: (
              <React.Fragment>
                {loadingDataCode ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
          <Grid
            container
            spacing={2}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Grid item md={2}>
              {selectCode}
            </Grid>
            <Grid
              item
              md={isRango ? 3 : 6}
              sx={{ display: 'flex', flexDirection: 'row' }}
            >
              <BotonFechas
                futuro={isFuturo}
                dateRange={(start, end) => {
                  setStartDate(dateformat(start, { full: true }));
                  setEndDate(dateformat(end, { full: true }));
                }}
                setIsRango={(v) => setIsRango(v)}
              />
              {isSwitch(isFuturo, startDate, endDate) || isRango ? null : (
                <Box sx={{ ml: 2, mt: 1 }}>
                  <SwitchFechaComponent
                    value={isFuturo}
                    onClickLeft={() => setIsFuturo(false)}
                    onClickRigth={() => setIsFuturo(true)}
                    onChange={(e) => setIsFuturo(e.target.checked)}
                  />
                </Box>
              )}
            </Grid>
            {isRango && (
              <FechasPersonalizada
                startDate={startDate}
                endDate={endDate}
                onStart={(value) => {
                  if (!value) return;
                  setStartDate(value);
                }}
                onEnd={(value) => {
                  if (!value) return;
                  setEndDate(value);
                }}
              />
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item md={12}>
        <ListadoAccesos
          params={accesosStore.params}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Grid>
    </Grid>
  );
};

export default AccesosView;
