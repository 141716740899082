import * as React from 'react';
import * as Redux from 'react-redux';
import MUIDataTable from 'mui-datatables';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Tooltip,
} from '@mui/material';
import {
  deleteIotsAction,
  deleteTunnelIotAction,
  getAllIotsAction,
  saveIotsAction,
  saveTunnelIotAction,
  setActivoIotsAction,
  setUpIotsAction,
} from '../IotsAction';
import ActionsList from '../../../components/ActionsList';
import * as CONST from '../../../constants/FieldsConst';
import { Modal } from '../../../components';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import {
  AZUL_COLOR,
  CYAN_COLOR,
  MORADO_COLOR,
  NEGRO_COLOR,
} from '../../../constants/ColorsConst';
import LoadingComponent from '../../../components/Loading';
import { getAllIotTunelesAction } from '../../iot_tuneles/IotTunelesAction';
import { FormControl } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { SU } from '../../../constants/ConfigConst';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { checkConnectionIotService } from '../IotsService';
import { useNotification } from '../../../helpers/notification';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';

const ListadoIots = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const iotsStore = Redux.useSelector((state) => state.iots);
  const iotTunelesStore = Redux.useSelector((state) => state.iotTuneles);
  const loginStore = Redux.useSelector((state) => state.login);
  const isRoot = loginStore.roles.indexOf(SU) !== -1;
  let history = useHistory();

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isSetUp, setIsSetUp] = React.useState(false);
  const [isSetImages, setIsSetImages] = React.useState(false);
  const [isToggleActive, setIsToggleActive] = React.useState(false);
  const [modalAsignTunnel, setModalAsignTunnel] = React.useState(false);
  const [loadingAsignarTunel, setLoadingAsignarTuunel] = React.useState(false);
  const [tunnel, setTunnel] = React.useState('9999999');

  const setLoading = (value) => {
    dispatch({
      type: 'LOADING_GETALL_IOTS',
      payload: value,
    });
  };

  React.useEffect(() => {
    if (iotsStore.all && iotsStore.all.length === 0) {
      getAllIotsAction(dispatch, entidadesStore.activo.id);
    }

    if (iotTunelesStore.all && iotTunelesStore.all.length === 0) {
      getAllIotTunelesAction(dispatch, entidadesStore.activo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRows([]);
    iotsStore.all.forEach((el) => {
      setRows((old) => [
        ...old,
        {
          ...el,
          active: el.active ? 'Activo' : 'Bloqueado',
          actions: el,
        },
      ]);
    });
  }, [iotsStore.all]);

  const _handleEditClick = async (id) => {
    const data = iotsStore.all.find((el) => el.id === id);
    await setActivoIotsAction(dispatch, data);
    setOpenModalForm(true);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteIotsAction(
      { dispatch, addNotification },
      id,
      entidadesStore.activo.id
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const _handleToggleActiveClick = async (id) => {
    setLoadingDelete(true);
    await saveIotsAction(
      { dispatch, addNotification },
      entidadesStore.activo.id,
      id,
      {
        active: !iotsStore.activo.active,
      }
    );
    setIsToggleActive(false);
    setLoadingDelete(false);
  };

  const _handleSetUpClick = async (id) => {
    setLoadingDelete(true);
    await setUpIotsAction({ dispatch, addNotification }, id, {
      setImages: isSetImages,
    });
    setIsSetUp(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.NAME,
      label: 'Nombre',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={() => _handleEditClick(tableMeta.rowData[0])}
            >
              {value}
            </NameComponent>
          );
        },
      },
    },
    {
      name: CONST.IDENTIFIER,
      label: 'Identificador',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              sx={{
                width: '10em',
              }}
            >
              {value}
            </Box>
          );
        },
      },
    },
    {
      name: CONST.URL_ADDRESS,
      label: 'IP',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              sx={{
                width: '10em',
              }}
            >
              {value}
            </Box>
          );
        },
      },
    },
    {
      name: CONST.TYPE,
      label: 'Tipo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              sx={{
                width: '10em',
              }}
            >
              {value?.toUpperCase()}
            </Box>
          );
        },
      },
    },
    {
      name: CONST.ACTIVE,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={value}
                size="small"
                sx={{
                  backgroundColor:
                    value === 'Activo' ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.TUNNEL,
      label: 'Túnel',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return !value ? (
            <Chip
              label="No Asignado"
              size="small"
              sx={{
                backgroundColor: AZUL_COLOR,
                color: 'white',
              }}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '20em',
                pt: 1,
              }}
            >
              <Chip
                key={value.name}
                label={value.name}
                variant="outlined"
                sx={{
                  color: NEGRO_COLOR,
                  borderColor: NEGRO_COLOR,
                  mr: 1,
                  mb: 1,
                  '&:hover': {
                    borderColor: MORADO_COLOR,
                    color: MORADO_COLOR,
                  },
                }}
                onClick={
                  isRoot
                    ? () => {
                        history.push('/iot-tuneles');
                      }
                    : null
                }
                onDelete={
                  isRoot
                    ? async () => {
                        await deleteTunnelIotAction(
                          { dispatch, addNotification },
                          entidadesStore.activo.id,
                          tableMeta.rowData[0],
                          value.id
                        );
                      }
                    : null
                }
              />
            </Box>
          );
        },
      },
    },
    {
      name: 'connection',
      label: 'Conexión',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = iotsStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          return (
            data && (
              <Tooltip describeChild title="Conexión del iot" placement="left">
                <Chip
                  label={data.online ? 'Online' : 'Offline'}
                  size="small"
                  sx={{
                    backgroundColor: data.online ? CYAN_COLOR : MORADO_COLOR,
                    color: 'white',
                  }}
                />
              </Tooltip>
            )
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = iotsStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          return (
            <Box align="left" sx={{ width: '10%' }}>
              <ActionsList
                id={tableMeta.rowData[0]}
                onEdit={() => _handleEditClick(tableMeta.rowData[0])}
                onDelete={async () => {
                  await setActivoIotsAction(dispatch, value);
                  setIsDelete(true);
                }}
                onToggleActive={async () => {
                  await setActivoIotsAction(dispatch, value);
                  setIsToggleActive(true);
                }}
                onSetUp={() => {
                  setActivoIotsAction(dispatch, value);
                  setIsSetUp(true);
                }}
                onAssignTunnel={
                  data && !data.tunnel
                    ? () => {
                        setActivoIotsAction(dispatch, value);
                        setModalAsignTunnel(true);
                      }
                    : null
                }
                onCheckConnection={async () => {
                  setLoading(true);
                  try {
                    const res = await checkConnectionIotService(
                      tableMeta.rowData[0]
                    );
                    addNotification(res.message || 'Conexión exitosa');
                  } catch (error) {
                    addNotification(
                      error.message ||
                        'No se pudo establecer conexión con el iot',
                      { error: true }
                    );
                  }
                  setLoading(false);
                }}
                active={value.active}
              />
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },

    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={iotsStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {iotsStore.activo ? (
        <Modal
          id="modalFormIots"
          title={`Eliminar`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este documento?
              <p>
                <strong>{iotsStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(iotsStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {iotsStore.activo ? (
        <Modal
          id="modalToggleActivo"
          title={`${iotsStore.activo.active ? 'Bloquear' : 'Activar'} ${
            iotsStore.activo.name
          }`}
          open={isToggleActive}
          maxWidth="sm"
          onClose={() => setIsToggleActive(!isToggleActive)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`¿Seguro que quieres ${
                iotsStore.activo.active ? 'Bloquear' : 'Activar'
              } esta iot?`}
              <p>
                <strong>{iotsStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleToggleActiveClick(iotsStore.activo.id);
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsToggleActive(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {iotsStore.activo ? (
        <Modal
          id="modalToggleActivo"
          title={`Configurar iot "${iotsStore.activo.name}"`}
          open={isSetUp}
          maxWidth="sm"
          onClose={() => setIsSetUp(!isSetUp)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres configurar este iot?
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSetImages}
                    onChange={(e) => setIsSetImages(e.target.checked)}
                  />
                }
                label="Agregar imagenes"
              />
            </DialogContentText>
            {isSetImages && (
              <DialogContentText>
                El dispositivo se reiniciara por 2 minutos aproximadamente
              </DialogContentText>
            )}
            <DialogContentText>
              <p>
                <strong>{iotsStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleSetUpClick(iotsStore.activo.id);
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsSetUp(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {iotsStore.activo ? (
        <Modal
          id="modalAssignIot"
          title={`Asignar iot a ${iotsStore.activo.name}`}
          open={modalAsignTunnel}
          maxWidth="sm"
          onClose={() => setModalAsignTunnel(!modalAsignTunnel)}
        >
          <DialogContent>
            <LoadingComponent
              isLoading={loadingAsignarTunel}
              text="Guardando asignación..."
            />
            <DialogContentText id="alert-dialog-slide-description">
              <FormControl fullWidth>
                <Select
                  labelId="tunelesSelect"
                  size="small"
                  value={tunnel}
                  onChange={(e) => {
                    setTunnel(e.target.value);
                  }}
                  id="puertasSelect"
                >
                  <MenuItem value="9999999">Seleccionar iot...</MenuItem>
                  {iotTunelesStore.all.map((e) => {
                    return (
                      <MenuItem key={`tunnel${e.id}`} value={e.id}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <BotonNuevoComponent
              click={async () => {
                setLoadingAsignarTuunel(true);
                const res = await saveTunnelIotAction(
                  { dispatch, addNotification },
                  entidadesStore.activo.id,
                  iotsStore.activo.id,
                  tunnel
                );
                if (res) {
                  setTunnel('9999999');
                  setLoadingAsignarTuunel(false);
                  setModalAsignTunnel(false);
                }
              }}
              disabled={loadingAsignarTunel || tunnel === '9999999'}
              text="GUARDAR"
              morado
            />
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => {
                setLoadingAsignarTuunel(false);
                setTunnel('9999999');
                setModalAsignTunnel(false);
              }}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoIots;
