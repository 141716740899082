import { tryCatchHelper } from '../../helpers';
import {
  deleteMetodosPagoService,
  getAllMetodosPagoService,
  getOneMetodosPagoService,
  saveMetodosPagoService,
} from '../../services';
import {
  ACTIVO_METODOS_PAGO,
  GETALL_METODOS_PAGO,
  LOADING_GETALL_METODOS_PAGO,
} from '../../store/Types';

export const getAllMetodosPagoAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_METODOS_PAGO,
      payload: true,
    });
    const res = await getAllMetodosPagoService({ entityId });
    if (res.status === 200) {
      dispatch({
        type: GETALL_METODOS_PAGO,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_METODOS_PAGO,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_METODOS_PAGO,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_METODOS_PAGO,
      payload: false,
    });
    tryCatchHelper(e);
  }
};

export const saveMetodosPagoAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveMetodosPagoService(id, data);
    addNotification(
      res.message ?? id
        ? 'Tipo de invitación actualizada.'
        : 'Tipo de invitación registrada.'
    );
    await getAllMetodosPagoAction(dispatch, entityId);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoMetodosPagoAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_METODOS_PAGO,
    payload: data,
  });
};

export const getOneMetodosPagoAction = async (dispatch, id) => {
  try {
    const res = await getOneMetodosPagoService(id);
    if (res.status === 200) {
      await setActivoMetodosPagoAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteMetodosPagoAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteMetodosPagoService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Tipo de invitación eliminada.');
      await setActivoMetodosPagoAction(dispatch, null);
      await getAllMetodosPagoAction(dispatch, entityId, null);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
