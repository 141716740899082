import { Avatar, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import * as Redux from 'react-redux';
import { getDateFormated } from '../../../../helpers';

const DetallesReservasRestaurante = ({ data }) => {
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const timezone = entidadesStore.activo.country.timezone;


  if (!data) {
    return null;
  }
  const infoUser = (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <strong>Cédula:</strong> {data.userCode.user.profile.typeDocument}
        {'-'}
        {data.userCode.user.profile.identificationNumber}
      </Grid>
      <Grid item xs={12} sm={12}>
        <strong>Nombre y apellido:</strong>{' '}
        {data.userCode.user.profile.firstName}{' '}
        {data.userCode.user.profile.lastName}
      </Grid>
      <Grid item xs={12} sm={12}>
        <strong>Código:</strong> {data.userCode.code.code}
      </Grid>
    </Grid>
  );

  const infoReserva = (
    <Grid container>
      {/* {!isMatchArea && (
        <Grid item xs={12} sm={12}>
          <strong>Área:</strong> {data.subarea.area.name}
        </Grid>
      )} */}
      <Grid item xs={12} sm={12}>
        <strong> Restaurante:</strong>{' '}
        {data.restaurant.name}
      </Grid>
      <Grid item xs={12} sm={12}>
        <strong> Numero de personas:</strong>{' '}
        {data.participantsCount}
      </Grid>
      <Grid item xs={12} sm={12}>
        <strong>Fecha:</strong>{' '}
        {getDateFormated(data.entryTime, 'DD-MM-YYYY', timezone)}
      </Grid>
      <Grid item xs={12} sm={12}>
        <strong>Hora de entrada:</strong>{' '}
        {getDateFormated(data.entryTime, 'HH:mm a', timezone)}
      </Grid>
      <Grid item xs={12} sm={12}>
        <strong>Hora de sàlida:</strong>{' '} 
        {getDateFormated(data.departureTime, 'HH:mm a', timezone)}
      </Grid>
      <Grid item xs={12} sm={12}>
        <strong>Ocasión:</strong> {' '}
        {data.occasion || 'Sin ocasión'}
      </Grid>
      <Grid item xs={12} sm={12}>
        <strong>Comentarios:</strong> {' '}
        {data.comments || 'Sin comentarios'}
      </Grid>
    </Grid>
  );
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Avatar
          alt={data.userCode.user.profile.avatar}
          sx={{ width: 80, height: 80 }}
          src={data.userCode.user.profile.avatar}
        />
        {infoUser}
      </Grid>
      <Grid item xs={12} sm={6}>
        {infoReserva}
      </Grid>
      {/* {(isPrice || isAreaExtras) && (
        <Grid item xs={12} sm={12}>
          {extrasWidget}
        </Grid>
      )} */}
      {/* <Grid item xs={12} sm={12}>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </Grid> */}
    </Grid>
  );
};

export default DetallesReservasRestaurante;
