import {
  GETALL_MODULOS,
  LOADING_GETALL_MODULOS,
  SET_ACTIVO_MODULOS,
  SET_LOGOUT,
  CLEAR_ALL,
  PARAMS_MODULOS,
} from '../../store/Types';

const initialState = {
  all: [],
  loadingGetAll: false,
  meta: null,
  links: null,
  activo: null,
  params: {
    entityId: null,
    sort: 'name',
    sortDirection: 'ASC',
  },
};

export const modulosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_MODULOS:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case SET_ACTIVO_MODULOS:
      return {
        ...state,
        activo: action.payload,
      };
    case PARAMS_MODULOS:
      return {
        ...state,
        params: action.payload,
      };
    case LOADING_GETALL_MODULOS:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case SET_LOGOUT || CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
