import {
  Avatar,
  Box,
  Card,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { CYAN_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';
import { SU } from '../../../constants/ConfigConst';
import { dateformat, getNameFromUserCode } from '../../../helpers';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { isManagerUserCode } from '../../modulos/modules.helpers';

const InfoClasesComponent = ({ setOpenModalForm, setOpenModalFormTeacher }) => {
  const clasesStore = Redux.useSelector((state) => state.clases);
  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const clasesDocentesStore = Redux.useSelector(
    (state) => state.clasesDocentes
  );

  const docente = clasesDocentesStore.activo || null;

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager =
    !isRoot &&
    isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);
  const activo = clasesStore.activo;
  return (
    <Paper elevation={2}>
      <CardMedia
        sx={{
          height: 200,
          width: '100%',
          position: 'relative',
        }}
        image={activo?.imageUrl}
        title={activo?.name}
      ></CardMedia>
      <Grid container spacing="1" sx={{ p: 2 }}>
        <Grid item md={12} align="center">
          {/* <img
                    src={activo?.image_url}
                    width="100%"
                    height='100'
                    onClick={() => setOpenModalForm(true)}
                /> */}
        </Grid>
        <Grid item md={12} align="center">
          <Typography variant="h6" sx={{ textTransform: 'uppercase' }}>
            {activo?.name}
          </Typography>
        </Grid>
        {/* <Grid item md={12} align='center'>
                <Typography variant="h6"> {activo?.entity_code}</Typography>
            </Grid>
            <Grid item md={12} align='center'>
                <Typography variant="h5">{profile.profile_type}</Typography>
            </Grid> */}
        <Grid item md={12} align="center">
          <Divider textAlign="left" sx={{ mt: 1, mb: 1 }}>
            Detalles
          </Divider>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Tipo: </strong>
            {activo?.type.name}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Categoría: </strong>
            {activo?.category.name}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Descripción: </strong>
            {activo?.description}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Estatus: </strong>
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={activo?.active ? 'Activo' : 'Bloqueado'}
                size="small"
                sx={{
                  backgroundColor: activo?.active ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          </Typography>
        </Grid>

        {!isManager && (
          <Grid item md={6} sx={{ mt: 3 }} align="center">
            <BotonNuevoComponent
              click={() => {
                setOpenModalForm(true);
              }}
              text="EDITAR"
              morado
            />
          </Grid>
        )}

        {!isManager && (
          <Grid item md={6} sx={{ mt: 3 }} align="center">
            <BotonNuevoComponent
              click={() => {
                setOpenModalFormTeacher(true);
              }}
              text="AGREGAR PROFESOR"
              morado
            />
          </Grid>
        )}

        {
          docente && (
            <>
              <Grid item md={12} align="center" marginTop={3}>
                <Divider textAlign="left" sx={{ mt: 1, mb: 1 }}>
                  Profesor Activo
                </Divider>
              </Grid>
              
              <Card key={docente.id} sx={{ p: 2, m: 1 }}>
                <Grid
                  item
                  md={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    alt={docente.userCode?.avatar}
                    src={docente.userCode?.avatar}
                    sx={{ width: 100, height: 100 }}
                  />
                  <Box sx={{ mt: 1, ml: 3 }}>
                    <Grid item md={12} align="left">
                      <Typography variant="body">
                        <strong>Nombre: </strong>
                        {getNameFromUserCode(docente.userCode)}
                      </Typography>
                    </Grid>
                    <Grid item md={12} align="left">
                      <Typography variant="body">
                        <strong>Correo: </strong>
                        {docente.userCode?.user.email}
                      </Typography>
                    </Grid>
                    <Grid item md={12} align="left">
                      <Typography variant="body">
                        <strong>Teléfono: </strong>
                        {docente.userCode?.user.profile.phone}
                      </Typography>
                    </Grid>
                    <Grid item md={12} align="left">
                      <Typography variant="body">
                        <strong>Identificación: </strong>
                        {docente.userCode?.user.profile.typeDocument}-
                        {docente.userCode?.user.profile.identificationNumber}
                      </Typography>
                    </Grid>
                    <Grid item md={12} align="left">
                      <Typography variant="body">
                        <strong>Código: </strong>
                        {docente.userCode?.code.code}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item md={12} align="left">
                  <Typography variant="body">
                    <strong>Fecha de inicio: </strong>
                    {dateformat(docente?.startDate, {})}
                  </Typography>
                </Grid>
                <Grid item md={12} align="left">
                  <Typography variant="body">
                    <strong>Fecha de finalización: </strong>
                    {dateformat(new Date(docente?.endDate), {})}
                  </Typography>
                </Grid>

                <Grid item md={12} align="left">
                  <Typography variant="body">
                    <strong>Costo: </strong>
                    {docente?.price ?? 0}
                  </Typography>
                </Grid>
                <Grid item md={12} align="left">
                  <Typography variant="body">
                    <strong>Edades permitidas: </strong>
                    {docente?.ages}
                  </Typography>
                </Grid>
                <Grid item md={12} align="left">
                  <Typography variant="body">
                    <strong>Puestos disponibles: </strong>
                    {docente?.numberSpots}
                  </Typography>
                </Grid>
                <Grid item md={12} align="left">
                  <Typography variant="body">
                    <strong>Nivel: </strong>
                    {docente?.level}
                  </Typography>
                </Grid>
                <Grid item md={12} align="left">
                  <Typography variant="body">
                    <strong>Horarios: </strong>
                    {docente?.schedules.map((s) => {
                      return (
                        <div key={s.id} style={{ marginLeft: 20 }}>
                          <Typography variant="body">
                            {`${s.day}: ${s.schedules.join(', ')}`}
                          </Typography>
                        </div>
                      );
                    })}
                  </Typography>
                </Grid>
                
              </Card>
              {!isManager && (
                <Grid item md={6} sx={{ mt: 3 }} align="center">
                  <BotonNuevoComponent
                    click={() => {
                      setOpenModalFormTeacher(null);
                    }}
                    text="Cerrar"
                    morado
                  />
                </Grid>
              )}
            </>
          )
        }


          

        {/* {clasesDocentesStore.all?.map((docente) => {
          return (
            <Card key={docente.id} sx={{ p: 2, m: 1 }}>
              <Grid
                item
                md={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  alt={docente.userCode?.avatar}
                  src={docente.userCode?.avatar}
                  sx={{ width: 100, height: 100 }}
                />
                <Box sx={{ mt: 1, ml: 3 }}>
                  <Grid item md={12} align="left">
                    <Typography variant="body">
                      <strong>Nombre: </strong>
                      {getNameFromUserCode(docente.userCode)}
                    </Typography>
                  </Grid>
                  <Grid item md={12} align="left">
                    <Typography variant="body">
                      <strong>Correo: </strong>
                      {docente.userCode?.user.email}
                    </Typography>
                  </Grid>
                  <Grid item md={12} align="left">
                    <Typography variant="body">
                      <strong>Teléfono: </strong>
                      {docente.userCode?.user.profile.phone}
                    </Typography>
                  </Grid>
                  <Grid item md={12} align="left">
                    <Typography variant="body">
                      <strong>Identificación: </strong>
                      {docente.userCode?.user.profile.typeDocument}-
                      {docente.userCode?.user.profile.identificationNumber}
                    </Typography>
                  </Grid>
                  <Grid item md={12} align="left">
                    <Typography variant="body">
                      <strong>Código: </strong>
                      {docente.userCode?.code.code}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>

              <Grid item md={12} align="left">
                <Typography variant="body">
                  <strong>Fecha de inicio: </strong>
                  {dateformat(docente?.startDate, {})}
                </Typography>
              </Grid>
              <Grid item md={12} align="left">
                <Typography variant="body">
                  <strong>Fecha de finalización: </strong>
                  {dateformat(new Date(docente?.endDate), {})}
                </Typography>
              </Grid>

              <Grid item md={12} align="left">
                <Typography variant="body">
                  <strong>Costo: </strong>
                  {docente?.price ?? 0}
                </Typography>
              </Grid>
              <Grid item md={12} align="left">
                <Typography variant="body">
                  <strong>Edades permitidas: </strong>
                  {docente?.ages}
                </Typography>
              </Grid>
              <Grid item md={12} align="left">
                <Typography variant="body">
                  <strong>Puestos disponibles: </strong>
                  {docente?.numberSpots}
                </Typography>
              </Grid>
              <Grid item md={12} align="left">
                <Typography variant="body">
                  <strong>Nivel: </strong>
                  {docente?.level}
                </Typography>
              </Grid>
              <Grid item md={12} align="left">
                <Typography variant="body">
                  <strong>Horarios: </strong>
                  {docente?.schedules.map((s) => {
                    return (
                      <div key={s.id} style={{ marginLeft: 20 }}>
                        <Typography variant="body">
                          {`${s.day}: ${s.schedules.join(', ')}`}
                        </Typography>
                      </div>
                    );
                  })}
                </Typography>
              </Grid>

              {!isManager && (
                <Grid item md={6} sx={{ mt: 3 }} align="center">
                  <BotonNuevoComponent
                    click={() => {
                      setOpenModalFormTeacher(docente.id);
                    }}
                    text="EDITAR"
                    morado
                  />
                </Grid>
              )}
            </Card>
          );
        })} */}
        {/* <Grid item md={6} sx={{ mt: 3 }}>
                    <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => {
                            // setOpenModalForm(true)
                        }}
                    >
                        Bloquear
                    </Button>
                </Grid> */}
      </Grid>
    </Paper>
  );
};

export default InfoClasesComponent;
