import React from "react";
import ContainerTemplate from "../../components/template/Container";
import InfoEntidadesView from "./InfoEntidadesView";
import * as ROUTES from "../../router/Routes";

const EntidadesInfoPage = () => {
  return (
    <ContainerTemplate
      active={ROUTES.entidadesInfo}
      routes={[ROUTES.entidades]}
    >
      <InfoEntidadesView />
    </ContainerTemplate>
  );
};

export default EntidadesInfoPage;
