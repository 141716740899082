import * as React from 'react';
import * as Redux from 'react-redux';
import { Grid, Typography, Paper, Divider } from '@mui/material';
import { GENDER_ENUM } from '../../../constants/enumsConst';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { dateformat } from '../../../helpers';
import QRCode from 'react-qr-code';
import { SU } from '../../../constants/ConfigConst';

const InfoUsuariosComponent = ({ setOpenModalForm }) => {
  const loginStore = Redux.useSelector((state) => state.login);
  const usuariosStore = Redux.useSelector((state) => state.usuarios);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const isPublic = entidadesStore.activo?.isPublic;
  const activo = usuariosStore.activo;
  const profile = usuariosStore.activo.profile;
  return (
    <Paper elevation={2} align="center" sx={{ p: 2 }}>
      <Grid container spacing="1">
        <Grid item md={12} align="center">
          <img
            alt="avatarusuario"
            src={profile.avatar}
            width="70%"
            onClick={() => setOpenModalForm(true)}
          />
        </Grid>
        <Grid item md={12} align="center">
          <Typography variant="h6" sx={{ textTransform: 'uppercase' }}>
            {profile.firstName} {profile.lastName}
          </Typography>
        </Grid>
        <Grid item md={12} align="center">
          <Typography variant="h6">
            {profile.typeDocument}-{profile.identificationNumber}
          </Typography>
        </Grid>
        <Grid item md={12} align="center">
          <Divider textAlign="left" sx={{ mt: 1, mb: 1 }}>
            Detalles
          </Divider>
        </Grid>
        {/* <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Nombre: </strong>
            <label style={{ textTransform: "uppercase" }}>
              {profile.firstName}
            </label>
          </Typography>
        </Grid> */}
        {/* {profile.middleName === null ? null : (
          <Grid item md={12} align="left">
            <Typography variant="body">
              <strong>Segundo nombre: </strong>
              <label style={{ textTransform: "uppercase" }}>
                {profile.middleName}
              </label>
            </Typography>
          </Grid>
        )} */}
        {/* <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Apellido: </strong>
            <label style={{ textTransform: "uppercase" }}>
              {profile.lastName}
            </label>
          </Typography>
        </Grid> */}
        {/* {profile.middleLastName === null ? null : (
          <Grid item md={12} align="left">
            <Typography variant="body">
              <strong>Segundo apellido: </strong>
              <label style={{ textTransform: "uppercase" }}>
                {profile.middleLastName}
              </label>
            </Typography>
          </Grid>
        )} */}
        <Grid item md={12} align="left">
          <Typography variant="body" sx={{ wordBreak: 'break-word' }}>
            <strong>Correo: </strong>
            {activo.email}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Teléfono: </strong>
            {profile.phone}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Cumpleaño: </strong>
            {dateformat(profile.birthday, {})}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Género: </strong>
            {GENDER_ENUM[profile.gender]}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Estatus: </strong>
            {activo.status}
          </Typography>
        </Grid>
        {!isPublic && (
          <Grid item md={12} sx={{ mt: 2, mb: 2 }}>
            <BotonNuevoComponent
              click={() => {
                setOpenModalForm(true);
              }}
              text="EDITAR"
              morado
            />
          </Grid>
        )}
        {loginStore.rol === SU ? (
          <Grid item md={12} align="center">
            <QRCode value={profile.qrCode} style={{ width: '50%' }} />
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
};

export default InfoUsuariosComponent;
