import { tryCatchHelper } from '../../helpers';
import { getAllUsuariosAction } from '../usuarios/UsuariosAction';
import { updateUserCodesService } from './UserCodesService';

export const updateUserCodesAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    const res = await updateUserCodesService(id, data);
    addNotification(res.data.message ?? 'Código del usuario actualizado.');
    await getAllUsuariosAction(dispatch, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return null;
  }
};
