import * as React from 'react';
import * as Redux from 'react-redux';
import MUIDataTable from 'mui-datatables';
import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Tooltip,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import {
  assignAllCodesAction,
  deleteIotPuertaAction,
  deletePuertasAction,
  getAllPuertasAction,
  saveIotPuertaAction,
  savePuertasAction,
  setActivoPuertasAction,
} from '../PuertasAction';
import ActionsList from '../../../components/ActionsList';
import * as CONST from '../../../constants/FieldsConst';
import { Modal } from '../../../components';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import {
  CYAN_COLOR,
  MORADO_COLOR,
  AZUL_COLOR,
  NEGRO_COLOR,
} from '../../../constants/ColorsConst';
import { SU } from '../../../constants/ConfigConst';
import LoadingComponent from '../../../components/Loading';
import { FormControl } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { getAllIotsAction } from '../../iots/IotsAction';
import { accessTypeAllowedLabel } from '../enum/access-type-allowed.enum';
import { useNotification } from '../../../helpers/notification';
import { puertas } from '../PuertasService';
import { downloadExcelFile } from '../../../helpers/exportHelper';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import { isManagerUserCode } from '../../modulos/modules.helpers';

const ListadoPuertas = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();
  const { addNotification } = useNotification();

  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const puertasStore = Redux.useSelector((state) => state.puertas);
  const iotsStore = Redux.useSelector((state) => state.iots);

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager = !isRoot && isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isToggleActive, setIsToggleActive] = React.useState(false);
  const [modelAssignIot, setModelAssignIot] = React.useState(false);
  const [modelAssignAllCodes, setModelAssignAllCodes] = React.useState(false);
  const [loadingAsignarIot, setLoadingAsignarIot] = React.useState(false);

  React.useEffect(() => {
    if (puertasStore.all && puertasStore.all.length === 0) {
      getAllPuertasAction(dispatch, entidadesStore.activo.id, null);
    }
    if (iotsStore.all && iotsStore.all.length === 0) {
      getAllIotsAction(dispatch, entidadesStore.activo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [iot, setIot] = React.useState('9999999');

  const _handleToggleActiveClick = async (id) => {
    setLoadingDelete(true);
    await savePuertasAction(
      { dispatch, addNotification },
      {
        active: !puertasStore.activo.active,
      },
      {
        id,
        entityId: entidadesStore.activo.id,
      }
    );
    setIsToggleActive(false);
    setLoadingDelete(false);
  };

  const removeAccessTypeAllowed = async (id, accessTypeAllowed) => {
    let puerta = JSON.parse(
      JSON.stringify(puertasStore.all.find((el) => el.id === id))
    );
    const index = puerta.accessTypeAllowed.indexOf(accessTypeAllowed);
    if (index > -1) {
      puerta.accessTypeAllowed.splice(index, 1);
    }
    setLoadingDelete(true);
    await savePuertasAction(
      { dispatch, addNotification },
      {
        accessTypeAllowed: puerta.accessTypeAllowed,
      },
      {
        id,
        entityId: entidadesStore.activo.id,
      }
    );
    setLoadingDelete(false);
  };

  const [columns, setColumns] = React.useState([
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.NAME,
      label: 'Acceso',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={!isManager ? () => {
                _handleEditClick(tableMeta.rowData[0]);
              } : null}
            >
              {value}
            </NameComponent>
          );
        },
      },
    },
    {
      name: CONST.ACTIVE,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={value ? 'Activo' : 'Bloqueado'}
                size="small"
                sx={{
                  backgroundColor: value ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.IOT,
      label: 'Iot',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return !value ? (
            <Chip
              label="No Asignado"
              size="small"
              sx={{
                backgroundColor: AZUL_COLOR,
                color: 'white',
              }}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '20em',
                pt: 1,
              }}
            >
              <Chip
                key={value.name}
                label={value.name}
                variant="outlined"
                sx={{
                  color: NEGRO_COLOR,
                  borderColor: NEGRO_COLOR,
                  mr: 1,
                  mb: 1,
                  '&:hover': {
                    borderColor: MORADO_COLOR,
                    color: MORADO_COLOR,
                  },
                }}
                onClick={
                  isRoot
                    ? () => {
                        history.push('/iots');
                      }
                    : null
                }
                onDelete={
                  isRoot
                    ? async () => {
                        await deleteIotPuertaAction(
                          { dispatch, addNotification },
                          entidadesStore.activo.id,
                          tableMeta.rowData[0],
                          value.id
                        );
                      }
                    : null
                }
              />
            </Box>
          );
        },
      },
    },
    {
      name: CONST.ACCESS_TYPE_ALLOWED,
      label: 'Tipos de accesos permitidos',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return !value.length ? (
            <Chip
              label="No Asignado"
              size="small"
              sx={{
                backgroundColor: AZUL_COLOR,
                color: 'white',
              }}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '20em',
                pt: 1,
              }}
            >
              {value.map((el) => {
                return (
                  <Chip
                    key={el}
                    label={accessTypeAllowedLabel[el]}
                    variant="outlined"
                    sx={{
                      color: NEGRO_COLOR,
                      borderColor: NEGRO_COLOR,
                      mr: 1,
                      mb: 1,
                      '&:hover': {
                        borderColor: MORADO_COLOR,
                        color: MORADO_COLOR,
                      },
                    }}
                    onDelete={!isManager ? async () => {
                      await removeAccessTypeAllowed(tableMeta.rowData[0], el);
                    } : null}
                  />
                );
              })}
            </Box>
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              align="left"
              sx={{ display: 'flex', flexDirection: 'row', width: '10%' }}
            >
              <ActionsList
                id={tableMeta.rowData[0]}
                onEdit={() => _handleEditClick(tableMeta.rowData[0])}
                onAsignarIot={
                  isRoot && !value.iot
                    ? async () => {
                        await setActivoPuertasAction(dispatch, value);
                        setModelAssignIot(true);
                      }
                    : null
                }
                onDelete={async () => {
                  await setActivoPuertasAction(dispatch, value);
                  setIsDelete(true);
                }}
                onToggleActive={async () => {
                  await setActivoPuertasAction(dispatch, value);
                  setIsToggleActive(true);
                }}
                onAssignAllCodes={async () => {
                  await setActivoPuertasAction(dispatch, value);
                  setModelAssignAllCodes(true);
                }}
                active={value.active}
              />
            </Box>
          );
        },
      },
    },
  ]);

  React.useEffect(() => {
    if (puertasStore.all.length === 0) {
      getAllPuertasAction(dispatch, entidadesStore.activo.id);
    }
    if (loginStore.rol === SU) {
      const index = 2;
      setColumns([
        ...columns.slice(0, index),
        {
          name: CONST.RELAY_NUM,
          label: 'Comando relay',
          options: {
            filter: true,
            sort: true,
          },
        },
        ...columns.slice(index),
      ]);
    }

    

  if (isManager) {
    const columns2 = [...columns];
    columns2.pop();
    setColumns(columns2);
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRows([]);
    puertasStore.all.forEach((el) => {
      setRows((old) => [
        ...old,
        {
          ...el,
          actions: el,
        },
      ]);
    });
  }, [puertasStore.all]);

  const _handleEditClick = async (id) => {
    const puerta = puertasStore.all.find((el) => el.id === id);
    await setActivoPuertasAction(dispatch, puerta);
    setOpenModalForm(true);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deletePuertasAction(
      { dispatch, addNotification },
      id,
      entidadesStore.activo.id
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const _handleAssignAllCodesClick = async (id) => {
    await assignAllCodesAction(
      { dispatch, addNotification },
      entidadesStore.activo.id,
      id
    );
    setModelAssignAllCodes(false);
  };

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onDownload: () => {
      const url = `${puertas}/export`;
      const name = 'puertas';
      const params = { entityId: entidadesStore.activo.id };
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },

    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={puertasStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {puertasStore.activo ? (
        <Modal
          id="modalFormPuertas"
          title={`Eliminar`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este puerta?
              <p>
                <strong>{puertasStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(puertasStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {puertasStore.activo ? (
        <Modal
          id="modalToggleActivo"
          title={`${puertasStore.activo.active ? 'Bloquear' : 'Activar'} ${
            puertasStore.activo.name
          }`}
          open={isToggleActive}
          maxWidth="sm"
          onClose={() => setIsToggleActive(!isToggleActive)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`¿Seguro que quieres ${
                puertasStore.activo.active ? 'Bloquear' : 'Activar'
              } esta puerta?`}
              <p>
                <strong>{puertasStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleToggleActiveClick(puertasStore.activo.id);
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsToggleActive(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {puertasStore.activo ? (
        <Modal
          id="modalAssignIot"
          title={`Asignar iot a ${puertasStore.activo.name}`}
          open={modelAssignIot}
          maxWidth="sm"
          onClose={() => setModelAssignIot(!modelAssignIot)}
        >
          <DialogContent>
            <LoadingComponent
              isLoading={loadingAsignarIot}
              text="Guardando asignación..."
            />
            <DialogContentText id="alert-dialog-slide-description">
              <FormControl fullWidth>
                <Select
                  labelId="puertasSelect"
                  size="small"
                  value={iot}
                  onChange={(e) => {
                    setIot(e.target.value);
                  }}
                  id="puertasSelect"
                >
                  <MenuItem value="9999999">Seleccionar iot...</MenuItem>
                  {iotsStore.all.map((e) => {
                    return (
                      <MenuItem key={`iot${e.id}`} value={e.id}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <BotonNuevoComponent
              click={async () => {
                setLoadingAsignarIot(true);
                const res = await saveIotPuertaAction(
                  { dispatch, addNotification },
                  entidadesStore.activo.id,
                  puertasStore.activo.id,
                  iot
                );
                if (res) {
                  setIot('9999999');
                  setModelAssignIot(false);
                }
                setLoadingAsignarIot(false);
              }}
              disabled={loadingAsignarIot || iot === '9999999'}
              text="GUARDAR"
              morado
            />
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => {
                setLoadingAsignarIot(false);
                setIot('9999999');
                setModelAssignIot(false);
              }}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {puertasStore.activo ? (
        <Modal
          id="modalAssignAllCodes"
          title={`Asignar todos los códigos a ${puertasStore.activo.name}`}
          open={modelAssignAllCodes}
          maxWidth="sm"
          onClose={() => setModelAssignAllCodes(!modelAssignAllCodes)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres asignar todos los códigos a esta puerta?
              <p>
                <strong>{puertasStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleAssignAllCodesClick(puertasStore.activo.id);
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setModelAssignAllCodes(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoPuertas;
