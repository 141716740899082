import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import DocumentosView from './DocumentosView';
import * as ROUTES from '../../../router/Routes';

const DocumentosPage = () => {
  return (
    <ContainerTemplate active={ROUTES.documentos} routes={[]}>
      <DocumentosView />
    </ContainerTemplate>
  );
};

export default DocumentosPage;
