import * as React from 'react';
import * as Redux from 'react-redux';
import {
  Grid,
  Typography,
  Chip,
  CardMedia,
  Box,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText,
} from '@mui/material';
import {
  DRIVER,
  FAMILY,
  MANAGER,
  NANNY,
  OTHER,
  OWNER,
  SECURITY,
  SERVICE,
  TEACHER,
  getCodeType,
} from '../../../constants/enumsConst';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  CYAN_COLOR,
  ERROR_COLOR,
  MORADO_COLOR,
} from '../../../constants/ColorsConst';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateUserCodesAction } from '../../userCodes/UserCodesAction';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { ButtonFile, Modal } from '../../../components';
import LoadingComponent from '../../../components/Loading';
import * as CONST from '../../../constants/FieldsConst';
import { ADMIN, CLIENT, SU } from '../../../constants/ConfigConst';
import { unassignUsuariosAction } from '../UsuariosAction';
import { acceptOnlyImages } from '../../../components/ButtonFile';
import { useNotification } from '../../../helpers/notification';

const cardCode = (key, data, click, clickRemove, isClub) => (
  <Grid key={key} item xs={12} md={6}>
    <Card
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="h5" component="div">
            {data.code.code}
          </Typography>
          <Typography sx={{ mb: 1 }}>
            {getCodeType(data.userType, isClub)}
          </Typography>
          <Chip
            label={data.active ? 'Activo' : 'Bloqueado'}
            sx={{
              backgroundColor: data.active ? CYAN_COLOR : MORADO_COLOR,
              color: 'white',
            }}
          />
        </CardContent>
      </Box>
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          sx={{ width: 151, height: 151 }}
          image={data.avatar}
          alt="Avatar"
        />
        <Box sx={{ position: 'absolute', top: '5px', right: '5px' }}>
          <IconButton
            aria-label=""
            size="10px"
            onClick={click}
            sx={{
              backgroundColor: MORADO_COLOR,
              color: 'white',
              width: '30px',
              height: '30px',
            }}
          >
            <EditIcon sx={{ width: '20px', height: '20px' }} />
          </IconButton>
          {data?.code?.codeType !== CLIENT && (
            <IconButton
              aria-label=""
              size="10px"
              onClick={clickRemove}
              sx={{
                backgroundColor: ERROR_COLOR,
                color: 'white',
                width: '30px',
                height: '30px',
                marginLeft: '5px',
              }}
            >
              <DeleteIcon sx={{ width: '20px', height: '20px' }} />
            </IconButton>
          )}
        </Box>
      </Box>
    </Card>
  </Grid>
);

const CodigoUsuariosComponent = () => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const loginStore = Redux.useSelector((state) => state.login);
  const usuariosStore = Redux.useSelector((state) => state.usuarios);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const a = usuariosStore.activo;

  const [fileImage, setFileImage] = React.useState();
  const [loadingAvatar, setLoadingAvatar] = React.useState(false);

  const [modalAvatar, setModalAvatar] = React.useState(false);
  const [modalRemoveCode, setModalRemoveCode] = React.useState(false);
  const [userCode, setUserCode] = React.useState();
  const [userCodes, setUserCodes] = React.useState([]);

  const isClub = entidadesStore.activo?.entityType?.id === 2;
  const isRoot = loginStore.roles.indexOf(SU) !== -1;
  const isAdmin = loginStore.roles.indexOf(ADMIN) !== -1;

  const _handleUnassignCodeClick = async (data) => {
    setLoadingAvatar(true);
    await unassignUsuariosAction(
      { dispatch, addNotification },
      a.id,
      data.code.id,
      {
        entityId: entidadesStore.activo.id,
      }
    );
    setModalRemoveCode(false);
    setLoadingAvatar(false);
  };

  React.useEffect(() => {
    const fetchData = () => {
      if (a.userCodes && a.userCodes.length > 0) {
        setUserCodes(a.userCodes);
      }
    };
    fetchData();
  }, [a]);

  const isCodeSecurity = userCode?.code?.codeType === SECURITY;
  const isCodeTeacher = userCode?.code?.codeType === TEACHER;
  const isCodeAdmin = userCode?.code?.codeType === ADMIN;
  const isCodeClient = userCode?.code?.codeType === CLIENT;

  return (
    <Grid container spacing={1}>
      {userCodes.map((el, index) => {
        return cardCode(
          index,
          el,
          () => {
            setUserCode(el);
            setModalAvatar(true);
          },
          () => {
            setUserCode(el);
            setModalRemoveCode(true);
          },
          isClub
        );
      })}
      <Modal
        id="modalFormAvatar"
        title={`Avatar ${userCode?.code.code}`}
        open={modalAvatar}
        maxWidth="xs"
        onClose={() => setModalAvatar(!modalAvatar)}
      >
        <DialogContent>
          <LoadingComponent
            isLoading={loadingAvatar}
            text="Guardando imagén..."
          />
          <ButtonFile
            id={'avatar'}
            onFile={(file) => setFileImage(file)}
            file={fileImage}
            text="Subir imagén"
            sxc={{ marginTop: '5px' }}
            size="50%"
            name={'avatar'}
            onDelete={() => setFileImage(null)}
            src={fileImage ? null : userCode?.avatar}
            accept={acceptOnlyImages}
          />
          <Grid item xs={12} md={12} mt={3}>
            <FormControl size="small" sx={{ width: '100%' }}>
              <InputLabel id="selectUserType">Tipo de perfil</InputLabel>
              <Select
                labelId="selectUserType"
                size="small"
                value={userCode?.userType}
                label="Tipo de perfil"
                disabled={isCodeSecurity || isCodeAdmin || isCodeClient || isCodeTeacher}
                onChange={(e) =>
                  setUserCode({ ...userCode, userType: e.target.value })
                }
                id={CONST.TYPE}
              >
                {isCodeAdmin && (<MenuItem value={ADMIN}>Administrador</MenuItem>)}
                
                {isCodeAdmin && (<MenuItem value={MANAGER}>Manager</MenuItem>)}
                {isCodeTeacher && (<MenuItem value={TEACHER}>Profesor</MenuItem>)}
                {isCodeClient && <MenuItem value={CLIENT}>Cliente</MenuItem>}
                <MenuItem value={OWNER}>{getCodeType(OWNER, isClub)}</MenuItem>
                {(isAdmin || isRoot) && isCodeSecurity && (
                  <MenuItem value={SECURITY}>Seguridad</MenuItem>
                )}
                {(isAdmin || isRoot) && isCodeSecurity && (
                  <MenuItem value={SECURITY}>Seguridad</MenuItem>
                )}
                <MenuItem value={FAMILY}>
                  {getCodeType(FAMILY, isClub)}
                </MenuItem>
                <MenuItem value={SERVICE}>Trabajador doméstico</MenuItem>
                <MenuItem value={NANNY}>Niñera</MenuItem>
                <MenuItem value={DRIVER}>Chofer</MenuItem>
                {!isClub && (
                  <MenuItem value={OTHER}>
                    {getCodeType(OTHER, isClub)}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} mt={3}>
            <FormControl fullWidth>
              <InputLabel id={`label-${CONST.ACTIVE}`}>Estatus</InputLabel>
              <Select
                labelId={`label-${CONST.ACTIVE}`}
                size="small"
                value={userCode?.active}
                label="Estatus"
                onChange={(e) =>
                  setUserCode({ ...userCode, active: e.target.value })
                }
                id={CONST.ACTIVE}
              >
                <MenuItem value={true}>Activo</MenuItem>
                <MenuItem value={false}>Bloqueado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BotonNuevoComponent
            click={async () => {
              setLoadingAvatar(true);
              const res = await updateUserCodesAction(
                { dispatch, addNotification },
                userCode.id,
                {
                  avatar: fileImage,
                  userType: userCode.userType,
                  active: userCode.active,
                },
                {
                  entityId: entidadesStore.activo.id,
                }
              );

              let list = [];
              if (res) {
                userCodes.forEach((el) => {
                  if (el.id === res.id) {
                    list.push({
                      ...el,
                      ...res,
                    });
                  } else {
                    list.push(el);
                  }
                });
              }
              setUserCodes(list);
              setFileImage(null);
              setModalAvatar(false);
              setLoadingAvatar(false);
            }}
            text="GUARDAR"
            morado
          />
          <Button
            sx={{ color: MORADO_COLOR }}
            onClick={() => {
              setLoadingAvatar(false);
              setModalAvatar(false);
              setFileImage(null);
            }}
          >
            CANCELAR
          </Button>
        </DialogActions>
      </Modal>
      <Modal
        id="modalRemoveCOde"
        title={`Eliminar de usuario ${userCode?.code.code}`}
        open={modalRemoveCode}
        maxWidth="sm"
        onClose={() => setModalRemoveCode(!modalRemoveCode)}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-usuario">
            ¿Seguro que desea eliminar el código de usuario?
            <p>
              <strong>
                {userCode?.code.code} - {userCode?.userType}
              </strong>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            onClick={async () => {
              await _handleUnassignCodeClick(userCode);
            }}
          >
            ELIMINAR
          </Button>
          <Button
            sx={{ color: MORADO_COLOR }}
            onClick={() => setModalRemoveCode(false)}
          >
            CANCELAR
          </Button>
        </DialogActions>
      </Modal>
    </Grid>
  );
};

export default CodigoUsuariosComponent;
