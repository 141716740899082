import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const options = {
  chart: {
    type: 'pie',
  },
  title: {
    text: 'Rendimiento',
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f}%',
      },
    },
  },
  series: [
    {
      name: 'Rendimiento',
      data: [
        {
          name: 'Trimestre 1',
          y: 48,
        },
        {
          name: 'Trimestre 2',
          y: 68,
        },
        {
          name: 'Trimestre 3',
          y: 78,
        },
        {
          name: 'Trimestre 4',
          y: 78,
        },
      ],
    },
  ],
};

const TrimestrePieChartPanelControl = () => (
  <HighchartsReact highcharts={Highcharts} options={options} />
);

export default TrimestrePieChartPanelControl;
