import React from "react";
import * as Redux from "react-redux";
import ContainerTemplate from "../../components/template/Container";
import ModulosView from "./ModulosView";
import * as ROUTES from "../../router/Routes";
import { SU } from "../../constants/ConfigConst";

const ModulosModule = () => {
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const loginStore = Redux.useSelector((state) => state.login);

  const isRoot =
    loginStore.roles.indexOf(SU) !== -1 && entidadesStore.activo == null;

  return (
    <ContainerTemplate
      active={isRoot ? ROUTES.appModules : ROUTES.modulos}
      routes={[]}
    >
      <ModulosView isGlobal={isRoot} />
    </ContainerTemplate>
  );
};

export default ModulosModule;
