export const ONPASS = "ONPASS";
export const TOKEN = `${ONPASS}Token`;
export const EMAIL = `${ONPASS}Email`;
export const USER = `${ONPASS}User`;
export const ROL = `${ONPASS}Rol`;
export const CLIENTH = `${ONPASS}Client`;
export const UIDH = `${ONPASS}Uid`;
export const I18N = `${ONPASS}I18n`;
export const I18N_LNG = `${ONPASS}I18Lng`;
export const REMEMBER_ME = `${ONPASS}RemenberMe`;
export const PERSIST = `persist:${ONPASS}`;

export const SU = `superAdmin`;
export const ADMIN = `admin`;
export const ENTITY = `entity`;
export const CLIENT = `client`;
