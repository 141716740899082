import React from "react";
import PropTypes from "prop-types";
import { Alert, AlertTitle, Grid } from "@mui/material";

const AlertMsgComponent = ({ onClose, alert }) => {
  return alert ? (
    <Grid item md={12} xs={12}>
      <Alert
        variant={alert.variant}
        severity={alert.severity ?? "info"}
        onClose={onClose}
      >
        <AlertTitle>{alert.title}</AlertTitle>
        {alert.message}
      </Alert>
    </Grid>
  ) : null;
};

AlertMsgComponent.propTypes = {
  alert: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default AlertMsgComponent;
