import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import * as ROUTES from '../../../router/Routes';
import Eventosview from './EventosView';

const EventosModule = () => {
  return (
    <ContainerTemplate active={ROUTES.eventos} routes={[]}>
      <Eventosview />
    </ContainerTemplate>
  );
};

export default EventosModule;
