import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import AppBarTemplate from './appbar/Appbar';
import DrawerTemplate from './drawer/Drawer';

export const mdTheme = createTheme();

const Template = ({ children }) => {
  const [open, setOpen] = React.useState(true);

  const openDrawer = 'openDrawer';
  const toggleDrawer = () => {
    setOpen(!open);
    localStorage.setItem(openDrawer, !open);
  };

  React.useEffect(() => {
    const od = localStorage.getItem(openDrawer);
    setOpen(od ? od === 'true' : true);
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarTemplate open={open} toggleDrawer={toggleDrawer} />
        <DrawerTemplate open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[900],
            flexGrow: 1,
            minHeight: '100vh',
            overflow: 'none',
          }}
        >
          <Toolbar />
          <Container
            maxWidth={false}
            sx={{
              mt: 2,
              mb: 2,
            }}
          >
            {children}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Template;
