import React from "react";
import ContainerTemplate from "../../components/template/Container";
import InvitadosView from "./InvitadosView";
import * as ROUTES from "../../router/Routes";

const InvitadosModule = () => {
  return (
    <ContainerTemplate active={ROUTES.invitados} routes={[]}>
      <InvitadosView />
    </ContainerTemplate>
  );
};

export default InvitadosModule;
