import * as React from 'react';
import * as Redux from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { Modal } from '../../components';
import ListadoIotTuneles from './components/ListadoIotTuneles';
import FormIotTuneles from './components/FormIotTuneles';
import {
  getAllIotTunelesAction,
  setActivoIotTunelesAction,
} from './IotTunelesAction';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { checkRootAccess } from '../modulos/modules.helpers';

const IotTunelesView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const iotTunelesStore = Redux.useSelector((state) => state.iotTuneles);

  const loginStore = Redux.useSelector((state) => state.login);
  checkRootAccess(loginStore);

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoIotTunelesAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        {iotTunelesStore.all.length > 0 && (
          <BotonNuevoComponent
            click={async () => {
              await setActivoIotTunelesAction(dispatch, null);
              await getAllIotTunelesAction(
                dispatch,
                entidadesStore.activo.id,
                null
              );
            }}
            disabled={iotTunelesStore.loadingGetAll}
            white
            text={
              iotTunelesStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'
            }
          />
        )}
      </Grid>
      <Grid item md={12}>
        <ListadoIotTuneles setOpenModalForm={(val) => setOpenModalForm(val)} />
      </Grid>
      <Modal
        id="modalFormIotTuneles"
        title={`${iotTunelesStore.activo ? 'Editar' : 'Nuevo'} iot`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {iotTunelesStore.activo ? (
          <Typography variant="h6">
            {iotTunelesStore.activo.nombres} {iotTunelesStore.activo.apellidos}
          </Typography>
        ) : null}
        <FormIotTuneles
          activo={iotTunelesStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default IotTunelesView;
