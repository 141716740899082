import { Link, Typography } from "@mui/material";

const CopyrightTemplate = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            Copyright {' ©'}
            <Link color="inherit">
                On Pass
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};

export default CopyrightTemplate;