import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import * as ROUTES from '../../../router/Routes';
import RestaurantesView from './RestaurantesView';

const RestaurantesModule = () => {
  return (
    <ContainerTemplate active={ROUTES.restaurantes} routes={[]}>
      <RestaurantesView />
    </ContainerTemplate>
  );
};

export default RestaurantesModule;
