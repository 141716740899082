import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { SU } from '../../constants/ConfigConst';
import { DASHBOARD_ENUM } from '../../constants/enumsConst';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../modulos/modules.helpers';
import { getDashboardAction } from './DashboardAction';

const DashboardView = () => {
  const dispatch = Redux.useDispatch();
  const history = useHistory();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const dashboardStore = Redux.useSelector((state) => state.dashboard);
  const loginStore = Redux.useSelector((state) => state.login);
  const isRoot = loginStore.roles.indexOf(SU) !== -1;

  React.useEffect(() => {
    async function fetchData() {
      if (entidadesStore.activo) {
        setTimeout(async () => {
          await getDashboardAction(dispatch, {
            entityId: entidadesStore.activo ? entidadesStore.activo.id : null,
          });
        }, 100);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entidadesStore.activo]);

  const Card = (icon, text, data, to, key) => (
    <Grid item xs={12} md={3} lg={3}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* {icon} */}
          <Typography variant="body" sx={{ fontWeight: '700' }}>
            {text}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Typography variant="h4"> {data} </Typography>
          {to ? (
            <Link
              to={to}
              style={{
                color: 'black',
                textDecoration: 'none',
              }}
            >
              {/* <pre>{JSON.stringify(key)}</pre>
              {
                ['users', 'reservations', 'guests', 'areas']
              } */}
              {((key === 'users' && checkModuleAccess('user', loginStore)) ||
                (key === 'reservations' &&
                  checkModuleAccess('reservation', loginStore)) ||
                (key === 'guests' &&
                  checkModuleAccess('invitation', loginStore)) ||
                (key === 'areas' &&
                  checkModuleAccess('reservation', loginStore)) ||
                isRoot) && (
                <BotonNuevoComponent
                  click={() => {
                    history.push(to);
                  }}
                  morado
                  text="Ver más"
                />
              )}
            </Link>
          ) : null}
        </Box>
      </Paper>
    </Grid>
  );

  const indexs = dashboardStore.dashboard
    ? Object.keys(dashboardStore.dashboard)
    : null;

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        {dashboardStore.loading ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress sx={{ mb: dashboardStore.dashboard > 0 ? 2 : 0 }} />
            {dashboardStore.dashboard === null ? (
              <Typography variant="h6">Cargando estadísticas...</Typography>
            ) : null}
          </Box>
        ) : null}
      </Grid>
      {indexs
        ? indexs.map((e) => {
            return Card(
              DASHBOARD_ENUM[e].icon,
              DASHBOARD_ENUM[e].title,
              dashboardStore.dashboard[e],
              DASHBOARD_ENUM[e].to,
              e
            );
          })
        : null}
    </Grid>
  );
};

export default DashboardView;
