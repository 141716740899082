import axios from "axios";
import { token } from "../services/Apis";
import { CircularProgress } from "@mui/material";


export const downloadExcelFile = async (url, params, name = 'data', addNotification = () => {}) => {
  if (params.page) delete params.page;
  if (params.limit) delete params.limit;
  // add spinner;
  const anchorOrigin = {
    vertical: 'top',
    horizontal: 'right'
  }
  const loadingNotification = addNotification('Descargando archivo...', {
    variant: 'info',
    persist: true,
    anchorOrigin,
    action: (
      <div className="spinner-border spinner-border-sm text-info" role="status">
        <CircularProgress size={20} />
      </div>
    )
  });
  await axios({
    url,
    method: 'GET',
    responseType: 'blob', // Indica que la respuesta es un archivo
    headers: await token({}),
    params
  })
    .then((response) => {
      loadingNotification?.close();
      addNotification('Archivo descargado', { anchorOrigin });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}.xlsx`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      loadingNotification?.close();
      addNotification('No hay datos para exportar', {anchorOrigin, variant: 'error'});
    });
  
};