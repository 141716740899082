import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const iotsApi = `${baseApi}/iots`;

export const getAllIotsService = async (entityId) => {
  const res = await axios.get(iotsApi, {
    headers: await token({}),
    params: {
      entityId,
    },
  });
  return res;
};

export const saveIotsService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${iotsApi}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${iotsApi}`, data, {
      headers: await token({}),
    });
  }
  return res;
};

export const getOneIotsService = async (id) => {
  const res = await axios.get(`${iotsApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteIotsService = async (id, entityId) => {
  const res = await axios.delete(`${iotsApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const setUpIotsService = async (id, dto) => {
  try {
    return await axios
      .post(`${iotsApi}/${id}/set-up`, dto, {
        headers: await token({}),
      })
      .then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

export const checkConnectionIotService = async (id) => {
  try {
    return await axios
      .get(`${iotsApi}/${id}/check-connection`, {
        headers: await token({}),
      })
      .then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

export const saveTunnelIotService = async (idIot, idTunnel) => {
  const res = await axios.post(
    `${iotsApi}/${idIot}/add-tunnel/${idTunnel}`,
    {},
    {
      headers: await token({}),
    }
  );
  return res.data;
};

export const deleteTunnelIotService = async (idIot, idTunnel) => {
  const res = await axios.delete(
    `${iotsApi}/${idIot}/remove-tunnel/${idTunnel}`,
    {
      headers: await token({}),
    }
  );
  return res.data;
};
