import {
  Avatar,
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../../components';
import ActionsList from '../../../../components/ActionsList';
import LoadingComponent from '../../../../components/Loading';
import { CYAN_COLOR, MORADO_COLOR } from '../../../../constants/ColorsConst';
import * as CONST from '../../../../constants/FieldsConst';
import { dateformat } from '../../../../helpers';
import { downloadExcelFile } from '../../../../helpers/exportHelper';
import { useNotification } from '../../../../helpers/notification';
import { textLabelsTable } from '../../../invitados/components/ListadoInvitados';
import { NameComponent } from '../../../usuarios/components/ListadoUsuarios';

import {
  deleteUsersDeleteAction,
  getAllUsersDeleteAction,
  saveUsersDeleteAction,
  setActivoUsersDeleteAction,
} from '../UsersDeleteAction';
import { usersDeleteApi } from '../UsersDeleteService';
import { deleteUsuariosAction } from '../../../usuarios/UsuariosAction';

const ListadoUsersDelete = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const usersDeleteStore = Redux.useSelector((state) => state.usersDelete);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isToggleActive, setIsToggleActive] = React.useState(false);

  React.useEffect(() => {
    if (usersDeleteStore.all && usersDeleteStore.all.length === 0) {
      getAllUsersDeleteAction(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRows([]);
    usersDeleteStore.all.forEach((el) => {
      setRows((old) => [
        ...old,
        {
          ...el,
          [CONST.DATE]: dateformat(el.date, {}),
          createdAt: dateformat(el.createdAt, {}),
          avatar: el.user.profile?.avatar,
          identificationNumber: el.user.profile ? `${el.user.profile?.typeDocument}-${el.user.profile?.identificationNumber}` : null,
          userData: {
            name: `${el.user.profile?.firstName} ${el.user.profile?.lastName}`,
            email: el.user.email,
          },
          active: el.active ? 'Activo' : 'Bloqueado',
          actions: el,
        },
      ]);
    });
  }, [usersDeleteStore.all]);

  console.log('usersDeleteStore', usersDeleteStore.all)

  const _handleEditClick = async (id) => {
    const data = usersDeleteStore.all.find((el) => el.id === id);
    await setActivoUsersDeleteAction(dispatch, data);
    setOpenModalForm(true);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteUsuariosAction(
      { dispatch, addNotification },
      id,
      {}
    );
    await getAllUsersDeleteAction(dispatch);
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const _handleToggleActiveClick = async (id) => {
    setLoadingDelete(true);
    await saveUsersDeleteAction(
      { dispatch, addNotification },
      entidadesStore.activo.id,
      id,
      {
        active: !usersDeleteStore.activo.active,
      }
    );
    setIsToggleActive(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'avatar',
      label: 'Foto',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box sx={{ width: '3em' }}>
              <Avatar
                alt={`avatr-${tableMeta.rowData[0]}`}
                sx={{ width: 30, height: 30 }}
                src={value}
              />
            </Box>
          );
        },
      },
    },
    {
      name: 'identificationNumber',
      label: 'C.I',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box sx={{ width: '8em' }}>
              {value}
            </Box>
          );
        },
      },
    },
    {
      name: 'userData',
      label: 'Nombre y apellido',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // const texts = value.split("|");
          return (
            <Stack>
              <div style={{fontWeight: 'bold'}}>{value.name}</div>
              <Typography variant="body1" color="initial">
                {value.email}
              </Typography>
            </Stack>
          );
        },
      }
    },
    {
      name: 'createdAt',
      label: 'Fecha de solicitud',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              sx={{
                width: '10em',
              }}
            >
              {value}
            </Box>
          );
        },
      },
    },
    {
      name: 'description',
      label: 'Motivo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              sx={{
                width: '10em',
              }}
            >
              {value}
            </Box>
          );
        },
      },
    },
    {
      name: CONST.ACTIVE,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={value}
                size="small"
                sx={{
                  backgroundColor:
                    value === 'Activo' ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box align="left" sx={{ width: '5em' }}>
              <ActionsList
                id={tableMeta.rowData[0]}
                onDelete={async () => {
                  await setActivoUsersDeleteAction(dispatch, value);
                  setIsDelete(true);
                }}
                active={value.active}
              />
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onSearchChange: (text) => {
      console.log('buscardor', text);
    },
    onDownload: () => {
      const url = `${usersDeleteApi}/export`;
      const name = 'usersDelete';
      const params = { entityId: entidadesStore.activo.id };
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={usersDeleteStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {usersDeleteStore.activo && (
        <Modal
          id="modalFormUsersDelete"
          title={`Eliminar`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este usuario?
              <p>
                <strong>{usersDeleteStore.activo.user.profile?.firstName} {usersDeleteStore.activo.user.profile?.lastName}</strong>
              </p>
              <p> {usersDeleteStore.activo.user.email}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(usersDeleteStore.activo.user.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      )}
    </Grid>
  );
};

export default ListadoUsersDelete;
