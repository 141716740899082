import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import ReferidosView from './ReferidosView';

const ReferidosPage = () => {
  return (
    <ContainerTemplate active={ROUTES.referidos} routes={[]}>
      <ReferidosView />
    </ContainerTemplate>
  );
};

export default ReferidosPage;
