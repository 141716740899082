import React from "react";
import ContainerTemplate from "../../../components/template/Container";
import UsuariosView from "../../usuarios/UsuariosView";
import * as ROUTES from "../../../router/Routes";

const UsersModule = () => {
  return (
    <ContainerTemplate active={ROUTES.users} routes={[]}>
      <UsuariosView isGlobal={true} />
    </ContainerTemplate>
  );
};

export default UsersModule;
