import React from "react";
import ContainerTemplate from "../../components/template/Container";
import PanelControlView from "./PanelControlView";
import * as ROUTES from "../../router/Routes";

const PanelControlModule = () => {
  return (
    <ContainerTemplate active={ROUTES.panelControl} routes={[]}>
      <PanelControlView />
    </ContainerTemplate>
  );
};

export default PanelControlModule;
