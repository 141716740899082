import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import * as Redux from 'react-redux';
import { ButtonFile } from '../../../../components';
import LoadingComponent from '../../../../components/Loading';
import { CLIENT } from '../../../../constants/ConfigConst';
import * as CONST from '../../../../constants/FieldsConst';
import {
  DRIVER,
  FAMILY,
  NANNY,
  OTHER,
  OWNER,
  SECURITY,
  SERVICE,
  getCodeType,
} from '../../../../constants/enumsConst';
import { dateformat } from '../../../../helpers';
import { validFielddHelper } from '../../../../helpers/ValidFieldsHelper';
import { useNotification } from '../../../../helpers/notification';
import BotonNuevoComponent from '../../../entidades/components/BotonNuevo';
import { FechaField } from '../../../usuarios/components/FieldsUsuarios';
import { saveNotificacionesAction } from '../NotificacionesAction';
import { getAllUsuariosShortService } from '../../../usuarios/UsuariosService';
import { sendNotificacionesService } from '../NotificacionesService';

const FormNotificaciones = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const [users, setUsers] = React.useState([]);
  const notificacionesStore = Redux.useSelector(
    (state) => state.notificaciones
  );
  const tiposNotificacionesStore = Redux.useSelector(
    (state) => state.tiposNotificaciones
  );

  const activo = notificacionesStore.activo;

  const initData = {
    ttile: '',
    body: '',
    userIds: [],
    isAllUsers: false,
  };

  const [notification, setNotification] = React.useState(initData);
  const [dataError, setDataRequired] = React.useState({
    title: false,
    body: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);

  const getUsers = async (params = {}) => {
    const res = await getAllUsuariosShortService(params);
    const data = res.data.data;
    const users = data.map((el) => ({
      id: el.id,
      name: `${el.profile.firstName} ${el.profile.lastName}`,
      avatar: el.userCodes[0]?.avatar || el.profile.avatar || null,
      email: el.email,
      documentId: `${el.profile.typeDocument}-${el.profile.identificationNumber}`,
      label: `${el.profile.firstName} ${el.profile.lastName} (${el.userCodes.map(
        (el) => `${el.code.code} - ${el.code.entity.abbrev}`
      ).join(', ')})`,
    }));
    if (res) setUsers(users);
  }

  React.useEffect(() => {
    getUsers();
  }, []);

  React.useEffect(() => {
    if (activo === null) return;
    let data = {};
    Object.keys(notification).map(
      (key) => (data[key] = notificacionesStore.activo[key])
    );
    // data.typeId = notificacionesStore.activo.type.id;
    setTimeout(() => {
      setNotification(data);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activo]);

  React.useEffect(() => {
    if (notification.isAllUsers) {
      setNotification({ ...notification, userIds: [] });
    }
  }, [notification.isAllUsers]);


  const _handleSetDataField = (key, value) =>
    setNotification({ ...notification, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).map((key) => {
      const val = notification[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      return va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const _handleSubmit = async () => {
    const valid = _validDataForm();

    if (valid) {
      const data = {
        ...notification,
      };
      setLoadingSave(true);
      let res = await sendNotificacionesService(
        data
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };

  return (
    <Box component="form" sx={{}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={activo ? 'Actualizando...' : 'Guardando...'}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            size="small"
            id="title"
            required
            label="Título"
            value={notification.title}
            onChange={(e) => _handleSetDataField('title', e.target.value)}
            helperText={dataError.title ? validFielddHelper(1) : null}
            error={dataError.title}
          />
        </Grid>
        
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            size="small"
            id="body"
            required
            label="Cuerpo"
            multiline
            rows={3}
            value={notification.body}
            onChange={(e) => _handleSetDataField('body', e.target.value)}
            helperText={dataError.body ? validFielddHelper(1) : null}
            error={dataError.body}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                value={notification.isAllUsers}
                checked={notification.isAllUsers}
                onChange={(e) => {
                  setNotification({ ...notification, isAllUsers: e.target.checked });
                }}
              />
            }
            label="Enviar a todos los usuarios"
          />
        </Grid>
        {
          !notification.isAllUsers && (
            <Grid item xs={12} md={12}>
              <Autocomplete
                fullWidth
                multiple
                disablePortal
                disableCloseOnSelect
                id="combo-box-demo"
                options={users}
                renderInput={(params) => <TextField {...params} label="Usuarios" />}
                key={(option) => option.id}
                onChange={(e, value) => {
                  console.log(value);
                  setNotification({ ...notification, userIds: value.map((el) => el.id) });
                }}
              />
            </Grid>
          )
        }
        <Grid item xs={12} md={12} align="center" sx={{ m: 2 }}>
          <BotonNuevoComponent
            click={_handleSubmit}
            text="Enviar"
            disabled={loadingSave}
            morado
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormNotificaciones;
