import { tryCatchHelper } from '../../helpers';
import {
  GETALL_RESERVAS,
  LOADING_GETALL_RESERVAS,
  ACTIVO_RESERVAS,
} from '../../store/Types';
import {
  deleteReservasService,
  getAllReservasService,
  getOneReservasService,
} from './ReservasService';

export const getAllReservasAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_RESERVAS,
      payload: true,
    });
    params.groupByBlockId = true;
    const res = await getAllReservasService({
      type: 'reservation',
      ...params,
    });
    dispatch({
      type: GETALL_RESERVAS,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_RESERVAS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_RESERVAS,
      payload: false,
    });
    tryCatchHelper(e);
  }
};

export const setActivoReservasAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_RESERVAS,
    payload: data,
  });
};

export const getOneReservasAction = async (dispatch, id) => {
  try {
    const res = await getOneReservasService(id);
    await setActivoReservasAction(dispatch, res.data);
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteReservasAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteReservasService(id);
    addNotification(res.data.message ?? 'Reserva eliminada.');
    await setActivoReservasAction(dispatch, null);
    getAllReservasAction(dispatch, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
