import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import * as ROUTES from '../../../router/Routes';
import PanelServiciosView from './PanelServiciosView';

const PanelServiciosModule = () => {
  return (
    <ContainerTemplate
      active={ROUTES.panelServicios}
      routes={[ROUTES.servicios]}
    >
      <PanelServiciosView />
    </ContainerTemplate>
  );
};

export default PanelServiciosModule;
