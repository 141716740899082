import {
  Avatar,
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import ActionsList from '../../../components/ActionsList';
import LoadingComponent from '../../../components/Loading';
import { CYAN_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';
import * as CONST from '../../../constants/FieldsConst';
import { dateformat } from '../../../helpers';
import { useNotification } from '../../../helpers/notification';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import {
  deleteReferidosAction,
  getAllReferidosAction,
  saveReferidosAction,
  setActivoReferidosAction,
} from '../ReferidosAction';

const ListadoReferidos = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const referidosStore = Redux.useSelector((state) => state.referidos);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isToggleActive, setIsToggleActive] = React.useState(false);

  React.useEffect(() => {
    if (referidosStore.all && referidosStore.all.length === 0) {
      getAllReferidosAction(dispatch, entidadesStore.activo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const fetchData = () => {
      const rows = [];
      referidosStore.all.forEach((el) => {
        rows.push({
          ...el,
          [CONST.DATE]: dateformat(el.date, {}),
          active: el.active ? 'Activo' : 'Bloqueado',
          actions: el,
        });
      });
      setRows(rows);
    };
    fetchData();
  }, [referidosStore.all]);

  const _handleEditClick = async (id) => {
    const data = referidosStore.all.find((el) => el.id === id);
    await setActivoReferidosAction(dispatch, data);
    setOpenModalForm(true);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteReferidosAction(
      { dispatch, addNotification },
      id,
      entidadesStore.activo.id
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const _handleToggleActiveClick = async (id) => {
    setLoadingDelete(true);
    await saveReferidosAction(
      { dispatch, addNotification },
      entidadesStore.activo.id,
      id,
      {
        active: !referidosStore.activo.active,
      }
    );
    setIsToggleActive(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.EMAIL,
      label: 'Correo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={() => _handleEditClick(tableMeta.rowData[0])}
            >
              {value}
            </NameComponent>
          );
        },
      },
    },
    
    {
      name: 'userCode',
      label: 'Invitado por',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {(value.user?.profile?.avatar || value.avatar) && (
                <Avatar
                  alt={value.user.profile.firstName}
                  sx={{ width: 30, height: 30, mr: 1 }}
                  src={value.avatar || value.user.profile.avatar}
                />
              )}
              <Typography variant="body">
                {value.user.profile
                  ? `${value.user.profile.firstName} ${value.user.profile.lastName}`
                  : 'ROOT'}
              </Typography>
            </Box>
          ) : (
            <Typography variant="body">ROOT</Typography>
          ),
      },
    },
    {
      name: 'isRegistered',
      label: 'Registrado',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = referidosStore.all.find( el => el.id === tableMeta.rowData[0]);
          return (
            data?.user ? (
              <Chip
                label="Sí"
                color="primary"
                variant="outlined"
              />
            ) : (
              <Chip
                label="No"
                color="error"
                variant="outlined"
              />
            )
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box align="left" sx={{ width: '5em' }}>
              <ActionsList
                id={tableMeta.rowData[0]}
                onDelete={async () => {
                  await setActivoReferidosAction(dispatch, value);
                  setIsDelete(true);
                }}
                active={value.active}
              />
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onSearchChange: (text) => {
      console.log('buscardor', text);
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={referidosStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {referidosStore.activo && (
        <Modal
          id="modalFormReferidos"
          title="Eliminar"
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este este referido?
              <p>
                <strong>{referidosStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(referidosStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      )}

      {referidosStore.activo && (
        <Modal
          id="modalToggleActivo"
          title={`${referidosStore.activo.active ? 'Bloquear' : 'Activar'} ${
            referidosStore.activo.name
          }`}
          open={isToggleActive}
          maxWidth="sm"
          onClose={() => setIsToggleActive(!isToggleActive)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`¿Seguro que quieres ${
                referidosStore.activo.active ? 'Bloquear' : 'Activar'
              } este tipo de invitación?`}
              <p>
                <strong>{referidosStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleToggleActiveClick(referidosStore.activo.id);
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsToggleActive(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      )}
    </Grid>
  );
};

export default ListadoReferidos;
