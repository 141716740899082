import React from "react";
import ContainerTemplate from "../../components/template/Container";
import ItosView from "./IotsView";
import * as ROUTES from "../../router/Routes";

const ItosPage = () => {
  return (
    <ContainerTemplate active={ROUTES.iotsQr} routes={[ROUTES.iots]}>
      <ItosView />
    </ContainerTemplate>
  );
};

export default ItosPage;
