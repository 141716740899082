import { tryCatchHelper } from '../../../helpers';
import {
  deleteNotificacionesService,
  getAllNotificacionesService,
  getOneNotificacionesService,
  saveNotificacionesService,
} from '../../../services';
import {
  ACTIVO_NOTIFICACIONES,
  GETALL_NOTIFICACIONES,
  LOADING_GETALL_NOTIFICACIONES,
} from '../../../store/Types';

export const getAllNotificacionesAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_NOTIFICACIONES,
      payload: true,
    });
    const res = await getAllNotificacionesService({ entityId });
    dispatch({
      type: GETALL_NOTIFICACIONES,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_NOTIFICACIONES,
      payload: false,
    });
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const saveNotificacionesAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveNotificacionesService(id, data);
    addNotification(
      res.message ?? id
        ? 'El documento actualizado.'
        : 'El documento registrado.'
    );
    await getAllNotificacionesAction(dispatch, entityId);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoNotificacionesAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_NOTIFICACIONES,
    payload: data,
  });
};

export const getOneNotificacionesAction = async (dispatch, id) => {
  try {
    const res = await getOneNotificacionesService(id);
    await setActivoNotificacionesAction(dispatch, res.data);
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteNotificacionesAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteNotificacionesService(id, entityId);
    addNotification(res.data?.message ?? 'Documento eliminado.');
    await setActivoNotificacionesAction(dispatch, null);
    await getAllNotificacionesAction(dispatch, entityId, null);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
