import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import * as ROUTES from '../../../router/Routes';
import ServiciosView from './ServiciosView';

const module = () => {
  return (
    <ContainerTemplate active={ROUTES.servicios} routes={[]}>
      <ServiciosView />
    </ContainerTemplate>
  );
};

export default module;
