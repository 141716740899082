import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../../modulos/modules.helpers';
import {
  getAllUsersDeleteAction,
  setActivoUsersDeleteAction,
} from './UsersDeleteAction';
import ListadoUsersDelete from './components/ListadoUsersDelete';

const UsersDeleteView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const usersDeleteStore = Redux.useSelector((state) => state.usersDelete);

  const loginStore = Redux.useSelector((state) => state.login);

  const accessName = 'usersDelete';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoUsersDeleteAction(dispatch, null);
            await getAllUsersDeleteAction(dispatch);
          }}
          disabled={
            usersDeleteStore.loadingGetAll || usersDeleteStore.all.length === 0
          }
          white
          text={
            usersDeleteStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'
          }
        />
      </Grid>
      <Grid item md={12}>
        <ListadoUsersDelete setOpenModalForm={(val) => setOpenModalForm(val)} />
      </Grid>
      {/* <Modal
        id="modalFormUsersDelete"
        title={`${usersDeleteStore.activo ? 'Editar' : 'Nuevo'} eliminancion`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {usersDeleteStore.activo && (
          <Typography variant="h6">
            {usersDeleteStore.activo.nombres}{' '}
            {usersDeleteStore.activo.apellidos}
          </Typography>
        )}
      </Modal> */}
    </Grid>
  );
};

export default UsersDeleteView;
