import {
  GETALL_IOT_TUNNELS,
  LOADING_GETALL_IOT_TUNNELS,
  ACTIVO_IOT_TUNNELS,
} from './IotTunelesReducer';
import {
  deleteIotTunelesService,
  getAllIotTunelesService,
  getOneIotTunelesService,
  saveIotTunelesService,
  setUpIotTunelesService,
} from '../../services';
import { tryCatchHelper } from '../../helpers';

export const getAllIotTunelesAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_IOT_TUNNELS,
      payload: true,
    });
    const res = await getAllIotTunelesService(entityId);
    if (res.status === 200) {
      dispatch({
        type: GETALL_IOT_TUNNELS,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_IOT_TUNNELS,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_IOT_TUNNELS,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_IOT_TUNNELS,
      payload: false,
    });
    tryCatchHelper(e);
  }
};

export const saveIotTunelesAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveIotTunelesService(id, data);
    addNotification(
      res.message ?? id ? 'Tunel iot actualizado.' : 'Tunel iot registrado.'
    );
    await getAllIotTunelesAction(dispatch, entityId);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoIotTunelesAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_IOT_TUNNELS,
    payload: data,
  });
};

export const getOneIotTunelesAction = async (dispatch, id) => {
  try {
    const res = await getOneIotTunelesService(id);
    if (res.status === 200) {
      await setActivoIotTunelesAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteIotTunelesAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteIotTunelesService(id);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Tunel itos eliminado.');
      await setActivoIotTunelesAction(dispatch, null);
      await getAllIotTunelesAction(dispatch, entityId, null);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setUpIotTunelesAction = async (
  { dispatch, addNotification },
  id,
  dto
) => {
  try {
    const res = await setUpIotTunelesService(id, dto);
    addNotification(res.data.message ?? 'Tunel iot configurado.');
    await setActivoIotTunelesAction(dispatch, null);
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
