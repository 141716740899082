import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const deliveriesApi = `${baseApi}/deliveries`;

export const getAllDeliveryService = async (params) => {
  const res = await axios.get(deliveriesApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveDeliveryService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${deliveriesApi}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${deliveriesApi}`, data, {
      headers: await token({}),
    });
  }
  return res;
};

export const getOneDeliveryService = async (id) => {
  const res = await axios.get(`${deliveriesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteDeliveryService = async (id) => {
  const res = await axios.delete(`${deliveriesApi}/${id}`, {
    headers: await token({}),
  });
  return res.data;
};
