import {
  deleteCuposClasesService,
  getAllCuposClasesService,
  getOneCuposClasesService,
  saveCuposClasesService,
} from '../../../services';
import { tryCatchHelper } from '../../../helpers';
import {
  ACTIVO_Cupos_CLASES,
  GETALL_Cupos_CLASES,
  LOADING_GETALL_Cupos_CLASES,
} from './CuposClasesReducer';

export const getAllCuposClasesAction = async (dispatch, teacherId, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_Cupos_CLASES,
      payload: true,
    });
    const res = await getAllCuposClasesService({ entityId, teacherId });
    if (res.status === 200) {
      dispatch({
        type: GETALL_Cupos_CLASES,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_Cupos_CLASES,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_Cupos_CLASES,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_Cupos_CLASES,
      payload: false,
    });
    let message =
      e.response?.data?.message ||
      e.message ||
      'Error al intentar, realizar el proceso';
    if (Array.isArray(e.message)) message = e.message.join(', ');
    if (Array.isArray(e.response?.data?.message)) {
      message = e.response?.data?.message.join(', ');
    }
    console.warn(message);
  }
};

export const saveCuposClasesAction = async (
  { dispatch, addNotification },
  entityId,
  teacherId,
  id,
  data
) => {
  try {
    const res = await saveCuposClasesService(id, data);
    addNotification(
      res.message ?? id
        ? 'Actualizado.'
        : 'Error al intentar, realizar el proceso.'
    );
    await getAllCuposClasesAction(dispatch, teacherId, entityId);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoCuposClasesAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_Cupos_CLASES,
    payload: data,
  });
};

export const getOneCuposClasesAction = async (dispatch, id) => {
  try {
    const res = await getOneCuposClasesService(id);
    if (res.status === 200) {
      await setActivoCuposClasesAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteCuposClasesAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteCuposClasesService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data?.message ?? 'Tipo de documento eliminado.');
      await setActivoCuposClasesAction(dispatch, null);
      await getAllCuposClasesAction(dispatch, entityId);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
