import {
  GETALL_AREAS,
  LOADING_GETALL_AREAS,
  ACTIVO_AREAS,
  GET_DATA_SEARCH_AREAS_RESERVAS_ESPECIALES,
} from '../../store/Types';
import {
  deleteAreasService,
  getAllAreasService,
  getOneAreasService,
  saveAreasService,
} from '../../services';
import { tryCatchHelper } from '../../helpers';

export const getAllAreasAction = async (dispatch, params) => {
  try {
    dispatch({ type: LOADING_GETALL_AREAS, payload: true });
    const res = await getAllAreasService(params);
    if (res.status === 200) {
      dispatch({ type: GETALL_AREAS, payload: res.data });
      dispatch({ type: LOADING_GETALL_AREAS, payload: false });
      if (res.data.data.length > 0) {
        dispatch({
          type: GET_DATA_SEARCH_AREAS_RESERVAS_ESPECIALES,
          payload: res.data.data[0].id,
        });
      }
      return res.data;
    }
    dispatch({ type: LOADING_GETALL_AREAS, payload: false });
    return null;
  } catch (e) {
    dispatch({ type: LOADING_GETALL_AREAS, payload: false });
    tryCatchHelper(e);
  }
};

export const saveAreasAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res = await saveAreasService(id, data);
    if (id) {
      await setActivoAreasAction(dispatch, res);
    }
    addNotification(
      res.message ?? id ? 'Área actualizada.' : 'Área registrada.'
    );
    await getAllAreasAction(dispatch, params);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoAreasAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_AREAS,
    payload: data,
  });
};

export const getOneAreasAction = async (dispatch, id) => {
  try {
    dispatch({
      type: LOADING_GETALL_AREAS,
      payload: true,
    });
    const res = await getOneAreasService(id);
    if (res.status === 200) {
      await setActivoAreasAction(dispatch, res.data);
      dispatch({
        type: LOADING_GETALL_AREAS,
        payload: false,
      });
      return true;
    }
    dispatch({
      type: LOADING_GETALL_AREAS,
      payload: false,
    });
    return false;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_AREAS,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const deleteAreasAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteAreasService(id);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Área eliminada.');
      await setActivoAreasAction(dispatch, null);
      await getAllAreasAction(dispatch, params);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
