import queryString from 'query-string';
import * as React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import LayoutInfoComponent from './LayoutInfo';
import { confirmEmailService } from './login/LoginService';

const CorreoVerificadoModule = () => {
  const location = useLocation();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const queryParams = queryString.parse(location.search);
        const token = queryParams.token;
        await confirmEmailService(token);
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <LayoutInfoComponent message="Cargando..." isReturn={false} />;
  }

  if (error) {
    return (
      <LayoutInfoComponent
        message="El token ya ha sido usado o esta expirado"
        isReturn={false}
      />
    );
  }

  return (
    <LayoutInfoComponent
      message="El correo electrónico ha sido verificado"
      isReturn={false}
    />
  );
};

export default CorreoVerificadoModule;
