import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';

export const restaurantes = `${baseApi}/restaurants`;

export const getAllRestaurantesService = async (params) => {
  if (params) params.panel = true;
  const res = await axios.get(restaurantes, {
    headers: await token({}),
    params,
  });
  return res;
};

// export const saveRestaurantesService = async (id, data) => {
//   if (data.image || data.mapImage) {
//     const formData = new FormData();

//     if (data.extras && data.extras.length) {
//       for (let i = 0; i < data.extras.length; i++) {
//         const extra = data.extras[i];
//         for (const key in extra) {
//           formData.append(`extras[${i}][${key}]`, extra[key]);
//         }
//       }
//       delete data.extras;
//     }

//     Object.keys(data).forEach((key) => {
//       formData.append(key, data[key]);
//     });
//     data = formData;
//   } else {
//     delete data.image;
//     delete data.mapImage;
//   }

//   let res;
//   if (id) {
//     res = await axios.put(`${restaurantes}/${id}`, data, {
//       headers: await token({
//         isFormData: !!data.image,
//       }),
//     });
//   } else {
//     res = await axios.post(restaurantes, data, {
//       headers: await token({
//         isFormData: !!data.image,
//       }),
//     });
//   }
//   return res.data;
// };

export const saveRestaurantesService = async (id, data) => {
  delete data.type;
  console.log('object');
  if (data.id) delete data.id;
  if (data.menu || data.image || data.newAboutImages?.length) {
    const formData = new FormData();

    if (data.newAboutImages?.length) {
      for (let i = 0; i < data.newAboutImages.length; i++) {
        formData.append(`newAboutImages`, data.newAboutImages[i])
      }
      delete data.newAboutImages;
    }

    if (data.paymentMethods?.length) {
      for (const paymentMethod of data.paymentMethods) {
        formData.append('paymentMethods[]', paymentMethod);
      }
      delete data.paymentMethods;
    }

    if (data.services?.length) {
      for (const service of data.services) {
        formData.append('services[]', service);
      }
      delete data.services;
    }

    if (data.deletedAboutImages && data.deletedAboutImages.length) {
      for (let i = 0; i < data.deletedAboutImages.length; i++) {
        formData.append(`deletedAboutImages[]`, data.deletedAboutImages[i])
      }
      delete data.deletedAboutImages;
    }

    if (data.schedules && data.schedules.length) {
      for (let i = 0; i < data.schedules.length; i++) {
        const schedule = data.schedules[i];
        formData.append(`schedules[${i}][day]`, schedule.day);
        if (schedule.schedules && schedule.schedules.length) {
          for (let ii = 0; ii < schedule.schedules.length; ii++) {
            const time = schedule.schedules[ii];
            formData.append(`schedules[${i}][schedules][${ii}]`, time);
          }
        }
      }
      delete data.schedules;
    } else {
      formData.append('schedules[]', '');
    }

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.avatar;
  }
  let res;
  if (id) {
    res = await axios.put(`${restaurantes}/${id}`, data, {
      headers: await token({
        isFormData: !!(data.menu || data.image || data.newAboutImages?.length),
      }),
    });
  } else {
    res = await axios.post(`${restaurantes}`, data, {
      headers: await token({
        isFormData: !!(data.menu || data.image || data.newAboutImages?.length),
      }),
    });
  }
  return res.data;
};

export const getOneRestaurantesService = async (id) => {
  const res = await axios.get(`${restaurantes}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteRestaurantesService = async (id) => {
  const res = await axios.delete(`${restaurantes}/${id}`, {
    headers: await token({}),
  });
  return res;
};
