import {
  Avatar,
  Card,
  CardContent,
  TextField,
  CircularProgress,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Paper } from "@mui/material";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import CopyrightTemplate from "../../../components/template/Copyright";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { isValidPhoneNumber } from "libphonenumber-js";
import axios from "axios";
import { baseApi } from "../../../services/Apis";
import moment from "moment";
import { useParams } from "react-router-dom";
import { validFielddHelper } from "../../../helpers/ValidFieldsHelper";
import * as CONST from "../../../constants/FieldsConst";
import PhoneNumberField from "../../../components/fields/PhoneNumberField";
import {
  CorreoField,
  FechaNacimientoField,
} from "../../usuarios/components/FieldsUsuarios";
import {
  ButtonFile,
  GenerosField,
  TiposDocumentosField,
} from "../../../components";
import { useNotification } from "../../../helpers/notification";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  byIdentifacionUsuariosService,
  completeRegistrationService,
  preCreateUsuariosService,
} from "../../usuarios/UsuariosService";
import { dateformat } from "../../../helpers";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import queryString from "query-string";
import PasswordField from "../login/components/PasswordField";
import { Visibility, VisibilityOff } from "@mui/icons-material";

var ExpRegSoloNumeros = "^[0-9]+$";
var ExpRegSoloNumerosPassport = "^[0-9a-zA-Z]+$";
var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const typeDocuments = ["V", "E", "P", "OP", "J"];

const passwordSchema = Yup.string()
  .min(8)
  .test("password", "Contraseña inválida", (value) => {
    if (!value) return true;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    return hasUpperCase && hasNumber;
  });

const validationSchema = Yup.object({
  firstName: Yup.string()
    .min(2, "Mínimo 2 caracteres")
    .max(50, "Máximo 50 caracteres")
    .required("Campo requerido"),
  lastName: Yup.string()
    .min(2, "Mínimo 2 caracteres")
    .max(50, "Máximo 50 caracteres")
    .required("Campo requerido"),
  email: Yup.string()
    .email("Correo electrónico inválido")
    .required("Campo requerido"),
  phone: Yup.string()
    .required()
    .test("phone", "Teléfono inválido", (value) =>
      isValidPhoneNumber(value, "VE")
    ),
  typeDocument: Yup.string().required("Campo requerido"),
  identificationNumber: Yup.string()
    .min(5, "Mínimo 5 caracteres")
    .max(20, "Máximo 20 caracteres")
    .required("Campo requerido"),
  gender: Yup.string().required("Campo requerido"),
  birthday: Yup.date()
    .max(dayjs().subtract(16, "years").toDate(), "Debes ser mayor de 16 años")
    .min(dayjs().subtract(100, "years").toDate(), "Fecha inválida")
    .required("Campo requerido"),
  password: passwordSchema.required(),
  passwordConfirmation: passwordSchema
    .oneOf([Yup.ref("password"), undefined], "Las contraseñas no coinciden")
    .required(),
});

let loadIdentificationTimeout = null;
const CompletarRegistro = () => {
  const location = useLocation();

  const queryParams = queryString.parse(location.search);
  const token = queryParams.token;

  const [user, setUser] = React.useState(null);

  const userDocumentId = `${user?.typeDocument}-${user?.identificationNumber}`;

  const { addNotification } = useNotification();
  // const [avatar, setAvatar] = React.useState(null);
  const acceptOnlyImages = ["image/jpeg", "image/png", "image/jpg"];

  const [isTokenInvalid, setIsTokenInvalid] = React.useState(false);
  const [isLoadingToken, setIsLoadingToken] = React.useState(true);
  const [isCompleteRegistration, setIsCompleteRegistration] =
    React.useState(false);
  const [isLoadIdentification, setIsLoadIdentification] = React.useState(false);
  const [isValidIdentification, setIsValidIdentification] =
    React.useState(true);
  const [isLoadedIdentification, setIsLoadedIdentification] =
    React.useState(false);
  const [isShowPassword, setIsShowPassword] = React.useState(false);

  const getUserByToken = async (token) => {
    try {
      await axios
        .get(`${baseApi}/auth/get-user-by-token/${token}`)
        .then((res) => {
          const user = res.data;
          setUser({
            email: user.email,
            typeDocument: user.profile.typeDocument,
            identificationNumber: user.profile.identificationNumber,
            gender: user.profile.gender,
            birthday: user.profile.birthday,
            phone: user.profile.phone,
            firstName: user.profile.firstName,
            lastName: user.profile.lastName,
            avatar: user.profile.avatar,
            password: "",
            passwordConfirmation: "",
          });
        });
    } catch (error) {
      setIsTokenInvalid(true);
    } finally {
      setIsLoadingToken(false);
    }
  };

  React.useEffect(() => {
    getUserByToken(token);
  }, []);

  const onSubmit = async ({ passwordConfirmation, ...values }) => {
    try {
      await completeRegistrationService(token, values);
      setIsCompleteRegistration(true);
    } catch (error) {
      console.error(error);
      addNotification("Ha ocurrido un error", { warning: true });
    }
  };

  return (
    <center
      style={
        isLoadingToken || isTokenInvalid || isCompleteRegistration
          ? { display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100vh" }
          : {}
      }
    >
      {!isLoadingToken ? (
        !isTokenInvalid ? (
          !isCompleteRegistration ? (
            <Box
              sx={{
                width: {
                  xs: "90%",
                  md: "40%",
                },
              }}
            >
              <Avatar
                alt="On Pass"
                src="/img/logoon.png"
                sx={{
                  width: {
                    xs: 100,
                    md: 150,
                  },
                  height: {
                    xs: 100,
                    md: 150,
                  },
                  padding: "20px",
                }}
              />
              <Typography
                component="h1"
                variant="h3"
                sx={{ fontWeight: "700" }}
              >
                ON PASS
              </Typography>
              <Card sx={{ maxWidth: 345, marginTop: "15px" }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Completar Registro
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Por favor completa los siguientes campos
                  </Typography>
                </CardContent>
              </Card>
              <Paper elevation={5} sx={{ p: 3, marginTop: "15px" }}>
                <Formik
                  initialValues={user}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                  validateOnChange={false}
                  validateOnBlur={true}
                >
                  {({
                    values,
                    setFieldValue,
                    handleSubmit,
                    errors,
                    validateField,
                    isSubmitting,
                    setValues,
                    resetForm,
                    setFieldError,
                  }) => {
                    const getUserByIdentificacion = async (number) => {
                      if (loadIdentificationTimeout) {
                        clearTimeout(loadIdentificationTimeout);
                      }
                      loadIdentificationTimeout = setTimeout(async () => {
                        setIsLoadIdentification(true);
                        await byIdentifacionUsuariosService(number)
                          .then(({ data, status }) => {
                            if (status === 404) {
                              setIsValidIdentification(true);
                              return;
                            }

                            const resDocumentId = `${data.profile.typeDocument}-${data.profile.identificationNumber}`;

                            if (resDocumentId === userDocumentId) {
                              setIsValidIdentification(true);
                              return;
                            }

                            setFieldError('identificationNumber', 'Número de identificación no disponible');
                            setIsValidIdentification(false);
                          })
                          .finally(() => {
                            setIsLoadIdentification(false);
                            setIsLoadedIdentification(true);
                          });
                      }, 500);
                    };

                    return (
                      <Grid>
                        <Grid item xs={12} md={3}>
                          <ButtonFile
                            id="avatar"
                            onFile={async (file) => {
                              setFieldValue("avatar", file);
                            }}
                            onDelete={async () => {
                              setFieldValue("avatar", null);
                            }}
                            text="Subir Foto"
                            sxc={{ marginTop: "5px" }}
                            name={""}
                            file={values.avatar}
                            size={100}
                            camera={true}
                            src={null}
                            accept={acceptOnlyImages}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              size="small"
                              id="email"
                              required
                              disabled={true}
                              label="Correo electrónico"
                              value={values.email}
                              onChange={async (e) => {
                                setFieldValue("email", e.target.value);
                                await sleep(10);
                                validateField("email");
                              }}
                              helperText={errors.email}
                              error={!!errors.email}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{
                            marginTop: 20,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <FormControl style={{ width: "100px" }}>
                            <InputLabel id="typeDocument">
                              Tipo de documento
                            </InputLabel>
                            <Select
                              fullWidth
                              size="small"
                              id="typeDocument"
                              required
                              disabled={isSubmitting}
                              label="Tipo de documento"
                              value={values.typeDocument}
                              onChange={async (e) => {
                                setFieldValue("typeDocument", e.target.value);
                                await sleep(10);
                                validateField("typeDocument");
                              }}
                              error={!!errors.typeDocument}
                            >
                              {typeDocuments.map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              size="small"
                              id="identificationNumber"
                              required
                              disabled={isSubmitting}
                              label="Número de identificación"
                              value={values.identificationNumber}
                              onChange={async (e) => {
                                let text = e.target.value;
                                var ExpRegSoloNumeros = "^[0-9]+$";
                                var ExpRegSoloNumerosPassport =
                                  "^[0-9a-zA-Z]+$";
                                if (values.typeDocument.toString() === "V") {
                                  text = text.replace(/[^0-9]/g, "");

                                  if (text.length === 9) return;
                                }

                                let v = false;
                                if (values.typeDocument.toString() === "P") {
                                  if (text.length === 20) return;
                                  v =
                                    text.match(ExpRegSoloNumerosPassport) !=
                                    null;
                                } else {
                                  v = text.match(ExpRegSoloNumeros) != null;
                                }

                                if (text === "") {
                                  setFieldValue("identificationNumber", "");
                                  await sleep(10);
                                  validateField("identificationNumber");
                                  return;
                                }

                                if (!v) {
                                  text = text.slice(0, -1);
                                }

                                setFieldValue("identificationNumber", text);
                                await sleep(10);
                                validateField("identificationNumber");
                                getUserByIdentificacion(text);
                              }}
                              helperText={errors.identificationNumber}
                              error={!!errors.identificationNumber}
                              InputProps={{
                                endAdornment: isLoadIdentification ? (
                                  <CircularProgress size={20} />
                                ) : isLoadedIdentification ? (
                                  isValidIdentification ? (
                                    <CheckIcon sx={{ color: "green" }} />
                                  ) : (
                                    <ErrorOutlineIcon sx={{ color: "red" }} />
                                  )
                                ) : null,
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              size="small"
                              id="firstName"
                              required
                              disabled={isSubmitting}
                              label="Nombres"
                              value={values.firstName}
                              onChange={async (e) => {
                                setFieldValue("firstName", e.target.value);
                                await sleep(10);
                                validateField("firstName");
                              }}
                              helperText={errors.firstName}
                              error={!!errors.firstName}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              size="small"
                              id="lastName"
                              required
                              disabled={isSubmitting}
                              label="Apellidos"
                              value={values.lastName}
                              onChange={async (e) => {
                                setFieldValue("lastName", e.target.value);
                                await sleep(10);
                                validateField("lastName");
                              }}
                              helperText={errors.lastName}
                              error={!!errors.lastName}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                          <FormControl fullWidth>
                            <PhoneNumberField
                              value={values.phone}
                              onChange={async (value) => {
                                setFieldValue("phone", value);
                                await sleep(10);
                                validateField("phone");
                              }}
                              disabled={isSubmitting}
                              fieldName="phone"
                              required={true}
                              dataError={errors}
                            />
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{ marginTop: 20 }}
                          sx={{
                            marginBottom: "25px",
                            textAlign: "left !important",
                          }}
                        >
                          <GenerosField
                            disabled={isSubmitting}
                            value={values.gender}
                            onChange={async (e, v) => {
                              setFieldValue("gender", e.target.value);
                              await sleep(10);
                              validateField("gender");
                            }}
                            error={errors.gender}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FechaNacimientoField
                            v={values.birthday}
                            on={async (e) => {
                              setFieldValue("birthday", dateformat(e, {}));
                              await sleep(10);
                              validateField("birthday");
                            }}
                            e={errors.birthday}
                            ht={errors.birthday}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} style={{ marginTop: 20 }}>
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              size="small"
                              id="password"
                              required
                              type={isShowPassword ? "text" : "password"}
                              disabled={isSubmitting}
                              label="Contraseña"
                              value={values.password}
                              onChange={async (e) => {
                                setFieldValue("password", e.target.value);
                                await sleep(10);
                                validateField("password");
                              }}
                              helperText={errors.password}
                              error={!!errors.password}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        setIsShowPassword(!isShowPassword)
                                      }
                                      edge="end"
                                    >
                                      {isShowPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ marginTop: 20 }}>
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              size="small"
                              id="passwordConfirmation"
                              required
                              type={isShowPassword ? "text" : "password"}
                              disabled={isSubmitting}
                              label="Confirmar contraseña"
                              value={values.passwordConfirmation}
                              onChange={async (e) => {
                                setFieldValue(
                                  "passwordConfirmation",
                                  e.target.value
                                );
                                await sleep(10);
                                validateField("passwordConfirmation");
                              }}
                              helperText={errors.passwordConfirmation}
                              error={!!errors.passwordConfirmation}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        setIsShowPassword(!isShowPassword)
                                      }
                                      edge="end"
                                    >
                                      {isShowPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item md={12} align="center" sx={{ m: 2 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={() => {
                              if (!isValidIdentification) {
                                return;
                              }
                              handleSubmit();
                            }}
                          >
                            {isSubmitting ? (
                              <CircularProgress size={24} />
                            ) : (
                              "Completar Registro"
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  }}
                </Formik>
              </Paper>
              <CopyrightTemplate sx={{ mt: 5 }} />
            </Box>
          ) : (
            <Typography sx={{ mt: 2, mb: 2 }} component="h1" variant="h5">
              <CheckIcon sx={{ fontSize: 100, color: "green" }} />
              <Typography variant="h6" component="div">
                ¡Se ha completado tu registro exitosamente!
              </Typography>

              <Typography variant="h6" component="div">
                Descarga la aplicación para continuar
              </Typography>
            </Typography>
          )
        ) : (
          <Typography sx={{ mt: 2, mb: 2 }} component="h1" variant="h5">
            Token inválido o expirado
          </Typography>
        )
      ) : (
        <CircularProgress />
      )}
    </center>
  );
};

export default CompletarRegistro;
