import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../../modulos/modules.helpers';
import { getAllTiposDocumentosAction } from '../tipos/TiposDocumentosAction';
import {
  getAllDocumentosAction,
  setActivoDocumentosAction,
} from './DocumentosAction';
import FormDocumentos from './components/FormDocumentos';
import ListadoDocumentos from './components/ListadoDocumentos';

const DocumentosView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const documentosStore = Redux.useSelector((state) => state.documentos);

  const loginStore = Redux.useSelector((state) => state.login);
  const accessName = 'document';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [openModalForm, setOpenModalForm] = React.useState(false);

  React.useEffect(() => {
    getAllTiposDocumentosAction(dispatch, entidadesStore.activo.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entidadesStore.activo.id]);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoDocumentosAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        <BotonNuevoComponent
          click={async () => {
            await setActivoDocumentosAction(dispatch, null);
            await getAllDocumentosAction(
              dispatch,
              entidadesStore.activo.id,
              null
            );
          }}
          disabled={
            documentosStore.loadingGetAll || documentosStore.all.length === 0
          }
          white
          text={
            documentosStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'
          }
        />
      </Grid>
      <Grid item md={12}>
        <ListadoDocumentos setOpenModalForm={(val) => setOpenModalForm(val)} />
      </Grid>
      <Modal
        id="modalFormDocumentos"
        title={`${documentosStore.activo ? 'Editar' : 'Nuevo'} documento`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {documentosStore.activo && (
          <Typography variant="h6">
            {documentosStore.activo.nombres} {documentosStore.activo.apellidos}
          </Typography>
        )}
        <FormDocumentos
          activo={documentosStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default DocumentosView;
