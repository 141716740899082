import React from "react";
import ContainerTemplate from "../../components/template/Container";
import AjustesView from "./AjustesView";
import * as ROUTES from "../../router/Routes";

const AjustesModule = () => {
  return (
    <ContainerTemplate active={ROUTES.ajustes} routes={[]}>
      <AjustesView />
    </ContainerTemplate>
  );
};

export default AjustesModule;
