import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';

export const tiposDocumentosApi = `${baseApi}/document-types`;

export const getAllTiposDocumentosService = async (params) => {
  // params.active = true;
  const res = await axios.get(tiposDocumentosApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveTiposDocumentosService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${tiposDocumentosApi}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${tiposDocumentosApi}`, data, {
      headers: await token({}),
    });
  }
  return res.data;
};

export const getOneTiposDocumentosService = async (id) => {
  const res = await axios.get(`${tiposDocumentosApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteTiposDocumentosService = async (id) => {
  const res = await axios.delete(`${tiposDocumentosApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};
