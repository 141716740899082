import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import * as ROUTES from '../../../router/Routes';
import TiposImagenClasesView from './TiposImagenClasesView';

const TiposImagenClasesPage = () => {
  return (
    <ContainerTemplate active={ROUTES.tiposImagenClases} routes={[]}>
      <TiposImagenClasesView />
    </ContainerTemplate>
  );
};

export default TiposImagenClasesPage;
