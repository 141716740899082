import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import * as CONST from '../../constants/FieldsConst';
import { getLabelTypeEntity } from '../../constants/enumsConst';
import { dateformat, getDateTimezone, isSwitch } from '../../helpers';
import { getSearchCodesCodigosAction } from '../../store/Actions';
import { PARAMS_DELIVERY } from '../../store/Types';
import FechasPersonalizada from '../accesos/components/FechasPersonalizada';
import BotonFechas from '../invitados/components/BotonFechas';
import SwitchFechaComponent from '../invitados/components/SwitchFecha';
import { checkModuleAccess } from '../modulos/modules.helpers';
import { getAllDeliveryAction } from './DeliveryAction';
import ListadoDelivery from './components/ListadoDelivery';

const DeliveryView = () => {
  const dispatch = Redux.useDispatch();
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const codigosStore = Redux.useSelector((state) => state.codigos);
  const deliveryStore = Redux.useSelector((state) => state.delivery);

  const loginStore = Redux.useSelector((state) => state.login);
  const accessName = 'delivery';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [laoding, setLoading] = React.useState(false);
  const [isRango, setIsRango] = React.useState(false);
  const [dataCodes, setDataCodes] = React.useState([]);
  const [loadingDataCode, setLoadingDataCode] = React.useState(false);
  const [isFuturo, setIsFuturo] = React.useState(true);
  const [startDate, setStartDate] = React.useState(
    dateformat(null, { full: true })
  );
  const [endDate, setEndDate] = React.useState(null);
  const [codeSelect, setCodeSelect] = React.useState(null);

  const timezone = entidadesStore.activo.country.timezone;

  const getParams = () => {
    return {
      ...deliveryStore.params,
      entityId: entidadesStore.activo.id,
      startDate: getDateTimezone(startDate, timezone),
      endDate: getDateTimezone(endDate, timezone),
    };
  };

  async function getAll() {
    await getAllDeliveryAction(dispatch, getParams());
  }

  React.useEffect(() => {
    const payload = {
      ...deliveryStore.params,
      entityId: entidadesStore.activo.id,
      codeId: codeSelect?.id,
    };
    dispatch({
      type: PARAMS_DELIVERY,
      payload,
    });
    getAllDeliveryAction(dispatch, payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeSelect]);

  React.useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (!laoding) {
        await getAll();
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  var delayTimer;
  function doSearch(text) {
    clearTimeout(delayTimer);
    setLoadingDataCode(true);
    delayTimer = setTimeout(async () => {
      await getSearchCodesCodigosAction(
        dispatch,
        entidadesStore.activo.id,
        text
      );
      setLoadingDataCode(false);
    }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
  }

  React.useEffect(() => {
    setDataCodes([]);
    if (codigosStore.searchCodes.length > 0) {
      codigosStore.searchCodes.map((code) => {
        return setDataCodes((old) => [
          ...old,
          {
            [CONST.ID]: code.id,
            [CONST.CODE]: code.code,
          },
        ]);
      });
    }
  }, [codigosStore.searchCodes]);

  const selectCode = (
    <Autocomplete
      freeSolo
      id={CONST.CODE}
      disableClearable
      options={dataCodes.map((option) => option.code)}
      size="small"
      loading={loadingDataCode}
      onChange={(event, newValue) => {
        const code = codigosStore.searchCodes.find((a) => a.code === newValue);
        setCodeSelect(code);
      }}
      onInputChange={(event, newInputValue) => {
        if (!newInputValue) {
          setCodeSelect(null);
          // Aquí puedes agregar más lógica si es necesario cuando newInputValue es vacío
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => doSearch(e.target.value)}
          label={`Buscar por ${getLabelTypeEntity(
            entidadesStore.entityType.name
          )}`}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            endAdornment: (
              <React.Fragment>
                {loadingDataCode ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
          <Grid
            container
            spacing={1}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Grid item md={3}>
              {selectCode}
            </Grid>
            <Grid
              item
              md={isRango ? 3 : 6}
              sx={{ display: 'flex', flexDirection: 'row' }}
            >
              <BotonFechas
                futuro={isFuturo}
                dateRange={(start, end) => {
                  setStartDate(dateformat(start, { full: true }));
                  setEndDate(dateformat(end, { full: true }));
                }}
                setIsRango={(v) => setIsRango(v)}
              />
              {isSwitch(isFuturo, startDate, endDate) || isRango ? null : (
                <Box sx={{ ml: 2, mt: 1 }}>
                  <SwitchFechaComponent
                    value={isFuturo}
                    onClickLeft={() => setIsFuturo(false)}
                    onClickRigth={() => setIsFuturo(true)}
                    onChange={(e) => setIsFuturo(e.target.checked)}
                  />
                </Box>
              )}
            </Grid>
            {isRango && (
              <FechasPersonalizada
                startDate={startDate}
                endDate={endDate}
                onStart={(value) => {
                  if (!value) return;
                  setStartDate(value);
                }}
                onEnd={(value) => {
                  if (!value) return;
                  setEndDate(value);
                }}
              />
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item md={12}>
        <ListadoDelivery getParams={getParams} />
      </Grid>
    </Grid>
  );
};

export default DeliveryView;
