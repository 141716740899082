import {
  GETALL_TIPOS_CONTACTOS,
  LOADING_GETALL_TIPOS_CONTACTOS,
  ACTIVO_TIPOS_CONTACTOS,
} from '../../../store/Types';
import {
  deleteTiposContactosService,
  getAllTiposContactosService,
  getOneTiposContactosService,
  saveTiposContactosService,
} from '../../../services';
import { tryCatchHelper } from '../../../helpers';

export const getAllTiposContactosAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_TIPOS_CONTACTOS,
      payload: true,
    });
    const res = await getAllTiposContactosService({ entityId });
    if (res.status === 200) {
      dispatch({
        type: GETALL_TIPOS_CONTACTOS,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_TIPOS_CONTACTOS,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_TIPOS_CONTACTOS,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_TIPOS_CONTACTOS,
      payload: false,
    });
    tryCatchHelper(e);
  }
};

export const saveTiposContactosAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveTiposContactosService(id, data);
    addNotification(
      res.message ?? id
        ? 'Tipo de contacto actualizado.'
        : 'Tipo de contacto registrado.'
    );
    await getAllTiposContactosAction(dispatch, entityId);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoTiposContactosAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_TIPOS_CONTACTOS,
    payload: data,
  });
};

export const getOneTiposContactosAction = async (dispatch, id) => {
  try {
    const res = await getOneTiposContactosService(id);
    if (res.status === 200) {
      await setActivoTiposContactosAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteTiposContactosAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteTiposContactosService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Tipo de contacto eliminado.');
      await setActivoTiposContactosAction(dispatch, null);
      await getAllTiposContactosAction(dispatch, entityId, null);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
