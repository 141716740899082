import * as React from 'react';
import * as Redux from 'react-redux';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormSettings from './components/FormSettings';
import { checkRootAccess } from '../modulos/modules.helpers';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const SettingsView = () => {
  const [value, setValue] = React.useState(0);
  const loginStore = Redux.useSelector((state) => state.login);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  checkRootAccess(loginStore)

  const views = [
    {
      name: 'Panel Administrativo',
      view: <FormSettings />,
    },
    // {
    //   name: 'App móvil',
    //   view: <FormSettings />,
    // },
    // {
    //   name: 'Globales',
    //   view: <FormSettings />,
    // },
    // {
    //   name: 'Parámetros',
    //   view: <div></div>,
    // },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Ajustes"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        {views.map((e, i) => {
          return (
            <Tab
              key={`tabs_${i}`}
              label={e.name}
              {...a11yProps(i)}
              sx={{ width: '180px' }}
            />
          );
        })}
      </Tabs>
      {views.map((e, i) => {
        return (
          <TabPanel value={value} key={i} index={i}>
            <Box
              sx={{
                mb: 2,
                width: '50vw',
                maxHeight: '70vh',
                overflowX: 'auto',
                p: 2,
              }}
            >
              {e.view}
            </Box>
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default SettingsView;
