import React from "react";
import ContainerTemplate from "../../components/template/Container";
import IotTunelesView from "./IotTunelesView";
import * as ROUTES from "../../router/Routes";

const IotTunelesPage = () => {
  return (
    <ContainerTemplate active={ROUTES.iotTuneles} routes={[ROUTES.iots]}>
      <IotTunelesView />
    </ContainerTemplate>
  );
};

export default IotTunelesPage;
