import React from "react";
import ContainerTemplate from "../../components/template/Container";
import PuertasView from "./PuertasView";
import * as ROUTES from "../../router/Routes";

const PuertasModule = () => {
  return (
    <ContainerTemplate active={ROUTES.puertas} routes={[]}>
      <PuertasView />
    </ContainerTemplate>
  );
};

export default PuertasModule;
