import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const accesos = `${baseApi}/access-logs`;

export const getAllAccesosService = async (params) => {
  const res = await axios.get(accesos, {
    headers: await token({}),
    params,
  });
  return res;
};
