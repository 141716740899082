import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';

export const tiposNivelClasesApi = `${baseApi}/class-levels`;

export const getAllTiposNivelClasesService = async (params) => {
  const res = await axios.get(tiposNivelClasesApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveTiposNivelClasesService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${tiposNivelClasesApi}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${tiposNivelClasesApi}`, data, {
      headers: await token({}),
    });
  }
  return res.data;
};

export const getOneTiposNivelClasesService = async (id) => {
  const res = await axios.get(`${tiposNivelClasesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteTiposNivelClasesService = async (id) => {
  const res = await axios.delete(`${tiposNivelClasesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};
