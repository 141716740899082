import {
  GET_ALL_CONTACTOS,
  LOADING_GETALL_CONTACTOS,
  SET_ACTIVO_CONTACTOS,
  SET_LOGOUT,
  PARAMS_CONTACTOS,
  CLEAR_ALL,
} from '../../../store/Types';

const initialState = {
  all: [],
  activo: null,
  message: null,
  loadingGetAll: false,
  params: {},
};

export const contactosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CONTACTOS:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case SET_ACTIVO_CONTACTOS:
      return {
        ...state,
        activo: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case LOADING_GETALL_CONTACTOS:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case PARAMS_CONTACTOS:
      return {
        ...state,
        params: action.payload,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
