import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';

export const clasesApi = `${baseApi}/classes`;

export const getAllClasesService = async (params) => {
  if (params) params.panel = true;
  const res = await axios.get(clasesApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveClasesService = async (id, data) => {
  // if (data.image) {
  //   const formData = new FormData();
  //   Object.keys(data).forEach((key) => {
  //     formData.append(key, data[key]);
  //   });
  //   data = formData;
  // } else {
  //   delete data.image;
  // }

  
  if (data.id) delete data.id;
  if (data.image) {
    const formData = new FormData();

    if (data.schedules && data.schedules.length) {
      for (let i = 0; i < data.schedules.length; i++) {
        const schedule = data.schedules[i];
        formData.append(`schedules[${i}][day]`, schedule.day);
        if (schedule.schedules && schedule.schedules.length) {
          for (let ii = 0; ii < schedule.schedules.length; ii++) {
            const time = schedule.schedules[ii];
            formData.append(`schedules[${i}][schedules][${ii}]`, time);
          }
        }
      }
      delete data.schedules;
    } else {
      formData.append('schedules[]', '');
    }

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.image;
  }

  let res;
  if (id) {
    res = await axios.put(`${clasesApi}/${id}`, data, {
      headers: await token({
        isFormData: !!data.image,
      }),
    });
  } else {
    res = await axios.post(clasesApi, data, {
      headers: await token({
        isFormData: !!data.image,
      }),
    });
  }
  return res.data;
};

export const getOneClasesService = async (id) => {
  const res = await axios.get(`${clasesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteClasesService = async (id) => {
  const res = await axios.delete(`${clasesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};
