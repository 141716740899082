import React from "react";
import ContainerTemplate from "../../components/template/Container";
import ReservasEspecialesView from "./ReservasEspecialesView";
import * as ROUTES from "../../router/Routes";

const ReservasEspecialesModule = () => {
  return (
    <ContainerTemplate active={ROUTES.reservasEspeciales} routes={[]}>
      <ReservasEspecialesView />
    </ContainerTemplate>
  );
};

export default ReservasEspecialesModule;
