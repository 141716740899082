import * as React from 'react';
import * as Redux from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { Modal } from '../../components';
import ListadoIots from './components/ListadoIots';
import FormIots from './components/FormIots';
import { getAllIotsAction, setActivoIotsAction } from './IotsAction';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { checkRootAccess } from '../modulos/modules.helpers';

const IotsView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const iotsStore = Redux.useSelector((state) => state.iots);

  const [openModalForm, setOpenModalForm] = React.useState(false);

  const loginStore = Redux.useSelector((state) => state.login);
  checkRootAccess(loginStore);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoIotsAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        {iotsStore.all.length > 0 ? (
          <BotonNuevoComponent
            click={async () => {
              await setActivoIotsAction(dispatch, null);
              await getAllIotsAction(dispatch, entidadesStore.activo.id, null);
            }}
            disabled={iotsStore.loadingGetAll}
            white
            text={iotsStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'}
          />
        ) : null}
      </Grid>
      <Grid item md={12}>
        <ListadoIots setOpenModalForm={(val) => setOpenModalForm(val)} />
      </Grid>
      <Modal
        id="modalFormIots"
        title={`${iotsStore.activo ? 'Editar' : 'Nuevo'} iot`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {iotsStore.activo ? (
          <Typography variant="h6">
            {iotsStore.activo.nombres} {iotsStore.activo.apellidos}
          </Typography>
        ) : null}
        <FormIots
          activo={iotsStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default IotsView;
