import { tryCatchHelper } from '../../../helpers';
import {
  GETALL_EVENTOS,
  LOADING_GETALL_EVENTOS,
  SET_ACTIVO_EVENTOS,
} from '../../../store/Types';
import {
  bulkExcelEventosService,
  deleteEventosService,
  getAllEventosService,
  saveEventosService,
} from './EventosService';

export const getAllEventosAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_EVENTOS,
      payload: true,
    });
    const res = await getAllEventosService(params);
    dispatch({
      type: GETALL_EVENTOS,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_EVENTOS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_EVENTOS,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const saveEventosAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res = await saveEventosService(id, data, params);
    addNotification(
      res.data.message ?? id ? 'Evento actualizado.' : 'Evento registrado.'
    );
    await getAllEventosAction(dispatch, params);
    setActivoEventosAction(dispatch, null);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoEventosAction = async (dispatch, data) => {
  dispatch({
    type: SET_ACTIVO_EVENTOS,
    payload: data,
  });
};

export const deleteEventosAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteEventosService(id);
    addNotification(res.data.message ?? 'Evento eliminado.');
    await setActivoEventosAction(dispatch, null);
    await getAllEventosAction(dispatch, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const bulkExcelEventosAction = async (
  { dispatch, addNotification },
  data
) => {
  try {
    const res = await bulkExcelEventosService(data);
    addNotification(res.data.message ?? 'Archivo cargado exitosamente');
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
