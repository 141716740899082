import { Button, Stack } from '@mui/material';
import * as React from 'react';
import { QRCode } from 'react-qrcode-logo';
import { logoOnCircle } from '../../../static/images';

const CodeQrComponent = ({
  id,
  name,
  download = false,
  maxWidth = '140',
  value,
  logo = true,
}) => {
  const ids = `codeqr-${id}`;

  const downloadCode = () => {
    const canvas = document.getElementById(ids);
    if (canvas) {
      const pngUrl = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      let downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `${id}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <div
      style={{
        height: 'auto',
        margin: '0 auto',
        maxWidth: maxWidth,
        width: '100%',
      }}
    >
      <Stack direction="column" alignItems="center">
        <QRCode
          value={value} // here you should keep the link/value(string) for which you are generation promocode
          size={maxWidth} // the dimension of the QR code (number)
          logoImage={logo ? logoOnCircle : null} // URL of the logo you want to use, make sure it is a dynamic url
          logoHeight={40}
          logoWidth={40}
          logoOpacity={1}
          quietZone={3}
          bgColor="transparent" // background color of the QR code
          // enableCORS={true} // enabling CORS, this is the thing that will bypass that DOM check
          // qrStyle="square" // type of qr code, wether you want dotted ones or the square ones
          eyeRadius={0} // radius of the promocode eye
          id={ids}
        />
        {/* <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={value}
            viewBox={`0 0 256 256`}
            id={'codeqrId'}
          /> */}
        {/* </div> */}
        {download && (
          <Button
            size="small"
            variant="text"
            onClick={downloadCode}
            sx={{ mt: 1 }}
          >
            Descargar
          </Button>
        )}
      </Stack>
    </div>
  );
};

export default CodeQrComponent;
