import * as React from 'react';
import * as Redux from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getAllTiposClasesAction } from '../TiposClasesAction';

const SelectFieldTiposClasesComponent = ({
  onChange,
  value = '',
  data,
  id,
  isAll = true,
  isAllText = false,
  isEmpty = false,
  selects = [],
}) => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposClasesStore = Redux.useSelector((state) => state.tiposClases);

  const [datas, setDatas] = React.useState([]);

  const _handlerSelects = () => {
    let items = [];
    if (selects.length > 0) {
      tiposClasesStore.all.forEach((tipo) => {
        const existSeletc = selects.some((item) => item.id === tipo.id);
        if (!existSeletc) items.push(tipo);
      });
      setDatas(items);
    } else {
      if (data) {
        setDatas(data);
      } else {
        setDatas(tiposClasesStore.all);
      }
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (!data && tiposClasesStore.all && tiposClasesStore.all.length === 0) {
        await getAllTiposClasesAction(dispatch, entidadesStore.activo.id, null);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(async () => _handlerSelects(), [selects]);

  const select = (
    <FormControl fullWidth size="small">
      <InputLabel id={`label_${id}`}>Categoría</InputLabel>
      <Select
        id={id}
        value={value?.toString()}
        labelId={`label_${id}`}
        label="Categoría"
        onChange={(e) => onChange(e.target.value)}
      >
        {isEmpty ||
          (isAll && (
            <MenuItem key={`item_${id}_000`} value={null}>
              {isAll ? 'TODOS' : 'NINGUNO'}
            </MenuItem>
          ))}

        {isAllText && (
          <MenuItem key={`item_${id}_all`} value={'all'}>
            TODOS
          </MenuItem>
        )}
        {datas.map((el, index) => {
          return (
            <MenuItem key={`item_${id}_${index}`} value={el.id}>
              {el.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  return select;
};

export default SelectFieldTiposClasesComponent;
