import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import ActionsList from '../../../components/ActionsList';
import LoadingComponent from '../../../components/Loading';
import { MORADO_COLOR } from '../../../constants/ColorsConst';
import * as CONST from '../../../constants/FieldsConst';
import { useNotification } from '../../../helpers/notification';
import { PARAMS_ENTIDAD_BANCOS } from '../../../store/Types';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import {
  deleteEntidadBancosAction,
  getAllEntidadBancosAction,
  setActivoEntidadBancosAction,
} from '../EntidadBancosAction';
import { checkModuleActionAccess } from '../../modulos/modules.helpers';

const ListadoEntidadBancos = () => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const entidadBancosStore = Redux.useSelector((state) => state.entidadBancos);
  const loginStore = Redux.useSelector((state) => state.login);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);

  

  const action = {
    read: checkModuleActionAccess('banks', 'read', loginStore),
    create: checkModuleActionAccess('banks', 'create', loginStore),
    delete: checkModuleActionAccess('banks', 'delete', loginStore),
    update: checkModuleActionAccess('banks', 'update', loginStore),
  }

  React.useEffect(() => {
    if (entidadBancosStore.all && entidadBancosStore.all.length === 0) {
      const payload = {
        entityId: entidadesStore.activo.id,
        ...entidadBancosStore.params,
      };
      dispatch({
        type: PARAMS_ENTIDAD_BANCOS,
        payload,
      });
      getAllEntidadBancosAction(dispatch, payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRows([]);
    entidadBancosStore.all.forEach((el) => {
      setRows((old) => [...old, el]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entidadBancosStore.all]);

  const _handleEditClick = async (id) => {
    const banco = entidadBancosStore.all.find((el) => el.id === id);
    await setActivoEntidadBancosAction(dispatch, banco);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteEntidadBancosAction({ dispatch, addNotification }, id, {
      ...entidadBancosStore.params,
      entityId: entidadesStore.activo.id,
    });
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
      },
    },
    {
      name: CONST.ACCOUNT_NUMBER,
      label: 'Cuenta',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={action.read ? () => _handleEditClick(tableMeta.rowData[0]) : null}
            >
              {value}
            </NameComponent>
          );
        },
      },
    },
    {
      name: CONST.BANK_DOCUMENT,
      label: 'Documento',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.ACCOUNT_TYPE,
      label: 'Tipo de cuenta',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value === 'CC' ? 'Corriente' : 'Ahorro';
        },
      },
    },
    {
      name: CONST.BANK,
      label: 'Banco',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return `(${value.code}) ${value.name}`;
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box align="left">
                <ActionsList
                  id={tableMeta.rowData[0]}
                  onEdit={action.update ? () => _handleEditClick(tableMeta.rowData[0]) : null}
                  onDelete={action.delete ? async () => {
                    const data = entidadBancosStore.all.find(
                      (el) => el.id === tableMeta.rowData[0]
                    );
                    await setActivoEntidadBancosAction(dispatch, data);
                    setIsDelete(true);
                  }: null}
                />
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={entidadBancosStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {entidadBancosStore.activo && (
        <Modal
          id="modalFormEntidadBancos"
          title={`Eliminar banco`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar esta cuenta?
              <br />
              <br />
              <Typography variant="body1" color="initial">
                <strong>Número de cuenta: </strong>{' '}
                {entidadBancosStore.activo.accountNumber}
              </Typography>
              <Typography variant="body1" color="initial">
                <strong>Banco: </strong> {entidadBancosStore.activo.bank.name}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(entidadBancosStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      )}
    </Grid>
  );
};

export default ListadoEntidadBancos;
