import { GETALL_IOTS, LOADING_GETALL_IOTS, ACTIVO_IOTS } from './IotsReducer';
import {
  deleteIotsService,
  deleteTunnelIotService,
  getAllIotsService,
  getOneIotsService,
  saveIotsService,
  saveTunnelIotService,
  setUpIotsService,
} from '../../services';
import { getAllPuertasAction } from '../puertas/PuertasAction';
import { tryCatchHelper } from '../../helpers';

export const getAllIotsAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_IOTS,
      payload: true,
    });
    const res = await getAllIotsService(entityId);
    if (res.status === 200) {
      dispatch({
        type: GETALL_IOTS,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_IOTS,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_IOTS,
      payload: false,
    });
    return null;
  } catch (e) {
    tryCatchHelper(e);
  }
};

export const saveIotsAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveIotsService(id, data);
    addNotification(
      res.data.message ?? id ? 'El iot actualizado.' : 'El iot registrado.'
    );
    await getAllIotsAction(dispatch, entityId);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoIotsAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_IOTS,
    payload: data,
  });
};

export const getOneIotsAction = async (dispatch, id) => {
  try {
    const res = await getOneIotsService(id);
    if (res.status === 200) {
      await setActivoIotsAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
  }
};

export const deleteIotsAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteIotsService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Iot eliminado.');
      await setActivoIotsAction(dispatch, null);
      await getAllIotsAction(dispatch, entityId, null);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setUpIotsAction = async (
  { dispatch, addNotification },
  id,
  dto
) => {
  try {
    const res = await setUpIotsService(id, dto);
    await setActivoIotsAction(dispatch, null);
    addNotification(res.data.message ?? 'Iot configurado.');
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const saveTunnelIotAction = async (
  { dispatch, addNotification },
  entityId,
  idIot,
  idTunnel
) => {
  try {
    const res = await saveTunnelIotService(idIot, idTunnel);
    await getAllIotsAction(dispatch, entityId, null);
    addNotification(res.data.message ?? 'El Iot asignado.');
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const deleteTunnelIotAction = async (
  { dispatch, addNotification },
  entityId,
  idIot,
  idTunnel
) => {
  try {
    const res = await deleteTunnelIotService(idIot, idTunnel);
    await getAllIotsAction(dispatch, entityId, null);
    addNotification(res.data.message ?? 'Iot eliminado de la puerta.');
    return true;
  } catch (e) {
    await getAllPuertasAction(dispatch, entityId, null);
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
