import { tryCatchHelper } from '../../../helpers';
import {
  deleteServiciosService,
  getAllServiciosService,
  getOneServiciosService,
  saveServiciosService,
} from '../../../services';
import {
  ACTIVO_SERVICIOS,
  GETALL_SERVICIOS,
  LOADING_GETALL_SERVICIOS,
} from './ServiciosReducer';

export const getAllServiciosAction = async (dispatch, params) => {
  try {
    dispatch({ type: LOADING_GETALL_SERVICIOS, payload: true });
    const res = await getAllServiciosService(params);
    if (res.status === 200) {
      dispatch({ type: GETALL_SERVICIOS, payload: res.data });
      dispatch({ type: LOADING_GETALL_SERVICIOS, payload: false });

      return res.data;
    }
    dispatch({ type: LOADING_GETALL_SERVICIOS, payload: false });
    return null;
  } catch (e) {
    dispatch({ type: LOADING_GETALL_SERVICIOS, payload: false });
    tryCatchHelper(e);
  }
};

export const saveServiciosAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res = await saveServiciosService(id, data);
    if (id) {
      await setActivoServiciosAction(dispatch, res);
    }
    addNotification(
      res.message ?? id ? 'Servicio actualizado.' : 'Servicio registrado.'
    );
    await getAllServiciosAction(dispatch, params);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoServiciosAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_SERVICIOS,
    payload: data,
  });
};

export const getOneServiciosAction = async (dispatch, id) => {
  try {
    dispatch({
      type: LOADING_GETALL_SERVICIOS,
      payload: true,
    });
    const res = await getOneServiciosService(id);
    if (res.status === 200) {
      await setActivoServiciosAction(dispatch, res.data);
      dispatch({
        type: LOADING_GETALL_SERVICIOS,
        payload: false,
      });
      return true;
    }
    await setActivoServiciosAction(dispatch, null);
    dispatch({
      type: LOADING_GETALL_SERVICIOS,
      payload: false,
    });
    return false;
  } catch (e) {
    await setActivoServiciosAction(dispatch, null);
    dispatch({
      type: LOADING_GETALL_SERVICIOS,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const deleteServiciosAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteServiciosService(id);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Servicio eliminado.');
      await setActivoServiciosAction(dispatch, null);
      await getAllServiciosAction(dispatch, params);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
