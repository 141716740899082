import { Box, Chip, Grid, Tooltip } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import ActionsList from '../../../components/ActionsList';
import LoadingComponent from '../../../components/Loading';
import { CYAN_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';
import * as CONST from '../../../constants/FieldsConst';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import { getAllBancosAction, setActivoBancosAction } from '../BancosAction';

const ListadoBancos = () => {
  const dispatch = Redux.useDispatch();

  const bancosStore = Redux.useSelector((state) => state.bancos);

  React.useEffect(() => {
    if (bancosStore.all && bancosStore.all.length === 0) {
      getAllBancosAction(dispatch, bancosStore.params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleEditClick = async (id) => {
    const recibo = bancosStore.all.find((el) => el.id === id);
    await setActivoBancosAction(dispatch, recibo);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
      },
    },
    {
      name: CONST.LOGO_URL,
      label: 'Logo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <img
              variant="square"
              alt="logourl-banco"
              src={value}
              style={{
                width: '80px',
                height: '50px',
                objectFit: 'contain',
              }}
            />
          );
        },
      },
    },
    {
      name: CONST.CODE,
      label: 'Código',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: CONST.NAME,
      label: 'Nombre',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={() => _handleEditClick(tableMeta.rowData[0])}
            >
              {value}
            </NameComponent>
          );
        },
      },
    },
    {
      name: CONST.ACTIVE,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={value ? 'Activo' : 'Bloqueado'}
                size="small"
                sx={{
                  backgroundColor: value ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box align="left">
                <ActionsList
                  id={tableMeta.rowData[0]}
                  onEdit={() => _handleEditClick(tableMeta.rowData[0])}
                />
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={bancosStore.loadingGetAll}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={bancosStore.all}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

export default ListadoBancos;
