import { validMessage } from "../constants/ValidsFormConst";

export const validFielddHelper = (key) => {
  let message;

  switch (key) {
    case 1:
      message = validMessage.required;
      break;
    case 2:
      message = validMessage.email;
      break;

    default:
      message = "";
      break;
  }

  return message;
};
