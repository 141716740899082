import { Avatar, Box, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { TYPE_ENTITY_ENUM } from '../../../constants/enumsConst';

const InfoEntidadComponent = () => {
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const activo = entidadesStore.activo;

  return (
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: [1],
      }}
    >
      <Typography
        variant="body1"
        noWrap
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Avatar
          variant="square"
          src={activo ? activo.images?.logo : 'img/logoon.png'}
          width={activo && activo.id === '2' ? '100' : '40'}
          style={{ marginRight: '10px' }}
          alt="image-info-entidad"
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              fontSize: '0.7em',
              marginBottom: '-7px',
            }}
          >
            {activo
              ? TYPE_ENTITY_ENUM[entidadesStore.entityType.name]
              : 'Twons'}
          </div>
          {activo ? activo.name : 'On Pass'}
        </Box>
      </Typography>
    </div>
  );
};

export default InfoEntidadComponent;
