import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import SettingsView from './SettingsView';
import * as ROUTES from '../../router/Routes';

const SettingsModule = () => {
  return (
    <ContainerTemplate active={ROUTES.settings} routes={[]}>
      <SettingsView />
    </ContainerTemplate>
  );
};

export default SettingsModule;
