import * as React from 'react';
import * as Redux from 'react-redux';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
} from '@mui/material';
import { Modal } from '../../../components';
import { MORADO_COLOR } from '../../../constants/ColorsConst';
import { deleteUsuariosAction } from '../UsuariosAction';
import { useNotification } from '../../../helpers/notification';

const ModalEliminarPermanenteUsuario = ({
  open,
  onClose,
  isGlobal,
  params,
}) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const usuariosStore = Redux.useSelector((state) => state.usuarios);

  const [key, setKey] = React.useState();
  const [keyError, setKeyError] = React.useState();
  return (
    isGlobal && (
      <Modal
        id="modalEliminarPermanentemente"
        title={`Eliminar usuario del sistema`}
        open={open}
        maxWidth="sm"
        onClose={() => onClose(false)}
      >
        <DialogContent>
          <DialogContentText id="alert-delete-permanente">
            ¿Seguro que quieres eliminar permanentemente este usuario del
            sistema?
            <p>
              <strong>
                {usuariosStore.activo.profile?.identificationNumber}
                {'  '}
                {usuariosStore.activo.profile?.firstName}
                {'  '}
                {usuariosStore.activo.profile?.lastName}
              </strong>
            </p>
            <Grid container spacing={0}>
              <Grid
                item
                md={8}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Ingrese la key para eliminar el usuario
              </Grid>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  size="small"
                  id={'Key'}
                  required
                  label="Key"
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                  helperText={keyError ? 'Este campo es requerido' : null}
                  error={keyError}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            onClick={async () => {
              if (key === 'bytwons') {
                await deleteUsuariosAction(
                  { dispatch, addNotification },
                  usuariosStore.activo.id,
                  params
                );
                onClose(false);
                setKey(null);
                setKeyError(false);
              } else {
                setKeyError(true);
              }
            }}
          >
            ELIMINAR PERMANENTEMENTE
          </Button>
          <Button sx={{ color: MORADO_COLOR }} onClick={() => onClose(false)}>
            CANCELAR
          </Button>
        </DialogActions>
      </Modal>
    )
  );
};

export default ModalEliminarPermanenteUsuario;
