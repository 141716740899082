import { SET_ENTIDAD_LAYOUT, SET_LOGOUT } from './Types';

const initialState = {
  vistas: [],
  entidad: null,
};

export const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ENTIDAD_LAYOUT:
      return {
        ...state,
        entidad: action.payload,
        vistas: action.payload.vistas,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
