import * as React from 'react';
import { Button, IconButton, List, ListItem, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { acceptOnlyImages } from './ButtonFile';
import AddIcon from '@mui/icons-material/Add';


const max = 10;
const ButtonFileMultipleImages = ({
  images = [],
  onChange,
  onDelete,
  accept = acceptOnlyImages,
}) => {


  const [previewImages, setPreviewImages] = React.useState(images.map(image => ({
    url: image,
  })));

  const [uploadImages, setUploadImages] = React.useState([]);

  React.useEffect(() => {
    setPreviewImages(images.map(image => ({
      url: image,
    })));
  }, [images]);

  const previewAndUpload = async (files) => {
    const allFiles = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      const promise = () => {
        return new Promise((resolve, reject) => {
            reader.onloadend = (files2) => {
              console.log(files2);
              allFiles.push({
                url: reader.result,
                name: file.name,
              });
              resolve(true);
            };
        });
      }
      await promise();
    }
    setPreviewImages([...previewImages, ...allFiles]);
    const newUploadImages = [...uploadImages, ...files];
    setUploadImages(newUploadImages);
    onChange(newUploadImages);
  }

  const handleDeleteImage = (url) => {
    const image = previewImages.find(image => image.url === url);
    // debugger
    if (image.name) {
      const newUploadImages = uploadImages.filter(img => img.name !== image.name);
      setUploadImages(newUploadImages);
      onChange(newUploadImages);
    } else if (image.url?.includes('http')) {
      onDelete(image.url);
    }
    setPreviewImages(previewImages.filter(image => image.url !== url));
  }
  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h6" gutterBottom component="div" align="center">
        Imágenes
        
        { previewImages.length < max && (
          <label htmlFor="contained-button-file">
            <input
              id="contained-button-file"
              name="contained-button-file"
              style={{ display: 'none' }}
              type="file"
              accept={accept}
              multiple
              onChange={(e) => previewAndUpload(e.target.files)}
            />
            <Button component="span" size="small">
              <AddIcon fontSize="small" />
            </Button>
          </label>
        )}
      </Typography>
      <List style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        {
          previewImages.map((image, index) => (
            <ListItem key={index} style={{ width: '100px', height: '100px', position: 'relative' }}>
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteImage(image.url)}
                style={{ position: 'absolute', zIndex: 1, top: 0, right: 0 }}
                color="error">
                <DeleteIcon style={{ width: '15px', height: '15px' }} />
              </IconButton>
              <img
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                }}
                src={image.url}
                alt={image.name}
              />
            </ListItem>
          ))
        }
      </List>
    </div>
  );
}

export default ButtonFileMultipleImages;