import { tryCatchHelper } from '../../helpers';
import {
  GETALL_BANCOS,
  LOADING_GETALL_BANCOS,
  SET_ACTIVO_BANCOS,
} from '../../store/Types';
import { getAllBancosService, saveBancosService } from './BancosService';

export const getAllBancosAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_BANCOS,
      payload: true,
    });
    const res = await getAllBancosService(params);
    dispatch({
      type: GETALL_BANCOS,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_BANCOS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_BANCOS,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const saveBancosAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res = await saveBancosService(id, data, params);
    addNotification(
      res.data.message ?? id ? 'Banco actualizado.' : 'Banco registrado.'
    );
    await getAllBancosAction(dispatch, params);
    setActivoBancosAction(dispatch, null);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoBancosAction = async (dispatch, data) => {
  dispatch({
    type: SET_ACTIVO_BANCOS,
    payload: data,
  });
};
