import {
  GETALL_ENTIDADES,
  ACTIVO_ENTIDADES,
  SET_LOGOUT,
  PARAMS_ENTIDADES,
  LOADING_GETALL_ENTIDADES,
} from '../../store/Types';

const initialState = {
  all: [],
  loadingGetAll: false,
  meta: null,
  links: null,
  activo: null,
  entityType: null,
  params: {},
};

export const entidadesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_ENTIDADES:
      return {
        ...state,
        all: action.payload,
      };
    case LOADING_GETALL_ENTIDADES:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case ACTIVO_ENTIDADES:
      return {
        ...state,
        activo: action.payload,
        entityType: action.payload ? action.payload.entityType : null,
      };
    case PARAMS_ENTIDADES:
      return {
        ...state,
        params: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
