import * as React from 'react';
import * as Redux from 'react-redux';
import MUIDataTable from 'mui-datatables';
import {
  Avatar,
  Grid,
  Tooltip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Chip,
} from '@mui/material';
import ExpandeRowSubAreas from './ExpandedRowSubAreas';
import {
  deleteSubAreasAction,
  getAllSubAreasAction,
  saveSubAreasAction,
  setActivoSubAreasAction,
} from '../SubAreasAction';
import ActionsList from '../../../components/ActionsList';
import * as CONST from '../../../constants/FieldsConst';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { LOADING_GETALL_SUBAREAS, PARAMS_SUBAREAS } from '../../../store/Types';
import { Button } from '@mui/material';
import { CYAN_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';
import Modal from '../../../components/modals/Modal';
import LoadingComponent from '../../../components/Loading';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import { useNotification } from '../../../helpers/notification';
import { SU } from '../../../constants/ConfigConst';
import { checkModuleActionAccess, isManagerUserCode } from '../../modulos/modules.helpers';

const ListadoSubAreas = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const areasStore = Redux.useSelector((state) => state.areas);
  const subareasStore = Redux.useSelector((state) => state.subareas);
  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager = !isRoot && isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isToggleActive, setIsToggleActive] = React.useState(false);

  const action = {
    read: checkModuleActionAccess('subareas', 'read', loginStore),
    create: checkModuleActionAccess('subareas', 'create', loginStore),
    delete: checkModuleActionAccess('subareas', 'delete', loginStore),
    update: checkModuleActionAccess('subareas', 'update', loginStore),
  }

  React.useEffect(() => {
    dispatch({
      type: LOADING_GETALL_SUBAREAS,
      payload: false,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const p = {
      areaId: areasStore.activo.id,
    };
    dispatch({ type: PARAMS_SUBAREAS, payload: p });
    if (!subareasStore.loadingGetAll) {
      getAllSubAreasAction(dispatch, p);
    }
  }, [areasStore.activo]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setRows([]);
    subareasStore.all.map((el) => {
      return setRows((old) => [
        ...old,
        { ...el, status: el.active ? 'Activo' : 'Bloqueado', actions: el },
      ]);
    });
  }, [subareasStore.all]);

  const _handleEditClick = async (id) => {
    const item = subareasStore.all.find((el) => el.id === id);
    await setActivoSubAreasAction(dispatch, item);
    setOpenModalForm(true);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteSubAreasAction(
      { dispatch, addNotification },
      id,
      subareasStore.params
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const _handleToggleActiveClick = async (id) => {
    setLoadingDelete(true);
    await saveSubAreasAction(
      { dispatch, addNotification },
      id,
      {
        active: !subareasStore.activo.active,
      },
      {
        areaId: areasStore.activo.id,
      }
    );
    setIsToggleActive(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.IMAGE_URL,
      label: 'Imagen',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Avatar alt={value} sx={{ width: 30, height: 30 }} src={value} />
          );
        },
      },
    },
    {
      name: CONST.NAME,
      label: 'Sub área',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={action.read ? () => _handleEditClick(tableMeta.rowData[0]) : null}
            >
              {value}
            </NameComponent>
          );
        },
      },
    },
    {
      name: CONST.STATUS,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={value}
                size="small"
                sx={{
                  backgroundColor:
                    value === 'Activo' ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ActionsList
              id={tableMeta.rowData[0]}
              onEdit={action.update ? () => _handleEditClick(tableMeta.rowData[0]) : null}
              onDelete={action.delete ? async () => {
                await setActivoSubAreasAction(dispatch, value);
                setIsDelete(true);
              } : null}
              onToggleActive={action.update ? async () => {
                await setActivoSubAreasAction(dispatch, value);
                setIsToggleActive(true);
              } : null}
              active={value.active}
            />
          );
        },
      },
    },
  ];
  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    selectableRowsHideCheckboxes: true,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <ExpandeRowSubAreas
          data={subareasStore.all.find((el) => el.id === rowData[0])}
          length={rowData.length}
        />
      );
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <LoadingComponent
          isLoading={subareasStore.loadingGetAll || loadingDelete}
          text="Cargando lista de sub áreas..."
        />
        <MUIDataTable
          title={'Lista de sub áreas'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {subareasStore.activo ? (
        <Modal
          id="modalEliminarSubArea"
          title={`Eliminar ${subareasStore.activo.name}`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar esta sub área?
              <p>
                <strong>{subareasStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(subareasStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {subareasStore.activo ? (
        <Modal
          id="modalToggleActivo"
          title={`${subareasStore.activo.active ? 'Bloquear' : 'Activar'} ${
            subareasStore.activo.name
          }`}
          open={isToggleActive}
          maxWidth="sm"
          onClose={() => setIsToggleActive(!isToggleActive)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`¿Seguro que quieres ${
                subareasStore.activo.active ? 'Bloquear' : 'Activar'
              } esta sub-area?`}
              <p>
                <strong>{subareasStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleToggleActiveClick(subareasStore.activo.id);
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsToggleActive(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoSubAreas;
