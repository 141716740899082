import React from "react";
import EntidadesView from "./EntidadesView";
import { entidades } from "../../router/Routes";
import ContainerTemplate from "../../components/template/Container";

const EntidadesModule = () => {
  return (
    <ContainerTemplate active={entidades} routes={[]}>
      <EntidadesView />
    </ContainerTemplate>
  );
};

export default EntidadesModule;
