import axios from "axios";
import { baseApi, token } from "../../services/Apis";

export const SUB_AREAS = `${baseApi}/subareas`;

export const getAllSubAreasService = async (params) => {
  const res = await axios.get(SUB_AREAS, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveSubAreasService = async (id, data) => {
  if (data.image) {
    const formData = new FormData();

    if (data.dates && data.dates.length) {
      for (let i = 0; i < data.dates.length; i++) {
        const date = data.dates[i];
        for (const key in date) {
          formData.append(`dates[${i}][${key}]`, date[key]);
        }
      }
      delete data.dates;
    } else {
      formData.append('dates[]', '');
    }

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.image;
  }

  let res;
  if (id) {
    res = await axios.put(`${SUB_AREAS}/${id}`, data, {
      headers: await token({
        isFormData: !!data.image,
      }),
    });
  } else {
    res = await axios.post(`${SUB_AREAS}`, data, {
      headers: await token({
        isFormData: !!data.image,
      }),
    });
  }
  return res.data;
};

export const getOneSubAreasService = async (id) => {
  const res = await axios.get(`${SUB_AREAS}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteSubAreasService = async (id) => {
  const res = await axios.delete(`${SUB_AREAS}/${id}`, {
    headers: await token({}),
  });
  return res;
};
