import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import dayjs from 'dayjs';
import * as React from 'react';
import { BLANCO_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';
import { dateformat } from '../../../helpers';

const options = [
  'Hoy',
  'Ayer',
  'Mañana',
  '1 Semana',
  '1 Mes',
  '3 Meses',
  '6 Meses',
  '1 Año',
  'Personalizado',
];

const dias = [
  dateformat(null, { full: true }),
  dayjs().add(-1, 'day').format('YYYY-MM-DD HH:mm:ss'),
  dayjs().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
  dayjs().add(7, 'day').format('YYYY-MM-DD HH:mm:ss'),
  dayjs().add(30, 'day').format('YYYY-MM-DD HH:mm:ss'),
  dayjs().add(90, 'day').format('YYYY-MM-DD HH:mm:ss'),
  dayjs().add(180, 'day').format('YYYY-MM-DD HH:mm:ss'),
  dayjs().add(365, 'day').format('YYYY-MM-DD HH:mm:ss'),
  null,
];

const diasPasados = [
  dateformat(null, { full: true }),
  dayjs().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
  dayjs().add(-1, 'day').format('YYYY-MM-DD HH:mm:ss'),
  dayjs().add(-7, 'day').format('YYYY-MM-DD HH:mm:ss'),
  dayjs().add(-30, 'day').format('YYYY-MM-DD HH:mm:ss'),
  dayjs().add(-90, 'day').format('YYYY-MM-DD HH:mm:ss'),
  dayjs().add(-180, 'day').format('YYYY-MM-DD HH:mm:ss'),
  dayjs().add(-365, 'day').format('YYYY-MM-DD HH:mm:ss'),
  null,
];

const BotonFechas = React.forwardRef(
  ({ futuro, dateRange, setIsRango, selectedItemIndex }, ref) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(
      selectedItemIndex || 0
    );

    const date = dias[0];

    React.useEffect(() => {
      dateRange(
        futuro ? date : diasPasados[selectedIndex],
        futuro ? dias[selectedIndex] : date
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [futuro]);

    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      const selectedIndex = index;
      setOpen(false);
      if (['Hoy', 'Ayer', 'Mañana'].includes(options[selectedIndex])) {
        dateRange(dias[index], dias[index]);
      } else {
        dateRange(
          futuro ? date : diasPasados[selectedIndex],
          futuro ? dias[index] : date
        );
        setIsRango(futuro ? dias[index] === null : diasPasados[index] === null);
      }
    };

    React.useImperativeHandle(ref, () => ({
      handleMenuItemClick,
    }));

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    };
    return (
      <React.Fragment>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            sx={{
              backgroundColor: MORADO_COLOR,
              color: BLANCO_COLOR,
              borderRight: `1px solid ${BLANCO_COLOR} !important`,
              borderColor: MORADO_COLOR,
              '&:hover': {
                backgroundColor: MORADO_COLOR,
              },
              '&:active': {
                backgroundColor: MORADO_COLOR,
              },
            }}
            onClick={() => {}}
          >
            {options[selectedIndex]}
          </Button>
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            sx={{
              backgroundColor: MORADO_COLOR,
              color: BLANCO_COLOR,
              '&:hover': {
                backgroundColor: MORADO_COLOR,
              },
              '&:active': {
                backgroundColor: MORADO_COLOR,
              },
            }}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 999,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        // disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
  }
);

export default BotonFechas;
