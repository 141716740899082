import { tryCatchHelper } from '../../../helpers';
import {
  GET_ALL_CONTACTOS,
  LOADING_GETALL_CONTACTOS,
  SET_ACTIVO_CONTACTOS,
} from '../../../store/Types';
import {
  deleteContactosService,
  getAllContactosService,
  getOneContactosService,
  saveContactosService,
} from './ContactosService';

export const getAllContactosAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_CONTACTOS,
      payload: true,
    });
    const res = await getAllContactosService({
      ...params,
      sort: 'name',
      sortDirection: 'ASC',
    });
    dispatch({
      type: GET_ALL_CONTACTOS,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_CONTACTOS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_CONTACTOS,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const saveContactosAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    const res = await saveContactosService(id, data);
    addNotification(
      res.message ?? id ? 'El contacto actualizado.' : 'El contacto registrado.'
    );
    await getAllContactosAction(dispatch, params);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoContactosAction = async (dispatch, data) => {
  dispatch({
    type: SET_ACTIVO_CONTACTOS,
    payload: data,
  });
};

export const getOneContactosAction = async (dispatch, id) => {
  try {
    const res = await getOneContactosService(id);
    await setActivoContactosAction(dispatch, res.data);
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteContactosAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteContactosService(id);
    addNotification(res.data.message ?? 'Contacto eliminado.');
    await setActivoContactosAction(dispatch, null);
    await getAllContactosAction(dispatch, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
