import axios from 'axios';
import { baseApi, token } from '../../services/Apis';
import { ENTITY_ID } from '../../constants/FieldsConst';

export const puertas = `${baseApi}/access-doors`;
export const puertasAsignar = `${baseApi}/access-door-codes`;

export const getAllPuertasService = async (entityId) => {
  let url = `${puertas}?${ENTITY_ID}=${entityId}`;
  const res = await axios.get(url, {
    headers: await token({}),
  });
  return res;
};

export const savePuertasService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${puertas}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${puertas}`, data, {
      headers: await token({}),
    });
  }
  return res;
};

export const getOnePuertasService = async (id) => {
  const res = await axios.get(`${puertas}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deletePuertasService = async (id) => {
  const res = await axios.delete(`${puertas}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const saveIotPuertaService = async (idPuerta, idIot) => {
  const res = await axios.post(
    `${puertas}/${idPuerta}/add-iot/${idIot}`,
    {},
    {
      headers: await token({}),
    }
  );
  return res.data;
};

export const deleteIotPuertaService = async (idPuerta, idIot) => {
  const res = await axios.delete(`${puertas}/${idPuerta}/remove-iot/${idIot}`, {
    headers: await token({}),
  });
  return res.data;
};

export const assignAllCodesService = async (idPuerta) => {
  const res = await axios.post(
    `${puertas}/${idPuerta}/assign-all-codes`,
    {},
    {
      headers: await token({}),
    }
  );
  return res.data;
};
