import { tryCatchHelper } from '../../../helpers';
import {
  ACTIVO_USERS_DELETE,
  GETALL_USERS_DELETE,
  LOADING_GETALL_USERS_DELETE,
} from '../../../store/Types';
import {
  deleteUsersDeleteService,
  getAllUsersDeleteService,
  getOneUsersDeleteService,
  saveUsersDeleteService,
} from './UsersDeleteService';

export const getAllUsersDeleteAction = async (dispatch) => {
  try {
    dispatch({
      type: LOADING_GETALL_USERS_DELETE,
      payload: true,
    });
    const res = await getAllUsersDeleteService();
    dispatch({
      type: GETALL_USERS_DELETE,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_USERS_DELETE,
      payload: false,
    });
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const saveUsersDeleteAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveUsersDeleteService(id, data);
    addNotification(
      res.message ?? id ? 'El usuario actualizado.' : 'El usuario registrado.'
    );
    await getAllUsersDeleteAction(dispatch, entityId);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoUsersDeleteAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_USERS_DELETE,
    payload: data,
  });
};

export const getOneUsersDeleteAction = async (dispatch, id) => {
  try {
    const res = await getOneUsersDeleteService(id);
    await setActivoUsersDeleteAction(dispatch, res.data);
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteUsersDeleteAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteUsersDeleteService(id, entityId);
    addNotification(res.data?.message ?? 'Usuario eliminado.');
    await setActivoUsersDeleteAction(dispatch, null);
    await getAllUsersDeleteAction(dispatch, entityId, null);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
