import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import * as Redux from 'react-redux';
import { ButtonFile } from '../../../../components';
import LoadingComponent from '../../../../components/Loading';
import { CLIENT } from '../../../../constants/ConfigConst';
import * as CONST from '../../../../constants/FieldsConst';
import {
  DRIVER,
  FAMILY,
  NANNY,
  OTHER,
  OWNER,
  SECURITY,
  SERVICE,
  getCodeType,
} from '../../../../constants/enumsConst';
import { dateformat } from '../../../../helpers';
import { validFielddHelper } from '../../../../helpers/ValidFieldsHelper';
import { useNotification } from '../../../../helpers/notification';
import BotonNuevoComponent from '../../../entidades/components/BotonNuevo';
import { FechaField } from '../../../usuarios/components/FieldsUsuarios';
import { saveDocumentosAction } from '../DocumentosAction';

const FormDocumentos = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const documentosStore = Redux.useSelector((state) => state.documentos);
  const tiposDocumentosStore = Redux.useSelector(
    (state) => state.tiposDocumentos
  );

  const documentTypes = tiposDocumentosStore.all;
  const isClub = entidadesStore.activo?.entityType?.id === 2;

  const activo = documentosStore.activo;

  const typeId = documentTypes.length > 0 ? documentTypes[0].id : null;
  const userTypes = typeId
    ? documentTypes.find((el) => el.id === typeId).userTypes
    : null;
  const isPublic = entidadesStore.activo?.isPublic;

  const initData = {
    [CONST.NAME]: '',
    [CONST.DATE]: dateformat(null, {}),
    [CONST.TYPE_ID]: typeId,
    [CONST.ACTIVE]: true,
    [CONST.USER_TYPES]: userTypes,
  };

  const [documento, setDocumento] = React.useState(initData);
  const [dataError, setDataRequired] = React.useState({
    [CONST.NAME]: false,
    [CONST.DATE]: false,
    [CONST.TYPE_ID]: false,
  });
  const [file, setFile] = React.useState();
  const [fileError, setFileError] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);

  React.useEffect(() => {
    if (activo === null) return;
    let data = {};
    Object.keys(documento).map(
      (key) => (data[key] = documentosStore.activo[key])
    );
    data.typeId = documentosStore.activo.type.id;
    setTimeout(() => {
      setDocumento(data);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activo]);

  const isDocumentTypeUserTypes = (userType) => {
    const type = documentTypes.find((el) => el.id === documento.typeId);
    return type?.userTypes.includes(userType);
  };

  const _handleSetDataField = (key, value) =>
    setDocumento({ ...documento, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).map((key) => {
      const val = documento[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      return va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const _handleSubmit = async () => {
    const valid = _validDataForm();

    if (!activo?.id) {
      if (file === null || file === undefined) {
        return setFileError(true);
      }
    }

    setFileError(false);
    if (valid) {
      const data = {
        ...documento,
        [CONST.ENTITY_ID]: entidadesStore.activo.id,
        file,
      };
      setLoadingSave(true);
      let res = await saveDocumentosAction(
        { dispatch, addNotification },
        entidadesStore.activo.id,
        activo ? activo.id : null,
        data
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };

  return (
    <Box component="form" sx={{}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={activo ? 'Actualizando...' : 'Guardando...'}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {fileError && (
            <Typography variant="body1" color="error">
              Falta agregar el archivo
            </Typography>
          )}
          <ButtonFile
            id={CONST.FILE}
            onFile={(file) => setFile(file)}
            file={file}
            text="Subir documento"
            sxc={{ marginTop: '5px' }}
            isFile={true}
            size="40%"
            name={activo ? activo.file : null}
            onDelete={() => setFile(null)}
            src={file ? null : activo ? activo.file : null}
            accept=".pdf"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            id={CONST.NAME}
            required
            label="Nombre"
            value={documento.name}
            onChange={(e) => _handleSetDataField(CONST.NAME, e.target.value)}
            helperText={dataError.name ? validFielddHelper(1) : null}
            error={dataError.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FechaField
            l="Fecha"
            v={documento.date}
            on={(e) => _handleSetDataField(CONST.DATE, e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl size="small" fullWidth>
            <InputLabel id="typeDocumentSelect">Tipo de documento</InputLabel>
            <Select
              id={CONST.TYPE_ID}
              label="Tipo de documento"
              labelId="typeDocumentSelect"
              size="small"
              value={documento.typeId}
              onChange={(e) => {
                const typeId = e.target.value;
                const userTypes = typeId
                  ? documentTypes.find((el) => el.id === typeId).userTypes
                  : null;
                setDocumento({
                  ...documento,
                  userTypes,
                  typeId,
                });
              }}
            >
              {documentTypes.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl size="small" fullWidth>
            <InputLabel id="selectUserType">
              Tipo de usuario permitido
            </InputLabel>
            <Select
              labelId="selectUserTypes"
              id={CONST.USER_TYPES}
              multiple={true}
              value={documento.userTypes}
              label="Tipo de usuario permitido"
              onChange={(e) =>
                _handleSetDataField(CONST.USER_TYPES, e.target.value)
              }
            >
              {!isPublic && isDocumentTypeUserTypes(OWNER) && (
                <MenuItem value={OWNER}>{getCodeType(OWNER, isClub)}</MenuItem>
              )}
              {!isPublic && isDocumentTypeUserTypes(FAMILY) && (
                <MenuItem value={FAMILY}>
                  {getCodeType(FAMILY, isClub)}
                </MenuItem>
              )}
              {!isPublic && isDocumentTypeUserTypes(SERVICE) && (
                <MenuItem value={SERVICE}>Trabajador doméstico</MenuItem>
              )}
              {!isPublic && isDocumentTypeUserTypes(NANNY) && (
                <MenuItem value={NANNY}>Niñera</MenuItem>
              )}
              {!isPublic && isDocumentTypeUserTypes(DRIVER) && (
                <MenuItem value={DRIVER}>Chofer</MenuItem>
              )}
              {!isPublic && isDocumentTypeUserTypes(OTHER) && (
                <MenuItem value={OTHER}>{getCodeType(OTHER, isClub)}</MenuItem>
              )}
              {isPublic && isDocumentTypeUserTypes(CLIENT) && (
                <MenuItem value={CLIENT}>Cliente</MenuItem>
              )}
              {isDocumentTypeUserTypes(SECURITY) && (
                <MenuItem value={SECURITY}>Seguridad</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl size="small" fullWidth>
            <InputLabel id={`label-${CONST.ACTIVE}`}>Estatus</InputLabel>
            <Select
              labelId={`label-${CONST.ACTIVE}`}
              value={documento.active}
              label="Estatus"
              onChange={(e) =>
                _handleSetDataField(CONST.ACTIVE, e.target.value)
              }
              id={CONST.ACTIVE}
            >
              <MenuItem value={true}>Activo</MenuItem>
              <MenuItem value={false}>Bloqueado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} align="center" sx={{ m: 2 }}>
          <BotonNuevoComponent
            click={_handleSubmit}
            text="GUARDAR"
            disabled={loadingSave}
            morado
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormDocumentos;
