import { tryCatchHelper } from '../../../helpers';
import {
  deleteTiposImagenClasesService,
  getAllTiposImagenClasesService,
  getOneTiposImagenClasesService,
  saveTiposImagenClasesService,
} from '../../../services';
import {
  ACTIVO_TIPOS_IMAGEN_EVENTOS,
  GETALL_TIPOS_IMAGEN_EVENTOS,
  LOADING_GETALL_TIPOS_IMAGEN_EVENTOS,
} from '../../../store/Types';

export const getAllTiposImagenClasesAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_TIPOS_IMAGEN_EVENTOS,
      payload: true,
    });
    const res = await getAllTiposImagenClasesService({ entityId });
    if (res.status === 200) {
      dispatch({
        type: GETALL_TIPOS_IMAGEN_EVENTOS,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_TIPOS_IMAGEN_EVENTOS,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_TIPOS_IMAGEN_EVENTOS,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_TIPOS_IMAGEN_EVENTOS,
      payload: false,
    });
    let message =
      e.response?.data?.message ||
      e.message ||
      'Error al intentar, realizar el proceso';
    if (Array.isArray(e.message)) message = e.message.join(', ');
    if (Array.isArray(e.response?.data?.message)) {
      message = e.response?.data?.message.join(', ');
    }
    console.warn(message);
  }
};

export const saveTiposImagenClasesAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveTiposImagenClasesService(id, data);
    addNotification(
      res.message ?? id
        ? 'El tipo de documento actualizado.'
        : 'El tipo de documento registrado.'
    );
    await getAllTiposImagenClasesAction(dispatch, entityId);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoTiposImagenClasesAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_TIPOS_IMAGEN_EVENTOS,
    payload: data,
  });
};

export const getOneTiposImagenClasesAction = async (dispatch, id) => {
  try {
    const res = await getOneTiposImagenClasesService(id);
    if (res.status === 200) {
      await setActivoTiposImagenClasesAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteTiposImagenClasesAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteTiposImagenClasesService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data?.message ?? 'Tipo de documento eliminado.');
      await setActivoTiposImagenClasesAction(dispatch, null);
      await getAllTiposImagenClasesAction(dispatch, entityId);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
