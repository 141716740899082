import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import * as ROUTES from '../../../router/Routes';
import UsersDeleteView from './UsersDeleteView';

const UsersDeletePage = () => {
  return (
    <ContainerTemplate active={ROUTES.usersDelete} routes={[]}>
      <UsersDeleteView />
    </ContainerTemplate>
  );
};

export default UsersDeletePage;
