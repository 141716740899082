import { tryCatchHelper } from '../../helpers';
import {
  GETALL_ENTIDAD_BANCOS,
  LOADING_GETALL_ENTIDAD_BANCOS,
  SET_ACTIVO_ENTIDAD_BANCOS,
} from '../../store/Types';
import {
  deleteEntidadBancosService,
  getAllEntidadBancosService,
  saveEntidadBancosService,
} from './EntidadBancosService';

export const getAllEntidadBancosAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_ENTIDAD_BANCOS,
      payload: true,
    });
    const res = await getAllEntidadBancosService(params);
    dispatch({
      type: GETALL_ENTIDAD_BANCOS,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_ENTIDAD_BANCOS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_ENTIDAD_BANCOS,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const saveEntidadBancosAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res = await saveEntidadBancosService(id, data, params);
    addNotification(
      res.data.message ?? id ? 'Banco actualizado.' : 'Banco registrado.'
    );
    await getAllEntidadBancosAction(dispatch, params);
    setActivoEntidadBancosAction(dispatch, null);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoEntidadBancosAction = async (dispatch, data) => {
  dispatch({
    type: SET_ACTIVO_ENTIDAD_BANCOS,
    payload: data,
  });
};

export const deleteEntidadBancosAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteEntidadBancosService(id);
    addNotification(res.data.message ?? 'Banco eliminado.');
    await setActivoEntidadBancosAction(dispatch, null);
    await getAllEntidadBancosAction(dispatch, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
