import { FormControl, Grid, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import * as Redux from 'react-redux';
import LoadingComponent from '../../../../components/Loading';
import * as CONST from '../../../../constants/FieldsConst';
import { validFielddHelper } from '../../../../helpers/ValidFieldsHelper';
import { useNotification } from '../../../../helpers/notification';
import BotonNuevoComponent from '../../../entidades/components/BotonNuevo';
import { saveTiposImagenClasesAction } from '../TiposImagenClasesAction';
import { ButtonFile } from '../../../../components';
import { SU } from '../../../../constants/ConfigConst';
export const acceptOnlyImages = 'image/svg+xml';
const FormTiposImagenClases = ({}) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposImagenClasesStore = Redux.useSelector(
    (state) => state.tiposImagenClases
  );
  const loginStore = Redux.useSelector((state) => state.login);

  const isRoot = loginStore.roles.indexOf(SU) !== -1;

  const activo = tiposImagenClasesStore.activo;

  const [fileImage, setFileImage] = React.useState();

  const dataDefault = {
    [CONST.NAME]: '',
  };

  const [tipoImagenClase, setTipoImagenCLase] = React.useState(dataDefault);
  const [dataError, setDataRequired] = React.useState({
    [CONST.NAME]: false,
    [CONST.MAX]: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);

  React.useEffect(() => {
    if (activo === null) {
      setTipoImagenCLase(dataDefault);
      return;
    }
    let data = {};
    Object.keys(tipoImagenClase).map(
      (key) => (data[key] = tiposImagenClasesStore.activo[key])
    );
    setTimeout(() => {
      setTipoImagenCLase(data);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activo]);

  const _handleSetDataField = (key, value) =>
    setTipoImagenCLase({ ...tipoImagenClase, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).map((key) => {
      const val = tipoImagenClase[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      return va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const _handleSubmit = async () => {
    const valid = await _validDataForm();

    if (valid) {
      setLoadingSave(true);
      const data = {
        ...tipoImagenClase,
        [CONST.ENTITY_ID]: entidadesStore.activo.id,
      };

      if (fileImage) data[CONST.IMAGE] = fileImage;

      await saveTiposImagenClasesAction(
        { dispatch, addNotification },
        entidadesStore.activo.id,
        activo ? activo.id : null,
        data
        
      );
      setLoadingSave(false);
    }
  };

  return (
    <Box component="form" sx={{ m: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={activo ? 'Actualizando...' : 'Guardando...'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ButtonFile
            id={CONST.IMG_FOTO}
            onFile={(file) => setFileImage(file)}
            file={fileImage}
            text="Subir imagén"
            sxc={{ marginTop: '5px' }}
            size="60%"
            name={tipoImagenClase.name}
            onDelete={() => setFileImage(null)}
            accept={acceptOnlyImages}
            src={
              fileImage
                ? null
                : tiposImagenClasesStore.activo
                ? tiposImagenClasesStore.activo.url
                : null
            }
          />
        </Grid>
        {
          tiposImagenClasesStore.activo?.name !== 'Default' && (
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                size="small"
                id={CONST.NAME}
                required
                label="Nombre"
                value={tipoImagenClase.name}
                onChange={(e) => _handleSetDataField(CONST.NAME, e.target.value)}
                helperText={dataError.name ? validFielddHelper(1) : null}
                error={dataError.name}
              />
            </Grid>
          )
        }
        <Grid item xs={12} md={12} align="center" sx={{ m: 2 }}>
          <BotonNuevoComponent
            click={_handleSubmit}
            text="GUARDAR"
            disabled={loadingSave}
            morado
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormTiposImagenClases;
