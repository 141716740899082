import {} from '../../../store/Types';
import {
  deleteClasesDocentesService,
  getAllClasesDocentesService,
  getOneClasesDocentesService,
  saveClasesDocentesService,
} from '../../../services';
import { tryCatchHelper } from '../../../helpers';
import {
  ACTIVO_CLASES_DOCENTES,
  GETALL_CLASES_DOCENTES,
  LOADING_GETALL_CLASES_DOCENTES,
} from './ClasesDocentesReducer';

export const getAllClasesDocentesAction = async (dispatch, params) => {
  try {
    dispatch({ type: LOADING_GETALL_CLASES_DOCENTES, payload: true });
    const res = await getAllClasesDocentesService(params);
    if (res.status === 200) {
      dispatch({ type: GETALL_CLASES_DOCENTES, payload: res.data });
      dispatch({ type: LOADING_GETALL_CLASES_DOCENTES, payload: false });

      return res.data;
    }
    dispatch({ type: LOADING_GETALL_CLASES_DOCENTES, payload: false });
    return null;
  } catch (e) {
    dispatch({ type: LOADING_GETALL_CLASES_DOCENTES, payload: false });
    tryCatchHelper(e);
  }
};

export const saveClasesDocentesAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res = await saveClasesDocentesService(id, data);
    if (id) {
      await setActivoClasesDocentesAction(dispatch, res);
    }
    addNotification(
      res.message ?? id ? 'Área actualizada.' : 'Área registrada.'
    );
    await getAllClasesDocentesAction(dispatch, params);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoClasesDocentesAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_CLASES_DOCENTES,
    payload: data,
  });
};

export const getOneClasesDocentesAction = async (dispatch, id) => {
  try {
    dispatch({
      type: LOADING_GETALL_CLASES_DOCENTES,
      payload: true,
    });
    const res = await getOneClasesDocentesService(id);
    if (res.status === 200) {
      await setActivoClasesDocentesAction(dispatch, res.data);
      dispatch({
        type: LOADING_GETALL_CLASES_DOCENTES,
        payload: false,
      });
      return true;
    }
    await setActivoClasesDocentesAction(dispatch, null);
    dispatch({
      type: LOADING_GETALL_CLASES_DOCENTES,
      payload: false,
    });
    return false;
  } catch (e) {
    await setActivoClasesDocentesAction(dispatch, null);
    dispatch({
      type: LOADING_GETALL_CLASES_DOCENTES,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const deleteClasesDocentesAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteClasesDocentesService(id);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Área eliminada.');
      await setActivoClasesDocentesAction(dispatch, null);
      await getAllClasesDocentesAction(dispatch, params);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
