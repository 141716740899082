import * as React from 'react';
import * as Redux from 'react-redux';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import { logoutAction } from '../../../store/Actions';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { MORADO_COLOR } from '../../../constants/ColorsConst';

const ButtonProfile = () => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();

  const loginStore = Redux.useSelector((state) => state.login);

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleModalClickOpen = () => {
    setOpen(true);
    setAnchorElUser(null);
  };

  const userType = loginStore.user?.userCodes[0]?.userType || loginStore.rol;

  const handleModalClose = () => setOpen(false);
  return (
    <div>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Perfil">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography
              component="label"
              variant="body"
              color="inherit"
              noWrap
              sx={{ mr: 1 }}
            >
              {loginStore.user.profile && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography
                    component="label"
                    variant="body"
                    color="inherit"
                    noWrap
                    sx={{ mr: 1 }}
                  >
                    {loginStore.user.profile.firstName}{' '}
                    {loginStore.user.profile.lastName}
                  </Typography>
                  <Typography
                    component="label"
                    variant="body"
                    color="inherit"
                    noWrap
                    sx={{ mr: 1 }}
                  >
                    {userType}
                  </Typography>
                </Box>
              )}
            </Typography>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
            </IconButton>
          </Box>
        </Tooltip>
        <Menu
          sx={{ mt: '40px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={() => setAnchorElUser(null)}
        >
          {/* <MenuItem onClick={() => { }}>
                        <FaceIcon />
                        <Typography sx={{ ml: 1 }} >Mi Perfil</Typography>
                    </MenuItem> */}
          {/* <MenuItem onClick={() => {
                        history.push(ROUTES.ajustes.to)
                    }}>
                        {ROUTES.ajustes.icon}
                        <Typography sx={{ ml: 1 }} >{ROUTES.ajustes.title}</Typography>
                    </MenuItem> */}
          {/* <Divider sx={{ my: 0.5 }} /> */}
          <MenuItem onClick={handleModalClickOpen}>
            <LogoutIcon />
            <Typography sx={{ ml: 1 }}>Cerrar Sesión</Typography>
          </MenuItem>
        </Menu>
      </Box>
      <Dialog
        open={open}
        onClose={handleModalClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>CERRAR SESIÓN</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Seguro que quieres salir del sistema?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              handleModalClose();
              logoutAction({ dispatch, history });
            }}
          >
            SALIR
          </Button>
          <Button sx={{ color: MORADO_COLOR }} onClick={handleModalClose}>
            CANCELAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ButtonProfile;
