import React from "react";
import ContainerTemplate from "../../components/template/Container";
import AccesosView from "./AccesosView";
import * as ROUTES from "../../router/Routes";

const AccesosModule = () => {
  return (
    <ContainerTemplate active={ROUTES.accesos} routes={[]}>
      <AccesosView />
    </ContainerTemplate>
  );
};

export default AccesosModule;
