import * as React from 'react';
import * as Redux from 'react-redux';
import MUIDataTable from 'mui-datatables';
import {
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from '@mui/material';
import {
  deleteContactosAction,
  getAllContactosAction,
  setActivoContactosAction,
} from '../ContactosAction';
import ActionsList from '../../../../components/ActionsList';
import * as CONST from '../../../../constants/FieldsConst';
import { Modal } from '../../../../components';
import { textLabelsTable } from '../../../invitados/components/ListadoInvitados';
import { MORADO_COLOR } from '../../../../constants/ColorsConst';
import { PARAMS_CONTACTOS } from '../../../../store/Types';
import { contactos } from '../ContactosService';
import LoadingComponent from '../../../../components/Loading';
import { downloadExcelFile } from '../../../../helpers/exportHelper';
import { useNotification } from '../../../../helpers/notification';
import { NameComponent } from '../../../usuarios/components/ListadoUsuarios';

const ListadoContactos = ({ setOpenModalForm, types = [] }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const contactosStore = Redux.useSelector((state) => state.contactos);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);

  const params = {
    ...contactosStore.params,
    entityId: entidadesStore.activo ? entidadesStore.activo.id : null,
  };

  React.useEffect(() => {
    dispatch({
      type: PARAMS_CONTACTOS,
      payload: params,
    });
    if (contactosStore.all && contactosStore.all.length === 0) {
      getAllContactosAction(dispatch, params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRows([]);
    contactosStore.all.forEach((contacto) => {
      setRows((old) => [...old, contacto]);
    });
  }, [contactosStore.all]);

  const _handleEditClick = async (id) => {
    const contacto = contactosStore.all.find((el) => el.id === id);
    await setActivoContactosAction(dispatch, contacto);
    setOpenModalForm(true);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteContactosAction({ dispatch, addNotification }, id, params);
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.AVATAR,
      label: 'Foto',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Avatar alt={value} sx={{ width: 30, height: 30 }} src={value} />
          );
        },
      },
    },
    {
      name: CONST.NAME,
      label: 'Nombre',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={() => _handleEditClick(tableMeta.rowData[0])}
            >
              {value}
            </NameComponent>
          );
        },
      },
    },
    {
      name: CONST.TYPE,
      label: 'Tipo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const contact = contactosStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          const type = types.find((el) => el.id === contact?.type?.id);
          return (
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {' '}
                {type?.name || 'N/A'}
              </Typography>
            </Box>
          );
        },
      },
    },
    {
      name: CONST.OCCUPATION,
      label: 'Ocupación',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.GENDER,
      label: 'Género',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.PHONE,
      label: 'Teléfono',
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: CONST.STATUS,
    //   label: "Estatus",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <Box
    //           sx={{
    //             width: "10em",
    //           }}
    //         >
    //           <Tooltip
    //             describeChild
    //             title="Estatus del usuario"
    //             placement="left"
    //           >
    //             <BotonNuevoComponent text={value} />
    //           </Tooltip>
    //         </Box>
    //       );
    //     },
    //   },
    // },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              align="left"
              sx={{ display: 'flex', flexDirection: 'row', width: '10%' }}
            >
              <ActionsList
                id={tableMeta.rowData[0]}
                onEdit={() => _handleEditClick(tableMeta.rowData[0])}
                onDelete={async () => {
                  const data = contactosStore.all.find(
                    (el) => el.id === tableMeta.rowData[0]
                  );
                  await setActivoContactosAction(dispatch, data);
                  setIsDelete(true);
                }}
              />
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onDownload: () => {
      const url = `${contactos}/export`;
      const name = 'contactos';
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={contactosStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {contactosStore.activo ? (
        <Modal
          id="modalFormContactos"
          title={`Eliminar`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este contacto?
              <p>
                <strong>{contactosStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(contactosStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoContactos;
