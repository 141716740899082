import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AZUL_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: MORADO_COLOR,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: AZUL_COLOR,
    boxSizing: 'border-box',
  },
}));

const SwitchFechaComponent = ({
  value,
  onChange,
  onClickLeft,
  onClickRigth,
}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography
        sx={{
          fontSize: 14,
          cursor: 'pointer',
          fontWeight: !value ? 'bold' : 'normal',
        }}
        onClick={onClickLeft}
      >
        Pasado
      </Typography>
      <AntSwitch
        defaultChecked={true}
        checked={value}
        onChange={onChange}
        inputProps={{ 'aria-label': 'ant design' }}
      />
      <Typography
        sx={{
          fontSize: 14,
          cursor: 'pointer',
          fontWeight: value ? 'bold' : 'normal',
        }}
        onClick={onClickRigth}
      >
        Futuro
      </Typography>
    </Stack>
  );
};

export default SwitchFechaComponent;
