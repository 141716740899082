import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';

export const contactos = `${baseApi}/contacts`;

export const getAllContactosService = async (params) => {
  const res = await axios.get(contactos, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveContactosService = async (id, data) => {
  delete data.type;
  if (data.id) delete data.id;
  if (data.avatar) {
    const formData = new FormData();

    if (data.userTypes?.length) {
      for (const userType of data.userTypes) {
        formData.append('userTypes[]', userType);
      }
      delete data.userTypes;
    }

    if (data.schedules && data.schedules.length) {
      for (let i = 0; i < data.schedules.length; i++) {
        const schedule = data.schedules[i];
        formData.append(`schedules[${i}][day]`, schedule.day);
        if (schedule.schedules && schedule.schedules.length) {
          for (let ii = 0; ii < schedule.schedules.length; ii++) {
            const time = schedule.schedules[ii];
            formData.append(`schedules[${i}][schedules][${ii}]`, time);
          }
        }
      }
      delete data.schedules;
    } else {
      formData.append('schedules[]', '');
    }

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.avatar;
  }
  let res;
  if (id) {
    res = await axios.put(`${contactos}/${id}`, data, {
      headers: await token({
        isFormData: !!data.avatar,
      }),
    });
  } else {
    res = await axios.post(`${contactos}`, data, {
      headers: await token({
        isFormData: !!data.avatar,
      }),
    });
  }
  return res.data;
};

export const getOneContactosService = async (id) => {
  const res = await axios.get(`${contactos}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteContactosService = async (id) => {
  const res = await axios.delete(`${contactos}/${id}`, {
    headers: await token({}),
  });
  return res;
};
