import { tryCatchHelper } from '../../helpers';
import {
  GET_ALL_INVITADOS,
  LOADING_GETALL_INVITADOS,
  SET_ACTIVO_INVITADOS,
} from '../../store/Types';
import {
  deleteInvitadosService,
  getAllInvitadosService,
  getOneInvitadosService,
} from './InvitadosService';

export const getAllInvitadosAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_INVITADOS,
      payload: true,
    });

    const res = await getAllInvitadosService(params);
    dispatch({
      type: GET_ALL_INVITADOS,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_INVITADOS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_INVITADOS,
      payload: false,
    });
    tryCatchHelper(e);
  }
};

export const setActivoInvitadosAction = async (dispatch, data) => {
  dispatch({
    type: SET_ACTIVO_INVITADOS,
    payload: data,
  });
};

export const getOneInvitadosAction = async (dispatch, id) => {
  try {
    const res = await getOneInvitadosService(id);
    await setActivoInvitadosAction(dispatch, res.data);
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteInvitadosAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    addNotification('Invitación eliminada.');
    const res = await deleteInvitadosService(id);
    await getAllInvitadosAction(dispatch, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
