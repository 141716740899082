import { tryCatchHelper } from '../../helpers';
import {
  GETALL_ENTIDADES,
  LOADING_GETALL_ENTIDADES,
  ACTIVO_ENTIDADES,
} from '../../store/Types';
import {
  deleteAssignModulesEntidadesService,
  deleteEntidadesService,
  getAllEntidadesService,
  getOneEntidadesService,
  saveAssignModulesEntidadesService,
  saveEntidadesService,
  saveImagesEntidadesService,
} from './EntidadesService';

export const getAllEntidadesAction = async (dispatch, params) => {
  try {
    dispatch({ type: LOADING_GETALL_ENTIDADES, payload: true });
    const res = await getAllEntidadesService(params);
    if (res.status === 200) {
      console.log(res.data);
      dispatch({ type: GETALL_ENTIDADES, payload: res.data.data });
      dispatch({ type: LOADING_GETALL_ENTIDADES, payload: false });
      return true;
    }
    dispatch({ type: LOADING_GETALL_ENTIDADES, payload: false });
    return null;
  } catch (e) {
    dispatch({ type: LOADING_GETALL_ENTIDADES, payload: false });
    tryCatchHelper(e);
  }
};

export const saveEntidadesAction = async (
  { dispatch, addNotification },
  data,
  { id = null, isSU },
  params
) => {
  try {
    const res = await saveEntidadesService(data, id);
    addNotification(
      res.message ?? id ? 'Entidad actualizada.' : 'Entidad registrada.'
    );
    if (isSU) await getAllEntidadesAction(dispatch, params);
    if (id) {
      await setActivoEntidadesAction(dispatch, res.data);
    }
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const saveEntidadesImagesAction = async (
  { dispatch, addNotification },
  id,
  data
) => {
  try {
    const res = await saveImagesEntidadesService(data, id);
    await setActivoEntidadesAction(dispatch, res.data);
    addNotification('Imágenes de la entidad actualizada.');
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoEntidadesAction = async (dispatch, entidad) => {
  if (!entidad) return dispatch({ type: ACTIVO_ENTIDADES, payload: null });
  dispatch({ type: LOADING_GETALL_ENTIDADES, payload: true });
  try {
    const { data } = await getOneEntidadesService(entidad.id);
    dispatch({
      type: ACTIVO_ENTIDADES,
      payload: data,
    });
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
  dispatch({ type: LOADING_GETALL_ENTIDADES, payload: false });
};

export const getOneEntidadesAction = async (dispatch, entityId) => {
  try {
    const res = await getOneEntidadesService(entityId);
    if (res.status === 200) {
      await setActivoEntidadesAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteEntidadesAction = async (
  { dispatch, addNotification },
  entityId,
  params
) => {
  try {
    const res = await deleteEntidadesService(entityId);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Entidad eliminada.');
      await setActivoEntidadesAction(dispatch, null);
      await getAllEntidadesAction(dispatch, params);
      return true;
    }
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const saveAssignModulesEntidadesAction = async (
  addNotification,
  entityId,
  data
) => {
  try {
    const res = await saveAssignModulesEntidadesService(entityId, data);
    addNotification(res.data.message ?? 'Asignación correcta.');
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const deleteAssignModulesEntidadesAction = async (
  addNotification,
  { entityId, idMod }
) => {
  try {
    const res = await deleteAssignModulesEntidadesService({ entityId, idMod });
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Asignación eliminada.');
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
