import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import Draggable from 'react-draggable';
import * as Redux from 'react-redux';
import { SU } from '../../../constants/ConfigConst';
import { useNotification } from '../../../helpers/notification';
import { isManagerUserCode } from '../../modulos/modules.helpers';
import {
  getAllCuposClasesAction,
  saveCuposClasesAction,
} from './CuposClasesAction';

const chainSize = 50;

const CuposClasesView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const cuposClasesStore = Redux.useSelector((state) => state.cuposClases);
  const clasesDocentesStore = Redux.useSelector(
    (state) => state.clasesDocentes
  );
  const clasesStore = Redux.useSelector((state) => state.clases);
  const { addNotification } = useNotification();

  const [cupos, setCupos] = React.useState(cuposClasesStore.all);

  const loginStore = Redux.useSelector((state) => state.login);

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager =
    !isRoot &&
    isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);
  // checkRootAccess(loginStore);

  React.useEffect(() => {
    if (clasesDocentesStore.activo) {
      setCupos(cuposClasesStore.all);
      setTimeout(() => {
        const canva = document.getElementById('canva_cupos');
        if (canva) {
          const top =
            clasesDocentesStore.activo.spotsShape === 'circle'
              ? (canva.scrollHeight - canva.getBoundingClientRect().height) / 2
              : 0;
          const left =
            (canva.scrollWidth - canva.getBoundingClientRect().width) / 2;
          canva.scrollTo({ top, left, behavior: 'smooth' });
        }
      }, 1000);
    }
  }, [clasesDocentesStore.activo, cuposClasesStore.all]);

  React.useEffect(() => {
    if (clasesDocentesStore.activo) {
      getAllCuposClasesAction(
        dispatch,
        clasesDocentesStore.activo.id,
        entidadesStore.activo.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clasesDocentesStore.activo]);

  let devHeight = (cupos.length / 620) * 2500 + 80;
  if (devHeight < 1500) devHeight = 1500;

  const spotRotation = clasesStore.activo?.spotRotation || 0;
  const teacherSpotRotation = clasesStore.activo?.teacherSpotRotation || 0;
  const spotNumberPosition = clasesStore.activo?.spotNumberPosition || 'top';
  const teacherSpotNumberPosition = clasesStore.activo?.teacherSpotNumberPosition || 'top';

  return (
    <Grid container spacing={1}>
      <Grid item md={12} style={{ overflow: 'auto' }}>
        {clasesDocentesStore.activo ? (
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '600px',
              overflow: 'auto',
              transition: 'scrollLeft 1s, scrollTop 1',
            }}
            id="canva_cupos"
          >
            <div
              style={{
                position: 'absolute',
                width: '1500px',
                height: `${devHeight}px`,
              }}
            >
              {cupos.map((cupo, index) => {
                return (
                  <Draggable
                    defaultPosition={cupo.position}
                    position={cupo.position}
                    grid={[chainSize / 2, chainSize / 2]}
                    scale={1}
                    disabled={isManager}
                    bounds="parent"
                    onStop={(e, data) => {
                      const position = { x: data.x, y: data.y };
                      if (
                        cupos[index].position.x === position.x &&
                        cupos[index].position.y === position.y
                      )
                        return;
                      const isAlready = cupos.filter((el, i) => {
                        if (i !== index) {
                          return (
                            el.position.x === position.x &&
                            el.position.y === position.y
                          );
                        }
                        return false;
                      });
                      if (isAlready.length) {
                        addNotification('Ya existe un cupo en esa posición', {
                          variant: 'warning',
                        });
                        return;
                      }
                      const _data = [...cupos];
                      _data[index].position = position;
                      setCupos(_data);
                      saveCuposClasesAction(
                        { dispatch, addNotification },
                        entidadesStore.activo.id,
                        clasesDocentesStore.activo.id,
                        cupo.id,
                        { position }
                      );
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        height: `${chainSize}px`,
                        width: `${chainSize}px`,
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: '#000',
                          borderRadius: '20px',
                          padding: '0 10px 2px 10px',
                          border: '1px solid #fff',
                          cursor: 'pointer',
                          flexDirection: cupo.isTeacherSpot ? 
                            teacherSpotNumberPosition === 'top' ? 'column-reverse' : 'column' :
                            spotNumberPosition === 'top' ? 'column-reverse' : 'column',
                        }}
                      >
                        <img 
                          src={clasesStore.activo?.layoutImage.url}
                          alt="layout"
                          style={{
                            userSelect: 'none',
                            pointerEvents: 'none',
                            width: '22px',
                            transform: `rotate(${cupo.isTeacherSpot ? teacherSpotRotation : spotRotation}deg)`,
                          }}
                        />
                        {
                          !cupo.isTeacherSpot ? (
                            <Typography variant="span" fontSize={11} lineHeight={1.1}> {cupo.number} </Typography>
                          ) : (
                            <Typography variant="span" fontSize={11}>Instructor</Typography>
                          )
                        }
                      </div>
                    </div>
                  </Draggable>
                );
              })}
            </div>
          </div>
        ) : (
          <Typography variant="h6">
            Seleccione una clase para ver los puestos
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default CuposClasesView;
