import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { checkRootAccess } from '../../modulos/modules.helpers';
import {
  getAllTiposRestauranteAction,
  setActivoTiposRestauranteAction,
} from './TiposRestauranteAction';
import FormTiposRestaurante from './components/FormTiposRestaurante';
import ListadoTiposRestaurante from './components/ListadoTiposRestaurante';

const TiposRestauranteView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposRestauranteStore = Redux.useSelector(
    (state) => state.tiposRestaurante
  );

  const loginStore = Redux.useSelector((state) => state.login);
  checkRootAccess(loginStore);

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoTiposRestauranteAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        {tiposRestauranteStore.all.length > 0 ? (
          <BotonNuevoComponent
            click={async () => {
              await setActivoTiposRestauranteAction(dispatch, null);
              await getAllTiposRestauranteAction(
                dispatch,
                entidadesStore.activo.id
              );
            }}
            disabled={tiposRestauranteStore.loadingGetAll}
            white
            text={
              tiposRestauranteStore.loadingGetAll
                ? 'Actualizando...'
                : 'Actualizar'
            }
          />
        ) : null}
      </Grid>
      <Grid item md={12}>
        <ListadoTiposRestaurante
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Grid>
      <Modal
        id="modalFormTiposRestaurante"
        title={`${
          tiposRestauranteStore.activo ? 'Editar' : 'Nuevo'
        } tipo de restaurante`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {tiposRestauranteStore.activo ? (
          <Typography variant="h6">
            {tiposRestauranteStore.activo.nombres}{' '}
            {tiposRestauranteStore.activo.apellidos}
          </Typography>
        ) : null}
        <FormTiposRestaurante
          activo={tiposRestauranteStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default TiposRestauranteView;
