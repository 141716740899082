import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as Redux from 'react-redux';
import ButtonFile, {
  acceptOnlyImages,
} from '../../../../components/ButtonFile';
import LoadingComponent from '../../../../components/Loading';
import * as CONST from '../../../../constants/FieldsConst';
import { validFielddHelper } from '../../../../helpers/ValidFieldsHelper';
import { useNotification } from '../../../../helpers/notification';
import { baseApi, token } from '../../../../services/Apis';
import BotonNuevoComponent from '../../../entidades/components/BotonNuevo';
import SelectFieldTiposClasesComponent from '../../tipos/components/SelectFieldTiposClases';
import { saveClasesAction } from '../ClasesAction';

const FormClases = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const clasesStore = Redux.useSelector((state) => state.clases);

  const [layoutImages, setLayoutImages] = React.useState([]);

  const [fileImage, setFileImage] = React.useState();
  const [dataForm, setDataForm] = React.useState({
    type: 'informative',
    layoutImageId: '',
    [CONST.CLASS_CATEGORY_ID]: '',
    [CONST.NAME]: '',
    [CONST.ACTIVE]: true,
    [CONST.DESCRIPTION]: '',
    spotNumberPosition: 'top',
    teacherSpotNumberPosition: 'top',
    spotRotation: 0,
    teacherSpotRotation: 0,
  });
  const [dataError, setDataRequired] = React.useState({
    [CONST.NAME]: false,
    [CONST.CLASS_CATEGORY_ID]: false,
    type: false,
    layoutImageId: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      axios
        .get(`${baseApi}/class-layout-images`, {
          headers: await token({}),
          params: {
            entityId: entidadesStore.activo.id,
          }
        })
        .then((res) => {
          if (!dataForm.layoutImageId) {
            _handleSetDataField('layoutImageId', res.data.data[0].id);
          }
          setLayoutImages(res.data.data);
        });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (clasesStore.activo === null) return;
    let data = {};
    Object.keys(dataForm).map((key) => (data[key] = clasesStore.activo[key]));

    data = {
      ...data,
      [CONST.CLASS_CATEGORY_ID]: clasesStore.activo.category.id,
      layoutImageId: clasesStore.activo.layoutImage?.id,
    };

    setTimeout(() => {
      setDataForm(data);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSetDataField = (key, value) =>
    setDataForm({ ...dataForm, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).forEach((key) => {
      const val = dataForm[key];
      const valid = val === null || val === '';
      r[key] = valid;
      va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const handleSubmit = async () => {
    const valid = await _validDataForm();
    if (valid) {
      const data = {
        ...dataForm,
        [CONST.ENTITY_ID]: entidadesStore.activo.id,
      };
      if (fileImage) data[CONST.IMAGE] = fileImage;
      setLoadingSave(true);
      let res = await saveClasesAction(
        { dispatch, addNotification },
        clasesStore.activo === null ? null : clasesStore.activo.id,
        data,
        clasesStore.params
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };
  // const tabPanel1 = <TabPanel value={1}>
  const tabPanel1 = (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <ButtonFile
          id={CONST.IMG_FOTO}
          onFile={(file) => setFileImage(file)}
          file={fileImage}
          text="Subir imagén"
          sxc={{ marginTop: '5px' }}
          size="40%"
          name={clasesStore.activo ? clasesStore.activo.name : null}
          onDelete={() => setFileImage(null)}
          accept={acceptOnlyImages}
          src={
            fileImage
              ? null
              : clasesStore.activo
              ? clasesStore.activo.imageUrl
              : null
          }
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          size="small"
          id={CONST.NAME}
          required
          label="Nombre"
          value={dataForm.name}
          onChange={(e) => _handleSetDataField(CONST.NAME, e.target.value)}
          helperText={dataError.name ? validFielddHelper(1) : null}
          error={dataError.name}
        />
      </Grid>

      

      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="level">Tipo de clase</InputLabel>
          <Select
            labelId="level"
            size="small"
            label="Tipo de clase"
            disabled={!!clasesStore.activo}
            value={dataForm.type}
            onChange={(e) => _handleSetDataField('type', e.target.value)}
            id={'type'}
          >
            <MenuItem value={'informative'}>Informativa</MenuItem>
            <MenuItem value={'reservable'}>Reservable</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {
        dataForm.type === 'reservable' && (
          <>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="level">Layout de reserva</InputLabel>
                <Select
                  labelId="level"
                  size="small"
                  label="Layout de reserva"
                  value={dataForm.layoutImageId}
                  startAdornment={
                    dataForm.layoutImageId ? (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30px' }}>
                        <img
                          src={
                            layoutImages.find(
                              (item) => item.id === dataForm.layoutImageId
                            )?.url
                          }
                          alt="layout"
                          width="20"
                          height="20"
                        />
                      </div>
                    ) : null
                  }
                  onChange={(e) =>
                    _handleSetDataField('layoutImageId', e.target.value)
                  }
                  id="layoutImageId"
                >
                  {layoutImages.map((item, index) => (
                    <MenuItem key={`item_${index}`} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="level">Posición de numero de puesto</InputLabel>
                <Select
                  labelId="level"
                  size="small"
                  label="Posición de numero de puesto"
                  value={dataForm.spotNumberPosition}
                  onChange={(e) => _handleSetDataField('spotNumberPosition', e.target.value)}
                  id={'type'}
                >
                  <MenuItem value={'top'}>Arriba</MenuItem>
                  <MenuItem value={'bottom'}>Abajo</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="level">Posición de numero de puesto (Profesor)</InputLabel>
                <Select
                  labelId="level"
                  size="small"
                  label="Posición de numero de puesto (Profesor)"
                  value={dataForm.teacherSpotNumberPosition}
                  onChange={(e) => _handleSetDataField('teacherSpotNumberPosition', e.target.value)}
                  id={'type'}
                >
                  <MenuItem value={'top'}>Arriba</MenuItem>
                  <MenuItem value={'bottom'}>Abajo</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                type="number"
                value={dataForm.spotRotation}
                label="Rotación de puestos"
                onChange={(e) =>
                  _handleSetDataField('spotRotation', e.target.value)
                }
                id={'spotRotation'}
                InputProps={{
                  inputProps: { min: 0, max: 360 },
                }}
                helperText={dataError.spotRotation ? validFielddHelper(1) : null}
                error={dataError.spotRotation}
              />
            </Grid>
            

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                type="number"
                value={dataForm.teacherSpotRotation}
                label="Rotación de puestos (Profesor)"
                onChange={(e) =>
                  _handleSetDataField('teacherSpotRotation', e.target.value)
                }
                id={'teacherSpotRotation'}
                InputProps={{
                  inputProps: { min: 0, max: 360 },
                }}
                helperText={dataError.teacherSpotRotation ? validFielddHelper(1) : null}
                error={dataError.teacherSpotRotation}
              />
            </Grid>

          </>

        )
      }

      <Grid item xs={12} md={6}>
        <SelectFieldTiposClasesComponent
          value={dataForm.classCategoryId}
          isEmpty={false}
          isAll={false}
          onChange={(value) =>
            _handleSetDataField(CONST.CLASS_CATEGORY_ID, value)
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="label_estatus">Estatus</InputLabel>
          <Select
            labelId="label_estatus"
            size="small"
            value={dataForm.active}
            label="Estatus"
            onChange={(e) => _handleSetDataField(CONST.ACTIVE, e.target.value)}
            id={CONST.ACTIVE}
          >
            <MenuItem value={true}>Activo</MenuItem>
            <MenuItem value={false}>Bloqueado</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          size="small"
          id={CONST.DESCRIPTION}
          label="Descripción"
          value={dataForm.description}
          onChange={(e) =>
            _handleSetDataField(CONST.DESCRIPTION, e.target.value)
          }
        />
      </Grid>
    </Grid>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="form" sx={{}}>
        <LoadingComponent
          isLoading={loadingSave}
          text={clasesStore.activo ? 'Actualizando...' : 'Guardando...'}
        />
        <Grid container>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="body" sx={{ fontWeight: 'bold' }}>
                  Información
                </Typography>
                <Divider sx={{ mb: 1 }}></Divider>
                {tabPanel1}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} align="center" sx={{ m: 2 }}>
            <BotonNuevoComponent
              click={handleSubmit}
              text="GUARDAR"
              disabled={loadingSave}
              morado
            />
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

FormClases.propTypes = {
  getAll: PropTypes.func,
  setOpenModalForm: PropTypes.func,
};

export default FormClases;
