import { Grid } from '@mui/material';
import React from 'react';
import { dateformat } from '../../../helpers';
import { useNotification } from '../../../helpers/notification';
import { FechaField } from '../../usuarios/components/FieldsUsuarios';

const FechasPersonalizada = ({
  mdStart = 3,
  mdEnd = 3,
  startDate,
  endDate,
  onStart = () => {},
  onEnd = () => {},
}) => {
  const { addNotification } = useNotification();

  return (
    <>
      <Grid item md={mdStart} sx={{ display: 'flex', alignItems: 'center' }}>
        <FechaField
          l="Desde"
          v={startDate}
          on={(e) => {
            const newDate = dateformat(e, { full: true });
            if (new Date(newDate) > new Date(endDate)) {
              addNotification(
                'La fecha no puede ser mayor que la fecha final',
                { variant: 'error' }
              );
              return onStart(null);
            }
            return onStart(newDate);
          }}
        />
      </Grid>
      <Grid item md={mdEnd} sx={{ display: 'flex', alignItems: 'center' }}>
        <FechaField
          l="Hasta"
          v={endDate}
          on={(e) => {
            const newDate = dateformat(e, { full: true });
            if (new Date(newDate) < new Date(startDate)) {
              addNotification(
                'La fecha no puede ser menor que la fecha inicial',
                { variant: 'error' }
              );
              return onEnd(null);
            }
            return onEnd(newDate);
          }}
        />
      </Grid>
    </>
  );
};

export default FechasPersonalizada;
