import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import EntidadBancosview from './EntidadBancosView';

const EntidadBancosModule = () => {
  return (
    <ContainerTemplate active={ROUTES.bancos} routes={[]}>
      <EntidadBancosview />
    </ContainerTemplate>
  );
};

export default EntidadBancosModule;
