import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../components';
import { SU } from '../../constants/ConfigConst';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import {
  checkModuleAccess,
  checkModuleActionAccess,
  isManagerUserCode,
} from '../modulos/modules.helpers';
import { getAllAreasAction, setActivoAreasAction } from './AreasAction';
import FormAreas from './components/FormAreas';
import ListadoAreas from './components/ListadoAreas';

const AreasView = () => {
  const dispatch = Redux.useDispatch();

  const areasStore = Redux.useSelector((state) => state.areas);

  const [openModalForm, setOpenModalForm] = React.useState(false);

  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager =
    !isRoot &&
    isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);
  const accessName = 'areas';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        {!isManager && checkModuleActionAccess('areas', 'create', loginStore) && (
          <BotonNuevoComponent
            click={async () => {
              await setActivoAreasAction(dispatch, null);
              setOpenModalForm(true);
            }}
            azul
            mr={2}
          />
        )}
        {checkModuleActionAccess('areas', 'read', loginStore) && areasStore.all.length > 0 ? (
          <BotonNuevoComponent
            click={async () => {
              await setActivoAreasAction(dispatch, null);
              await getAllAreasAction(dispatch, areasStore.params);
            }}
            white
            disabled={areasStore.loadingGetAll}
            text={areasStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'}
          />
        ) : null}
      </Grid>
      <Grid item md={12}>
        <ListadoAreas setOpenModalForm={(val) => setOpenModalForm(val)} />
      </Grid>
      <Modal
        id="modalFormAreas"
        title={`${areasStore.activo ? 'Editar' : 'Nueva'} área`}
        open={openModalForm}
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {areasStore.activo && (
          <Typography variant="h6">
            {areasStore.activo.nombres} {areasStore.activo.apellidos}
          </Typography>
        )}
        <FormAreas
          activo={areasStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default AreasView;
