import { tryCatchHelper } from '../../helpers';
import {
  GETALL_RECIBOS,
  LOADING_GETALL_RECIBOS,
  SET_ACTIVO_RECIBOS,
} from '../../store/Types';
import {
  bulkExcelRecibosService,
  deleteRecibosService,
  getAllRecibosService,
  restartPaidRecibosService,
  savePaidRecibosService,
  saveRecibosService,
} from './RecibosService';

export const getAllRecibosAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_RECIBOS,
      payload: true,
    });
    const res = await getAllRecibosService(params);
    dispatch({
      type: GETALL_RECIBOS,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_RECIBOS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_RECIBOS,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const saveRecibosAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res = await saveRecibosService(id, data, params);
    addNotification(
      res.data.message ?? id
        ? 'El recibo actualizado.'
        : 'El recibo registrado.'
    );
    await getAllRecibosAction(dispatch, params);
    if (id) setActivoRecibosAction(dispatch, res.data);
    else setActivoRecibosAction(dispatch, null);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const savePaidRecibosAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res = await savePaidRecibosService(id, data, params);
    addNotification(res.data.message ?? 'El recibo actualizado.');
    await getAllRecibosAction(dispatch, params);
    setActivoRecibosAction(dispatch, res.data);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const restartPaidRecibosAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res = await restartPaidRecibosService(id, data, params);
    addNotification(res.data.message ?? 'El recibo actualizado.');
    await getAllRecibosAction(dispatch, params);
    setActivoRecibosAction(dispatch, res.data);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoRecibosAction = async (dispatch, data) => {
  dispatch({
    type: SET_ACTIVO_RECIBOS,
    payload: data,
  });
};

export const deleteRecibosAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteRecibosService(id);
    addNotification(res.data.message ?? 'El recibo eliminado.');
    await setActivoRecibosAction(dispatch, null);
    await getAllRecibosAction(dispatch, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const bulkExcelRecibosAction = async (
  { dispatch, addNotification },
  data
) => {
  try {
    const res = await bulkExcelRecibosService(data);
    addNotification(res.data.message ?? 'Archivo cargado exitosamente');
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
