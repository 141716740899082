import * as React from 'react';
import * as Redux from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import * as CONST from '../../../constants/FieldsConst';
import Box from '@mui/material/Box';
import { saveEntidadesImagesAction } from '../EntidadesAction';
import { ButtonFile } from '../../../components';
import BotonNuevoComponent from './BotonNuevo';
import LoadingComponent from '../../../components/Loading';
import { acceptOnlyImages } from '../../../components/ButtonFile';
import { useNotification } from '../../../helpers/notification';

const accept = acceptOnlyImages;

const FormImagenesComponents = ({ openModal = () => {} }) => {
  const dispatch = Redux.useDispatch();
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const { addNotification } = useNotification();

  let activo = entidadesStore.activo;

  const [fileLogoOscuro, setFileLogoOscuro] = React.useState();
  const [imgLogoOscuro, setImgLogoOscuro] = React.useState();
  const [fileLogoClaro, setFileLogoClaro] = React.useState();
  const [imgLogoClaro, setImgLogoClaro] = React.useState();
  const [fileInicio, setFileInicio] = React.useState();
  const [imgInicio, setImgInicio] = React.useState();
  const [fileFondo, setFileFondo] = React.useState();
  const [imgFondo, setImgFondo] = React.useState();
  const [fileIotBg, setFileIotBg] = React.useState();
  const [imgIotBg, setImgIotBg] = React.useState();
  const [fileIotLogo, setFileIotLogo] = React.useState();
  const [imgIotLogo, setImgIotLogo] = React.useState();

  const [fileAccessBg, setFileAccessBg] = React.useState();
  const [imgAccessBg, setImgAccessBg] = React.useState();
  const [filePaymentBg, setFilePaymentBg] = React.useState();
  const [imgPaymentBg, setImgPaymentBg] = React.useState();
  const [fileHistoryBg, setFileHistoryBg] = React.useState();
  const [imgHistoryBg, setImgHistoryBg] = React.useState();
  const [fileReservationBg, setFileReservationBg] = React.useState();
  const [imgReservationBg, setImgReservationBg] = React.useState();
  const [fileInvitationBg, setFileInvitationBg] = React.useState();
  const [imgInvitationBg, setImgInvitationBg] = React.useState();

  const [fileAboutBg, setFileAboutBg] = React.useState();
  const [imgAboutBg, setImgAboutBg] = React.useState();

  const [loadingSave, setLoadingSave] = React.useState(false);

  React.useEffect(() => {
    if (activo === null) return;
    setImgLogoOscuro(activo.images.darkLogo);
    setImgLogoClaro(activo.images?.logo);
    setImgInicio(activo.images.banner);
    setImgFondo(activo.images.sidebar);
    setImgIotBg(activo.images.iotBg);
    setImgIotLogo(activo.images.iotLogo);
    setImgAccessBg(activo.images.accessBg);
    setImgPaymentBg(activo.images.paymentBg);
    setImgHistoryBg(activo.images.historyBg);
    setImgReservationBg(activo.images.reservationBg);
    setImgInvitationBg(activo.images.invitationBg);
    setImgAboutBg(activo.images.aboutBg);
  }, [activo]);

  const _handleSubmit = async () => {
    if (
      !fileLogoClaro &&
      !fileLogoOscuro &&
      !fileInicio &&
      !fileFondo &&
      !fileIotBg &&
      !fileIotLogo &&
      !fileAccessBg &&
      !filePaymentBg &&
      !fileHistoryBg &&
      !fileReservationBg &&
      !fileInvitationBg && 
      !fileAboutBg
    ) {
      addNotification('Debes subir al menos una imagén', { warning: true });
      return;
    }
    let data = {
      [CONST.LOGO]: fileLogoClaro,
      [CONST.DARK_LOGO]: fileLogoOscuro,
      [CONST.BANNER]: fileInicio,
      [CONST.SIDEBAR]: fileFondo,
      [CONST.IOT_BG]: fileIotBg,
      [CONST.IOT_LOGO]: fileIotLogo,
      [CONST.ACCESS_BG]: fileAccessBg,
      [CONST.PAYMENT_BG]: filePaymentBg,
      [CONST.HISTORY_BG]: fileHistoryBg,
      [CONST.RESERVATION_BG]: fileReservationBg,
      [CONST.INVITATION_BG]: fileInvitationBg,
      [CONST.ABOUT_BG]: fileAboutBg,
    };
    setLoadingSave(true);
    let res = await saveEntidadesImagesAction(
      { dispatch, addNotification },
      activo.id,
      data
    );
    openModal(!res);
    setLoadingSave(false);
  };

  return (
    <Box component="form">
      <Grid container spacing={2} sx={{ mt: loadingSave ? 2 : 0 }}>
        <LoadingComponent
          isLoading={loadingSave || loadingSave}
          text={activo ? 'Actualizando...' : 'Guardando...'}
        />
        <Grid item xs={12} md={3}>
          <ButtonFile
            id={CONST.DARK_LOGO}
            onFile={(file) => setFileLogoOscuro(file)}
            file={fileLogoOscuro}
            text="Subir logo oscuro"
            sxc={{ marginTop: '5px' }}
            onDelete={() => setFileLogoOscuro(null)}
            name={activo ? 'Logo Oscuro' : null}
            src={fileLogoOscuro ? null : imgLogoOscuro}
            accept={accept}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonFile
            id={CONST.LOGO}
            onFile={(file) => setFileLogoClaro(file)}
            file={fileLogoClaro}
            text="Subir logo inicio"
            sxc={{ marginTop: '5px' }}
            onDelete={() => setFileLogoClaro(null)}
            name={activo ? 'Logo' : null}
            st={{ backgroundColor: 'gray' }}
            src={fileLogoClaro ? null : imgLogoClaro}
            accept={accept}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonFile
            id={CONST.BANNER}
            onFile={(file) => setFileInicio(file)}
            file={fileInicio}
            text="Subir imagen de inicio"
            sxc={{ marginTop: '5px' }}
            onDelete={() => setFileInicio(null)}
            name={activo ? 'Inicio' : null}
            src={fileInicio ? null : imgInicio}
            accept={accept}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonFile
            id={CONST.SIDEBAR}
            onFile={(file) => setFileFondo(file)}
            file={fileFondo}
            text="Subir imagen sidebar"
            sxc={{ marginTop: '5px' }}
            onDelete={() => setFileFondo(null)}
            name={activo ? 'Sidebar' : null}
            src={fileFondo ? null : imgFondo}
            accept={accept}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonFile
            id={CONST.IOT_BG}
            onFile={(file) => setFileIotBg(file)}
            file={fileIotBg}
            text="Subir imagen iot background"
            sxc={{ marginTop: '5px' }}
            onDelete={() => setFileIotBg(null)}
            name={activo ? 'Iot Background' : null}
            src={fileIotBg ? null : imgIotBg}
            accept={accept}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonFile
            id={CONST.IOT_LOGO}
            onFile={(file) => setFileIotLogo(file)}
            file={fileIotLogo}
            text="Subir imagen iot logo"
            sxc={{ marginTop: '5px' }}
            onDelete={() => setFileIotLogo(null)}
            name={activo ? 'Iot Logo' : null}
            src={fileIotLogo ? null : imgIotLogo}
            accept=".png"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonFile
            id={CONST.ACCESS_BG}
            onFile={(file) => setFileAccessBg(file)}
            file={fileAccessBg}
            text="Subir imagen de fondo para la pantalla de acceso"
            sxc={{ marginTop: '5px' }}
            onDelete={() => setFileAccessBg(null)}
            name={activo ? 'Acceso' : null}
            src={fileAccessBg ? null : imgAccessBg}
            accept={accept}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonFile
            id={CONST.PAYMENT_BG}
            onFile={(file) => setFilePaymentBg(file)}
            file={filePaymentBg}
            text="Subir imagen de fondo para la pantalla de recibos"
            sxc={{ marginTop: '5px' }}
            onDelete={() => setFilePaymentBg(null)}
            name={activo ? 'Recibos' : null}
            src={filePaymentBg ? null : imgPaymentBg}
            accept={accept}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonFile
            id={CONST.HISTORY_BG}
            onFile={(file) => setFileHistoryBg(file)}
            file={fileHistoryBg}
            text="Subir imagen de fondo para la pantalla de historial"
            sxc={{ marginTop: '5px' }}
            onDelete={() => setFileHistoryBg(null)}
            name={activo ? 'Historial' : null}
            src={fileHistoryBg ? null : imgHistoryBg}
            accept={accept}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <ButtonFile
            id={CONST.RESERVATION_BG}
            onFile={(file) => setFileReservationBg(file)}
            file={fileReservationBg}
            text="Subir imagen de fondo para la pantalla de reservas"
            sxc={{ marginTop: '5px' }}
            onDelete={() => setFileReservationBg(null)}
            name={activo ? 'Reservas' : null}
            src={fileReservationBg ? null : imgReservationBg}
            accept={accept}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonFile
            id={CONST.INVITATION_BG}
            onFile={(file) => setFileInvitationBg(file)}
            file={fileInvitationBg}
            text="Subir imagen de fondo para la pantalla de invitaciones"
            sxc={{ marginTop: '5px' }}
            onDelete={() => setFileInvitationBg(null)}
            name={activo ? 'Invitaciones' : null}
            src={fileInvitationBg ? null : imgInvitationBg}
            accept={accept}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonFile
            id={CONST.ABOUT_BG}
            onFile={(file) => setFileAboutBg(file)}
            file={fileAboutBg}
            text="Subir imagen de fondo para la pantalla de about us"
            sxc={{ marginTop: '5px' }}
            onDelete={() => setFileAboutBg(null)}
            name={activo ? 'About us' : null}
            src={fileAboutBg ? null : imgAboutBg}
            accept={accept}
          />
        </Grid>

        <Grid item md={12} align="center" sx={{ p: 2 }}>
          {entidadesStore.activo && (
            <BotonNuevoComponent
              click={_handleSubmit}
              disabled={loadingSave}
              text="SUBIR IMAGÉNES"
              morado
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

FormImagenesComponents.propTypes = {
  openModal: PropTypes.func,
};

export default FormImagenesComponents;
