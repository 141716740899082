import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Tooltip,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../../components';
import ActionsList from '../../../../components/ActionsList';
import LoadingComponent from '../../../../components/Loading';
import { CYAN_COLOR, MORADO_COLOR } from '../../../../constants/ColorsConst';
import * as CONST from '../../../../constants/FieldsConst';
import { dateformat } from '../../../../helpers';
import { downloadExcelFile } from '../../../../helpers/exportHelper';
import { useNotification } from '../../../../helpers/notification';
import { textLabelsTable } from '../../../invitados/components/ListadoInvitados';
import { NameComponent } from '../../../usuarios/components/ListadoUsuarios';
import {
  deleteNotificacionesAction,
  getAllNotificacionesAction,
  saveNotificacionesAction,
  setActivoNotificacionesAction,
} from '../NotificacionesAction';
import { notificationsApi } from '../NotificacionesService';

const ListadoNotificaciones = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const notificacionesStore = Redux.useSelector(
    (state) => state.notificaciones
  );

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isToggleActive, setIsToggleActive] = React.useState(false);

  React.useEffect(() => {
    if (notificacionesStore.all && notificacionesStore.all.length === 0) {
      getAllNotificacionesAction(dispatch, entidadesStore.activo?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRows([]);
    notificacionesStore.all.forEach((el) => {
      setRows((old) => [
        ...old,
        {
          ...el,
          createdAt: dateformat(el.createdAt, {}),
          title: el.title,
          body: el.body,
          active: el.active ? 'Activo' : 'Bloqueado',
          actions: el,
        },
      ]);
    });
  }, [notificacionesStore.all]);

  const _handleEditClick = async (id) => {
    const data = notificacionesStore.all.find((el) => el.id === id);
    await setActivoNotificacionesAction(dispatch, data);
    setOpenModalForm(true);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteNotificacionesAction(
      { dispatch, addNotification },
      id,
      entidadesStore.activo.id
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const _handleToggleActiveClick = async (id) => {
    setLoadingDelete(true);
    await saveNotificacionesAction(
      { dispatch, addNotification },
      entidadesStore.activo.id,
      id,
      {
        active: !notificacionesStore.activo.active,
      }
    );
    setIsToggleActive(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Fecha',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'title',
      label: 'Título',
      options: {
        filter: true,
        sort: true
      },
    },
    {
      name: 'body',
      label: 'Cuerpo',
      options: {
        filter: true,
        sort: true
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onSearchChange: (text) => {
      console.log('buscardor', text);
    },
    onDownload: () => {
      const url = `${notificationsApi}/export`;
      const name = 'notificaciones';
      const params = { entityId: entidadesStore.activo.id };
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={notificacionesStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {notificacionesStore.activo && (
        <Modal
          id="modalFormNotificaciones"
          title={`Eliminar`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este documento?
              <p>
                <strong>{notificacionesStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(notificacionesStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      )}

      {notificacionesStore.activo && (
        <Modal
          id="modalToggleActivo"
          title={`${
            notificacionesStore.activo.active ? 'Bloquear' : 'Activar'
          } ${notificacionesStore.activo.name.substring(0, 40)}${
            notificacionesStore.activo.name.length > 40 ? '...' : ''
          }`}
          open={isToggleActive}
          maxWidth="sm"
          onClose={() => setIsToggleActive(!isToggleActive)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`¿Seguro que quieres ${
                notificacionesStore.activo.active ? 'Bloquear' : 'Activar'
              } este documento?`}
              <p>
                <strong>{notificacionesStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleToggleActiveClick(notificacionesStore.activo.id);
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsToggleActive(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      )}
    </Grid>
  );
};

export default ListadoNotificaciones;
