import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import * as ROUTES from '../../../router/Routes';
import TiposEventosView from './TiposEventosView';

const TiposEventosPage = () => {
  return (
    <ContainerTemplate active={ROUTES.tiposEventos} routes={[]}>
      <TiposEventosView />
    </ContainerTemplate>
  );
};

export default TiposEventosPage;
