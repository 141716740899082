import { Grid, Paper } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { checkRootAccess } from '../../modulos/modules.helpers';
import {
  getAllTiposImagenClasesAction,
  setActivoTiposImagenClasesAction,
} from './TiposImagenClasesAction';
import FormTiposImagenClases from './components/FormTiposImagenClases';
import ListadoTiposImagenClases from './components/ListadoTiposImagenClases';

const TiposImagenClasesView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposEventosStore = Redux.useSelector((state) => state.tiposEventos);

  const loginStore = Redux.useSelector((state) => state.login);
  checkRootAccess(loginStore);

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoTiposImagenClasesAction(dispatch, null);
          }}
          azul
          mr={2}
        />
          <BotonNuevoComponent
            click={async () => {
              await setActivoTiposImagenClasesAction(dispatch, null);
              await getAllTiposImagenClasesAction(
                dispatch,
                entidadesStore.activo.id
              );
            }}
            disabled={tiposEventosStore.loadingGetAll}
            white
            text={
              tiposEventosStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'
            }
          />
      </Grid>
      <Grid item md={4}>
        <Paper elevation={3}>
          <FormTiposImagenClases />
        </Paper>
      </Grid>
      <Grid item md={8}>
        <ListadoTiposImagenClases
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Grid>
    </Grid>
  );
};

export default TiposImagenClasesView;
