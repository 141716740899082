import * as React from "react";
import { Box } from "@mui/material";
import BreadcrumbTemplate from "./Breadcrumb";

const ContainerTemplate = ({ children, active, routes }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <BreadcrumbTemplate active={active} routes={routes} />
      {children}
    </Box>
  );
};

export default ContainerTemplate;
