import {
  GETALL_DOCUMENTOS,
  LOADING_GETALL_DOCUMENTOS,
  ACTIVO_DOCUMENTOS,
} from '../../../store/Types';
import {
  deleteDocumentosService,
  getAllDocumentosService,
  getOneDocumentosService,
  saveDocumentosService,
} from '../../../services';
import { tryCatchHelper } from '../../../helpers';

export const getAllDocumentosAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_DOCUMENTOS,
      payload: true,
    });
    const res = await getAllDocumentosService({ entityId });
    dispatch({
      type: GETALL_DOCUMENTOS,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_DOCUMENTOS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const saveDocumentosAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveDocumentosService(id, data);
    addNotification(
      res.message ?? id
        ? 'El documento actualizado.'
        : 'El documento registrado.'
    );
    await getAllDocumentosAction(dispatch, entityId);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoDocumentosAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_DOCUMENTOS,
    payload: data,
  });
};

export const getOneDocumentosAction = async (dispatch, id) => {
  try {
    const res = await getOneDocumentosService(id);
    await setActivoDocumentosAction(dispatch, res.data);
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteDocumentosAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteDocumentosService(id, entityId);
    addNotification(res.data?.message ?? 'Documento eliminado.');
    await setActivoDocumentosAction(dispatch, null);
    await getAllDocumentosAction(dispatch, entityId, null);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
