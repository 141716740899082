import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import DashboardView from './DashboardView';

async function clearLocalStorage() {
  localStorage.clear();
}

const DashboardPage = () => {
  const history = useHistory();

  try {
    return (
      <ContainerTemplate active={ROUTES.dashboard} routes={[]}>
        <DashboardView />
      </ContainerTemplate>
    );
  } catch (error) {
    clearLocalStorage();
    alert('Sesión inesperada, vuelva a intentarlo.');
    history.push('/login');
  }
};

export default DashboardPage;
