import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import * as ROUTES from '../../../router/Routes';
import TiposRestauranteView from './TiposRestauranteView';

const TiposRestaurantePage = () => {
  return (
    <ContainerTemplate active={ROUTES.tiposRestaurante} routes={[]}>
      <TiposRestauranteView />
    </ContainerTemplate>
  );
};

export default TiposRestaurantePage;
