import {
  CLEAR_ALL,
  GETALL_RECIBOS,
  LOADING_GETALL_RECIBOS,
  PARAMS_RECIBOS,
  SET_ACTIVO_RECIBOS,
  SET_LOGOUT,
} from '../../store/Types';

const initialState = {
  all: [],
  meta: null,
  links: null,
  activo: null,
  params: {
    date: null,
    perMonth: true,
  },
  loadingGetAll: false,
};

export const recibosReducer = (state = initialState, action) => {
  const sortByEnumValue = (a, b) => {
    const order = ['pending', 'debt', 'pay']; // Desired order
    const enumValueA = order.indexOf(a.status); // Get index of enum value in order array
    const enumValueB = order.indexOf(b.status);
    return enumValueA - enumValueB;
  };

  switch (action.type) {
    case GETALL_RECIBOS:
      return {
        ...state,
        all: action.payload.data.sort(sortByEnumValue),
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    case SET_ACTIVO_RECIBOS:
      return {
        ...state,
        activo: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case LOADING_GETALL_RECIBOS:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case PARAMS_RECIBOS:
      return {
        ...state,
        params: action.payload,
      };
    default:
      return state;
  }
};
