import * as React from 'react';
import * as Redux from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import * as CONST from '../../../constants/FieldsConst';
import Box from '@mui/material/Box';
import { savePuertasAction } from '../PuertasAction';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { SU } from '../../../constants/ConfigConst';
import LoadingComponent from '../../../components/Loading';
import {
  accessTypeAllowed,
  accessTypeAllowedLabel,
} from '../enum/access-type-allowed.enum';
import { useNotification } from '../../../helpers/notification';

const FormPuertas = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const puertasStore = Redux.useSelector((state) => state.puertas);

  const activo = puertasStore.activo;
  const initData = {
    [CONST.NAME]: '',
    [CONST.RELAY_NUM]: 0,
    [CONST.ACTIVE]: true,
    [CONST.IS_MAIN]: false,
    [CONST.ACCESS_TYPE_ALLOWED]: [
      accessTypeAllowed.SCAN,
      accessTypeAllowed.REMOTE,
    ],
  };

  const [puerta, setPuerta] = React.useState(initData);
  const [dataError, setDataRequired] = React.useState({
    [CONST.NAME]: false,
    [CONST.RELAY_NUM]: loginStore.rol !== SU,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);


  React.useEffect(() => {
    if (activo === null) return;
    setTimeout(() => {
      setPuerta(activo);
    }, 300);
  }, [activo]);

  const _handleSetDataField = (key, value) =>
    setPuerta({ ...puerta, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).map((key) => {
      const val = puerta[key];
      const valid = val === null || val === '' || val === undefined;
      r[key] = valid;
      return va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const handleSubmit = async () => {
    const valid = _validDataForm();

    if (valid) {
      const data = {
        [CONST.NAME]: puerta.name,
        [CONST.RELAY_NUM]: parseInt(puerta.relayNum),
        [CONST.ACTIVE]: puerta.active,
        [CONST.IS_MAIN]: puerta.isMain,
        [CONST.ENTITY_ID]: entidadesStore.activo.id,
        [CONST.ACCESS_TYPE_ALLOWED]: puerta[CONST.ACCESS_TYPE_ALLOWED],
      };

      setLoadingSave(true);
      let res = await savePuertasAction({ dispatch, addNotification }, data, {
        entityId: entidadesStore.activo.id,
        id: activo === null ? null : activo.id,
      });
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };

  return (
    <Box component="form" sx={{}}>
      <LoadingComponent
        isLoading={loadingSave}
        text={puertasStore.activo ? 'Actualizando...' : 'Guardando...'}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={loginStore.rol === SU ? 6 : 9}>
          <TextField
            fullWidth
            size="small"
            id={CONST.NAME}
            required
            label="Nombre"
            value={puerta.name}
            onChange={(e) => _handleSetDataField(CONST.NAME, e.target.value)}
            helperText={dataError.name ? validFielddHelper(1) : null}
            error={dataError.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id={`${CONST.ACTIVE}_select`}>Estatus</InputLabel>
            <Select
              labelId={`${CONST.ACTIVE}_select`}
              label="Estatus"
              size="small"
              value={puerta.active}
              onChange={(e) =>
                _handleSetDataField(CONST.ACTIVE, e.target.value)
              }
              id={CONST.ACTIVE}
            >
              <MenuItem value={true}>ACTIVO</MenuItem>
              <MenuItem value={false}>BLOQUEADO</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {loginStore.rol === SU ? (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              label="Comando relay"
              size="small"
              type="number"
              value={puerta.relayNum}
              onChange={(e) =>
                _handleSetDataField(CONST.RELAY_NUM, e.target.value)
              }
              id={CONST.RELAY_NUM}
              helperText={dataError.relayNum ? validFielddHelper(1) : null}
              error={dataError.relayNum}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id={`${CONST.ACCESS_TYPE_ALLOWED}_select`}>
              Tipos de accesos permitidos
            </InputLabel>
            <Select
              labelId={`${CONST.ACCESS_TYPE_ALLOWED}_select`}
              label="Tipos de accesos permitidos"
              size="small"
              multiple={true}
              value={puerta[CONST.ACCESS_TYPE_ALLOWED]}
              onChange={(e) =>
                _handleSetDataField(CONST.ACCESS_TYPE_ALLOWED, e.target.value)
              }
              id={CONST.ACCESS_TYPE_ALLOWED}
            >
              <MenuItem value={accessTypeAllowed.SCAN}>
                {accessTypeAllowedLabel.SCAN}
              </MenuItem>
              <MenuItem value={accessTypeAllowed.REMOTE}>
                {accessTypeAllowedLabel.REMOTE}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  value={puerta.isMain}
                  checked={puerta.isMain}
                  onChange={(e) => {
                    const newPuerta = { ...puerta, isMain: e.target.checked };
                    setPuerta(newPuerta);
                  }}
                  name={CONST.IS_MAIN}
                />
              }
              label="Puerta principal"
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={loginStore.rol === SU ? 12 : 3}
          sx={{ pl: 2, pt: 0.5 }}
          align="center"
        >
          <BotonNuevoComponent
            click={handleSubmit}
            text="GUARDAR"
            disabled={loadingSave}
            morado
          />
        </Grid>
      </Grid>
    </Box>
  );
};

FormPuertas.propTypes = {
  setOpenModalForm: PropTypes.func,
};

export default FormPuertas;
