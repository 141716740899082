import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import Bancosview from './BancosView';

const BancosModule = () => {
  return (
    <ContainerTemplate active={ROUTES.banks} routes={[]}>
      <Bancosview />
    </ContainerTemplate>
  );
};

export default BancosModule;
