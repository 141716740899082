import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const codigosApi = `${baseApi}/codes`;

export const getAllCodigosService = async (params) => {
  const res = await axios.get(codigosApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveCodigosService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${codigosApi}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${codigosApi}`, data, {
      headers: await token({}),
    });
  }
  return res;
};

export const getOneCodigosService = async (id) => {
  const res = await axios.get(`${codigosApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const getSearchCodesCodigosService = async (entityId, data) => {
  const res = await axios.get(codigosApi, {
    headers: await token({}),
    params: {
      entityId,
    },
  });
  return res;
};

export const deleteCodigosService = async (id) => {
  const res = await axios.delete(`${codigosApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const savePuertaCodigoService = async (
  idCode,
  idPuerta,
  accessTypeAllowed
) => {
  const res = await axios.post(
    `${codigosApi}/${idCode}/add-access-door/${idPuerta}`,
    { accessTypeAllowed },
    {
      headers: await token({}),
    }
  );
  return res.data;
};

export const deletePuertaCodigoService = async (idCode, idPuerta) => {
  const res = await axios.delete(
    `${codigosApi}/${idCode}/remove-access-door/${idPuerta}`,
    {
      headers: await token({}),
    }
  );
  return res;
};

export const updateCodeService = async (id, data) => {
  return await axios
    .put(`${codigosApi}/${id}`, data, {
      headers: await token({}),
    })
    .then((res) => res.data);
};

export const editPuertaCodigoService = async (
  idPuertaCode,
  accessTypeAllowed
) => {
  const res = await axios.put(
    `${baseApi}/access-door-codes/${idPuertaCode}`,
    { accessTypeAllowed },
    {
      headers: await token({}),
    }
  );
  return res.data;
};
