import * as React from 'react';
import * as Redux from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { Modal } from '../../../components';
import ListadoClases from './components/ListadoClases';
import FormClases from './components/FormClases';
import { getAllClasesAction, setActivoClasesAction } from './ClasesAction';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { checkModuleAccess, isManagerUserCode } from '../../modulos/modules.helpers';
import { SU } from '../../../constants/ConfigConst';

const ClasesView = () => {
  const dispatch = Redux.useDispatch();

  const clasesStore = Redux.useSelector((state) => state.clases);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const [openModalForm, setOpenModalForm] = React.useState(false);
  const loginStore = Redux.useSelector((state) => state.login);

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager = !isRoot && isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);

  const accessName = 'classes';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        {
          !isManager && (
            <BotonNuevoComponent
              click={async () => {
                await setActivoClasesAction(dispatch, null);
                setOpenModalForm(true);
              }}
              azul
              mr={2}
            />
          )
        }
        <BotonNuevoComponent
          click={async () => {
            await setActivoClasesAction(dispatch, null);
            await getAllClasesAction(dispatch, clasesStore.params);
          }}
          white
          disabled={clasesStore.loadingGetAll}
          text={clasesStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'}
        />
      </Grid>
      <Grid item md={12}>
        <ListadoClases setOpenModalForm={(val) => setOpenModalForm(val)} />
      </Grid>
      <Modal
        id="modalFormClases"
        title={`${clasesStore.activo ? 'Editar' : 'Nueva'} clase`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {clasesStore.activo && (
          <Typography variant="h6">
            {clasesStore.activo.nombres} {clasesStore.activo.apellidos}
          </Typography>
        )}
        <FormClases
          activo={clasesStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default ClasesView;
