import {
  Avatar,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../../components';
import ActionsList from '../../../components/ActionsList';
import LoadingComponent from '../../../components/Loading';
import { CYAN_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';
import { SU } from '../../../constants/ConfigConst';
import * as CONST from '../../../constants/FieldsConst';
import { STATUS_ENUM, TYPE_ENTITY_ENUM } from '../../../constants/enumsConst';
import { downloadExcelFile } from '../../../helpers/exportHelper';
import { useNotification } from '../../../helpers/notification';
import * as ROUTES from '../../../router/Routes';
import { getDashboardAction } from '../../dashboard/DashboardAction';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import {
  deleteEntidadesAction,
  saveEntidadesAction,
  setActivoEntidadesAction,
} from '../EntidadesAction';
import { entidades } from '../EntidadesService';

const ListadoEntidades = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const loginStore = Redux.useSelector((state) => state.login);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isToggleActive, setIsToggleActive] = React.useState(false);

  const isRoot = loginStore.roles.indexOf(SU) !== -1;

  React.useEffect(() => {
    setRows([]);
    entidadesStore.all.forEach((el) => {
      setRows((old) => [
        ...old,
        {
          ...el,
          [CONST.NAME]: `${el.name}|${el.address}`,
          [CONST.ENTITY_TYPE_ID]: TYPE_ENTITY_ENUM[el.entityType.name],
          actions: el,
        },
      ]);
    });
  }, [entidadesStore.all]);

  const _handleEditClick = async (id) => {
    const data = entidadesStore.all.find((el) => el.id === id);
    await setActivoEntidadesAction(dispatch, data);
    setOpenModalForm(true);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteEntidadesAction(
      { dispatch, addNotification },
      id,
      entidadesStore.activo.id,
      entidadesStore.params
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const _handleToggleActiveClick = async (id) => {
    setLoadingDelete(true);
    await saveEntidadesAction(
      { dispatch, addNotification },
      {
        status: entidadesStore.activo.active
          ? STATUS_ENUM.BLOCKED
          : STATUS_ENUM.ACTIVE,
      },
      { id, isSU: isRoot }
    );
    setIsToggleActive(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.LOGO,
      label: 'Logo',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = entidadesStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          return (
            <Avatar
              alt={value}
              sx={{ width: 30, height: 30 }}
              src={data?.images?.logo}
            />
          );
        },
      },
    },
    {
      name: CONST.NAME,
      label: 'Entidad',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          var texts = value.split('|');
          const activeEntity =
            entidadesStore.activo !== null &&
            entidadesStore.activo.id === tableMeta.rowData[0];
          const data = entidadesStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          return (
            <>
              <NameComponent
                onClick={() => {
                  if (activeEntity) return;
                  setActivoEntidadesAction(dispatch, data);
                  getDashboardAction(dispatch, {
                    entityId: data.id,
                  });
                  history.push(ROUTES.dashboard.to);
                }}
              >
                {texts[0]}
              </NameComponent>
              <Typography variant="body">{texts[1]}</Typography>
            </>
          );
        },
      },
    },
    {
      name: CONST.ENTITY_TYPE_ID,
      label: 'Tipo',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.STATUS,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={value}
                size="small"
                sx={{
                  backgroundColor:
                    value === 'ACTIVE' ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          );
        },
      },
    },
  ];

  if (isRoot) {
    columns.push({
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack direction="row">
              <ActionsList
                id={tableMeta.rowData[0]}
                onEdit={() => _handleEditClick(tableMeta.rowData[0])}
                onDelete={async () => {
                  await setActivoEntidadesAction(dispatch, value);
                  setIsDelete(true);
                }}
                onToggleActive={async () => {
                  await setActivoEntidadesAction(dispatch, value);
                  setIsToggleActive(true);
                }}
                active={value.active}
              />
            </Stack>
          );
        },
      },
    });
  }

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },

    onDownload: () => {
      const url = `${entidades}/export`;
      const name = 'entidades';
      const params = {};
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },

    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={entidadesStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {entidadesStore.activo ? (
        <Modal
          id="modalFormEntidades"
          title={`Eliminar`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar esta entidad?
              <p>
                <strong>{entidadesStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(entidadesStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {entidadesStore.activo ? (
        <Modal
          id="modalToggleActivo"
          title={`${entidadesStore.activo.active ? 'Bloquear' : 'Activar'} ${
            entidadesStore.activo.name
          }`}
          open={isToggleActive}
          maxWidth="sm"
          onClose={() => setIsToggleActive(!isToggleActive)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`¿Seguro que quieres ${
                entidadesStore.activo.active ? 'Bloquear' : 'Activar'
              } esta entidad?`}
              <p>
                <strong>{entidadesStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleToggleActiveClick(entidadesStore.activo.id);
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsToggleActive(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoEntidades;
