import { useSnackbar } from 'notistack';

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const addNotification = (message, options = {}) => {
    const defaultOptions = {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    };

    const actualOptions = {
      ...defaultOptions,
      ...options,
    };

    if (options.error) actualOptions.variant = 'error';
    if (options.warning) actualOptions.variant = 'warning';

    if (options.vertical) {
      options.anchorOrigin = {
        vertical: options.vertical,
        horizontal: options.horizontal,
      };
    }

    const key = enqueueSnackbar(message, actualOptions);

    return {
      close: () => closeSnackbar(key),
    };
  };

  return {
    addNotification,
  };
};
