import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Box, Grid, Paper, Tab, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../../components';
import * as ROUTES from '../../../router/Routes';
import { checkModuleAccess } from '../../modulos/modules.helpers';
import FormServicios from '../servicios/components/FormServicios';
import InfoServiciosComponent from './InfoServicios';

const PanelServiciosView = () => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const serviciosStore = Redux.useSelector((state) => state.servicios);
  const serviciosDocentesStore = Redux.useSelector(
    (state) => state.serviciosDocentes
  );
  const loginStore = Redux.useSelector((state) => state.login);

  const accessName = 'services';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  React.useEffect(() => {
    if (serviciosStore.activo === null || serviciosStore.activo === undefined) {
      history.push(ROUTES.panelServicios.to);
      return;
    }
  }, [serviciosStore.all]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!serviciosStore.activo) {
    window.location.href = '/servicios';
  }

  const [openModalForm, setOpenModalForm] = React.useState(false);
  const [openModalFormTeacher, setOpenModalFormTeacher] = React.useState(false);

  const [tab, setTab] = React.useState(1);

  const tabPanel = (
    <Paper elevation={2}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                classes={{
                  indicator: {
                    backgroundColor: 'red',
                  },
                }}
                onChange={(e, nv) => setTab(nv)}
                aria-label="tabs-users"
              >
                <Tab label="Profesores" value={1} />
                <Tab label="Puestos" value={2} />
                <Tab label="Reservaciones" value={3} />
              </TabList>
            </Box>
          </TabContext>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Grid container spacing={2} sx={{ marginTop: '1px' }}>
      <Grid item md={4}>
        <InfoServiciosComponent
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Grid>
      <Grid item md={8}>
        {tabPanel}
      </Grid>
      <Modal
        id="modalFormServicios"
        title={`${serviciosStore.activo ? 'Editar' : 'Nueva'} área`}
        open={openModalForm}
        onClose={() => setOpenModalForm(!openModalForm)}
        maxWidth="sm"
      >
        {serviciosStore.activo && (
          <Typography variant="h6">
            {serviciosStore.activo.nombres} {serviciosStore.activo.apellidos}
          </Typography>
        )}
        <FormServicios
          activo={serviciosStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default PanelServiciosView;
