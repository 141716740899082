import {
  GETALL_CODIGOS,
  LOADING_GETALL_CODIGOS,
  ACTIVO_CODIGOS,
  SET_LOGOUT,
  PARAMS_CODIGOS,
  CLEAR_ALL,
  SEARCH_CODES_CODIGOS,
} from '../../store/Types';

const initialState = {
  all: [],
  loadingGetAll: false,
  meta: null,
  links: null,
  activo: null,
  params: {
    sort: 'code',
    sortDirection: 'ASC',
    page: 1,
    limit: 15,
  },
  searchCodes: [],
};

export const codigosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_CODIGOS:
      const result = {
        ...state,
        all: action.payload.data,
      };
      if (action.payload.links) result.links = action.payload.links;
      if (action.payload.meta) result.meta = action.payload.meta;
      return result;
    case ACTIVO_CODIGOS:
      return {
        ...state,
        activo: action.payload,
      };
    case SET_LOGOUT || CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    case LOADING_GETALL_CODIGOS:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case PARAMS_CODIGOS:
      return {
        ...state,
        params: action.payload,
      };
    case SEARCH_CODES_CODIGOS:
      return {
        ...state,
        searchCodes: action.payload,
      };
    default:
      return state;
  }
};
