import { SET_LOGOUT, CLEAR_ALL } from '../../../store/Types';

export const GETALL_Cupos_CLASES = 'GETALL_Cupos_CLASES';
export const ACTIVO_Cupos_CLASES = 'ACTIVO_Cupos_CLASES';
export const LOADING_GETALL_Cupos_CLASES = 'LOADING_GETALL_Cupos_CLASES';
export const PARAMS_Cupos_CLASES = 'PARAMS_Cupos_CLASES';

const initialState = {
  all: [],
  activo: null,
  loadingGetAll: false,
  params: {},
};

export const CuposClasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_Cupos_CLASES:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    case ACTIVO_Cupos_CLASES:
      return {
        ...state,
        activo: action.payload,
      };
    case LOADING_GETALL_Cupos_CLASES:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case PARAMS_Cupos_CLASES:
      return {
        ...state,
        params: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
