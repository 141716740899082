import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import * as ROUTES from '../../router/Routes';
import MetodosPagoView from './MetodosPagoView';

const MetodosPagoPage = () => {
  return (
    <ContainerTemplate active={ROUTES.metodosPago} routes={[]}>
      <MetodosPagoView />
    </ContainerTemplate>
  );
};

export default MetodosPagoPage;
