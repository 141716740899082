import {
  GETALL_CODIGOS,
  LOADING_GETALL_CODIGOS,
  ACTIVO_CODIGOS,
  SEARCH_CODES_CODIGOS,
} from '../../store/Types';
import {
  deleteCodigosService,
  deletePuertaCodigoService,
  getAllCodigosService,
  getSearchCodesCodigosService,
  getOneCodigosService,
  saveCodigosService,
  savePuertaCodigoService,
  editPuertaCodigoService,
} from '../../services';
import { tryCatchHelper } from '../../helpers';

export const getAllCodigosAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_CODIGOS,
      payload: true,
    });
    const res = await getAllCodigosService(params);
    dispatch({
      type: GETALL_CODIGOS,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_CODIGOS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_CODIGOS,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const saveCodigosAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res;
    if (id) {
      res = await saveCodigosService(id, data);
    } else {
      res = await saveCodigosService(null, data);
    }
    addNotification(
      res.message ?? id ? 'El código actualizado.' : 'El código registrado.'
    );
    await getAllCodigosAction(dispatch, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoCodigosAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_CODIGOS,
    payload: data,
  });
};

export const getOneCodigosAction = async (dispatch, id) => {
  try {
    const res = await getOneCodigosService(id);
    await setActivoCodigosAction(dispatch, res.data);
    return true;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteCodigosAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteCodigosService(id);
    addNotification(res.data.message ?? 'Código eliminada.');
    await setActivoCodigosAction(dispatch, null);
    await getAllCodigosAction(dispatch, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const getSearchCodesCodigosAction = async (dispatch, entityId, data) => {
  try {
    dispatch({
      type: LOADING_GETALL_CODIGOS,
      payload: true,
    });
    const res = await getSearchCodesCodigosService(entityId, data);
    dispatch({
      type: SEARCH_CODES_CODIGOS,
      payload: res.data.data,
    });
    dispatch({
      type: LOADING_GETALL_CODIGOS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const savePuertaCodigoAction = async (
  { dispatch, addNotification },
  idCode,
  idPuerta,
  accessTypeAllowed,
  params
) => {
  try {
    await savePuertaCodigoService(idCode, idPuerta, accessTypeAllowed);
    addNotification('El acceso asignado.');
    await getAllCodigosAction(dispatch, params);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const editPuertaCodigoAction = async (
  { dispatch, addNotification },
  idPuertaCode,
  accessTypeAllowed,
  params
) => {
  try {
    await editPuertaCodigoService(idPuertaCode, accessTypeAllowed);
    addNotification('El acceso actualizado.');
    await getAllCodigosAction(dispatch, params);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const deletePuertaCodigoAction = async (
  { dispatch, addNotification },
  idCode,
  idPuerta,
  params
) => {
  try {
    dispatch({
      type: LOADING_GETALL_CODIGOS,
      payload: true,
    });
    const res = await deletePuertaCodigoService(idCode, idPuerta);
    addNotification('Acceso eliminado del código.');
    await getAllCodigosAction(dispatch, params);
    dispatch({
      type: LOADING_GETALL_CODIGOS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    await getAllCodigosAction(dispatch, params);
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
