import {
  ACTIVO_RESERVAS,
  GETALL_RESERVAS,
  LOADING_GETALL_RESERVAS,
  PARAMS_RESERVAS,
  SET_LOGOUT,
} from '../../store/Types';

const initialState = {
  all: [],
  loadingGetAll: false,
  meta: null,
  links: null,
  activo: null,
  params: {
    areaId: null,
    codeId: null,
    date: null,
    dateTo: null,
    userId: null,
  },
};

export const reservasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_RESERVAS:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case ACTIVO_RESERVAS:
      return {
        ...state,
        activo: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case LOADING_GETALL_RESERVAS:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case PARAMS_RESERVAS:
      return {
        ...state,
        params: action.payload,
      };
    default:
      return state;
  }
};
