import {
  GETALL_TIPOS_INVITACION,
  LOADING_GETALL_TIPOS_INVITACION,
  ACTIVO_TIPOS_INVITACION,
} from '../../store/Types';
import {
  deleteTiposInvitacionService,
  getAllTiposInvitacionService,
  getOneTiposInvitacionService,
  saveTiposInvitacionService,
} from '../../services';
import { tryCatchHelper } from '../../helpers';

export const getAllTiposInvitacionAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_TIPOS_INVITACION,
      payload: true,
    });
    const res = await getAllTiposInvitacionService({ entityId });
    if (res.status === 200) {
      dispatch({
        type: GETALL_TIPOS_INVITACION,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_TIPOS_INVITACION,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_TIPOS_INVITACION,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_TIPOS_INVITACION,
      payload: false,
    });
    tryCatchHelper(e);
  }
};

export const saveTiposInvitacionAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveTiposInvitacionService(id, data);
    addNotification(
      res.message ?? id
        ? 'Tipo de invitación actualizada.'
        : 'Tipo de invitación registrada.'
    );
    await getAllTiposInvitacionAction(dispatch, entityId);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoTiposInvitacionAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_TIPOS_INVITACION,
    payload: data,
  });
};

export const getOneTiposInvitacionAction = async (dispatch, id) => {
  try {
    const res = await getOneTiposInvitacionService(id);
    if (res.status === 200) {
      await setActivoTiposInvitacionAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteTiposInvitacionAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteTiposInvitacionService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Tipo de invitación eliminada.');
      await setActivoTiposInvitacionAction(dispatch, null);
      await getAllTiposInvitacionAction(dispatch, entityId, null);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
