import { ADMIN } from "../../../constants/ConfigConst";
import { SET_DATA_AUTH, SET_LOGOUT, ERROR_LOGIN, SET_AUTH_MODULES } from "../../../store/Types";

const initialState = {
  user: null,
  rol: null,
  perfil: null,
  token: null,
  error: null,
  modules: [],
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_AUTH:
      const userCodes = action.payload.userCodes.filter(({ code }) => code.codeType === ADMIN);
      return {
        ...state,
        user: action.payload,
        rol: action.payload.roles[0].name,
        roles: action.payload.roles.map((role) => role.name),
        perfil: action.payload.profile,
        entities: userCodes.map((userCode) => userCode.code.entity),
      };

    case SET_LOGOUT:
      return {
        ...state,
        user: null,
      };

    case ERROR_LOGIN:
      return {
        ...state,
        error: action.payload,
      };
    case SET_AUTH_MODULES:
      return {
        ...state,
        modules: action.payload,
      };

    default:
      return state;
  }
};
