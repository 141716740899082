import React from "react";
import ContainerTemplate from "../../components/template/Container";
import AreasView from "./AreasView";
import * as ROUTES from "../../router/Routes";

const AreasModule = () => {
  return (
    <ContainerTemplate active={ROUTES.areas} routes={[]}>
      <AreasView />
    </ContainerTemplate>
  );
};

export default AreasModule;
