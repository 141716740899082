import { SET_LOGOUT, CLEAR_ALL } from '../../store/Types';

export const GETALL_IOT_TUNNELS = 'GETALL_IOT_TUNNELS';
export const ACTIVO_IOT_TUNNELS = 'ACTIVO_IOT_TUNNELS';
export const LOADING_GETALL_IOT_TUNNELS = 'LOADING_GETALL_IOT_TUNNELS';

const initialState = {
  all: [],
  loadingGetAll: false,
  activo: null,
  params: {},
};

export const iotTunelesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_IOT_TUNNELS:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case ACTIVO_IOT_TUNNELS:
      return {
        ...state,
        activo: action.payload,
      };
    case LOADING_GETALL_IOT_TUNNELS:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case SET_LOGOUT || CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
