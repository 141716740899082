import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import * as ROUTES from '../../../router/Routes';
import TiposServiciosView from './TiposServiciosView';

const module = () => {
  return (
    <ContainerTemplate active={ROUTES.tiposServicios} routes={[]}>
      <TiposServiciosView />
    </ContainerTemplate>
  );
};

export default module;
