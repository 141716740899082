import { tryCatchHelper } from '../../../helpers';
import {
  ACTIVO_RESERVAS,
  ACTIVO_RESERVAS_RESTAURANTE,
  GETALL_RESERVAS,
  GETALL_RESERVAS_RESTAURANTE,
  LOADING_GETALL_RESERVAS,
  LOADING_GETALL_RESERVAS_RESTAURANTE,
} from '../../../store/Types';
import {
  deleteReservasRestauranteService,
  getAllReservasRestauranteService,
  getOneReservasRestauranteService,
} from './ReservasRestauranteService';

export const getAllReservasRestauranteAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_RESERVAS_RESTAURANTE,
      payload: true,
    });
    const res = await getAllReservasRestauranteService(params);
    dispatch({
      type: GETALL_RESERVAS_RESTAURANTE,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_RESERVAS_RESTAURANTE,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_RESERVAS_RESTAURANTE,
      payload: false,
    });
    tryCatchHelper(e);
  }
};

export const setActivoReservasRestauranteAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_RESERVAS_RESTAURANTE,
    payload: data,
  });
};

export const getOneReservasRestauranteAction = async (dispatch, id) => {
  try {
    const res = await getOneReservasRestauranteService(id);
    await setActivoReservasRestauranteAction(dispatch, res.data);
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteReservasRestauranteAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteReservasRestauranteService(id);
    addNotification(res.data.message ?? 'Reserva eliminada.');
    await setActivoReservasRestauranteAction(dispatch, null);
    getAllReservasRestauranteAction(dispatch, params);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
