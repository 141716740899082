import {
  CLEAR_ALL,
  GETALL_ENTIDAD_BANCOS,
  LOADING_GETALL_ENTIDAD_BANCOS,
  PARAMS_ENTIDAD_BANCOS,
  SET_ACTIVO_ENTIDAD_BANCOS,
  SET_LOGOUT,
} from '../../store/Types';

const initialState = {
  all: [],
  meta: null,
  links: null,
  activo: null,
  params: {},
  loadingGetAll: false,
};

export const entidadBancosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_ENTIDAD_BANCOS:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    case SET_ACTIVO_ENTIDAD_BANCOS:
      return {
        ...state,
        activo: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case LOADING_GETALL_ENTIDAD_BANCOS:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case PARAMS_ENTIDAD_BANCOS:
      return {
        ...state,
        params: action.payload,
      };
    default:
      return state;
  }
};
