import { tryCatchHelper } from '../../helpers';
import {
  deleteReferidosService,
  getAllReferidosService,
  getOneReferidosService,
  saveReferidosService,
} from '../../services';
import {
  ACTIVO_REFERIDOS,
  GETALL_REFERIDOS,
  LOADING_GETALL_REFERIDOS,
} from '../../store/Types';

export const getAllReferidosAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_REFERIDOS,
      payload: true,
    });
    const res = await getAllReferidosService({ entityId });
    if (res.status === 200) {
      dispatch({
        type: GETALL_REFERIDOS,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_REFERIDOS,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_REFERIDOS,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_REFERIDOS,
      payload: false,
    });
    tryCatchHelper(e);
  }
};

export const saveReferidosAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveReferidosService(id, data);
    addNotification(
      res.message ?? id
        ? 'Tipo de invitación actualizada.'
        : 'Tipo de invitación registrada.'
    );
    await getAllReferidosAction(dispatch, entityId);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoReferidosAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_REFERIDOS,
    payload: data,
  });
};

export const getOneReferidosAction = async (dispatch, id) => {
  try {
    const res = await getOneReferidosService(id);
    if (res.status === 200) {
      await setActivoReferidosAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteReferidosAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteReferidosService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Tipo de invitación eliminada.');
      await setActivoReferidosAction(dispatch, null);
      await getAllReferidosAction(dispatch, entityId, null);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
