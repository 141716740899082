import * as React from 'react';
import * as Redux from 'react-redux';
import MUIDataTable from 'mui-datatables';
import {
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandeRowUsuarios from './ExpandedRowUsuarios';
import {
  assingCodeUsuariosAction,
  getAllCodigosAction,
  resendEmailCompleteRegistrationUsuariosAction,
  resendEmailConfirmationUsuariosAction,
  setActivoUsuariosAction,
  unassignUsuariosAction,
} from '../../../store/Actions';
import ActionsList from '../../../components/ActionsList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as ROUTES from '../../../router/Routes';
import { useHistory } from 'react-router-dom';
import * as CONST from '../../../constants/FieldsConst';
import styled from 'styled-components';
import {
  CLIENT,
  DRIVER,
  FAMILY,
  MANAGER,
  NANNY,
  OTHER,
  OWNER,
  SECURITY,
  SERVICE,
  TEACHER,
  getCodeType,
  statusColorEnum,
  statusEnum,
} from '../../../constants/enumsConst';
import { ButtonFile, Modal } from '../../../components';
import { MORADO_COLOR, NEGRO_COLOR } from '../../../constants/ColorsConst';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { BY_IDENTIFICACION_USUARIOS } from '../../../store/Types';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { Chip } from '@mui/material';
import ModalEliminarPermanenteUsuario from './ModalEliminarPermanenteUsuario';
import { ADMIN, SU } from '../../../constants/ConfigConst';
import { FormControl } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { InputLabel } from '@mui/material';
import LoadingComponent from '../../../components/Loading';
import { TYPE_ENTITY_ENUM } from '../../../constants/enumsConst';
import { acceptOnlyImages } from '../../../components/ButtonFile';
import { usersApi } from '../UsuariosService';
import { downloadExcelFile } from '../../../helpers/exportHelper';
import { centerLabelDataHelper } from '../../../helpers';
import { useNotification } from '../../../helpers/notification';
import AlertDialog from '../../../components/AlertDialog';

export const NameComponent = styled.p`
  cursor: pointer;
  font-weight: bold;
  color: black;
  margin: 0px 0px;
  text-transform: uppercase;
  margin-right: ${({ mr }) => mr ?? '2em'};

  &:hover {
    color: purple;
  }
`;

let intervalPage = null;
let intervalSearch = null;

const ListadoUsuarios = ({
  isGlobal,
  setOpenModalForm,
  pagination,
  setPagination,
  params
}) => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();
  const { addNotification } = useNotification();

  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const usuariosStore = Redux.useSelector((state) => state.usuarios);
  const codigosStore = Redux.useSelector((state) => state.codigos);
  const [fileImage, setFileImage] = React.useState();

  const isAdmin = loginStore.roles.some((role) => role === ADMIN);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isDeletePermanent, setIsDeletePermanent] = React.useState(false);
  const [modelAssignCode, setModelAssignCode] = React.useState(false);
  const [loadingAsignarCodigo, setLoadingAsignarCodigo] = React.useState(false);
  const [codigo, setCodigo] = React.useState('9999999');
  const [tipoUsuario, setTipoUsuario] = React.useState('owner');
  const [isDeleteCode, setIsDeleteCode] = React.useState(false);

  const isClub =
    entidadesStore.activo && entidadesStore.activo.entityType.id === 2;
  const isPublic = entidadesStore.activo && entidadesStore.activo.isPublic;

  React.useEffect(() => {
    setDataTabla(usuariosStore.all);
  }, [usuariosStore.all]);

  React.useEffect(() => {
    setDataTabla(usuariosStore.filtro);
  }, [usuariosStore.filtro]);

  React.useEffect(() => {
    if (!isGlobal) {
      getAllCodigosAction(dispatch, { entityId: entidadesStore.activo.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleInfoClick = async (id) => {
    const user = usuariosStore.all.find((el) => el.id === id);
    await setActivoUsuariosAction(dispatch, user);
    history.push(ROUTES.usuariosInfo.to);
  };

  const _handleEditClick = async (id) => {
    if (isPublic && !isGlobal) return;
    const user = usuariosStore.all.find((el) => el.id === id);
    await setActivoUsuariosAction(dispatch, user);
    dispatch({
      type: BY_IDENTIFICACION_USUARIOS,
      payload: null,
    });
    setOpenModalForm(true);
  };

  const _handleUnassignCodeClick = async (idCode) => {
    setLoadingDelete(true);
    await unassignUsuariosAction(
      { dispatch, addNotification },
      usuariosStore.activo.id,
      idCode ?? usuariosStore.activo.userCodes[0].code.id,
      params
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const setDataTabla = (values = []) => {
    const rowsData = [];
    values.map((el) => {
      const existe = el.userCodes.length > 0;

      return rowsData.push({
        ...el,
        identificationNumber: el.profile?.identificationNumber
          ? `${el.profile.typeDocument}-${el.profile.identificationNumber}`
          : '--',
        name: {
          name: el.profile
            ? `${el.profile.firstName} ${el.profile.lastName}`
            : '--',
          email: el.email,
        },
        userCodes: existe ? el.userCodes : [],
        statusCode: existe ? el.userCodes[0].code.status : '--',
      });
    });
    setRows(rowsData);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: loginStore.rol === SU ? true : 'excluded',
        filter: false,
        sort: true
      },
    },
    {
      name: CONST.USER_CODES,
      label: 'Foto',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box sx={{ width: '3em' }}>
              <Avatar
                alt={`avatr-${tableMeta.rowData[0]}`}
                sx={{ width: 30, height: 30 }}
                src={value.length === 0 ? null : value[0].avatar}
              />
            </Box>
          );
        },
      },
    },
    {
      name: CONST.IDENTIFICATION_NUMBER,
      label: 'C.I',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box sx={{ width: '8em' }}>
              <NameComponent
                onClick={() => {
                  if (isGlobal) return;
                  _handleEditClick(tableMeta.rowData[0]);
                }}
              >
                {value}
              </NameComponent>
            </Box>
          );
        },
      },
    },
    {
      name: CONST.NAME,
      label: 'Nombre y apellido',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // const texts = value.split("|");
          return (
            <Stack>
              <NameComponent
                onClick={() => {
                  if (isGlobal) return;
                  _handleInfoClick(tableMeta.rowData[0]);
                }}
              >
                {value.name}
              </NameComponent>
              <Typography variant="body1" color="initial">
                {value.email}
              </Typography>
            </Stack>
          );
        },
      },
    },
    // {
    //   name: CONST.EMAIL,
    //   label: 'Correo',
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <Typography variant="body">{value}</Typography>;
    //     },
    //   },
    // },
    {
      name: CONST.STATUS,
      label: 'Estatus del Usuario',
      options: {
        filter: true,
        // ...centerLabelDataHelper,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              label={statusEnum[value]}
              size="small"
              sx={{
                backgroundColor: statusColorEnum[value],
                color: 'white',
              }}
            />
          );
        },
      },
    },
    {
      name: CONST.USER_CODES,
      label:
        loginStore.rol === SU || entidadesStore.activo === null
          ? 'Código'
          : TYPE_ENTITY_ENUM[entidadesStore.entityType.name],
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = usuariosStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          return (
            <Stack
              direction="row"
              style={{ maxWidth: '600px', flexWrap: 'wrap' }}
            >
              {(value?.length && value.filter(el => el.code.codeType !== CLIENT).length)
                ? value
                  .filter(el => el.code.codeType !== CLIENT)
                  .map((el) => {
                    const entityName = isGlobal
                      ? `${el.code.entity.abbrev} | `
                      : '';
                    return (
                      <Chip
                        key={el.id}
                        label={`${entityName}${el.code.code} | ${getCodeType(
                          el.userType,
                          isClub
                        )}`}
                        variant="outlined"
                        size="small"
                        sx={{
                          color: NEGRO_COLOR,
                          borderColor: NEGRO_COLOR,
                          mr: 1,
                          mb: 1,
                          '&:hover': {
                            borderColor: MORADO_COLOR,
                            color: MORADO_COLOR,
                          },
                        }}
                        onClick={() => {}}
                        onDelete={
                          (isAdmin && el.userType === ADMIN) ||
                          (el.code.entity.isPublic &&
                            el.code.codeType !== ADMIN)
                            ? null
                            : async () => {
                                setCodigo(el.code.id);
                                setActivoUsuariosAction(dispatch, data);
                                setIsDeleteCode(true);
                              }
                        }
                      />
                    );
                  })
                : 'N/A'}
            </Stack>
          );
        },
      },
    },
    {
      name: CONST.STATUS_CODE,
      label: 'Estatus de código',
      options: {
        filter: true,
        sort: true,
        // ...centerLabelDataHelper,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              label={statusEnum[value]}
              size="small"
              sx={{
                backgroundColor: statusColorEnum[value],
                color: 'white',
              }}
            />
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        empty: true,
        ...centerLabelDataHelper,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = usuariosStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ width: isGlobal ? '60px' : '100px' }}
            >
              {!isGlobal && (
                <Tooltip title="Información">
                  <IconButton
                    aria-label="edit"
                    size="small"
                    color="primary"
                    onClick={() => _handleInfoClick(tableMeta.rowData[0])}
                  >
                    <VisibilityIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              <ActionsList
                id={tableMeta.rowData[0]}
                onEdit={() => _handleEditClick(tableMeta.rowData[0])}
                onDeletePermanent={
                  loginStore.rol === SU && isGlobal
                    ? async () => {
                        const data = usuariosStore.all.find(
                          (el) => el.id === tableMeta.rowData[0]
                        );
                        await setActivoUsuariosAction(dispatch, data);
                        setIsDeletePermanent(true);
                      }
                    : null
                }
                onSendConfirmationEmail={
                  data?.status === 'EMAIL_NOT_VERIFIED'
                    ? async () => {
                      await resendEmailConfirmationUsuariosAction(
                        { dispatch, addNotification },
                        tableMeta.rowData[0]
                      );
                    }
                    : null
                }
                onSendCompleteRegistrationEmail={
                  data?.status === 'PRE_CREATED'
                    ? async () => {
                      await resendEmailCompleteRegistrationUsuariosAction(
                        { dispatch, addNotification },
                        tableMeta.rowData[0]
                      );
                    }
                    : null
                }
                onAsignarCodigo={
                  !isGlobal
                    ? async () => {
                        const data = usuariosStore.all.find(
                          (el) => el.id === tableMeta.rowData[0]
                        );
                        await setActivoUsuariosAction(dispatch, data);
                        setModelAssignCode(true);
                      }
                    : null
                }
              />
            </Stack>
          );
        },
      },
    },
  ];

  if (!isGlobal && isPublic) columns.pop();
  const options = {
    serverSide: true,
    filter: false,
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onDownload: () => {
      const url = `${usersApi}/export`;
      const name = 'Usuarios';
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },
    onChangePage: (currentPage) => {
      clearTimeout(intervalPage);
      intervalPage = setTimeout(() => {
        setPagination({
          ...pagination,
          page: currentPage + 1,
        });
      }, 500);
    },
    onSearchChange: (searchQuery) => {
      clearTimeout(intervalSearch);
      intervalSearch = setTimeout(() => {
        setPagination({
          ...pagination,
          q: searchQuery,
          page: 1,
        });
      }, 800);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setPagination({
        ...pagination,
        limit: numberOfRows,
        page: 1,
      });
    },
    rowsPerPage: pagination.limit,
    count: pagination.total,
    page: pagination.page - 1,
    rowsPerPageOptions: [15, 30, 50, 100],
    // jumpToPage: true,
    selectableRowsHideCheckboxes: true,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <ExpandeRowUsuarios
          data={usuariosStore.all.find((el) => el.id === rowData[0])}
          length={rowData.length}
        />
      );
    },
    print: false,
    textLabels: textLabelsTable,
    customSort: (data, colIndex, sortDirection, meta) => {
      return data;
    },
    onColumnSortChange: (sort, sortDirection) => {
      console.log('asdasd')
      if (sort === 'userCodes') sort = 'code';
      if (sort === 'name') sort = 'firstName';
      setPagination({
        ...pagination,
        page: 1,
        sort,
        sortDirection
      })
    }
  };
  const code = codigosStore.all.find((el) => el.id === codigo);
  const isCodeSecurity = code?.codeType === SECURITY;
  const isCodeTeacher = code?.codeType === TEACHER;
  const isCodeAdmin = code?.codeType === ADMIN;
  const isCodeClient = !isCodeSecurity && !isCodeAdmin && !isCodeTeacher;
  return (
    <Grid container>
      <LoadingComponent
        isLoading={usuariosStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Lista de usuarios'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {usuariosStore.activo ? (
        <>
          <ModalEliminarPermanenteUsuario
            open={isDeletePermanent}
            onClose={setIsDeletePermanent}
            isGlobal={isGlobal}
            params={params}
          />
          <Modal
            id="modalEliminarUsuario"
            title={`Eliminar`}
            open={isDelete}
            maxWidth="sm"
            onClose={() => setIsDelete(!isDelete)}
          >
            <DialogContent>
              {isGlobal ? (
                <DialogContentText id="alert-dialog-onpass">
                  <p>
                    <strong>
                      {usuariosStore.activo.profile?.identificationNumber}
                      {'  '}
                      {usuariosStore.activo.profile?.firstName}
                      {'  '}
                      {usuariosStore.activo.profile?.lastName}
                    </strong>
                  </p>
                  <h3>Códigos asignados</h3>
                  {usuariosStore.activo.userCodes.map((el) => {
                    return (
                      <Chip
                        label={el.code.code}
                        variant="outlined"
                        sx={{
                          color: NEGRO_COLOR,
                          borderColor: NEGRO_COLOR,
                          mr: 1,
                          mb: 1,
                          '&:hover': {
                            borderColor: MORADO_COLOR,
                            color: MORADO_COLOR,
                          },
                        }}
                        onClick={() => {}}
                        onDelete={async () => {
                          await _handleUnassignCodeClick(el.code.id);
                        }}
                      />
                    );
                  })}
                </DialogContentText>
              ) : (
                <DialogContentText id="alert-dialog-usuario">
                  ¿Seguro que quieres eliminar este usuario?
                  <p>
                    <strong>
                      {usuariosStore.activo.profile?.identificationNumber}
                      {'  '}
                      {usuariosStore.activo.profile?.firstName}
                      {'  '}
                      {usuariosStore.activo.profile?.lastName}
                    </strong>
                  </p>
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              {isGlobal ? null : (
                <Button
                  color="error"
                  variant="outlined"
                  onClick={async () => {
                    await _handleUnassignCodeClick();
                  }}
                >
                  ELIMINAR
                </Button>
              )}
              <Button
                sx={{ color: MORADO_COLOR }}
                onClick={() => setIsDelete(false)}
              >
                CANCELAR
              </Button>
            </DialogActions>
          </Modal>
          <Modal
            id="modalFormCodigos"
            title={`Asignar codigo a ${usuariosStore.activo.profile?.firstName} ${usuariosStore.activo.profile?.lastName}`}
            open={modelAssignCode}
            maxWidth="sm"
            onClose={() => setModelAssignCode(!modelAssignCode)}
          >
            <DialogContent>
              <LoadingComponent
                isLoading={loadingAsignarCodigo}
                text="Guardando asignación..."
              />
              <DialogContentText id="alert-dialog-slide-description">
                <ButtonFile
                  id={'avatar'}
                  onFile={(file) => setFileImage(file)}
                  file={fileImage}
                  text="Subir imagén"
                  sxc={{ marginTop: '5px' }}
                  size="100%"
                  name={'avatar'}
                  onDelete={() => setFileImage(null)}
                  src={null}
                  accept={acceptOnlyImages}
                />

                <FormControl fullWidth>
                  <Select
                    labelId="codigosSelect"
                    size="small"
                    value={codigo}
                    onChange={(e) => {
                      const code = codigosStore.all.find(
                        (el) => el.id === e.target.value
                      );
                      setCodigo(e.target.value);
                      setTipoUsuario(code?.codeType);
                    }}
                    id="puertasSelect"
                  >
                    <MenuItem value="9999999">
                      Seleccionar una codigo...
                    </MenuItem>
                    {codigosStore.all.map((e) => {
                      return (
                        <MenuItem key={`codigo${e.id}`} value={e.id}>
                          {e.code}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  sx={{ width: '100%', marginTop: '30px' }}
                >
                  <InputLabel id="selectUserType">Tipo de código</InputLabel>
                  <Select
                    labelId="selectUserType"
                    size="small"
                    value={tipoUsuario}
                    label="Tipo de perfil"
                    onChange={(e) => setTipoUsuario(e.target.value)}
                    id={CONST.TYPE}
                  >
                    {loginStore.rol === SU && isCodeAdmin && (
                      <MenuItem value={ADMIN}>Administrador</MenuItem>
                    )}
                    {(loginStore.rol === ADMIN || loginStore.rol === SU) && isCodeAdmin && (
                      <MenuItem value={MANAGER}>Manager</MenuItem>
                    )}
                    {!isPublic && isCodeClient && (
                      <MenuItem value={OWNER}>
                        {getCodeType(OWNER, isClub)}
                      </MenuItem>
                    )}
                    {isCodeSecurity && (<MenuItem value={SECURITY}>Seguridad</MenuItem>)}
                    {isCodeTeacher && (<MenuItem value={TEACHER}>Profesor</MenuItem>)}
                    {!isPublic && isCodeClient && (
                      <MenuItem value={FAMILY}>
                        {getCodeType(FAMILY, isClub)}
                      </MenuItem>
                    )}
                    {!isPublic && isCodeClient && (
                      <MenuItem value={SERVICE}>Trabajador doméstico</MenuItem>
                    )}
                    {!isPublic && isCodeClient && <MenuItem value={NANNY}>Niñera</MenuItem>}
                    {!isPublic && isCodeClient && <MenuItem value={DRIVER}>Chofer</MenuItem>}
                    { isPublic && isCodeClient && (<MenuItem value={CLIENT}>Cliente</MenuItem>) }
                    {!isPublic && isCodeClient && !isClub && (
                      <MenuItem value={OTHER}>
                        {getCodeType(OTHER, isClub)}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <BotonNuevoComponent
                click={async () => {
                  setLoadingAsignarCodigo(true);
                  await assingCodeUsuariosAction(
                    { dispatch, addNotification },
                    usuariosStore.activo.id,
                    codigo,
                    {
                      userType: tipoUsuario,
                      avatar: fileImage,
                    },
                    params
                  );
                  setModelAssignCode(false);
                  setLoadingAsignarCodigo(false);
                }}
                disabled={
                  loadingAsignarCodigo ||
                  codigo === '9999999' ||
                  tipoUsuario === '9999999'
                }
                text="GUARDAR"
                morado
              />
              <Button
                sx={{ color: MORADO_COLOR }}
                onClick={() => {
                  setLoadingAsignarCodigo(false);
                  setCodigo('9999999');
                  setModelAssignCode(false);
                }}
              >
                CANCELAR
              </Button>
            </DialogActions>
          </Modal>
        </>
      ) : null}
      {usuariosStore.activo && isDeleteCode && (
        <AlertDialog
          description="¿Seguro que quieres eliminar este código de usuario?"
          title="Eliminar código"
          onClose={() => setIsDeleteCode(false)}
          onAccept={async () => {
            await unassignUsuariosAction(
              { dispatch, addNotification },
              usuariosStore.activo.id,
              codigo,
              params
            );
            setIsDeleteCode(false);
          }}
          data={usuariosStore.activo}
        />
      )}
    </Grid>
  );
};

export default ListadoUsuarios;
