import * as React from 'react';
import { TextField } from '@mui/material';
import { validFielddHelper } from '../../helpers/ValidFieldsHelper';
import * as CONST from "../../constants/FieldsConst";

const PhoneNumberField = ({
    onChange,
    required,
    value,
    disabled = false,
    fieldName = CONST.PHONE,
    dataError = {},
    label = "Teléfono"
}) => {
  return (
    <TextField
      fullWidth
      size="small"
      id={fieldName}
      required={required}
      label={label}
      value={value}
      disabled={disabled}
      onChange={(e) => {
        const inputValue = e.target.value;
        let cleanInput = inputValue.replace(/[^0-9+]/g, '');
        
        if (cleanInput.startsWith('+')) {
          cleanInput = '+' + cleanInput.slice(1).replace(/\+/g, ''); // Elimina otros "+" al principio
        } else {
          cleanInput = '+' + cleanInput.replace(/\+/g, ''); // Agrega "+" al principio
        }
        onChange(cleanInput);
      }}
      helperText={
        dataError[fieldName] ? validFielddHelper(1) : "Ej. +582125558899"
      }
      error={dataError[fieldName]}
    />
  );
};


export default PhoneNumberField;