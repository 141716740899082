import * as React from 'react';
import * as Redux from 'react-redux';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import * as CONST from '../../../constants/FieldsConst';
import Box from '@mui/material/Box';
import { saveIotTunelesAction } from '../IotTunelesAction';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import LoadingComponent from '../../../components/Loading';
import { useNotification } from '../../../helpers/notification';

const FormIotTuneles = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const iotTunelesStore = Redux.useSelector((state) => state.iotTuneles);

  const activo = iotTunelesStore.activo;

  const initData = {
    [CONST.NAME]: '',
    [CONST.IDENTIFIER]: '',
    [CONST.ACTIVE]: true,
  };

  const [iot, setDocumento] = React.useState(initData);
  const [dataError, setDataRequired] = React.useState({
    [CONST.NAME]: false,
    [CONST.IDENTIFIER]: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);

  React.useEffect(() => {
    if (activo === null) return;
    let data = {};
    Object.keys(iot).map((key) => (data[key] = iotTunelesStore.activo[key]));
    setTimeout(() => {
      setDocumento(data);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activo]);

  const _handleSetDataField = (key, value) =>
    setDocumento({ ...iot, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).map((key) => {
      const val = iot[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      return va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const _handleSubmit = async () => {
    const valid = _validDataForm();

    if (valid) {
      const data = {
        ...iot,
        [CONST.ENTITY_ID]: entidadesStore.activo.id,
      };
      setLoadingSave(true);
      let res = await saveIotTunelesAction(
        { dispatch, addNotification },
        entidadesStore.activo.id,
        activo === null ? null : activo.id,
        data
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };

  return (
    <Box component="form">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={iotTunelesStore.activo ? 'Actualizando...' : 'Guardando...'}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextField
            fullWidth
            size="small"
            id={CONST.NAME}
            required
            label="Nombre"
            value={iot.name}
            onChange={(e) => _handleSetDataField(CONST.NAME, e.target.value)}
            helperText={dataError.name ? validFielddHelper(1) : null}
            error={dataError.name}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextField
            fullWidth
            size="small"
            id={CONST.IDENTIFIER}
            required
            label="Identificador"
            value={iot.identifier}
            onChange={(e) =>
              _handleSetDataField(CONST.IDENTIFIER, e.target.value)
            }
            helperText={dataError.identifier ? validFielddHelper(1) : null}
            error={dataError.identifier}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id={`label-${CONST.ACTIVE}`}>Estatus</InputLabel>
            <Select
              labelId={`label-${CONST.ACTIVE}`}
              size="small"
              value={iot.active}
              label="Estatus"
              onChange={(e) =>
                _handleSetDataField(CONST.ACTIVE, e.target.value)
              }
              id={CONST.ACTIVE}
            >
              <MenuItem value={true}>Activo</MenuItem>
              <MenuItem value={false}>Bloqueado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={12} align="center" sx={{ m: 2 }}>
          <BotonNuevoComponent
            click={_handleSubmit}
            text="GUARDAR"
            disabled={loadingSave}
            morado
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormIotTuneles;
