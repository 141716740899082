import * as React from "react";
import LayoutInfoComponent from "./LayoutInfo";

const TokenExpiradoModule = () => {
  return (
    <LayoutInfoComponent message="Su token ha expirado, por favor vuelva iniciar sesión" />
  );
};

export default TokenExpiradoModule;
