import * as React from 'react';
import * as Redux from 'react-redux';
import { Button, Grid, Typography, Paper, Divider } from '@mui/material';
import { dateformat } from '../../../helpers';

const InfoEntidadesComponent = ({ setOpenModalForm }) => {
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const activo = entidadesStore.activo;
  const a = activo.attributes;

  return (
    <Paper elevation={2} align="center" sx={{ p: 2 }}>
      <Grid container spacing="1">
        <Grid item md={12} align="center">
          <img
            src={a.image_url}
            width="80%"
            alt="imagenurl"
            onClick={() => setOpenModalForm(true)}
          />
        </Grid>
        <Grid item md={12} align="center">
          <Typography variant="h6" sx={{ textTransform: 'uppercase' }}>
            {a.name}
          </Typography>
        </Grid>
        <Grid item md={12} align="center">
          <Typography variant="h6"> {a.abbreviation}</Typography>
        </Grid>
        <Grid item md={12} align="center">
          <Typography variant="h6"> {a.phone}</Typography>
        </Grid>

        <Grid item md={12} align="center">
          <Divider textAlign="left" sx={{ mt: 1, mb: 1 }}>
            Detalles
          </Divider>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Hora de apertura: </strong>
            {dateformat(a.opening_time, { isHHmm: true })}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Hora de cierre: </strong>
            {dateformat(a.closing_time, { isHHmm: true })}
          </Typography>
        </Grid>
        {/* 
            <Grid item md={12} align='left'>
                <Typography variant="body" >
                    <strong>Tipo de reserva: </strong>
                    {a.block_reservation}
                </Typography>
            </Grid>
            <Grid item md={12} align='left'>
                <Typography variant="body" >
                    <strong>max_reserves: </strong>
                    {a.max_reserves}
                </Typography>
            </Grid>
            <Grid item md={12} align='left'>
                <Typography variant="body" >
                    <strong>days_max_reserves: </strong>
                    {a.days_max_reserves}
                </Typography>
            </Grid>
            <Grid item md={12} align='left'>
                <Typography variant="body" >
                    <strong>Reservas activas: </strong>
                    {a.active_reserves}
                </Typography>
            </Grid>
            <Grid item md={12} align='left'>
                <Typography variant="body" >
                    <strong> Bloques seguidos: </strong>
                    {a.continuous_block}
                </Typography>
            </Grid>
            <Grid item md={12} align='left'>
                <Typography variant="body" >
                    <strong>status: </strong>
                    {a.status.name}
                </Typography>
            </Grid> */}
        <Grid item md={12} sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setOpenModalForm(true);
            }}
          >
            Editar
          </Button>
        </Grid>
        {/* <Grid item md={6} sx={{ mt: 3 }}>
                <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => {
                        // setOpenModalForm(true)
                    }}
                >Bloquear</Button>
            </Grid> */}
      </Grid>
    </Paper>
  );
};

export default InfoEntidadesComponent;
