import {
  GET_ALL_ACCESOS,
  LOADING_GETALL_ACCESOS,
  SET_ACTIVO_ACCESOS,
  SET_LOGOUT,
  PARAMS_ACCESOS,
} from '../../store/Types';

const initialState = {
  all: [],
  loadingGetAll: false,
  meta: null,
  links: null,
  activo: null,
  params: {
    userCodeId: null,
    securityUserCodeId: null,
    invitationId: null,
    guestId: null,
    startDate: null,
    endDate: null,
  },
};

export const accesosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ACCESOS:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case SET_ACTIVO_ACCESOS:
      return {
        ...state,
        activo: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case LOADING_GETALL_ACCESOS:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case PARAMS_ACCESOS:
      return {
        ...state,
        params: action.payload,
      };
    default:
      return state;
  }
};
