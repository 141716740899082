import * as React from 'react';
import * as Redux from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { Modal } from '../../../components';
import ListadoTiposClases from './components/ListadoTiposClases';
import FormTiposClases from './components/FormTiposClases';
import {
  getAllTiposClasesAction,
  setActivoTiposClasesAction,
} from './TiposClasesAction';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { checkRootAccess } from '../../modulos/modules.helpers';

const TiposClasesView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposClasesStore = Redux.useSelector((state) => state.tiposClases);

  const loginStore = Redux.useSelector((state) => state.login);
  checkRootAccess(loginStore);

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoTiposClasesAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        {tiposClasesStore.all.length > 0 ? (
          <BotonNuevoComponent
            click={async () => {
              await setActivoTiposClasesAction(dispatch, null);
              await getAllTiposClasesAction(dispatch, entidadesStore.activo.id);
            }}
            disabled={tiposClasesStore.loadingGetAll}
            white
            text={
              tiposClasesStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'
            }
          />
        ) : null}
      </Grid>
      <Grid item md={12}>
        <ListadoTiposClases setOpenModalForm={(val) => setOpenModalForm(val)} />
      </Grid>
      <Modal
        id="modalFormTiposClases"
        title={`${tiposClasesStore.activo ? 'Editar' : 'Nuevo'} tipo de clase`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {tiposClasesStore.activo ? (
          <Typography variant="h6">
            {tiposClasesStore.activo.nombres}{' '}
            {tiposClasesStore.activo.apellidos}
          </Typography>
        ) : null}
        <FormTiposClases
          activo={tiposClasesStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default TiposClasesView;
