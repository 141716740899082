import {
  Avatar,
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import ActionsList from '../../../components/ActionsList';
import LoadingComponent from '../../../components/Loading';
import { MORADO_COLOR, NEGRO_COLOR } from '../../../constants/ColorsConst';
import { SU } from '../../../constants/ConfigConst';
import * as CONST from '../../../constants/FieldsConst';
import { getCodeType } from '../../../constants/enumsConst';
import { getDateFormated, getDateTimezone } from '../../../helpers';
import { downloadExcelFile } from '../../../helpers/exportHelper';
import { useNotification } from '../../../helpers/notification';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import {
  deleteDeliveryAction,
  setActivoDeliveryAction,
} from '../DeliveryAction';
import { deliveriesApi } from '../DeliveryService';

const ListadoDelivery = ({ getParams = () => {} }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const deliveryStore = Redux.useSelector((state) => state.delivery);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const loginStore = Redux.useSelector((state) => state.login);

  const [isDelete, setIsDelete] = React.useState(false);

  const timezone = entidadesStore.activo.country.timezone;
  const isClub = entidadesStore.activo?.entityType?.id === 2;

  const isRoot = loginStore.roles.indexOf(SU) !== -1;

  const _handleDeleteClick = async (id) => {
    await deleteDeliveryAction({ dispatch, addNotification }, id, getParams());
    setIsDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.DESCRIPTION,
      label: 'Descripción',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.DATE,
      label: 'Fecha',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Typography variant="body2">
            {getDateFormated(value, 'DD-MM-YYYY', timezone)}
          </Typography>
        ),
      },
    },
    {
      name: 'PROPIETARIO',
      label: 'Propietario',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = deliveryStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          if (!data) return <LoadingComponent />;
          const code = data.userCode.code;
          const profile = data.userCode.user.profile;
          const ownerName = `${profile.firstName} ${profile.lastName}`;
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '6px',
                marginBottom: '3px',
              }}
            >
              <Avatar
                alt={value}
                sx={{ width: 30, height: 30 }}
                src={data.userCode.avatar}
              />
              <Box>
                <NameComponent style={{ marginLeft: '10px' }}>
                  {ownerName}
                </NameComponent>
                <Chip
                  label={`${code.code} | ${getCodeType(
                    data.userCode.userType,
                    isClub
                  )}`}
                  variant="outlined"
                  size="small"
                  sx={{
                    color: NEGRO_COLOR,
                    borderColor: NEGRO_COLOR,
                    mr: 1,
                    mb: 1,
                    marginLeft: '10px',
                    '&:hover': {
                      borderColor: MORADO_COLOR,
                      color: MORADO_COLOR,
                    },
                  }}
                  onClick={() => {}}
                />
              </Box>
            </Box>
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = deliveryStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          const startDate = data
            ? getDateTimezone(data.startDate, timezone)
            : moment();

          const disabledDeleted = isRoot
            ? false
            : moment(startDate).startOf('day').isBefore(moment());
          return (
            <Box align="left" sx={{ width: '10%' }}>
              <ActionsList
                id={tableMeta.rowData[0]}
                onDelete={async () => {
                  await setActivoDeliveryAction(dispatch, data);
                  setIsDelete(true);
                }}
                disabledDeleted={disabledDeleted}
              />
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onDownload: () => {
      const url = `${deliveriesApi}/export`;
      const name = 'delivery';
      const params = getParams();
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
    customSearch: (q, currentRow, columns) => {
      q = q.toLowerCase();
      const data = deliveryStore.all.find(({ id }) => id === currentRow[0]);
      if (!data) return true;
      const guestData =
        `${data.guest.firstName} ${data.guest.lastName} ${data.guest.identificationNumber}`.toLowerCase();
      const ownerData =
        `${data.userCode.user.profile.firstName} ${data.userCode.user.profile.lastName} ${data.userCode.code.code}`.toLowerCase();
      const dateData = `${currentRow[3]} ${currentRow[4]}}`.toLowerCase();

      return (
        guestData.includes(q) || ownerData.includes(q) || dateData.includes(q)
      );
    },
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={deliveryStore.loadingGetAll}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={deliveryStore.all}
          columns={columns}
          options={options}
        />
      </Grid>

      {deliveryStore.activo ? (
        <Modal
          id="modal-deleteasd"
          title={`Eliminar Delivery`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este delivery?
              <p>
                <strong>
                  {deliveryStore.activo.firstName}{' '}
                  {deliveryStore.activo.lastName}
                </strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(deliveryStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoDelivery;
