export const accessLogTypes = {
  SCAN: "SCAN",
  REMOTE: "REMOTE",
  MANUAL: "MANUAL"
}

export const accessLogTypesLabel = {
  [accessLogTypes.SCAN]: "Escáner",
  [accessLogTypes.REMOTE]: "Control",
  [accessLogTypes.MANUAL]: "Manual"
}