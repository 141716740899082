import { dateformat } from '../../helpers';
import {
  GETALL_RESERVAS_ESPECIALES,
  LOADING_GETALL_RESERVAS_ESPECIALES,
  ACTIVO_RESERVAS_ESPECIALES,
  SET_LOGOUT,
  GET_DATE_SEARCH_RESERVAS_ESPECIALES,
  GET_DATA_SEARCH_AREAS_RESERVAS_ESPECIALES,
} from '../../store/Types';

const initialState = {
  all: [],
  meta: null,
  links: null,
  activo: null,
  params: [],
  dateSearchOrigin: dateformat(null, {}),
  dateSearch: dateformat(null, { format: 'DD-MM-YYYY' }),
  dataSearchAreaId: null,
  loadingGetAll: false,
};

export const reservasEspecialesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_RESERVAS_ESPECIALES:
      return {
        ...state,
        all: action.payload,
      };
    case ACTIVO_RESERVAS_ESPECIALES:
      return {
        ...state,
        activo: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case LOADING_GETALL_RESERVAS_ESPECIALES:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case GET_DATE_SEARCH_RESERVAS_ESPECIALES:
      return {
        ...state,
        dateSearchOrigin: action.payload,
        dateSearch: dateformat(action.payload, {
          format: 'YYYY-MM-DD HH:mm:ss',
        }),
      };
    case GET_DATA_SEARCH_AREAS_RESERVAS_ESPECIALES:
      return {
        ...state,
        dataSearchAreaId: action.payload,
      };

    default:
      return state;
  }
};
