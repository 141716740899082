import React from "react";
import ContainerTemplate from "../../components/template/Container";
import PanelUsuariosView from "./PanelUsuariosView";
import * as ROUTES from "../../router/Routes";

const UsuariosInfoPage = () => {
  return (
    <ContainerTemplate active={ROUTES.usuariosInfo} routes={[ROUTES.usuarios]}>
      <PanelUsuariosView />
    </ContainerTemplate>
  );
};

export default UsuariosInfoPage;
