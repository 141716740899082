import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "./RootReducer";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ONPASS } from "../constants/ConfigConst";

const persistConfig = {
  key: ONPASS,
  debug: true,
  storage,
};

const middlewares = [thunk, createDebounce()];
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);

export { persistor, store };
