import {
  Avatar,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Tooltip,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../../components';
import ActionsList from '../../../../components/ActionsList';
import LoadingComponent from '../../../../components/Loading';
import * as CONST from '../../../../constants/FieldsConst';
import {
  dateformat,
  getDateFormated,
  getDateTimezone,
} from '../../../../helpers';
import { downloadExcelFile } from '../../../../helpers/exportHelper';
import { useNotification } from '../../../../helpers/notification';
import { textLabelsTable } from '../../../invitados/components/ListadoInvitados';
import { checkModuleActionAccess } from '../../../modulos/modules.helpers';
import { NameComponent } from '../../../usuarios/components/ListadoUsuarios';
import {
  deleteReservasRestauranteAction,
  setActivoReservasRestauranteAction,
} from '../ReservasRestauranteAction';
import {
  restaurantReservations,
  updateIsAttendedRestauranteService,
} from '../ReservasRestauranteService';

const ListadoReservasRestaurante = ({
  date,
  dateTo,
  setOpenModalDetalles = () => {},
  isDetalles = false,
  setIsDetalles = () => {},
}) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const areasStore = Redux.useSelector((state) => state.areas);
  const reservasRestauranteStore = Redux.useSelector(
    (state) => state.reservasRestaurante
  );
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const loginStore = Redux.useSelector((state) => state.login);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const timezone = entidadesStore.activo.country.timezone;
  const [attendedLoading, setAttendedLoading] = React.useState({});

  React.useEffect(() => {
    setRows([]);
    reservasRestauranteStore.all.forEach((restaurantReserva) => {
      setRows((old) => [
        ...old,
        {
          id:  restaurantReserva.id,
          avatar: restaurantReserva.userCode.user.profile.avatar,
          name: `${restaurantReserva.userCode.user.profile.firstName} ${restaurantReserva.userCode.user.profile.lastName}`,
          identificationNumber: `${restaurantReserva.userCode.user.profile.typeDocument}-${restaurantReserva.userCode.user.profile.identificationNumber}`,
          code: restaurantReserva.userCode.code.code,
          dateFrom: getDateFormated(
            restaurantReserva.entryTime,
            'DD-MM-YYYY',
            timezone
          ),
          entryTime: getDateFormated(
            restaurantReserva.entryTime,
            'HH:mm a',
            timezone
          ),
          departureTime: getDateFormated(
            restaurantReserva.departureTime,
            'HH:mm a',
            timezone
          ),
          // requestOrigin: '',
          // createdById: '',
          isAttended: restaurantReserva.isAttended,
          // [CONST.ID]: reserva.id,
          // [CONST.AVATAR]: reserva.userCode.user.profile.avatar,
          // [CONST.NAME]: `${reserva.userCode.user.profile.firstName} ${reserva.userCode.user.profile.lastName}`,
          // [CONST.IDENTIFICATION_NUMBER]: `${reserva.userCode.user.profile.typeDocument}-${reserva.userCode.user.profile.identificationNumber}`,
          // [CONST.CODE]: reserva.userCode.code.code,
          // [CONST.AREA_ID]: reserva.subarea ? reserva.subarea.name : 'N/A',
          // [CONST.DESCRIPTION]: reserva.description,
          // [CONST.PRICE]: reserva.price,
          // [CONST.DATE_FROM]: getDateFormated(
          //   reserva.entryTime,
          //   'DD-MM-YYYY',
          //   timezone
          // ),
          // [CONST.ENTRY_TIME]: getDateFormated(
          //   reserva.entryTime,
          //   'HH:mm a',
          //   timezone
          // ),
          // [CONST.DEPARTURE_TIME]: getDateFormated(
          //   reserva.departureTime,
          //   'HH:mm a',
          //   timezone
          // ),
          // requestOrigin:
          //   reserva.requestOrigin.charAt(0).toUpperCase() +
          //   reserva.requestOrigin.slice(1),
          // createdById: reserva.createdBy,
          // isAttended: reserva.isAttended,
        },
      ]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservasRestauranteStore.all]);

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteReservasRestauranteAction(
      { dispatch, addNotification },
      reservasRestauranteStore.activo.id,
      {
        ...reservasRestauranteStore.params,
        date: getDateTimezone(date, timezone),
        dateTo: getDateTimezone(dateTo, timezone),
      }
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const _detallesHandler = (id) => {
    const data = reservasRestauranteStore.all.find((el) => el.id === id);
    if (data) {
      setActivoReservasRestauranteAction(dispatch, data);
      setIsDetalles(true);
      setOpenModalDetalles(true);
    }
  };

  const renderCheckbox = (value, reservationId) => {
    return (
      <Checkbox
        disabled={
          attendedLoading[reservationId] ||
          !checkModuleActionAccess('reservation', 'update', loginStore)
        }
        checked={value}
        onChange={async (e) => {
          try {
            const isAttended = e.target.checked;
            setAttendedLoading({
              ...attendedLoading,
              [reservationId]: true,
            });
            await updateIsAttendedRestauranteService(reservationId, isAttended);
            const rows2 = [...rows];
            const row = rows2.find((el) => el[CONST.ID] === reservationId);
            row.isAttended = isAttended;
            console.log(isAttended);
            console.log(row);
            setRows(rows2);
          } catch (error) {
          } finally {
            console.log(attendedLoading);
            setAttendedLoading({
              ...attendedLoading,
              [reservationId]: false,
            });
          }
        }}
      />
    );
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
      },
    },
    {
      name: CONST.AVATAR,
      label: 'Foto',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Avatar alt={value} sx={{ width: 30, height: 30 }} src={value} />
          );
        },
      },
    },
    {
      name: CONST.NAME,
      label: 'Nombre y apellido',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={() => _detallesHandler(tableMeta.rowData[0])}
            >
              {value}
            </NameComponent>
          );
        },
      },
    },
    {
      name: CONST.IDENTIFICATION_NUMBER,
      label: 'Cédula',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={() => _detallesHandler(tableMeta.rowData[0])}
            >
              {value}
            </NameComponent>
          );
        },
      },
    },
    {
      name: CONST.CODE,
      label: 'Codigo',
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: CONST.AREA_ID,
    //   label: 'Sub área',
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    // {
    //   name: CONST.DESCRIPTION,
    //   label: 'Motivo',
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <NameComponent>{value}</NameComponent>;
    //     },
    //   },
    // },
    // {
    //   name: CONST.PRICE,
    //   label: 'Precio',
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <NameComponent>{value}</NameComponent>;
    //     },
    //   },
    // },
    {
      name: CONST.DATE_FROM,
      label: 'Fecha',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.ENTRY_TIME,
      label: 'Hora de entrada',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.DEPARTURE_TIME,
      label: 'Hora de salida',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'isAttended',
      label: 'Asistió',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return renderCheckbox(value, tableMeta.rowData[0]);
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box align="left" sx={{ display: 'flex', flexDirection: 'row' }}>
                <ActionsList
                  id={tableMeta.rowData[0]}
                  // onDetalles={() => {
                  //   const data = reservasRestauranteStore.all.find(
                  //     (el) => el.id === tableMeta.rowData[0]
                  //   );
                  //   setActivoReservasRestauranteAction(dispatch, data);
                  // }}
                  onDelete={
                    checkModuleActionAccess('reservation', 'delete', loginStore)
                      ? () => {
                          const data = reservasRestauranteStore.all.find(
                            (el) => el.id === tableMeta.rowData[0]
                          );
                          setActivoReservasRestauranteAction(dispatch, data);
                          setIsDelete(true);
                        }
                      : undefined
                  }
                />
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onDownload: () => {
      const url = `${restaurantReservations}/export`;
      const name = 'reservaciones';
      const params = {
        ...reservasRestauranteStore.params,
        date: getDateTimezone(date, timezone),
        dateTo: getDateTimezone(dateTo, timezone),
        type: 'reservation',
        groupByBlockId: true,
      };
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={
          areasStore.loadingGetAll ||
          reservasRestauranteStore.loadingGetAll ||
          loadingDelete
        }
        text={
          areasStore.loadingGetAll ? 'Cargando áreas...' : 'Cargando lista...'
        }
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {reservasRestauranteStore.activo && !isDetalles && (
        <Modal
          id="modalDeleteReserva"
          title={`Eliminar bloque de reserva`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => {
            setIsDelete(!isDelete);
            setActivoReservasRestauranteAction(dispatch, null);
          }}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar esta reserva?
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '15px',
                }}
              >
                <div>
                  Motivo:&nbsp;&nbsp;&nbsp;
                  <strong>{reservasRestauranteStore.activo.description}</strong>
                </div>
                <div>
                  Entrada:&nbsp;&nbsp;&nbsp;
                  <strong>
                    {dateformat(reservasRestauranteStore.activo.entryTime, {
                      format: 'YYYY-MM-DD HH:mm',
                    })}
                  </strong>
                </div>
                <div>
                  Salida:&nbsp;&nbsp;&nbsp;
                  <strong>
                    {dateformat(reservasRestauranteStore.activo.departureTime, {
                      format: 'YYYY-MM-DD HH:mm',
                    })}
                  </strong>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(reservasRestauranteStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button onClick={() => setIsDelete(false)}>CANCELAR</Button>
          </DialogActions>
        </Modal>
      )}
    </Grid>
  );
};

export default ListadoReservasRestaurante;
