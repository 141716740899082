import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';

export const tiposClasesApi = `${baseApi}/class-categories`;

export const getAllTiposClasesService = async (params) => {
  const res = await axios.get(tiposClasesApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveTiposClasesService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${tiposClasesApi}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${tiposClasesApi}`, data, {
      headers: await token({}),
    });
  }
  return res.data;
};

export const getOneTiposClasesService = async (id) => {
  const res = await axios.get(`${tiposClasesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteTiposClasesService = async (id) => {
  const res = await axios.delete(`${tiposClasesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};
