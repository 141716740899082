import * as React from 'react';

import {Camera} from "react-camera-pro";
import { Modal, Box, Button } from '@mui/material';
import CameraIcon from '@mui/icons-material/Camera';

const CameraButton = ({ 
  onFile = () => {},
  onClose = () => {}
 }) => {

  
  const camera = React.useRef(null);

  function base64ToFile(base64, filename) {
    let arr = base64.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%'}}>

        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'transparent'}} onClick={() => onClose()}></div>
        <Box sx={{
          width: '400px', 
          height: 400, 
          bgcolor: 'background.paper', 
          p: 2, 
          position: 'relative', 
          maxWidth: 'calc(100% - 40px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
          borderRadius: 2,
          overflow: 'hidden'
        }}>
          <Camera ref={camera} facingMode='user' />
          <Button 
            onClick={() => {
              const photo = camera.current.takePhoto();
              const file = base64ToFile(photo, `photo-${Date.now()}.png`);
              onFile(file);
            }}>
            <CameraIcon 
              sx={{
                fontSize: '3rem',
                color: 'white',
                borderRadius: '50%',
                border: '2px solid gray',
              }}
            />
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export default CameraButton;