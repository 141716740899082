import * as React from 'react';
import * as Redux from 'react-redux';
import {
  Grid,
  Typography,
  Paper,
  Divider,
  CardMedia,
  Tooltip,
  Chip,
} from '@mui/material';
import { TIPO_RESERVA_ENUM } from '../../../constants/enumsConst';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { dateformat } from '../../../helpers';
import { CYAN_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';
import { SU } from '../../../constants/ConfigConst';
import { checkModuleActionAccess, isManagerUserCode } from '../../modulos/modules.helpers';

const InfoAreasComponent = ({ setOpenModalForm }) => {
  const areasStore = Redux.useSelector((state) => state.areas);
  const activo = areasStore.activo;
  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const action = {
    read: checkModuleActionAccess('areas', 'read', loginStore),
    create: checkModuleActionAccess('areas', 'create', loginStore),
    delete: checkModuleActionAccess('areas', 'delete', loginStore),
    update: checkModuleActionAccess('areas', 'update', loginStore),
  }

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager = !isRoot && isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);
  return (
    <Paper elevation={2}>
      <CardMedia
        sx={{
          height: 200,
          width: '100%',
          position: 'relative',
        }}
        image={activo.imageUrl}
        title={activo.name}
      ></CardMedia>
      <Grid container spacing="1" sx={{ p: 2 }}>
        <Grid item md={12} align="center">
          {/* <img
                    src={activo.image_url}
                    width="100%"
                    height='100'
                    onClick={() => setOpenModalForm(true)}
                /> */}
        </Grid>
        <Grid item md={12} align="center">
          <Typography variant="h6" sx={{ textTransform: 'uppercase' }}>
            {activo.name}
          </Typography>
        </Grid>
        {/* <Grid item md={12} align='center'>
                <Typography variant="h6"> {activo.entity_code}</Typography>
            </Grid>
            <Grid item md={12} align='center'>
                <Typography variant="h5">{profile.profile_type}</Typography>
            </Grid> */}
        <Grid item md={12} align="center">
          <Divider textAlign="left" sx={{ mt: 1, mb: 1 }}>
            Detalles
          </Divider>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Hora de apertura: </strong>
            {dateformat(activo.openingTime, {
              complete: true,
              isHHmm: true,
            })}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Hora de cierre: </strong>
            {dateformat(activo.closingTime, {
              complete: true,
              isHHmm: true,
            })}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Tipo de reserva: </strong>
            {TIPO_RESERVA_ENUM[activo.blockReservation]}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Número de reservas permitidas: </strong>
            {activo.maxReserves}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>
              Cantidad de días para el número de reservas permitidas:{' '}
            </strong>
            {activo.daysMaxReserves}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Reservas activas: </strong>
            {activo.activeReserves}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Bloques seguidos: </strong>
            {activo.continuousBlock}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Categoría: </strong>
            {activo.category.name}
          </Typography>
        </Grid>
        <Grid item md={12} align="left">
          <Typography variant="body">
            <strong>Estatus: </strong>
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={activo.active ? 'Activo' : 'Bloqueado'}
                size="small"
                sx={{
                  backgroundColor: activo.active ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          </Typography>
        </Grid>
        {
          action.update && (
            <Grid item md={12} sx={{ mt: 3 }} align="center">
              <BotonNuevoComponent
                click={() => {
                  setOpenModalForm(true);
                }}
                text="EDITAR"
                morado
              />
            </Grid>
          )
        }
        {/* <Grid item md={6} sx={{ mt: 3 }}>
                    <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => {
                            // setOpenModalForm(true)
                        }}
                    >
                        Bloquear
                    </Button>
                </Grid> */}
      </Grid>
    </Paper>
  );
};

export default InfoAreasComponent;
