import { CLEAR_ALL, SET_LOGOUT } from '../../../store/Types';

export const GETALL_SERVICIOS = 'GETALL_SERVICIOS';
export const LOADING_GETALL_SERVICIOS = 'LOADING_GETALL_SERVICIOS';
export const ACTIVO_SERVICIOS = 'ACTIVO_SERVICIOS';
export const PARAMS_SERVICIOS = 'PARAMS_SERVICIOS';

const initialState = {
  all: [],
  loadingGetAll: false,
  meta: null,
  links: null,
  activo: null,
  params: {},
};

export const serviciosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_SERVICIOS:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case LOADING_GETALL_SERVICIOS:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case ACTIVO_SERVICIOS:
      return {
        ...state,
        activo: action.payload,
      };
    case PARAMS_SERVICIOS:
      return {
        ...state,
        params: action.payload,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
