import { tryCatchHelper } from '../../helpers';
import {
  GET_ALL_ACCESOS,
  LOADING_GETALL_ACCESOS,
  SET_ACTIVO_ACCESOS,
} from '../../store/Types';
import { getAllAccesosService } from './AccesosService';

export const getAllAccesosAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_ACCESOS,
      payload: true,
    });

    const res = await getAllAccesosService(params);
    if (res.status === 200) {
      dispatch({
        type: GET_ALL_ACCESOS,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_ACCESOS,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_ACCESOS,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({ type: LOADING_GETALL_ACCESOS, payload: false });
    tryCatchHelper(e);
  }
};

export const setActivoAccesosAction = async (dispatch, data) => {
  dispatch({
    type: SET_ACTIVO_ACCESOS,
    payload: data,
  });
};
