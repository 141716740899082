import { ACTIVO_SETTINGS } from '../../store/Types';
import { getSettingsService, saveSettingsService } from '../../services';
import { tryCatchHelper } from '../../helpers';

export const getSettingsAction = async ({ dispatch, addNotification }) => {
  try {
    const settings = await getSettingsService();
    dispatch({
      type: ACTIVO_SETTINGS,
      payload: settings,
    });
  } catch (e) {
    tryCatchHelper({ e, addNotification });
  }
};

export const saveSettingsAction = async (
  { dispatch, addNotification },
  data
) => {
  try {
    const settings = await saveSettingsService(data);
    addNotification('Configuración actualizada.');
    dispatch({
      type: ACTIVO_SETTINGS,
      payload: settings,
    });
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoSettingsAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_SETTINGS,
    payload: data,
  });
};
