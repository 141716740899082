import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as Redux from 'react-redux';
import ButtonFile, {
  acceptOnlyImages,
} from '../../../../components/ButtonFile';
import LoadingComponent from '../../../../components/Loading';
import * as CONST from '../../../../constants/FieldsConst';
import { validFielddHelper } from '../../../../helpers/ValidFieldsHelper';
import { useNotification } from '../../../../helpers/notification';
import { baseApi, token } from '../../../../services/Apis';
import BotonNuevoComponent from '../../../entidades/components/BotonNuevo';
import SelectFieldTiposServiciosComponent from '../../tipos/components/SelectFieldTiposServicios';
import { saveServiciosAction } from '../ServiciosAction';
import { CorreoField } from '../../../usuarios/components/FieldsUsuarios';
import PhoneNumberField from '../../../../components/fields/PhoneNumberField';
import Schedules from '../../../contactos/contactos/components/Schedules';
import ButtonFileMultipleImages from '../../../../components/FieldFileMultilpleImages';

const FormServicios = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const serviciosStore = Redux.useSelector((state) => state.servicios);

  const [types, setTypes] = React.useState([]);
  const schedules = ['08:00 AM - 12:00 PM', '02:00 PM - 06:00 PM'];
  const [fileImage, setFileImage] = React.useState();
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [service, setService] = React.useState({
    [CONST.TYPE_ID]: '',
    [CONST.NAME]: '',
    [CONST.ACTIVE]: true,
    [CONST.DESCRIPTION]: '',
    [CONST.SCHEDULES]: [
      { day: 'monday', schedules },
      { day: 'tuesday', schedules },
      { day: 'wednesday', schedules },
      { day: 'thursday', schedules },
      { day: 'friday', schedules },
      { day: 'saturday', schedules: ['08:00 AM - 02:00 PM'] },
      { day: 'sunday', schedules: ['08:00 AM - 02:00 PM'] }
    ],
    deletedAboutImages: [],
    newAboutImages: [],
    aboutImages: [],
    address: '',
    email: '',
    phone: '',
  });
  const [dataError, setDataRequired] = React.useState({
    [CONST.NAME]: false,
    [CONST.TYPE_ID]: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      axios
        .get(`${baseApi}/service-types`, {
          headers: await token({}),
        })
        .then((res) => {
          setService({
            ...service,
            [CONST.TYPE_ID]: res.data.data[0].id,
          });
          setTypes(res.data.data);
        });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (serviciosStore.activo === null) return;
    let data = {};
    Object.keys(service).map(
      (key) => (data[key] = serviciosStore.activo[key])
    );

    data = {
      ...data,
      [CONST.TYPE_ID]: serviciosStore.activo.type.id,
      deletedAboutImages: [],
      // [CONST.CLASS_TYPE_ID]: serviciosStore.activo.type.id,
      // [CONST.CLASS_CATEGORY_ID]: serviciosStore.activo.category.id,
    };

    setTimeout(() => {
      setService(data);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSetDataField = (key, value) =>
    setService({ ...service, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).forEach((key) => {
      const val = service[key];
      const valid = val === null || val === '';
      r[key] = valid;
      va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const handleSubmit = async () => {
    const valid = await _validDataForm();
    if (valid) {
      const data = {
        ...service,
      };
      if (fileImage) data[CONST.IMAGE] = fileImage;
      setLoadingSave(true);
      let res = await saveServiciosAction(
        { dispatch, addNotification },
        serviciosStore.activo === null ? null : serviciosStore.activo.id,
        data,
        serviciosStore.params
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };
  // const tabPanel1 = <TabPanel value={1}>
  const tabPanel1 = (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <ButtonFile
          id={CONST.IMG_FOTO}
          onFile={(file) => setFileImage(file)}
          file={fileImage}
          text="Subir imagén"
          sxc={{ marginTop: '5px' }}
          size="40%"
          name={serviciosStore.activo ? serviciosStore.activo.name : null}
          onDelete={() => setFileImage(null)}
          accept={acceptOnlyImages}
          src={
            fileImage
              ? null
              : serviciosStore.activo
              ? serviciosStore.activo.imageUrl
              : null
          }
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          size="small"
          id={CONST.NAME}
          required
          label="Nombre"
          value={service.name}
          onChange={(e) => _handleSetDataField(CONST.NAME, e.target.value)}
          helperText={dataError.name ? validFielddHelper(1) : null}
          error={dataError.name}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <FormControl fullWidth>
          <InputLabel id="level">Tipo de servicio</InputLabel>
          <Select
            labelId="level"
            size="small"
            label="Tipo de servicio"
            // disabled={!!serviciosStore.activo}
            value={service.typeId}
            onChange={(e) =>{
              setService({
                ...service,
                [CONST.TYPE_ID]: e.target.value,
              });
            }}
            id="typeId"
          >
            {types.map((item, index) => (
              <MenuItem key={`item_${index}`} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={12}>
        <FormControl fullWidth>
          <InputLabel id="label_estatus">Estatus</InputLabel>
          <Select
            labelId="label_estatus"
            size="small"
            value={service.active}
            label="Estatus"
            onChange={(e) => _handleSetDataField(CONST.ACTIVE, e.target.value)}
            id={CONST.ACTIVE}
          >
            <MenuItem value={true}>Activo</MenuItem>
            <MenuItem value={false}>Bloqueado</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          size="small"
          id={CONST.DESCRIPTION}
          label="Descripción"
          value={service.description}
          onChange={(e) =>
            _handleSetDataField(CONST.DESCRIPTION, e.target.value)
          }
        />
      </Grid>
      
      <Grid item xs={12} md={6}>
        <CorreoField
          v={service.email}
          on={(e) => {
            let text = e.target.value;
            _handleSetDataField(CONST.EMAIL, text);
            var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
            if (validEmail.test(text)) {
              setDataRequired({
                ...dataError,
                [CONST.EMAIL]: false,
              });
              setErrorEmail(false);
              return;
            }
            setErrorEmail(true);
            setDataRequired({
              ...dataError,
              [CONST.EMAIL]: true,
            });
          }}
          dis={!!serviciosStore.activo}
          autoComplete="off"
          errorEmail={errorEmail}
          e={errorEmail || dataError.email}
        />
      </Grid>
      
      <Grid item xs={12} md={6}>
        <PhoneNumberField
          value={service.phone}
          onChange={(value) => _handleSetDataField(CONST.PHONE, value)}
          error={dataError.phone}
          fieldName={CONST.PHONE}
          required={true}
          dataError={dataError}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          size="small"
          id={CONST.ADDRESS}
          label="Dirección"
          value={service.address}
          onChange={(e) =>
            _handleSetDataField(CONST.ADDRESS, e.target.value)
          }
        />
      </Grid>

      
      <Grid item xs={12} md={12}>
        <ButtonFileMultipleImages
          images={service.aboutImages}
          onChange={(files) => {
            setService({ ...service, newAboutImages: files });
          }}
          onDelete={(image) => {
            setService({
              ...service,
              deletedAboutImages: [...service.deletedAboutImages, image],
            });
          }}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Schedules
          schedules={service.schedules}
          onChange={(e) => {
            setService({ ...service, schedules: e });
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="form" sx={{}}>
        <LoadingComponent
          isLoading={loadingSave}
          text={serviciosStore.activo ? 'Actualizando...' : 'Guardando...'}
        />
        <Grid container>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="body" sx={{ fontWeight: 'bold' }}>
                  Información
                </Typography>
                <Divider sx={{ mb: 1 }}></Divider>
                {tabPanel1}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} align="center" sx={{ m: 2 }}>
            <BotonNuevoComponent
              click={handleSubmit}
              text="GUARDAR"
              disabled={loadingSave}
              morado
            />
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

FormServicios.propTypes = {
  getAll: PropTypes.func,
  setOpenModalForm: PropTypes.func,
};

export default FormServicios;
