import {
  GETALL_PUERTAS,
  LOADING_UPDATE_PUERTAS,
  ACTIVO_PUERTAS,
  SET_LOGOUT,
  CLEAR_ALL,
} from '../../store/Types';

const initialState = {
  all: [],
  loadingGetAll: false,
  activo: null,
  params: {},
};

export const puertasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_PUERTAS:
      return {
        ...state,
        all: action.payload.data,
      };
    case ACTIVO_PUERTAS:
      return {
        ...state,
        activo: action.payload,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case LOADING_UPDATE_PUERTAS:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    default:
      return state;
  }
};
