import * as React from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ESTADO, TIPO_ENTIDAD } from "../../constants/FieldsConst";
import { validFielddHelper } from "../../helpers/ValidFieldsHelper";

export const tipoDocumentoEnums = [
  { id: "V", nombre: "V" },
  { id: "E", nombre: "E" },
  { id: "P", nombre: "P" },
  { id: "OP", nombre: "OP" },
  { id: "J", nombre: "J" },
];

const TiposDocumentosField = ({
  onChange,
  required,
  value,
  helperText,
  error,
  isSearch,
  disabled,
}) => {
  const xName = "Tipo de documento";

  const xSearch = (
    <Autocomplete
      disablePortal
      id={ESTADO}
      options={tipoDocumentoEnums}
      size="small"
      onChange={onChange}
      getOptionLabel={(option) => option.label}
      value={value}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          fullWidth
          label={xName}
          helperText={helperText ?? error ? validFielddHelper(1) : null}
          error={error}
          disabled={disabled}
          required={required ?? true}
          {...params}
        />
      )}
    />
  );

  const xSelect = (
    <FormControl fullWidth error={error} size="small" required>
      <InputLabel id={TIPO_ENTIDAD}>{xName}</InputLabel>
      <Select
        labelId={TIPO_ENTIDAD}
        id={TIPO_ENTIDAD}
        value={value}
        label={xName}
        disabled={disabled}
        onChange={onChange}
      >
        {tipoDocumentoEnums.map((item) => {
          return (
            <MenuItem key={item.id} value={item.id}>
              {item.nombre}
            </MenuItem>
          );
        })}
      </Select>
      {error ? (
        <FormHelperText>
          {helperText ?? error ? validFielddHelper(1) : null}
        </FormHelperText>
      ) : (
        <></>
      )}
    </FormControl>
  );

  return isSearch ? xSearch : xSelect;
};

TiposDocumentosField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TiposDocumentosField;
