import {
  GET_ALL_DELIVERY,
  LOADING_GETALL_DELIVERY,
  SET_ACTIVO_DELIVERY,
  SET_LOGOUT,
  PARAMS_DELIVERY,
} from '../../store/Types';

const initialState = {
  all: [],
  loadingGetAll: false,
  meta: null,
  links: null,
  activo: null,
  params: {},
};

export const deliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DELIVERY:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case SET_ACTIVO_DELIVERY:
      return {
        ...state,
        activo: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case LOADING_GETALL_DELIVERY:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case PARAMS_DELIVERY:
      return {
        ...state,
        params: action.payload,
      };
    default:
      return state;
  }
};
