import { Grid, Paper } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { checkRootAccess } from '../../modulos/modules.helpers';
import {
  getAllTiposEventosAction,
  setActivoTiposEventosAction,
} from './TiposEventosAction';
import FormTiposEventos from './components/FormTiposEventos';
import ListadoTiposEventos from './components/ListadoTiposEventos';

const TiposEventosView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposEventosStore = Redux.useSelector((state) => state.tiposEventos);

  const loginStore = Redux.useSelector((state) => state.login);
  checkRootAccess(loginStore);

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoTiposEventosAction(dispatch, null);
          }}
          azul
          mr={2}
        />
        {tiposEventosStore.all.length > 0 && (
          <BotonNuevoComponent
            click={async () => {
              await setActivoTiposEventosAction(dispatch, null);
              await getAllTiposEventosAction(
                dispatch,
                entidadesStore.activo.id
              );
            }}
            disabled={tiposEventosStore.loadingGetAll}
            white
            text={
              tiposEventosStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'
            }
          />
        )}
      </Grid>
      <Grid item md={4}>
        <Paper elevation={3}>
          <FormTiposEventos />
        </Paper>
      </Grid>
      <Grid item md={8}>
        <ListadoTiposEventos
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Grid>
    </Grid>
  );
};

export default TiposEventosView;
