import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import * as ROUTES from '../../../router/Routes';
import NotificacionesView from './NotificacionesView';

const NotificacionesPage = () => {
  return (
    <ContainerTemplate active={ROUTES.notificaciones} routes={[]}>
      <NotificacionesView />
    </ContainerTemplate>
  );
};

export default NotificacionesPage;
