import {
  deleteTiposClasesService,
  getAllTiposClasesService,
  getOneTiposClasesService,
  saveTiposClasesService,
} from '../../../services';
import { tryCatchHelper } from '../../../helpers';
import {
  ACTIVO_TIPOS_CLASES,
  GETALL_TIPOS_CLASES,
  LOADING_GETALL_TIPOS_CLASES,
} from './TiposClasesReducer';

export const getAllTiposClasesAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_TIPOS_CLASES,
      payload: true,
    });
    const res = await getAllTiposClasesService({ entityId });
    if (res.status === 200) {
      dispatch({
        type: GETALL_TIPOS_CLASES,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_TIPOS_CLASES,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_TIPOS_CLASES,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_TIPOS_CLASES,
      payload: false,
    });
    let message =
      e.response?.data?.message ||
      e.message ||
      'Error al intentar, realizar el proceso';
    if (Array.isArray(e.message)) message = e.message.join(', ');
    if (Array.isArray(e.response?.data?.message)) {
      message = e.response?.data?.message.join(', ');
    }
    console.warn(message);
  }
};

export const saveTiposClasesAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveTiposClasesService(id, data);
    addNotification(
      res.message ?? id
        ? 'El tipo de documento actualizado.'
        : 'El tipo de documento registrado.'
    );
    await getAllTiposClasesAction(dispatch, entityId);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoTiposClasesAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_TIPOS_CLASES,
    payload: data,
  });
};

export const getOneTiposClasesAction = async (dispatch, id) => {
  try {
    const res = await getOneTiposClasesService(id);
    if (res.status === 200) {
      await setActivoTiposClasesAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteTiposClasesAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteTiposClasesService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data?.message ?? 'Tipo de documento eliminado.');
      await setActivoTiposClasesAction(dispatch, null);
      await getAllTiposClasesAction(dispatch, entityId);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
