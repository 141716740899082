import { Autocomplete, Button, Card, Checkbox, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import * as Redux from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import * as yup from 'yup';
import LoadingComponent from '../../../components/Loading';
import { useNotification } from '../../../helpers/notification';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import RSelect from 'react-select/creatable'
import { Remove } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
const DynamicSelect = () => {
  // Estado para las opciones dinámicas
  const [options, setOptions] = React.useState([
    { value: 'option1', label: 'Opción 1' },
    { value: 'option2', label: 'Opción 2' },
    { value: 'option3', label: 'Opción 3' },
  ]);

  // const defaultOptions = 

  // const selectoptions = [];

  // Estado para los valores seleccionados
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  // Manejar el cambio en el select
  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
  };

  return (
    <FormControl sx={{ width: "300px" }}>
      <InputLabel id="dynamic-multiple-select-label">Seleccionar Opciones</InputLabel>
      <Select
        labelId="dynamic-multiple-select-label"
        id="dynamic-multiple-select"
        multiple
        value={selectedOptions}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={selectedOptions.indexOf(option.value) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
const FormAreaExtra = ({ setOpenModalForm, onSuccess = () => {}, extra }) => {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const timezone = entidadesStore.activo.country.timezone;
  const utcOffset = entidadesStore.activo.country.utcOffset || 'UTC-04:00';

  const onSubmit = async (values) => {
    onSuccess(values);
  };

  // const [options, setOptions] = React.useState([]);

  // const addOption = () => {
  //   setOptions([...options, { id: Date.now(), name: '', values: [] }])
  // }

  // const removeOption = (id) => {
  //   setOptions(options.filter(option => option.id !== id))
  // }

  // const updateOptionName = (id, name) => {
  //   setOptions(options.map(option => 
  //     option.id === id ? { ...option, name } : option
  //   ))
  // }

  // const addValue = (optionId) => {
  //   setOptions(options.map(option => 
  //     option.id === optionId 
  //       ? { ...option, values: [...option.values, { id: Date.now(), value: '', stock: 0 }] }
  //       : option
  //   ))
  // }

  // const removeValue = (optionId, valueId) => {
  //   setOptions(options.map(option => 
  //     option.id === optionId 
  //       ? { ...option, values: option.values.filter(v => v.id !== valueId) }
  //       : option
  //   ))
  // }

  // const updateValue = (optionId, valueId, field, newValue) => {
  //   setOptions(options.map(option => 
  //     option.id === optionId 
  //       ? { 
  //           ...option, 
  //           values: option.values.map(v => 
  //             v.id === valueId 
  //               ? { ...v, [field]: field === 'stock' ? parseInt(newValue) : newValue }
  //               : v
  //           )
  //         }
  //       : option
  //   ))
  // }

  const initialOption = {
    id: null,
    name: '',
    values: [],
    new: true
  }

  const initialOptionValue = {
    id: null,
    value: '',
    stock: 0,
    new: true
  }

  const validationSchema = yup.object().shape({
    name: yup.string().required('El nombre es requerido'),
    description: yup.string().optional(),
    price: yup.number().required('El precio es requerido'),
    stock: yup.number().required('El stock es requerido'),
    maxPerReservation: yup.number().required('El máximo por reserva es requerido'),
    options: yup.array().of(yup.object().shape({
      name: yup.string().required('El nombre de la opción es requerido'),
      values: yup.array().of(yup.object().shape({
        value: yup.string().required('El valor es requerido'),
        stock: yup.number().min(0, 'El stock debe ser mayor a 0').required('El stock es requerido'),
      })).optional(),
    })).optional(),
  });

  const initialValue = {
    id: extra?.id || null,
    name: extra?.name || '',
    description: extra?.description || '',
    price: extra?.price || 0,
    stock: extra?.stock || 0,
    maxPerReservation: extra?.maxPerReservation || 0,
    options: extra?.options || [],
  };

  return (
    <Box component="form" sx={{}}>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, handleSubmit, errors, validateField, handleChange }) => {
          const options = JSON.parse(JSON.stringify(values.options));

          const addOption = () => {
            options.push({ ...initialOption });
            setFieldValue('options', options);
          }

          const removeOption = (index) => {
            options.splice(index, 1);
            setFieldValue('options', options);
          }

          const updateOptionName = (index, name) => {
            options[index].name = name;
            setFieldValue('options', options);
          }

          const addValue = (index) => {
            options[index].values.push({ ...initialOptionValue });
            setFieldValue('options', options);
          }

          const removeValue = (index, valueIndex) => {
            options[index].values.splice(valueIndex, 1);
            setFieldValue('options', options);
          }

          const updateValue = (index, valueIndex, field, newValue) => {
            options[index].values[valueIndex][field] = newValue;
            setFieldValue('options', options);
          }

          return (
            <Grid container spacing={2}>
              
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="name"
                  required
                  label="Nombre"
                  value={values.name}
                  onChange={handleChange('name')}
                  helperText={errors.name}
                  error={!!errors.name}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="description"
                  label="Descripción"
                  value={values.description}
                  onChange={handleChange('description')}
                  helperText={errors.description}
                  error={!!errors.description}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  value={values.price}
                  onChange={handleChange('price')}
                  id="price"
                  label="Precio"
                  required
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  helperText={errors.price}
                  error={!!errors.price}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  value={values.stock}
                  onChange={handleChange('stock')}
                  id="stock"
                  label="Stock"
                  required
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  helperText={errors.stock}
                  error={!!errors.stock}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  value={values.maxPerReservation}
                  onChange={handleChange('maxPerReservation')}
                  id="maxPerReservation"
                  label="Máximo por reserva"
                  required
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  helperText={errors.maxPerReservation}
                  error={!!errors.maxPerReservation}
                />
              </Grid>
  
              <Grid item xs={12} md={12}>
                <Box sx={{ mt: 4, mb: 2 }}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={addOption}
                  >
                    Agregar Opción
                  </Button>
                </Box>
                {values.options.map((option, index) => (
                  <Card key={index} sx={{ mb: 2, p: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <TextField
                      label="Nombre de la opción"
                      value={option.name}
                      onChange={(e) => updateOptionName(index, e.target.value)}
                      variant="outlined"
                      sx={{ width: '75%' }}
                      size="small"
                      helperText={errors?.options?.[index]?.name}
                      error={!!errors?.options?.[index]?.name}
                    />
                    <IconButton onClick={() => removeOption(index)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  {option.values.map((value, valueIndex) => (
                    <Box key={valueIndex} sx={{ display: 'flex', mb: 2 }}>
                      <TextField
                        label="Valor"
                        value={value.value}
                        onChange={(e) => updateValue(index, valueIndex, 'value', e.target.value)}
                        variant="outlined"
                        size="small"
                        sx={{ width: '40%', mr: 1 }}
                        helperText={errors?.options?.[index]?.values?.[valueIndex]?.value}
                        error={!!errors?.options?.[index]?.values?.[valueIndex]?.value}
                      />
                      <TextField
                        label="Stock"
                        type="number"
                        value={value.stock}
                        onChange={(e) => updateValue(index, valueIndex, 'stock', e.target.value)}
                        variant="outlined"
                        size="small"
                        sx={{ width: '40%', mr: 1 }}
                        helperText={errors?.options?.[index]?.values?.[valueIndex]?.stock}
                        error={!!errors?.options?.[index]?.values?.[valueIndex]?.stock}
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                      />
                      <IconButton onClick={() => removeValue(index, valueIndex)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => addValue(index)}
                    size="small"
                  >
                    Agregar Valor
                  </Button>
                </Card>
                ))}
              </Grid>
              <Grid item md={12} align="center" sx={{ m: 2 }}>
                <BotonNuevoComponent
                  click={handleSubmit}
                  text="GUARDAR"
                  morado
                />
              </Grid>
              {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
            </Grid>
          )
        }}
      </Formik>
    </Box>
  );
};

export default FormAreaExtra;
