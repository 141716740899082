import React from "react";
import ContainerTemplate from "../../components/template/Container";
import UsuariosView from "./UsuariosView";
import * as ROUTES from "../../router/Routes";

const UsuariosModule = () => {
  return (
    <ContainerTemplate active={ROUTES.usuarios} routes={[]}>
      <UsuariosView isGlobal={false} />
    </ContainerTemplate>
  );
};

export default UsuariosModule;
