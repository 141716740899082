import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../components';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../modulos/modules.helpers';
import {
  getAllMetodosPagoAction,
  setActivoMetodosPagoAction,
} from './MetodosPagoAction';
import FormMetodosPago from './components/FormMetodosPago';
import ListadoMetodosPago from './components/ListadoMetodosPago';

const MetodosPagoView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposInvitacionStore = Redux.useSelector(
    (state) => state.tiposInvitacion
  );

  const loginStore = Redux.useSelector((state) => state.login);
  const accessName = 'methodsPay';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoMetodosPagoAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        {tiposInvitacionStore.all.length > 0 && (
          <BotonNuevoComponent
            click={async () => {
              await setActivoMetodosPagoAction(dispatch, null);
              await getAllMetodosPagoAction(
                dispatch,
                entidadesStore.activo.id,
                null
              );
            }}
            disabled={tiposInvitacionStore.loadingGetAll}
            white
            text={
              tiposInvitacionStore.loadingGetAll
                ? 'Actualizando...'
                : 'Actualizar'
            }
          />
        )}
      </Grid>
      <Grid item md={12}>
        <ListadoMetodosPago setOpenModalForm={(val) => setOpenModalForm(val)} />
      </Grid>
      <Modal
        id="modalFormMetodosPago"
        title={`${
          tiposInvitacionStore.activo ? 'Editar' : 'Nuevo'
        } tipo de invitación`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {tiposInvitacionStore.activo && (
          <Typography variant="h6">
            {tiposInvitacionStore.activo.nombres}{' '}
            {tiposInvitacionStore.activo.apellidos}
          </Typography>
        )}
        <FormMetodosPago
          activo={tiposInvitacionStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default MetodosPagoView;
