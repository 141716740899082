import { Box, LinearProgress } from '@mui/material';
import React from 'react';

const LoadingComponent = ({ isLoading, isActivo, text }) => {
  return (
    isLoading && (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  );
};

export default LoadingComponent;
