import {
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../../components';
import ActionsList from '../../../../components/ActionsList';
import LoadingComponent from '../../../../components/Loading';
import { MORADO_COLOR } from '../../../../constants/ColorsConst';
import { SU } from '../../../../constants/ConfigConst';
import * as CONST from '../../../../constants/FieldsConst';
import { dateformat, getNameFromUserCode } from '../../../../helpers';
import { useNotification } from '../../../../helpers/notification';
import { textLabelsTable } from '../../../invitados/components/ListadoInvitados';
import { isManagerUserCode } from '../../../modulos/modules.helpers';
import { NameComponent } from '../../../usuarios/components/ListadoUsuarios';
import {
  deleteReservacionesClasesAction,
  saveReservacionesClasesAction,
  setActivoReservacionesClasesAction,
} from '../ReservacionesClasesAction';

const ListadoReservacionesClases = ({ setOpenModalForm, params = {} }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const reservacionesClasesStore = Redux.useSelector(
    (state) => state.reservacionesClases
  );

  const loginStore = Redux.useSelector((state) => state.login);

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager =
    !isRoot &&
    isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isToggleActive, setIsToggleActive] = React.useState(false);

  React.useEffect(() => {
    setRows([]);
    reservacionesClasesStore.all.forEach((el) => {
      setRows((old) => [
        ...old,
        {
          ...el,
          [CONST.DATE]: dateformat(el.date, {}),
          user: getNameFromUserCode(el.userCode, { code: true }),
          teacher: el.classSpot.teacher.userCode,
          schedule: el.schedule,
          active: el.active ? 'Activo' : 'Bloqueado',
          number: el.classSpot.number,
          actions: el,
        },
      ]);
    });
  }, [reservacionesClasesStore.all]);

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteReservacionesClasesAction(
      { dispatch, addNotification },
      id,
      params
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const _handleToggleActiveClick = async (id) => {
    setLoadingDelete(true);
    await saveReservacionesClasesAction(
      { dispatch, addNotification },
      entidadesStore.activo.id,
      id,
      {
        active: !reservacionesClasesStore.activo.active,
      }
    );
    setIsToggleActive(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'userCode',
      label: 'Usuario',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{ display: 'flex', alignItems: 'center', padding: '5px' }}
            >
              <div>
                <Avatar
                  alt={value.avatar}
                  sx={{ width: 30, height: 30 }}
                  src={value.avatar}
                />
              </div>
              <div style={{ marginLeft: '10px' }}>
                <NameComponent>
                  {getNameFromUserCode(value, { code: true })}
                </NameComponent>
                <NameComponent>
                  CI:{' '}
                  {`${value.user.profile.typeDocument}-${value.user.profile.identificationNumber}`}
                </NameComponent>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'teacher',
      label: 'Profesor',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(value);
          return (
            <div
              style={{ display: 'flex', alignItems: 'center', padding: '5px' }}
            >
              <div>
                <Avatar
                  alt={value.avatar}
                  sx={{ width: 30, height: 30 }}
                  src={value.avatar}
                />
              </div>
              <div style={{ marginLeft: '10px' }}>
                <NameComponent>
                  {getNameFromUserCode(value, { code: true })}
                </NameComponent>
                <NameComponent>
                  CI:{' '}
                  {`${value.user.profile.typeDocument}-${value.user.profile.identificationNumber}`}
                </NameComponent>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: CONST.DATE,
      label: 'Fecha',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'schedule',
      label: 'Horario',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'number',
      label: 'Cupo',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box align="left" sx={{ width: '5em' }}>
              <ActionsList
                id={tableMeta.rowData[0]}
                onDelete={async () => {
                  await setActivoReservacionesClasesAction(dispatch, value);
                  setIsDelete(true);
                }}
                active={value.active}
              />
            </Box>
          );
        },
      },
    },
  ];

  if (isManager) {
    columns.pop();
  }

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onSearchChange: (text) => {
      console.log('buscardor', text);
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={reservacionesClasesStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable data={rows} columns={columns} options={options} />
      </Grid>
      {reservacionesClasesStore.activo ? (
        <Modal
          id="modalFormReservacionesClases"
          title={`Eliminar`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este tipo de documento?
              <p>
                <strong>{reservacionesClasesStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(reservacionesClasesStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {reservacionesClasesStore.activo ? (
        <Modal
          id="modalToggleActivo"
          title={`${
            reservacionesClasesStore.activo.active ? 'Bloquear' : 'Activar'
          } ${reservacionesClasesStore.activo.name}`}
          open={isToggleActive}
          maxWidth="sm"
          onClose={() => setIsToggleActive(!isToggleActive)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`¿Seguro que quieres ${
                reservacionesClasesStore.activo.active ? 'Bloquear' : 'Activar'
              } este tipo de documento?`}
              <p>
                <strong>{reservacionesClasesStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleToggleActiveClick(
                  reservacionesClasesStore.activo.id
                );
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsToggleActive(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoReservacionesClases;
