import {
  Box,
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { green, grey } from '@mui/material/colors';
import * as React from 'react';
import * as Redux from 'react-redux';
import styled from 'styled-components';

const Cards = styled.div`
  background-color: ${({ activo }) => (activo ? green[500] : grey[200])};
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  color: ${({ activo }) => (activo ? 'white' : 'black')};
  &:hover {
    background-color: ${grey[300]};
    color: ${green[900]};
  }
`;

const CardSucripcionesEntidades = ({ entidad, activo, onClick }) => {
  return (
    <Cards onClick={onClick} activo={activo}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: '700' }}>
          {entidad.nombre}
        </Typography>
      </Box>
      <Typography variant="body2" sx={{}}>
        {entidad.correo}
      </Typography>
      <Typography variant="body2" sx={{}}>
        {entidad.direccion ? entidad.direccion.direccion : ''}
      </Typography>
    </Cards>
  );
};

const SearchEntidad = ({ onClickCard, select }) => {
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const [buscarText, setBuscarText] = React.useState('');
  const [loadingBuscar, setLoadingBuscar] = React.useState(false);
  const _handleBuscar = async () => {
    setLoadingBuscar(true);

    setLoadingBuscar(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={9}>
        <TextField
          fullWidth
          size="small"
          id="buscarTextEntidad"
          label="Buscar por el nombre de la entidad"
          value={buscarText}
          onChange={(e) => setBuscarText(e.target.value)}
        />
      </Grid>
      <Grid item md={3}>
        <Button
          variant="contained"
          onClick={_handleBuscar}
          disabled={buscarText === ''}
        >
          Buscar
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        {loadingBuscar && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        <Grid container spacing={1}>
          {entidadesStore.buscados.map((item) => {
            return (
              <Grid key={`ent_${item.id}`} item xs={12} md={12}>
                <CardSucripcionesEntidades
                  entidad={item}
                  activo={select ? select.id === item.id : false}
                  onClick={() => {
                    onClickCard(item);
                    setBuscarText('');
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

SearchEntidad.propTypes = {};

export default SearchEntidad;
