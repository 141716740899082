import * as React from 'react';
import PropTypes from 'prop-types';
import { Card, CardMedia } from '@mui/material';
import FormEntidades from '../../entidades/components/FormEntidades';

const FormAjustes = ({ activo, openModal }) => {
  const isH = activo.id === '2';

  return (
    <Card sx={{}}>
      <CardMedia
        sx={{
          height: 200,
          borderRadius: 2,
          position: 'relative',
        }}
        image={activo.banner}
        title={activo.name}
      >
        <img
          style={{
            position: 'absolute',
            bottom: isH ? 20 : 10,
            left: 20,
            height: isH ? 80 : null,
          }}
          alt="imagenajustes"
          src={activo.images?.logo}
          width={isH ? null : '130'}
          height={isH ? null : '80'}
          title={activo.name}
        />
      </CardMedia>
      <FormEntidades openModal={openModal} />
    </Card>
  );
};

FormAjustes.propTypes = {
  openModal: PropTypes.func,
};

export default FormAjustes;
