import axios from "axios";
import { baseApi, token, isTokenValid } from "../../services/Apis";

export const dashboard = `${baseApi}/dashboard`;

export const getDashboardService = async (params) => {
  try {
    const res = await axios.get(dashboard, {
      headers: await token({}),
      params,
    });
    return res;
  } catch (e) {
    isTokenValid(e);
    return e.response;
  }
};
