import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import ContactosView from './ContactosView';
import * as ROUTES from '../../../router/Routes';

const ContactosModule = () => {
  return (
    <ContainerTemplate active={ROUTES.contactos} routes={[]}>
      <ContactosView />
    </ContainerTemplate>
  );
};

export default ContactosModule;
