import {
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Tooltip,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../../components';
import ActionsList from '../../../../components/ActionsList';
import LoadingComponent from '../../../../components/Loading';
import { CYAN_COLOR, MORADO_COLOR } from '../../../../constants/ColorsConst';
import * as CONST from '../../../../constants/FieldsConst';
import { dateformat } from '../../../../helpers';
import { useNotification } from '../../../../helpers/notification';
import { textLabelsTable } from '../../../invitados/components/ListadoInvitados';
import { NameComponent } from '../../../usuarios/components/ListadoUsuarios';
import {
  deleteTiposImagenClasesAction,
  getAllTiposImagenClasesAction,
  saveTiposImagenClasesAction,
  setActivoTiposImagenClasesAction,
} from '../TiposImagenClasesAction';
import { SU } from '../../../../constants/ConfigConst';

const ListadoTiposImagenClases = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposImagenClasesStore = Redux.useSelector(
    (state) => state.tiposImagenClases
  );
  const loginStore = Redux.useSelector((state) => state.login);
  const isRoot = loginStore.roles.indexOf(SU) !== -1;

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isToggleActive, setIsToggleActive] = React.useState(false);

  React.useEffect(() => {
    if (
      tiposImagenClasesStore.all &&
      tiposImagenClasesStore.all.length === 0
    ) {
      getAllTiposImagenClasesAction(dispatch, entidadesStore.activo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRows([]);
    tiposImagenClasesStore.all.forEach((el) => {
      setRows((old) => [
        ...old,
        {
          ...el,
          [CONST.DATE]: dateformat(el.date, {}),
          active: el.active ? 'Activo' : 'Bloqueado',
          actions: el,
        },
      ]);
    });
  }, [tiposImagenClasesStore.all]);

  const _handleEditClick = async (id) => {
    const data = tiposImagenClasesStore.all.find((el) => el.id === id);
    await setActivoTiposImagenClasesAction(dispatch, data);
    setOpenModalForm(true);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteTiposImagenClasesAction(
      { dispatch, addNotification },
      id,
      entidadesStore.activo.id
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const _handleToggleActiveClick = async (id) => {
    setLoadingDelete(true);
    await saveTiposImagenClasesAction(
      { dispatch, addNotification },
      entidadesStore.activo.id,
      id,
      {
        active: !tiposImagenClasesStore.activo.active,
      }
    );
    setIsToggleActive(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.NAME,
      label: 'Nombre',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={(value !== 'Default' || isRoot) ? () => _handleEditClick(tableMeta.rowData[0]) : undefined}
            >
              {value}
            </NameComponent>
          );
        },
      },
    },
    {
      name: 'url',
      label: 'Imágenes',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30px' }}>
              <img
                src={value}
                alt="layout"
                width="20"
                height="20"
              />
            </div>
          );
        }
      },
    },
    {
      name: CONST.ACTIVE,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={value}
                size="small"
                sx={{
                  backgroundColor:
                    value === 'Activo' ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = tiposImagenClasesStore.all.find(el => el.id === tableMeta.rowData[0]);
          if (data?.name === 'Default' && !isRoot) return <div style={{ height: '30px'}}></div>;
          return (
            <Box align="left" sx={{ width: '5em' }}>
              <ActionsList
                id={tableMeta.rowData[0]}
                onEdit={() => _handleEditClick(tableMeta.rowData[0])}
                onDelete={(data?.name === 'Default') ? undefined : async () => {
                  await setActivoTiposImagenClasesAction(dispatch, value);
                  setIsDelete(true);
                }}
                onToggleActive={(data?.name === 'Default') ? undefined : async () => {
                  await setActivoTiposImagenClasesAction(dispatch, value);
                  setIsToggleActive(true);
                }}
                active={value.active}
              />
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onSearchChange: (text) => {
      console.log('buscardor', text);
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={tiposImagenClasesStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {tiposImagenClasesStore.activo ? (
        <Modal
          id="modalFormTiposImagenClases"
          title={`Eliminar`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este tipo de documento?
              <p>
                <strong>{tiposImagenClasesStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(tiposImagenClasesStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {tiposImagenClasesStore.activo ? (
        <Modal
          id="modalToggleActivo"
          title={`${
            tiposImagenClasesStore.activo.active ? 'Bloquear' : 'Activar'
          } ${tiposImagenClasesStore.activo.name}`}
          open={isToggleActive}
          maxWidth="sm"
          onClose={() => setIsToggleActive(!isToggleActive)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`¿Seguro que quieres ${
                tiposImagenClasesStore.activo.active ? 'Bloquear' : 'Activar'
              } este tipo de documento?`}
              <p>
                <strong>{tiposImagenClasesStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleToggleActiveClick(
                  tiposImagenClasesStore.activo.id
                );
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsToggleActive(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoTiposImagenClases;
