import {
  SET_DATA_AUTH,
  SET_LOGOUT,
  ERROR_LOGIN,
  SET_AUTH_MODULES,
} from '../../../store/Types';
import {
  authUserService,
  getAllModulosAdminService,
  loginService,
} from '../../../services';
import * as ROUTES from '../../../router/Routes';
import { PERSIST, TOKEN, SU, ADMIN } from '../../../constants/ConfigConst';
import {
  getOneEntidadesAction,
  setActivoEntidadesAction,
} from '../../entidades/EntidadesAction';

import * as _ from 'lodash';

export const loginAction = async ({
  dispatch,
  loginStore,
  history,
  dataLogin,
}) => {
  try {
    const res = await loginService({
      username: dataLogin.email,
      password: dataLogin.password,
    });

    if (res.status >= 400 || res.status === undefined || res.status === null)
      return setErrorLogin(
        dispatch,
        800,
        res.message ?? 'Hubo un error en el inicio de sesión'
      );

    if (res.status === 200) {
      localStorage.setItem(TOKEN, res.data.accessToken);
      const resUser = await authUserService();
      const user = resUser.data;

      dispatch({
        type: SET_DATA_AUTH,
        payload: user,
      });

      const isRoot = user.roles.some((role) => role.name === SU);
      const isAdmin =
        user.roles.some((role) => role.name === ADMIN) &&
        user.userCodes.some((userCode) => userCode.code.codeType === ADMIN);

      if (isRoot) {
        setActivoEntidadesAction(dispatch, null);
        history.push(ROUTES.panelControl.to);
        return;
      }

      if (isAdmin) {
        if (user.userCodes.length === 1) {
          const modules = await getAllModulosAdminService(user.userCodes[0].id);
          dispatch({
            type: SET_AUTH_MODULES,
            payload: modules.data.data,
          });
        }

        const entityIds = _.uniq(
          user.userCodes.map((userCode) => userCode.code.entity.id)
        );
        if (entityIds.length === 1) {
          const resEnt = await getOneEntidadesAction(dispatch, entityIds[0]);
          if (resEnt) {
            history.push(ROUTES.dashboard.to);
          } else {
            logoutAction({ dispatch, history });
          }
        } else {
          setActivoEntidadesAction(dispatch, null);
          history.push(ROUTES.entidades.to);
        }
        return;
      }

      logoutAction({ dispatch, history });
      setErrorLogin(
        dispatch,
        410,
        'No tiene una entidad asignada. Comuniquese con un administrador del sistema'
      );
    }
  } catch (e) {}
};

export const setErrorLogin = (dispatch, code, message) => {
  dispatch({
    type: ERROR_LOGIN,
    payload: {
      code,
      message,
    },
  });
  setTimeout(() => {
    dispatch({
      type: ERROR_LOGIN,
      payload: null,
    });
  }, 5000);
};

export const logoutAction = async ({ dispatch, history }) => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(PERSIST);
  history.push(ROUTES.login.to);
  dispatch({
    type: SET_LOGOUT,
  });
};
