import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import LoadingComponent from "../../../components/Loading";
import * as Yup from "yup";
import { ButtonFile } from "../../../components";
import { useState } from "react";
import {
  byIdentifacionUsuariosService,
  preCreateUsuariosService,
  saveUsuariosService,
} from "../UsuariosService";
import BotonNuevoComponent from "../../entidades/components/BotonNuevo";
import { useNotification } from "../../../helpers/notification";
import * as Redux from "react-redux";
import PhoneNumberField from "../../../components/fields/PhoneNumberField";
import { isValidPhoneNumber } from "libphonenumber-js";

import CheckIcon from '@mui/icons-material/Check';

let loadIdentificationTimeout = null;

export const typeDocuments = ["V", "E", "P", "OP", "J"];
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const FormUser = ({ setOpenModalForm, params, onRefresh, onSuccess }) => {
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const [isLoadIdentification, setIsLoadIdentification] = useState(false);
  const [isLoadEmail, setIsLoadEmail] = useState(false);
  const [loadUser, setLoadUser] = useState(null);
  

  const [fileFoto, setFileFoto] = useState(null);
  const { addNotification } = useNotification();

  const initialValue = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    typeDocument: "V",
    identificationNumber: "",
    avatar: null,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, "Mínimo 2 caracteres")
      .max(50, "Máximo 50 caracteres")
      .required("Campo requerido"),
    lastName: Yup.string()
      .min(2, "Mínimo 2 caracteres")
      .max(50, "Máximo 50 caracteres")
      .required("Campo requerido"),
    email: Yup.string()
      .email("Correo electrónico inválido")
      .required("Campo requerido"),
    phone: Yup.string()
      .required()
      .test("phone", "Teléfono inválido", (value) =>
        isValidPhoneNumber(value, "VE")
      ),
    typeDocument: Yup.string().required("Campo requerido"),
    identificationNumber: Yup.string()
      .min(5, "Mínimo 5 caracteres")
      .max(20, "Máximo 20 caracteres")
      .required("Campo requerido"),
  });

  const onSubmit = async (values) => {
    try {
      if (loadUser) {
        return onSuccess(loadUser);
      }
      const res = await preCreateUsuariosService(values);
      onSuccess(res.data);
    } catch (error) {
      addNotification(error.response?.data?.message || "Error al guardar", {
        error: true,
      });
    }
  };

  

  

  return (
    <Box component="form" sx={{}}>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={true}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          validateField,
          isSubmitting,
          setValues,
          resetForm
        }) => {
          const getUserByIdentificacion = async (number) => {
            if (loadIdentificationTimeout) {
              clearTimeout(loadIdentificationTimeout);
            }
            loadIdentificationTimeout = setTimeout(async () => {
              setIsLoadIdentification(true);
              await byIdentifacionUsuariosService(number)
                .then(({ data, status }) => {
                  if (status === 404) {
                    if (loadUser) {
                      setLoadUser(null);
                      resetForm();
                      setFieldValue("identificationNumber", number);
                    }
                    return;
                  }
                  const userCode = data.userCodes.find(uc => uc.code.entity.id === entidadesStore.activo.id);
                  const values = {
                    firstName: data.profile.firstName,
                    lastName: data.profile.lastName,
                    email: data.email,
                    phone: data.profile.phone,
                    typeDocument: data.profile.typeDocument,
                    identificationNumber: data.profile.identificationNumber,
                    avatar: userCode.avatar || data.profile.avatar || null,
                  }
                  setValues(values);
                  setLoadUser(data);
                })
                .finally(() => {
                  setIsLoadIdentification(false);
                });
            }, 500);
          };
          return (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <LoadingComponent
                  isLoading={isSubmitting}
                  text={"Guardando..."}
                />
  
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{}}>
                    <ButtonFile
                      id="avatar"
                      onFile={(file) => setFieldValue("avatar", file)}
                      onDelete={() => setFieldValue("avatar", null)}
                      text="Subir Foto"
                      sxc={{ marginTop: "5px" }}
                      // name={'Mi foto'}
                      file={values.avatar}
                      size={130}
                      avatar={true}
                      disabled={isSubmitting || loadUser}
                      // src={fileFoto ? null : avatar}
                      // accept={acceptOnlyImages}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <FormControl style={{ width: "100px" }}>
                    <InputLabel id="typeDocument">Tipo de documento</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      id="typeDocument"
                      required
                      disabled={isSubmitting}
                      label="Tipo de documento"
                      value={values.typeDocument}
                      onChange={async (e) => {
                        setFieldValue("typeDocument", e.target.value);
                        await sleep(10);
                        validateField("typeDocument");
                      }}
                      error={!!errors.typeDocument}
                    >
                      {typeDocuments.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      size="small"
                      id="identificationNumber"
                      required
                      disabled={isSubmitting}
                      label="Número de identificación"
                      value={values.identificationNumber}
                      onChange={async (e) => {
                        let text = e.target.value;
                        var ExpRegSoloNumeros = '^[0-9]+$';
                        var ExpRegSoloNumerosPassport = '^[0-9a-zA-Z]+$';
                        if (values.typeDocument.toString() === "V") {
                          text = text.replace(/[^0-9]/g, "");
  
                          if (text.length === 9) return;
                        }
  
                        let v = false;
                        if (values.typeDocument.toString() === "P") {
                          if (text.length === 20) return;
                          v = text.match(ExpRegSoloNumerosPassport) != null;
                        } else {
                          v = text.match(ExpRegSoloNumeros) != null;
                        }
  
                        if (text === "") {
                            setFieldValue("identificationNumber", '');
                            await sleep(10);
                            validateField("identificationNumber");
                          return;
                        }
  
                        if (!v) {
                          text = text.slice(0, -1);
                        }
  
                          setFieldValue("identificationNumber", text);
                          await sleep(10);
                          validateField("identificationNumber");
                          getUserByIdentificacion(text);
                      }}
                      helperText={errors.identificationNumber}
                      error={!!errors.identificationNumber}
                      InputProps={{
                        endAdornment: isLoadIdentification 
                          ? <CircularProgress size={20} />
                          : loadUser
                            ? <CheckIcon sx={{ color: "green" }} />
                            : null
                        
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      size="small"
                      id="firstName"
                      required
                      disabled={loadUser || isSubmitting}
                      label="Nombres"
                      value={values.firstName}
                      onChange={(e) => {
                        setFieldValue("firstName", e.target.value);
                        validateField("firstName");
                      }}
                      helperText={errors.firstName}
                      error={!!errors.firstName}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      size="small"
                      id="lastName"
                      required
                      disabled={loadUser || isSubmitting}
                      label="Apellidos"
                      value={values.lastName}
                      onChange={async (e) => {
                        setFieldValue("lastName", e.target.value);
                        await sleep(10);
                        validateField("lastName");
                      }}
                      helperText={errors.lastName}
                      error={!!errors.lastName}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      size="small"
                      id="email"
                      required
                      disabled={loadUser || isSubmitting}
                      label="Correo electrónico"
                      value={values.email}
                      onChange={async (e) => {
                        setFieldValue("email", e.target.value);
                        await sleep(10);
                        validateField("email");
                      }}
                      helperText={errors.email}
                      error={!!errors.email}
                    />
                  </FormControl>
                </Grid>
  
                <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                  <FormControl fullWidth>
                    <PhoneNumberField
                      value={values.phone}
                      onChange={async (value) => {
                        setFieldValue("phone", value);
                        await sleep(10);
                        validateField("phone");
                      }}
                      disabled={loadUser || isSubmitting}
                      fieldName="phone"
                      required={true}
                      dataError={errors}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12} sx={{ mt: 3 }} align="center">
                  <BotonNuevoComponent
                    click={handleSubmit}
                    text={loadUser ? "Asignar" : "Guardar"}
                    disabled={isSubmitting || isLoadIdentification}
                    morado
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        }}
      </Formik>
    </Box>
  );
};

export default FormUser;
