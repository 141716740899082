import * as React from 'react';
import * as Redux from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { Modal } from '../../components';
import ListadoPuertas from './components/ListadoPuertas';
import FormPuertas from './components/FormPuertas';
import { getAllPuertasAction, setActivoPuertasAction } from './PuertasAction';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { checkModuleAccess, isManagerUserCode } from '../modulos/modules.helpers';
import { SU } from '../../constants/ConfigConst';

const PuertasView = () => {
  const dispatch = Redux.useDispatch();
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const puertasStore = Redux.useSelector((state) => state.puertas);
  const loginStore = Redux.useSelector((state) => state.login);

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager = !isRoot && isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);
  const accessName = 'accessDoor';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        {
          !isManager && (
            <BotonNuevoComponent
              click={async () => {
                await setActivoPuertasAction(dispatch, null);
                setOpenModalForm(true);
              }}
              azul
              mr={2}
            />
          )
        }
        {puertasStore.all.length > 0 ? (
          <BotonNuevoComponent
            click={async () => {
              await setActivoPuertasAction(dispatch, null);
              await getAllPuertasAction(
                dispatch,
                entidadesStore.activo.id,
                null
              );
            }}
            disabled={puertasStore.loadingGetAll}
            white
            text={puertasStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'}
          />
        ) : null}
      </Grid>
      <Grid item md={12}>
        <ListadoPuertas setOpenModalForm={(val) => setOpenModalForm(val)} />
      </Grid>

      <Modal
        id="modalFormPuertas"
        title={`${puertasStore.activo ? 'Editar' : 'Nuevo'} acceso`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {puertasStore.activo ? (
          <Typography variant="h6">
            {puertasStore.activo.nombres} {puertasStore.activo.apellidos}
          </Typography>
        ) : null}
        <FormPuertas
          activo={puertasStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default PuertasView;
