import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../../modulos/modules.helpers';
import {
  getAllDescuentosAction,
  setActivoDescuentosAction,
} from './DescuentosAction';
import FormDescuentos from './components/FormDescuentos';
import ListadoDescuentos from './components/ListadoDescuentos';

const DescuentosView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const descuentosStore = Redux.useSelector((state) => state.descuentos);

  const loginStore = Redux.useSelector((state) => state.login);
  const accessName = 'document';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [openModalForm, setOpenModalForm] = React.useState(false);

  React.useEffect(() => {
    getAllDescuentosAction(dispatch, entidadesStore.activo.id, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entidadesStore.activo.id]);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoDescuentosAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        <BotonNuevoComponent
          click={async () => {
            await setActivoDescuentosAction(dispatch, null);
            await getAllDescuentosAction(
              dispatch,
              entidadesStore.activo.id,
              null
            );
          }}
          disabled={
            descuentosStore.loadingGetAll || descuentosStore.all.length === 0
          }
          white
          text={
            descuentosStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'
          }
        />
      </Grid>
      <Grid item md={12}>
        <ListadoDescuentos setOpenModalForm={(val) => setOpenModalForm(val)} />
      </Grid>
      <Modal
        id="modalFormDescuentos"
        title={`${descuentosStore.activo ? 'Editar' : 'Nuevo'} descuento`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {descuentosStore.activo && (
          <Typography variant="h6">
            {descuentosStore.activo.nombres} {descuentosStore.activo.apellidos}
          </Typography>
        )}
        <FormDescuentos
          activo={descuentosStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default DescuentosView;
