import { Card, Grid, Paper, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../components';
import DevicesComponent from '../reservasEspeciales/components/Devices';
import FormReserva from './components/FormReservas';
import ReservationUsedBlocks from '../../components/ReservationUsedBlocks';

const PanelReservasView = () => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();

  // const entidadesStore = Redux.useSelector((state) => state.entidades);
  // const areasStore = Redux.useSelector((state) => state.areas);

  React.useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const [refresh, setRefresh] = React.useState(false);
  const [areaIds, setAreaIds] = React.useState([]);
  const [dates, setDates] = React.useState([]);

  const [openModalForm, setOpenModalForm] = React.useState(false);

  const tabPanel = (
    <Paper elevation={2}>
      <Grid container>
        <Grid item xs={12} md={12}></Grid>
      </Grid>
    </Paper>
  );

  return (
    <Grid container spacing={2} sx={{ marginTop: '1px' }}>
      <Grid item md={4}>
        <Card>
          <FormReserva
            setOpenModalForm={(val) => { }}
            // params={reservasStore.params}
            onRefresh={() => {
              setRefresh(true);
              setTimeout(() => {
                setRefresh(false);
              }, 100);
            }}
            onChangeAreas={(val) => setAreaIds(val)}
            onChangeDates={(val) => setDates(val)}
          />
        </Card>
      </Grid>
      <Grid item md={8}>
        <Paper elevation={2} sx={{ p: 2 }}>
          <Grid container>
            <ReservationUsedBlocks 
              areaIds={areaIds}
              dates={dates}
              refresh={refresh}
            />
          </Grid>
        </Paper>
      </Grid>
      <Modal
        id="modalFormAreas"
        title="Nueva reserva"
        open={openModalForm}
        onClose={() => setOpenModalForm(!openModalForm)}
      ></Modal>
    </Grid>
  );
};

export default PanelReservasView;
