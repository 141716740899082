import React from 'react';

export function FluentMdl2AllApps(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 2048 2048"
      {...props}
    >
      <path
        fill="black"
        d="M0 1408v-384h384v384zm128-256v128h128v-128zM0 896V512h384v384zm128-256v128h128V640zM0 384V0h384v384zm128-256v128h128V128zm512 640V640h1152v128zm896 384v128H640v-128zM640 128h1408v128H640zM0 1920v-384h384v384zm128-256v128h128v-128zm512 128v-128h1152v128z"
      ></path>
    </svg>
  );
}
