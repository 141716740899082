import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const referidosApi = `${baseApi}/referrals`;

export const getAllReferidosService = async (params) => {
  const res = await axios.get(referidosApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveReferidosService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${referidosApi}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${referidosApi}`, data, {
      headers: await token({}),
    });
  }
  return res.data;
};

export const getOneReferidosService = async (id) => {
  const res = await axios.get(`${referidosApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteReferidosService = async (id) => {
  const res = await axios.delete(`${referidosApi}/${id}`, {
    headers: await token({}),
  });
  return res.data;
};
