import React from 'react';
import ContainerTemplate from '../../components/template/Container';
import CodigosView from './CodigosView';

const CodigosModule = ({ route }) => {
  return (
    <ContainerTemplate active={route} routes={[]}>
      <CodigosView />
    </ContainerTemplate>
  );
};

export default CodigosModule;
