import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const tiposInvitacionApi = `${baseApi}/invitation-types`;

export const getAllTiposInvitacionService = async (params) => {
  const res = await axios.get(tiposInvitacionApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveTiposInvitacionService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${tiposInvitacionApi}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${tiposInvitacionApi}`, data, {
      headers: await token({}),
    });
  }
  return res.data;
};

export const getOneTiposInvitacionService = async (id) => {
  const res = await axios.get(`${tiposInvitacionApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteTiposInvitacionService = async (id) => {
  const res = await axios.delete(`${tiposInvitacionApi}/${id}`, {
    headers: await token({}),
  });
  return res.data;
};
