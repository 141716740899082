import * as React from 'react';
import * as Redux from 'react-redux';
import { Grid } from '@mui/material';
import { Modal } from '../../../components';
import ListadoContactos from './components/ListadoContactos';
import FormContactos from './components/FormContactos';
import {
  getAllContactosAction,
  setActivoContactosAction,
} from './ContactosAction';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';
import { checkModuleAccess } from '../../modulos/modules.helpers';

const getTypes = async (entityId) => {
  const { data } = await axios.get(`${baseApi}/contact-types`, {
    headers: await token({}),
    params: { active: true, entityId: entityId },
  });
  return data.data;
};

const ContactosView = () => {
  const dispatch = Redux.useDispatch();

  const contactosStore = Redux.useSelector((state) => state.contactos);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const loginStore = Redux.useSelector((state) => state.login);

  const [openModalForm, setOpenModalForm] = React.useState(false);
  const [types, setTypes] = React.useState([]);
  const accessName = 'contact';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  React.useEffect(() => {
    getTypes(entidadesStore.activo.id).then((types) => {
      setTypes(types);
    });
  }, [entidadesStore.activo]);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoContactosAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        {contactosStore.all.length > 0 ? (
          <BotonNuevoComponent
            click={async () => {
              await setActivoContactosAction(dispatch, null);
              await getAllContactosAction(dispatch, contactosStore.params);
            }}
            disabled={contactosStore.loadingGetAll}
            white
            text={
              contactosStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'
            }
          />
        ) : null}
      </Grid>
      <Grid item md={12}>
        <ListadoContactos
          setOpenModalForm={(val) => setOpenModalForm(val)}
          types={types}
        />
      </Grid>
      <Modal
        id="modalFormContactos"
        title={
          contactosStore.activo
            ? `Editar ${
                contactosStore.activo ? contactosStore.activo.name : ''
              }`
            : 'Nuevo contactos'
        }
        open={openModalForm}
        maxWidth="lg"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        <FormContactos
          activo={contactosStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
          types={types}
        />
      </Modal>
    </Grid>
  );
};

export default ContactosView;
