import BusinessIcon from '@mui/icons-material/Business';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Toolbar } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MORADO_COLOR } from '../../../constants/ColorsConst';
import { SU } from '../../../constants/ConfigConst';
import * as ROUTES from '../../../router/Routes';
import { setActivoEntidadesAction } from '../../../store/Actions';
import { PARAMS_MODULOS } from '../../../store/Types';
import { drawerWidth } from '../drawer/Drawer';
import ButtonProfile from './ButtonProfile';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBarTemplate = ({ open, toggleDrawer }) => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();

  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const modulosStore = Redux.useSelector((state) => state.modulos);

  const activo = entidadesStore.activo;
  const isMoreEntities =
    loginStore.entities.length > 1 || loginStore.rol === SU;
  // debugger

  return (
    <AppBar position="fixed" open={open} sx={{ backgroundColor: MORADO_COLOR }}>
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        {isMoreEntities && activo ? (
          <IconButton
            aria-label="delete"
            size="medium"
            sx={{
              mx: 1,
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: '#eeeeee',
              },
            }}
            onClick={() => {
              dispatch({
                type: PARAMS_MODULOS,
                payload: {
                  ...modulosStore.params,
                  entityId: null,
                },
              });
              setActivoEntidadesAction(dispatch, null);
              history.push(ROUTES.entidades.to);
            }}
          >
            <BusinessIcon fontSize="inherit" sx={{ color: MORADO_COLOR }} />
          </IconButton>
        ) : null}
        <strong>{isMoreEntities && activo ? activo.name : null}</strong>
        {/* <SearchAppbar /> */}
        <Box sx={{ flexGrow: 1 }} />
        {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}> */}
        {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit" sx={{ mr: 0 }}>
                        <Badge badgeContent={4} color="error">
                            <MailIcon />
                        </Badge>
                    </IconButton> */}
        {/* <IconButton
                        size="large"
                        aria-label="show 17 new notifications"
                        color="inherit"
                        sx={{ mr: 1 }}
                    >
                        <Badge badgeContent={17} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton> */}
        {/* </Box> */}
        <ButtonProfile />
      </Toolbar>
    </AppBar>
  );
};

export default AppBarTemplate;
