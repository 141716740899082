import { Box } from '@mui/material';
import React from 'react';
import { MarvelDevices } from 'react-css-devices';
import styled from 'styled-components';

const HeaderPhone = styled.div`
  display: flex;
  background-color: white;
  width: 100%;
  height: 100%;
  color: black;
`;

const BodyPhone = styled.div`
  display: flex;
  background-color: white;
  width: 100%;
  height: 100%;
  color: black;
`;

const DevicesComponent = ({ children, img }) => {
  return (
    <MarvelDevices
      deviceName={'nexus5'}
      color={'white'}
      orientation={'portrait'}
      transform={0.8}
    >
      <HeaderPhone>{img}</HeaderPhone>
      <Box
        sx={{
          width: '320px',
          height: '568px',
          backgroundColor: 'white',
        }}
      >
        {children}
      </Box>
    </MarvelDevices>
  );
};

export default DevicesComponent;
