import { tryCatchHelper } from '../../helpers';
import {
  GET_ALL_DELIVERY,
  LOADING_GETALL_DELIVERY,
  SET_ACTIVO_DELIVERY,
} from '../../store/Types';
import {
  deleteDeliveryService,
  getAllDeliveryService,
  getOneDeliveryService,
} from './DeliveryService';

export const getAllDeliveryAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_DELIVERY,
      payload: true,
    });

    const res = await getAllDeliveryService(params);
    dispatch({
      type: GET_ALL_DELIVERY,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_DELIVERY,
      payload: false,
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_DELIVERY,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const setActivoDeliveryAction = async (dispatch, data) => {
  dispatch({
    type: SET_ACTIVO_DELIVERY,
    payload: data,
  });
};

export const getOneDeliveryAction = async (dispatch, id) => {
  try {
    const res = await getOneDeliveryService(id);
    await setActivoDeliveryAction(dispatch, res.data);
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteDeliveryAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    await deleteDeliveryService(id);
    const res = await getAllDeliveryAction(dispatch, params);
    addNotification('Delivery eliminado.');
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
