import * as React from 'react';
import * as Redux from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { Modal } from '../../components';
import * as ROUTES from '../../router/Routes';
import { useHistory } from 'react-router-dom';
// import { Paper } from '@mui/material';
import FormEntidades from './components/FormEntidades';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
// import { Tab } from '@mui/material';
import InfoEntidadesComponent from './components/InfoEntidades';

const InfoEntidadesView = () => {
  let history = useHistory();

  const entidadesStore = Redux.useSelector((state) => state.entidades);

  React.useEffect(() => {
    if (entidadesStore.activo === null || entidadesStore.activo === undefined) {
      history.push(ROUTES.entidadesInfo.to);
      return;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [openModalForm, setOpenModalForm] = React.useState(false);

  // const [tab, setTab] = React.useState(1);

  // const tab1 = (
  //   <TabPanel value={1} sx={{ p: 0 }}>
  //     {/* <SubEntidadesModule isBlock={true} /> */}
  //   </TabPanel>
  // );

  // const tabPanel = (
  //   <Paper elevation={2}>
  //     <Grid container>
  //       <Grid item xs={12} md={12}>
  //         <TabContext value={tab}>
  //           <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
  //             <TabList onChange={(e, nv) => setTab(nv)} aria-label="tabs-users">
  //               <Tab label="Modulos" value={1} />
  //             </TabList>
  //           </Box>
  //           {tab1}
  //         </TabContext>
  //       </Grid>
  //     </Grid>
  //   </Paper>
  // );

  return (
    <Grid container spacing={2} sx={{ marginTop: '1px' }}>
      <Grid item md={3}>
        <InfoEntidadesComponent
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Grid>
      <Grid item md={9}>
        {/* {tabPanel} */}
      </Grid>
      <Modal
        id="modalFormEntidades"
        title={`${entidadesStore.activo ? 'Editar' : 'Nueva'} área`}
        open={openModalForm}
        maxWidth="lg"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {entidadesStore.activo ? (
          <Typography variant="h6">
            {entidadesStore.activo.nombres} {entidadesStore.activo.apellidos}
          </Typography>
        ) : (
          <></>
        )}
        <FormEntidades
          activo={entidadesStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default InfoEntidadesView;
