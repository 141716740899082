import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import * as Redux from 'react-redux';
import { ButtonFile } from '../../../components';
import LoadingComponent from '../../../components/Loading';
import * as CONST from '../../../constants/FieldsConst';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import { useNotification } from '../../../helpers/notification';
import { saveBancosAction } from '../../../store/Actions';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';

const acceptOnlyImages = 'image/png, image/jpeg, image/jpg';
const FormBancos = () => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const [fileImage, setFileImage] = React.useState(null);

  const bancosStore = Redux.useSelector((state) => state.bancos);
  const activo = bancosStore.activo;

  const dataDefault = {
    [CONST.NAME]: '',
    [CONST.CODE]: '',
    [CONST.WEBSITE]: '',
    [CONST.LOGO_URL]: '',
    [CONST.ACTIVE]: true,
  };
  const [banco, setRecibo] = React.useState(dataDefault);
  const [dataError, setDataRequired] = React.useState({
    [CONST.NAME]: false,
    [CONST.CODE]: false,
    [CONST.WEBSITE]: false,
    [CONST.LOGO_URL]: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);

  React.useEffect(() => {
    setFileImage(null);
    if (!activo) {
      setRecibo(dataDefault);
      return;
    }
    let data = {};
    Object.keys(banco).map((key) => (data[key] = bancosStore.activo[key]));
    setTimeout(() => setRecibo(data), 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activo]);

  const _handleSetDataField = (key, value) =>
    setRecibo({ ...banco, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).forEach((key) => {
      const val = banco[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const _handleSubmit = async () => {
    const valid = _validDataForm();
    if (valid) {
      setLoadingSave(true);
      const data = { ...banco };
      if (fileImage) data[CONST.LOGO] = fileImage;
      await saveBancosAction({ dispatch, addNotification }, activo?.id, data);
      setLoadingSave(false);
    }
  };

  return (
    <Box component="form" sx={{ m: 2 }}>
      {/*       
      <pre>
            {JSON.stringify(banco, null, 2)}
          </pre> */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={activo ? 'Actualizando...' : 'Guardando...'}
          />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {activo ? 'Editar' : 'Nuevo'} banco
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} align="center">
          <ButtonFile
            id={CONST.LOGO}
            onFile={(file) => setFileImage(file)}
            file={fileImage}
            text="Subir imagén de banco"
            size="40%"
            onDelete={() => setFileImage(null)}
            accept={acceptOnlyImages}
            src={
              fileImage
                ? null
                : bancosStore.activo
                ? bancosStore.activo.logoUrl
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={12} align="center">
          {/* <img
            src={fileImage ? URL.createObjectURL(fileImage) : banco.logoUrl}
            variant="square"
            alt="logourl-banco"
            style={{
              width: 100,
              height: 100,
              objectFit: 'contain',
            }}
          /> */}
          <TextField
            fullWidth
            size="small"
            id={CONST.NAME}
            required
            label="Nombre"
            value={banco.name}
            onChange={(e) => _handleSetDataField(CONST.NAME, e.target.value)}
            helperText={dataError.name ? validFielddHelper(1) : null}
            error={dataError.name}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            size="small"
            id={CONST.CODE}
            required
            label="Código"
            value={banco.code}
            onChange={(e) => _handleSetDataField(CONST.CODE, e.target.value)}
            helperText={dataError.code ? validFielddHelper(1) : null}
            error={dataError.code}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            size="small"
            id={CONST.WEBSITE}
            required
            multiline
            rows={3}
            label="Página web"
            value={banco.website}
            onChange={(e) => _handleSetDataField(CONST.WEBSITE, e.target.value)}
            helperText={dataError.website ? validFielddHelper(1) : null}
            error={dataError.website}
          />
        </Grid>
        {/* <Grid item xs={12} md={12} align="center">
          <TextField
            fullWidth
            size="small"
            id={CONST.LOGO_URL}
            required
            multiline
            rows={3}
            label="Logo url"
            value={banco.logoUrl}
            onChange={(e) =>
              _handleSetDataField(CONST.LOGO_URL, e.target.value)
            }
            helperText={dataError.logoUrl ? validFielddHelper(1) : null}
            error={dataError.logoUrl}
          />
        </Grid> */}
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <InputLabel id={`label-${CONST.ACTIVE}`}>Estatus</InputLabel>
            <Select
              labelId={`label-${CONST.ACTIVE}`}
              size="small"
              value={banco.active}
              label="Estatus"
              onChange={(e) =>
                _handleSetDataField(CONST.ACTIVE, e.target.value)
              }
              id={CONST.ACTIVE}
            >
              <MenuItem value={true}>Activo</MenuItem>
              <MenuItem value={false}>Bloqueado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={12} sx={{ m: 2 }} align="center">
          <BotonNuevoComponent
            click={_handleSubmit}
            text="GUARDAR"
            disabled={loadingSave}
            morado
            mr={2}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormBancos;
