import axios from "axios";
import { baseApi, isTokenValid, token } from "../../services/Apis";

export const userCodes = `${baseApi}/user-codes`;

export const updateUserCodesService = async (id, data) => {
  
  if (data.avatar) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.avatar;
  }
  const res = await axios.put(`${userCodes}/${id}`, data, {
    headers: await token({ isFormData: !!data.avatar }),
  });
  return res;
};

export const getAllUsuariosCodigosService = async (params = {}) => {
  try {
    const res = await axios.get(userCodes, {
      headers: await token({}),
      params,
    });
    return res;
  } catch (e) {
    isTokenValid(e);
    return e.response;
  }
};