export const NAME = 'name';
export const MAX = 'max';
export const ABBREV = 'abbrev';
export const ADDRESS = 'address';
export const PLACE = 'place';
export const LAT = 'lat';
export const LNG = 'lng';
export const PHONE = 'phone';
export const EMAIL = 'email';
export const RIF = 'rif';
export const MOROSITY_TIME = 'morosityTime';
export const ENTITY_TYPE_ID = 'entityTypeId';
export const COUNTRY_ID = 'countryId';
export const OPENING_TIME = 'openingTime';
export const CLOSING_TIME = 'closingTime';
export const LOGO = 'logo';
export const LOGO_URL = 'logoUrl';
export const DARK_LOGO = 'darkLogo';
export const BANNER = 'banner';
export const SIDEBAR = 'sidebar';
export const IOT_BG = 'iotBg';
export const IOT_LOGO = 'iotLogo';
export const ACCESS_BG = 'accessBg';
export const PAYMENT_BG = 'paymentBg';
export const HISTORY_BG = 'historyBg';
export const RESERVATION_BG = 'reservationBg';
export const INVITATION_BG = 'invitationBg';
export const ABOUT_BG = 'aboutBg';
export const IMAGE = 'image';
export const MAP_IMAGE = 'mapImage';
export const STATUS_ID = 'status_id';
export const IMAGE_TYPE = 'image_type';
export const PROFILE_TYPE = 'profile_type';
export const DISPONIBLE = 'disponible';
export const IDENTIFIER = 'identifier';
export const URL_ADDRESS = 'urlAddress';
export const USER_ID = 'user_id';
export const USER = 'user';
export const PASSWORD = 'password';
export const PASSWORD_CONFIRMATION = 'password_confirmation';
export const PROFILE = 'profile';
export const IDENTIFICATION_NUMBER = 'identificationNumber';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const FIRST_LAST_NAME = 'lastName';
export const MIDDLE_NAME = 'middle_name';
export const SECOND_LAST_NAME = 'second_last_name';
export const BIRTHDAY = 'birthday';
export const GENDER = 'gender';
export const TYPE_DOCUMENT = 'typeDocument';
export const TYPE = 'type';
export const ENTITY_ID = 'entityId';
export const TYPE_ID = 'typeId';
export const BANK_ID = 'bankId';
export const USER_TYPES = 'userTypes';
export const AVATAR_UPDATABLE = 'avatarUpdatable';
export const OWNER_UPDATABLE_USER_INFO = 'ownerUpdatableUserInfo';
export const ALLOW_CREATE_EVENTS = 'allowCreateEvents';
export const ALLOW_ENTRY_CONFIRMATION = 'allowEntryConfirmation';
export const ENTRY_CONFIRMATION_TIME_LIMIT = 'entryConfirmationTimeLimit';
export const WAIT_BLOCK_TIME_LIMIT = 'waitBlockTimeLimit';
export const WAITLIST_SEND_INTERVAL = 'waitlistSendInterval';
export const IS_PUBLIC = 'isPublic';
export const AVATAR = 'avatar';
export const ID = 'id';
export const ROL = 'rol';
export const CODE = 'code';
export const CODES = 'codes';
export const USER_CODES = 'userCodes';
export const ACTIONS = 'actions';
export const DATE_FROM = 'dateFrom';
export const DATE_TO = 'dateTo';
export const DAY = 'day';
export const DAYS_MAX_RESERVAS = 'daysMaxReserves';
export const MAX_RESERVAS = 'maxReserves';
export const LIMIT_RESERVATIONS = 'limitReservations';
export const ACTIVE_RESERVES = 'activeReserves';
export const DAYS_PREV_FOR_RESERVE = 'daysPrevForReserve';
export const BLOCK_RESERVATION = 'blockReservation';
export const CONTINUOS_BLOCK = 'continuousBlock';
export const STATUS = 'status';
export const STATUS_CODE = 'statusCode';
export const URL = 'url';
export const CATEGORY_ID = 'categoryId';
export const SCHEDULES = 'schedules';
export const AREA_ID = 'areaId';
export const SUB_AREA_ID = 'subareaId';
export const USER_CODE_ID = 'userCodeId';
export const ENTRY_TIME = 'entryTime';
export const DEPARTURE_TIME = 'departureTime';
export const DESCRIPTION = 'description';
export const ENTRY_TIME_ERROR = 'entryTimeError';
export const DEPARTURE_TIME_ERROR = 'departureTimeError';
export const TIME_ERROR_IGUAl = 'timeErrorIgual';
export const CATEGORY = 'category';
export const AREA_CATEGORY = 'areaCategory';
export const AREA_CATEGORY_ID = 'areaCategoryId';
export const MAX_PERSONS = 'maxPersons';
export const MIN_PERSONS = 'minPersons';
export const MAX_PERSONS_SHARED = 'maxPersonsShared';
export const INTERVAL = 'interval';
export const MINUTES_USE = 'minutesUse';
export const PRICE = 'price';
export const DAYS_FOR_RESERVE = 'daysForReserve';
export const HOURS_FOR_RESERVE = 'hoursForReserve';
export const MIN_PREV_FOR_RESERVE = 'minPrevForReserve';
export const IMAGE_URL = 'imageUrl';
export const ACTIVE = 'active';
export const IS_MAIN = 'isMain';
export const HIDDEN = 'hidden';
export const OWNER = 'owner';
export const FAMILY = 'family';
export const SERVICE = 'service';
export const OTHER = 'other';
export const DRIVER = 'driver';
export const NANNY = 'nanny';
export const CLIENT = 'client';
export const MANAGER = 'manager';
export const WEBSITE = 'website';
export const ARREARS = 'arrears';
export const CODE_ID = 'codeId';
export const CODE_TYPE = 'codeType';
export const PLACE_ID = 'place_id';
export const AMOUNT = 'amount';
export const COLOR = 'color';
export const REFERENCE = 'reference';
export const VOUCHER = 'voucher';
export const ACCOUNT = 'account';
export const CODE_IDS = 'code_ids';
export const MAX_INVITATIONS = 'maxInvitations';
export const DAYS_MAX_INVITATIONS = 'daysMaxInvitations';
export const MAX_INVITATIONS_PER_GUEST = 'maxInvitationsPerGuest';
export const DAYS_MAX_INVITATIONS_PER_GUEST = 'daysMaxInvitationsPerGuest';
export const FILE = 'file';
export const DATE = 'date';
export const ENTiTY_DOCUMENT_TYPE = 'entity_document_type';
export const DOCUMENT = 'document';
export const RELAY_NUM = 'relayNum';
export const IP = 'ip';
export const IOT = 'iot';
export const ACCESS_DOORS = 'accessDoors';
export const TUNNEL = 'tunnel';
export const OCCUPATION = 'occupation';
export const NOMBRE = 'nombre';
export const NOMBRES = 'nombres';
export const ESTATUS = 'estatus';
export const IMG_FOTO = 'imgFoto';
export const MAP_IMG_FOTO = 'mapImgFoto';
export const DNI = 'dni';
export const APELLIDOS = 'apellidos';
export const TIPO_SANGRE = 'tipoSangre';
export const FECHA_NACIMIENTO = 'fechaNacimiento';
export const GENERO = 'genero';
export const TIENE_INTERNET = 'tieneInternet';
export const ESTADO = 'estado';
export const CIUDAD = 'ciudad';
export const DIRECCION = 'direccion';
export const OPERADORA = 'operadora';
export const NUMERO = 'numero';
export const CORREO = 'correo';
export const PERFIL = 'perfil';
export const USERNAME = 'username';
export const ENTIDAD = 'entidad';
export const ENTIDAD_PERFIL = 'entidadPerfil';
export const INFORMACION = 'informacion';
export const TELEFONOS = 'telefonos';
export const CONTACT_TYPE = 'contactType';
export const ACCESS_TYPE_ALLOWED = 'accessTypeAllowed';
export const TIPO_ENTIDAD = 'tipoEntidad';
export const IMG_LOGO = 'imgLogo';
export const IMG_FONDO = 'imgFondo';
export const IS_ABIERTO = 'isAbierto';
export const LATITUD = 'latitud';
export const LONGITUD = 'longitud';
export const PAIS = 'pais';
export const FECHA = 'fecha';
export const DESC = 'desc';
export const TITULAR = 'titular';
export const VISTAS = 'vistas';
export const APP_NAME = 'appName';
export const APP_VERSION = 'appVersion';
export const APP_TESTING_VERSION = 'appTestingVersion';
export const APP_FORCE_UPDATE = 'appForceUpdate';
export const APP_MAINTENANCE = 'appMaintenance';
export const GOOGLE_SIGN_IN = 'googleSignIn';
export const TEACHER_USER_CODE_ID = 'teacherUserCodeId';
export const CLASS_TYPE_ID = 'classTypeId';
export const CLASS_CATEGORY_ID = 'classCategoryId';
export const AGES = 'ages';
export const NUMBER_SPOTS = 'numberSpots';
export const LEVEL = 'level';
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const START_TIME = 'startTime';
export const END_TIME = 'endTime';
export const SHAPE = 'shape';
export const ACCOUNT_NUMBER = 'accountNumber';
export const BANK_DOCUMENT = 'bankDocument';
export const ACCOUNT_TYPE = 'accountType';
export const BANK = 'bank';
export const METHOD = 'method';
