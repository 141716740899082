import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import CopyrightTemplate from '../../../components/template/Copyright';
import * as ROUTES from '../../../router/Routes';
import { recoverPassword } from '../../../services';

const RecoverModule = () => {
  let history = useHistory();

  const [email, setEmail] = React.useState();
  const [emailError, setEmailError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  return (
    <center>
      <Box
        sx={{
          width: {
            xs: '90%',
            md: '30%',
          },
        }}
      >
        <Avatar
          alt="On Pass"
          src="./img/logoon.png"
          sx={{
            width: {
              xs: 100,
              md: 150,
            },
            height: {
              xs: 100,
              md: 150,
            },
            padding: '20px',
          }}
        />
        <Typography component="h1" variant="h3" sx={{ fontWeight: '700' }}>
          ON PASS
        </Typography>
        <Typography sx={{ mt: 2, mb: 2 }} component="h1" variant="h5">
          Recuperar Contraseña
        </Typography>
        <Paper elevation={5} sx={{ p: 3 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} align="center">
              <TextField
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                value={email}
                autoComplete="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                autoFocus
                error={emailError}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={12} align="center">
              <LoadingButton
                fullWidth
                variant="contained"
                loading={loading}
                sx={{ mt: 3 }}
                onClick={async () => {
                  setEmailError(false);
                  if (email === undefined || email === null) {
                    return setEmailError(true);
                  }
                  setLoading(true);
                  const res = await recoverPassword(email);
                  if (res.status >= 200 && res.status <= 203) {
                    setLoading(false);
                    history.push(ROUTES.login.to);
                  }
                }}
              >
                ENVIAR
              </LoadingButton>
            </Grid>
          </Grid>
        </Paper>
        <Button
          sx={{
            mt: 3,
            mb: 3,
          }}
          onClick={() => {
            history.push('/');
          }}
        >
          Volver al Inicio de Sesión
        </Button>
        <CopyrightTemplate sx={{ mt: 5 }} />
      </Box>
    </center>
  );
};

export default RecoverModule;
