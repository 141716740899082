import { tryCatchHelper } from '../../../helpers';
import {
  deleteDescuentosService,
  getAllDescuentosService,
  getOneDescuentosService,
  saveDescuentosService,
} from '../../../services';
import {
  ACTIVO_DESCUENTOS,
  GETALL_DESCUENTOS,
  LOADING_GETALL_DESCUENTOS,
} from '../../../store/Types';

export const getAllDescuentosAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_DESCUENTOS,
      payload: true,
    });
    const res = await getAllDescuentosService({ entityId });
    dispatch({
      type: GETALL_DESCUENTOS,
      payload: res.data,
    });
    dispatch({
      type: LOADING_GETALL_DESCUENTOS,
      payload: false,
    });
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const saveDescuentosAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveDescuentosService(id, data);
    console.log(res);
    addNotification(
      res.message ?? id
        ? 'El documento actualizado.'
        : 'El documento registrado.'
    );
    await getAllDescuentosAction(dispatch, entityId);
    return true;
  } catch (e) {
    console.log(e);
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoDescuentosAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_DESCUENTOS,
    payload: data,
  });
};

export const getOneDescuentosAction = async (dispatch, id) => {
  try {
    const res = await getOneDescuentosService(id);
    await setActivoDescuentosAction(dispatch, res.data);
    return res.data;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteDescuentosAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteDescuentosService(id, entityId);
    addNotification(res.data?.message ?? 'Documento eliminado.');
    await setActivoDescuentosAction(dispatch, null);
    await getAllDescuentosAction(dispatch, entityId, null);
    return res.data;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
