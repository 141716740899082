import * as React from 'react';
import * as Redux from 'react-redux';
import MUIDataTable from 'mui-datatables';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Tooltip,
} from '@mui/material';
import {
  deleteIotTunelesAction,
  getAllIotTunelesAction,
  saveIotTunelesAction,
  setActivoIotTunelesAction,
  setUpIotTunelesAction,
} from '../IotTunelesAction';
import ActionsList from '../../../components/ActionsList';
import * as CONST from '../../../constants/FieldsConst';
import { Modal } from '../../../components';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { CYAN_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';
import LoadingComponent from '../../../components/Loading';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import { useNotification } from '../../../helpers/notification';

const ListadoIotTuneles = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const iotTunelesStore = Redux.useSelector((state) => state.iotTuneles);

  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isSetUp, setIsSetUp] = React.useState(false);
  const [isSetImages, setIsSetImages] = React.useState(false);
  const [isToggleActive, setIsToggleActive] = React.useState(false);

  React.useEffect(() => {
    if (iotTunelesStore.all && iotTunelesStore.all.length === 0) {
      getAllIotTunelesAction(dispatch, entidadesStore.activo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRows([]);
    iotTunelesStore.all.forEach((el) => {
      setRows((old) => [
        ...old,
        {
          ...el,
          active: el.active ? 'Activo' : 'Bloqueado',
          actions: el,
        },
      ]);
    });
  }, [iotTunelesStore.all]);

  const _handleEditClick = async (id) => {
    const data = iotTunelesStore.all.find((el) => el.id === id);
    await setActivoIotTunelesAction(dispatch, data);
    setOpenModalForm(true);
  };

  const _handleDeleteClick = async (id) => {
    setLoadingDelete(true);
    await deleteIotTunelesAction(
      { dispatch, addNotification },
      id,
      entidadesStore.activo.id
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const _handleToggleActiveClick = async (id) => {
    setLoadingDelete(true);
    await saveIotTunelesAction(
      { dispatch, addNotification },
      entidadesStore.activo.id,
      id,
      {
        active: !iotTunelesStore.activo.active,
      }
    );
    setIsToggleActive(false);
    setLoadingDelete(false);
  };

  const _handleSetUpClick = async (id) => {
    setLoadingDelete(true);
    await setUpIotTunelesAction({ dispatch, addNotification }, id, {
      setImages: isSetImages,
    });
    setIsSetUp(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.NAME,
      label: 'Nombre',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NameComponent
              onClick={() => _handleEditClick(tableMeta.rowData[0])}
            >
              {value}
            </NameComponent>
          );
        },
      },
    },
    {
      name: CONST.IDENTIFIER,
      label: 'Identificador',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              sx={{
                width: '10em',
              }}
            >
              {value}
            </Box>
          );
        },
      },
    },
    {
      name: CONST.ACTIVE,
      label: 'Estatus',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              describeChild
              title="Estatus del registro"
              placement="left"
            >
              <Chip
                label={value}
                size="small"
                sx={{
                  backgroundColor:
                    value === 'Activo' ? CYAN_COLOR : MORADO_COLOR,
                  color: 'white',
                }}
              />
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'connection',
      label: 'Conexión',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = iotTunelesStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          return (
            data && (
              <Tooltip describeChild title="Conexión del iot" placement="left">
                <Chip
                  label={data.online ? 'Online' : 'Offline'}
                  size="small"
                  sx={{
                    backgroundColor: data.online ? CYAN_COLOR : MORADO_COLOR,
                    color: 'white',
                  }}
                />
              </Tooltip>
            )
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box align="left" sx={{ width: '10%' }}>
              <ActionsList
                id={tableMeta.rowData[0]}
                onEdit={() => _handleEditClick(tableMeta.rowData[0])}
                onDelete={async () => {
                  await setActivoIotTunelesAction(dispatch, value);
                  setIsDelete(true);
                }}
                onToggleActive={async () => {
                  await setActivoIotTunelesAction(dispatch, value);
                  setIsToggleActive(true);
                }}
                active={value.active}
              />
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },

    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={iotTunelesStore.loadingGetAll || loadingDelete}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {iotTunelesStore.activo ? (
        <Modal
          id="modalFormIotTuneles"
          title={`Eliminar`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este documento?
              <p>
                <strong>{iotTunelesStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(iotTunelesStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {iotTunelesStore.activo ? (
        <Modal
          id="modalToggleActivo"
          title={`${iotTunelesStore.activo.active ? 'Bloquear' : 'Activar'} ${
            iotTunelesStore.activo.name
          }`}
          open={isToggleActive}
          maxWidth="sm"
          onClose={() => setIsToggleActive(!isToggleActive)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`¿Seguro que quieres ${
                iotTunelesStore.activo.active ? 'Bloquear' : 'Activar'
              } esta iot?`}
              <p>
                <strong>{iotTunelesStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleToggleActiveClick(iotTunelesStore.activo.id);
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsToggleActive(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}

      {iotTunelesStore.activo ? (
        <Modal
          id="modalToggleActivo"
          title={`Configurar iot "${iotTunelesStore.activo.name}"`}
          open={isSetUp}
          maxWidth="sm"
          onClose={() => setIsSetUp(!isSetUp)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres configurar este iot?
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSetImages}
                    onChange={(e) => setIsSetImages(e.target.checked)}
                  />
                }
                label="Agregar imagenes"
              />
            </DialogContentText>
            {isSetImages && (
              <DialogContentText>
                El dispositivo se reiniciara por 2 minutos aproximadamente
              </DialogContentText>
            )}
            <DialogContentText>
              <p>
                <strong>{iotTunelesStore.activo.name}</strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleSetUpClick(iotTunelesStore.activo.id);
              }}
            >
              ACEPTAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsSetUp(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoIotTuneles;
