import { tryCatchHelper } from '../../helpers';
import {
  GETALL_MODULOS,
  LOADING_GETALL_MODULOS,
  SET_ACTIVO_MODULOS,
} from '../../store/Types';
import {
  entityUpdateModulosService,
  getAllModulosService,
  getOneModulosService,
  saveModulosService,
} from './ModulosService';

export const getAllModulosAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_MODULOS,
      payload: true,
    });
    const res = await getAllModulosService(params);
    if (res.status === 200) {
      dispatch({
        type: GETALL_MODULOS,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_MODULOS,
        payload: false,
      });
      return res.data;
    }
  } catch (e) {
    tryCatchHelper(e);
  }
};

export const saveModulosAction = async (
  { dispatch, addNotification },
  isGlobal,
  id,
  data,
  params
) => {
  try {
    let res;
    if (isGlobal) {
      res = await saveModulosService(id, data);
    } else {
      res = await entityUpdateModulosService(id, data);
    }
    await getAllModulosAction(dispatch, params);
    addNotification(res.message ?? 'El módulo actualizado.');
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoModulosAction = async (dispatch, data) => {
  dispatch({
    type: SET_ACTIVO_MODULOS,
    payload: data,
  });
};

export const getOneModulosAction = async (dispatch, id) => {
  try {
    const res = await getOneModulosService(id);
    if (res.status === 200) {
      await setActivoModulosAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};
