import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';

export const restaurantReservations = `${baseApi}/restaurant-reservations`;

export const getAllReservasRestauranteService = async (params) => {
  console.log('kssssssssssssssss');
  const res = await axios.get(restaurantReservations, {
    headers: await token({}),
    params,
  }).catch((e) => {
    console.log(e);
  });
  return res;
};

export const saveReservasRestauranteService = async (data) => {
  let res = await axios.post(`${restaurantReservations}`, data, {
    headers: await token({}),
  });
  return res;
};

export const getOneReservasRestauranteService = async (id) => {
  const res = await axios.get(`${restaurantReservations}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteReservasRestauranteService = async (id, params) => {
  const res = await axios.delete(`${restaurantReservations}/${id}`, {
    params,
    headers: await token({}),
  });
  return res;
};

export const updateIsAttendedRestauranteService = async (id, isAttended) => {
  const res = await axios.put(
    `${restaurantReservations}/${id}/attend`,
    { isAttended },
    {
      headers: await token({}),
    }
  );
  return res;
};
