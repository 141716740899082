import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const options = {
  chart: {
    type: 'bar',
  },
  title: {
    text: 'Gráfico de Barras Apiladas',
  },
  series: [
    {
      name: 'Serie 1',
      data: [1, 2, 3, 4, 5],
    },
    {
      name: 'Serie 2',
      data: [5, 4, 3, 2, 1],
    },
  ],
};

const StackedBarChartPanelControl = () => (
  <HighchartsReact highcharts={Highcharts} options={options} />
);

export default StackedBarChartPanelControl;
