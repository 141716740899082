import { GET_DASHBOARD, LOADING_DASHBOARD } from "../../store/Types";
import { getDashboardService } from "../../services";

export const getDashboardAction = async (dispatch, params) => {
  const res = await getDashboardService(params);
  dispatch({
    type: LOADING_DASHBOARD,
    payload: true,
  });
  if (res && res.status === 200) {
    dispatch({
      type: GET_DASHBOARD,
      payload: res,
    });
    dispatch({
      type: LOADING_DASHBOARD,
      payload: false,
    });
    return true;
  }
};
