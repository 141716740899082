import { Grid } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../modulos/modules.helpers';
import {
  getAllReferidosAction,
  setActivoReferidosAction,
} from './ReferidosAction';
import ListadoReferidos from './components/ListadoReferidos';

const ReferidosView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const referidosStore = Redux.useSelector((state) => state.referidos);

  const loginStore = Redux.useSelector((state) => state.login);
  const accessName = 'methodsPay';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [openModalForm, setOpenModalForm] = React.useState(false);

  React.useEffect(() => {
    getAllReferidosAction(dispatch, entidadesStore.activo.id, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entidadesStore.activo.id]);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        {/* <BotonNuevoComponent
          click={async () => {
            await setActivoReferidosAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        /> */}

        <BotonNuevoComponent
          click={async () => {
            await setActivoReferidosAction(dispatch, null);
            await getAllReferidosAction(
              dispatch,
              entidadesStore.activo.id,
              null
            );
          }}
          disabled={
            referidosStore.loadingGetAll
          }
          white
          text={referidosStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'}
        />
      </Grid>
      <Grid item md={12}>
        <ListadoReferidos setOpenModalForm={(val) => setOpenModalForm(val)} />
      </Grid>
      {/* <Modal
        id="modalFormReferidos"
        title={`${
          referidosStore.activo ? 'Editar' : 'Nuevo'
        } tipo de invitación`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {referidosStore.activo && (
          <Typography variant="h6">
            {referidosStore.activo.nombres}{' '}
            {referidosStore.activo.apellidos}
          </Typography>
        )}
        <FormReferidos
          activo={referidosStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal> */}
    </Grid>
  );
};

export default ReferidosView;
