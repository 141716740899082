import dayjs from 'dayjs';
import * as React from 'react';
import * as Redux from 'react-redux';
import { dateformat } from '../../helpers';
import { PARAMS_RESERVAS } from '../../store/Types';
import { getAllAreasAction } from '../areas/AreasAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ReservasHook = () => {
  const dispatch = Redux.useDispatch();

  const history = useHistory();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const reservasStore = Redux.useSelector((state) => state.reservas);

  const [openModalForm, setOpenModalForm] = React.useState(false);
  const [openModalDetalles, setOpenModalDetalles] = React.useState(false);
  const [isDetalles, setIsDetalles] = React.useState(false);

  const [date, setDate] = React.useState(
    dayjs(dateformat(null, { full: true })).toDate()
  );
  const [dateTo, setDateTo] = React.useState(
    dayjs(dateformat(null, { full: true }))
      .add(30, 'd')
      .toDate()
  );

  const utcOffset = entidadesStore.activo.country.utcOffset || 'UTC-04:00';

  React.useEffect(() => {
    const fetchData = async () => {
      const { data: areas } = await getAllAreasAction(dispatch, {
        entityId: entidadesStore.activo.id,
      });
      const queryParams = new URLSearchParams(history.location.search);
      const areaId = Number(queryParams.get('areaId'));
      const payload = {
        ...reservasStore.params,
        entityId: entidadesStore.activo.id,
        date: dayjs(date).utcOffset(utcOffset).toDate(),
        dateTo: dayjs(dateTo).utcOffset(utcOffset).toDate(),
        areaId: areaId || areas[0]?.id || null,
        userId: null,
        codeId: null,
      };

      dispatch({
        type: PARAMS_RESERVAS,
        payload,
      });
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    if (reservasStore.activo) {
      setOpenModalDetalles(true);
    }
  }, [reservasStore.activo]);

  return {
    openModalForm,
    setOpenModalForm,
    openModalDetalles,
    setOpenModalDetalles,
    utcOffset,
    date,
    setDate,
    dateTo,
    setDateTo,
    isDetalles,
    setIsDetalles,
  };
};

export default ReservasHook;
