import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';

export const loginService = async (data) => {
  const res = await axios
    .post(`${baseApi}/auth/login`, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response.data;
    });

  return res;
};

export const authUserService = async () => {
  try {
    const res = await axios.get(`${baseApi}/auth/user`, {
      headers: await token({}),
      params: {
        panel: true,
      },
    });
    return res;
  } catch (e) {
    console.log(e);
  }
};

export const loguotService = async () => {
  const res = await axios
    .delete(`${baseApi}/auth/sign_out`, {
      headers: await token({}),
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response.data;
    });

  return res;
};

export const resetPassService = async (pass1, _token) => {
  const res = await axios
    .post(
      `${baseApi}/auth/update-password/${_token}`,
      {
        password: pass1,
      },
      {}
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response.data;
    });

  return res;
};

// /admin/unassign_user_code/:user_id
export const confirmEmailService = async (_token) => {
  let url = `${baseApi}/auth/confirm-email/${_token}`;
  const res = await axios.post(url);
  return res;
};

export const recoverPassword = async (email) => {
  const res = await axios
    .post(
      `${baseApi}/auth/password-recovery`,
      {
        email,
      },
      {}
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response.data;
    });

  return res;
};

export const setUserDeletionRequests = async (data) => {
  const res = await axios.post(`${baseApi}/user-deletion-requests`, data, {});

  return res;
};
