import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import { ESTADO, PAIS } from '../../constants/FieldsConst';
import { validFielddHelper } from '../../helpers/ValidFieldsHelper';
const PaisField = ({
  onChange,
  required,
  value,
  helperText,
  error,
  isSearch,
  disabled = false,
  paises,
}) => {
  const xName = 'Pais';

  const xSearch = (
    <Autocomplete
      disablePortal
      id={ESTADO}
      options={paises}
      size="small"
      onChange={onChange}
      getOptionLabel={(option) => option.label}
      value={value}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          fullWidth
          label={xName}
          helperText={helperText ?? error ? validFielddHelper(1) : null}
          error={error}
          required={required ?? true}
          {...params}
        />
      )}
    />
  );

  const xSelect = (
    <FormControl
      fullWidth
      error={error}
      size="small"
      required
      disabled={disabled}
    >
      <InputLabel id={PAIS}>{xName}</InputLabel>
      <Select
        labelId={PAIS}
        id={PAIS}
        value={value}
        label={xName}
        onChange={onChange}
      >
        {paises.map((item) => {
          return (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
      {error && (
        <FormHelperText>
          {helperText ?? error ? validFielddHelper(1) : null}
        </FormHelperText>
      )}
    </FormControl>
  );

  return isSearch ? xSearch : xSelect;
};

PaisField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default PaisField;
