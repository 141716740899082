import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { PASSWORD } from "../../../../constants/FieldsConst";

const PasswordField = ({
  value,
  error,
  onClick,
  showPassword,
  onChange,
  size,
  mtl,
  req,
  disabled,
  autoComplete,
  label,
  colorLabel,
}) => {
  return (
    <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
      {/* <InputLabel
        htmlFor={PASSWORD}
        sx={{ marginTop: mtl ?? 0, color: error ? "red" : colorLabel }}
      >{`${label || "Contraseña"} ${
        req === null ? "*" : req ? "*" : ""
      }`}</InputLabel> */}
      <TextField
        id={PASSWORD}
        type={showPassword ? "text" : "password"}
        label={`${label || "Contraseña"} ${
          req === null ? "*" : req ? "*" : ""
        }`}
        disabled={disabled}
        value={value}
        onChange={onChange}
        required={req ?? true}
        size={size}
        error={error}
        helperText={error}
        autoComplete={autoComplete}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onClick}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

PasswordField.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export default PasswordField;
