import React from "react";
import ContainerTemplate from "../../../components/template/Container";
import ModulosView from "../../modulos/ModulosView";
import * as ROUTES from "../../../router/Routes";

const AppModulesModule = () => {
  return (
    <ContainerTemplate active={ROUTES.appModules} routes={[]}>
      <ModulosView isGlobal={true} />
    </ContainerTemplate>
  );
};

export default AppModulesModule;
