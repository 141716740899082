import * as React from 'react';
import * as Redux from 'react-redux';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import * as CONST from '../../../../constants/FieldsConst';
import Box from '@mui/material/Box';
import { saveContactosAction } from '../ContactosAction';
import { validFielddHelper } from '../../../../helpers/ValidFieldsHelper';
import { ButtonFile, GenerosField } from '../../../../components';
import {
  CorreoField,
  FechaNacimientoField,
} from '../../../usuarios/components/FieldsUsuarios';
import BotonNuevoComponent from '../../../entidades/components/BotonNuevo';
import { dateformat } from '../../../../helpers';
import Schedules from './Schedules';
import LoadingComponent from '../../../../components/Loading';
import { getAllTiposContactosAction } from '../../tipos/TiposContactosAction';
import PhoneNumberField from '../../../../components/fields/PhoneNumberField';
import { acceptOnlyImages } from '../../../../components/ButtonFile';
import { useNotification } from '../../../../helpers/notification';
import {
  DRIVER,
  FAMILY,
  NANNY,
  OTHER,
  OWNER,
  SECURITY,
  SERVICE,
  getCodeType,
} from '../../../../constants/enumsConst';
import { CLIENT } from '../../../../constants/ConfigConst';

const FormContactos = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const contactosStore = Redux.useSelector((state) => state.contactos);
  const tiposContactosStore = Redux.useSelector(
    (state) => state.tiposContactos
  );
  const isClub = entidadesStore.activo?.entityType?.id === 2;

  const [errorEmail, setErrorEmail] = React.useState(false);
  const isPublic = entidadesStore.activo?.isPublic;

  const schedules = ['08:00 - 12:00', '14:00 - 18:00'];
  const activo = contactosStore.activo;
  const initData = {
    [CONST.NAME]: '',
    [CONST.PHONE]: '',
    [CONST.OCCUPATION]: '',
    [CONST.GENDER]: 'male',
    [CONST.EMAIL]: '',
    [CONST.BIRTHDAY]: null,
    [CONST.TYPE_ID]: tiposContactosStore.all[0]?.id || null,
    [CONST.USER_TYPES]: [
      OWNER,
      FAMILY,
      SERVICE,
      NANNY,
      DRIVER,
      OTHER,
      SECURITY,
      CLIENT,
    ],
    [CONST.SCHEDULES]: [
      { day: 'monday', schedules },
      { day: 'tuesday', schedules },
      { day: 'wednesday', schedules },
      { day: 'thursday', schedules },
      { day: 'friday', schedules },
      { day: 'saturday', schedules: ['08:00 - 14:00'] },
      { day: 'sunday', schedules: ['08:00 - 14:00'] },
      { day: 'holiday', schedules: ['08:00 - 14:00'] },
    ],
  };

  const [contacto, setContacto] = React.useState(initData);
  const [dataError, setDataRequired] = React.useState({
    [CONST.NAME]: false,
    [CONST.PHONE]: false,
    [CONST.OCCUPATION]: false,
    [CONST.TYPE_ID]: false,
    [CONST.USER_TYPES]: false,
  });
  const [avatar, setAvatar] = React.useState();

  React.useEffect(() => {
    const fetchData = async () => {
      if (tiposContactosStore.all && tiposContactosStore.all.length === 0) {
        await getAllTiposContactosAction(dispatch, entidadesStore.activo.id);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSetDataField = (key, value) =>
    setContacto({ ...contacto, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).map((key) => {
      const val = contacto[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      return va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const [loadingSave, setLoadingSave] = React.useState(false);
  const handleSubmit = async () => {
    const valid = _validDataForm();

    if (valid) {
      const data = {
        ...contacto,
        [CONST.ENTITY_ID]: entidadesStore.activo.id,
        avatar,
      };
      setLoadingSave(true);
      let res = await saveContactosAction(
        { dispatch, addNotification },
        activo ? activo.id : null,
        data,
        contactosStore.params
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };
  var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
  React.useEffect(() => {
    if (activo === null) return;
    setTimeout(() => {
      setContacto({
        ...activo,
        [CONST.TYPE_ID]: activo.type?.id,
      });
    }, 300);
  }, [activo]);

  return (
    <Box component="form" sx={{}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={contactosStore.activo ? 'Actualizando...' : 'Guardando...'}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonFile
            id={CONST.AVATAR}
            onFile={(avatar) => setAvatar(avatar)}
            onDelete={() => setAvatar(null)}
            text="Subir Foto"
            sxc={{ marginTop: '5px' }}
            name={'Foto'}
            file={avatar}
            size={100}
            src={avatar ? null : activo ? activo.avatar : null}
            accept={acceptOnlyImages}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                size="small"
                id={CONST.NAME}
                required
                label="Nombre y apellido"
                value={contacto.name}
                onChange={(e) =>
                  _handleSetDataField(CONST.NAME, e.target.value)
                }
                helperText={dataError.name ? validFielddHelper(1) : null}
                error={dataError.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <GenerosField
                value={contacto.gender}
                onChange={(e, v) =>
                  _handleSetDataField(CONST.GENDER, e.target.value)
                }
                error={dataError.gender}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PhoneNumberField
                value={contacto.phone}
                onChange={(value) => _handleSetDataField(CONST.PHONE, value)}
                error={dataError.phone}
                fieldName={CONST.PHONE}
                required={true}
                dataError={dataError}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CorreoField
                v={contacto.email}
                on={(e) => {
                  let text = e.target.value;
                  _handleSetDataField(CONST.EMAIL, text);
                  if (validEmail.test(text)) {
                    setErrorEmail(false);
                    return;
                  }
                  setErrorEmail(true);
                }}
                autoComplete="off"
                errorEmail={errorEmail}
                e={errorEmail || dataError.email}
                required={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FechaNacimientoField
                v={contacto.birthday}
                on={(e) =>
                  _handleSetDataField(CONST.BIRTHDAY, dateformat(e, {}))
                }
                required={false}
                e={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="label_type">Tipo de contacto</InputLabel>
                <Select
                  labelId="label_type"
                  id={CONST.TYPE_ID}
                  value={contacto.typeId}
                  label="Tipo de contacto"
                  onChange={(e) => {
                    _handleSetDataField(CONST.TYPE_ID, e.target.value);
                  }}
                >
                  {tiposContactosStore.all.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                id={CONST.OCCUPATION}
                required
                label="Ocupación"
                value={contacto.occupation}
                onChange={(e) =>
                  _handleSetDataField(CONST.OCCUPATION, e.target.value)
                }
                helperText={dataError.occupation ? validFielddHelper(1) : null}
                error={dataError.occupation}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl size="small" sx={{ width: '100%' }}>
                <InputLabel id="selectUserType">
                  Tipo de usuario permitido
                </InputLabel>
                <Select
                  labelId="selectUserTypes"
                  size="small"
                  multiple={true}
                  value={contacto.userTypes}
                  label="Tipo de usuario permitido"
                  onChange={(e) =>
                    _handleSetDataField(CONST.USER_TYPES, e.target.value)
                  }
                  id={CONST.USER_TYPES}
                >
                  {!isPublic && (
                    <MenuItem value={OWNER}>
                      {getCodeType(OWNER, isClub)}
                    </MenuItem>
                  )}
                  {!isPublic && (
                    <MenuItem value={FAMILY}>
                      {getCodeType(FAMILY, isClub)}
                    </MenuItem>
                  )}
                  {!isPublic && (
                    <MenuItem value={SERVICE}>Trabajador doméstico</MenuItem>
                  )}
                  {!isPublic && <MenuItem value={NANNY}>Niñera</MenuItem>}
                  {!isPublic && <MenuItem value={DRIVER}>Chofer</MenuItem>}
                  {!isPublic && (
                    <MenuItem value={OTHER}>
                      {getCodeType(OTHER, isClub)}
                    </MenuItem>
                  )}
                  {isPublic && <MenuItem value={CLIENT}>Cliente</MenuItem>}
                  <MenuItem value={SECURITY}>Seguridad</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Schedules
                schedules={contacto.schedules}
                onChange={(e) => _handleSetDataField(CONST.SCHEDULES, e)}
              />
            </Grid>

            <Grid item md={12} align="center" sx={{ m: 2 }}>
              <BotonNuevoComponent
                click={handleSubmit}
                text="GUARDAR"
                disabled={loadingSave}
                morado
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormContactos;
