import * as React from 'react';
import * as Redux from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { TextField, Typography, Paper, Box, Grid, Alert } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { mdTheme } from '../../../components/template';
import CopyrightTemplate from '../../../components/template/Copyright';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import { loginAction } from './LoginAction';
import PasswordField from './components/PasswordField';
import { REMEMBER_ME } from '../../../constants/ConfigConst';
import { SET_LOGOUT } from '../../../store/Types';
import { Button } from '@mui/material';
import LogOnComponent from '../../../components/template/drawer/Logon';
import { BLANCO_COLOR, MORADO_COLOR } from '../../../constants/ColorsConst';

const LoginView = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const loginStore = Redux.useSelector((state) => state.login);

  const [dataLogin, setDataLogin] = React.useState({
    email: '',
    password: '',
    isRemember: false,
  });
  const [userError, setUserError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [num, setNum] = React.useState(Math.floor(Math.random() * 10) + 1);

  React.useEffect(() => {
    dispatch({
      type: SET_LOGOUT,
    });
    // const userLocal = localStorage.getItem(EMAIL);
    const rememberMeLocal = localStorage.getItem(REMEMBER_ME);
    const x = {
      email: '',
      isRemember: rememberMeLocal === '1',
    };
    setDataLogin({ ...dataLogin, ...x });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (loginStore.error) {
      return setErrorMessage(loginStore.error.message);
    }
    setErrorMessage(null);
  }, [loginStore.error]);

  const _handleSubmit = async (event) => {
    event.preventDefault();

    let validUser = dataLogin.email === '';
    let validPaswword = dataLogin.password === '';

    setUserError(validUser);
    setPasswordError(validPaswword);

    if (!validUser && !validPaswword) {
      setLoading(true);
      await loginAction({ dispatch, history, dataLogin });
      setLoading(false);
    }
  };

  const _handleSetDataLogin = (prop, check) => (event) => {
    setDataLogin({
      ...dataLogin,
      [prop]: check ? event.target.checked : event.target.value,
    });
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: `url(./img/${num}.jpg)`,
            // backgroundImage: 'url(https://source.unsplash.com/random)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          component={Paper}
          elevation={10}
          square
        >
          <Box
            sx={{
              mt: 3,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '80%',
            }}
            autoComplete="off"
          >
            <img alt="On Pass" src="./img/logo2.png" width="340" />
            <Typography sx={{ mt: 2, mb: 2 }} component="h1" variant="h5">
              Inicio de Sesión
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={_handleSubmit}
              sx={{
                mt: 1,
              }}
            >
              {errorMessage ? (
                <Alert
                  severity="error"
                  sx={{ mb: 2 }}
                  onClick={() => {
                    setErrorMessage(null);
                  }}
                >
                  {errorMessage}
                </Alert>
              ) : null}
              <TextField
                required
                fullWidth
                id="user"
                label="Usuario"
                name="user"
                value={dataLogin.email ?? ''}
                autoComplete="user"
                onChange={_handleSetDataLogin('email', false)}
                autoFocus
                error={userError}
                sx={{ mb: 2 }}
              />
              <PasswordField
                value={dataLogin.password}
                error={passwordError}
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                showPassword={showPassword}
                onChange={_handleSetDataLogin('password', false)}
              />
              {/* <FormControlLabel
                                control={<Checkbox
                                    checked={dataLogin.isRemember}
                                    onChange={_handleSetDataLogin('isRemember', true)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="primary"
                                    id='isRemember'
                                />}
                                label="Recordarme"
                            /> */}
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: MORADO_COLOR,
                  color: BLANCO_COLOR,
                  '&:hover': {
                    backgroundColor: MORADO_COLOR,
                  },
                }}
              >
                ENTRAR
              </LoadingButton>

              <Grid container>
                <Grid item xs>
                  <Button
                    sx={{
                      mt: 0,
                    }}
                    onClick={() => {
                      history.push('/recoverPassword');
                    }}
                  >
                    Recuperar contraseña
                  </Button>
                </Grid>
                {/* <Grid item>
                                    <Link href="#" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>  */}
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              flexDirection: 'column',
              height: '100px',
              width: '100%',
              pr: 2,
            }}
          >
            <LogOnComponent open={true} />
            <CopyrightTemplate />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default LoginView;
