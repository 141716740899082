import {
  FormControl,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as Redux from 'react-redux';
import ButtonFileMultipleImages from '../../../components/FieldFileMultilpleImages';
import LoadingComponent from '../../../components/Loading';
import { useNotification } from '../../../helpers/notification';
import Schedules from '../../contactos/contactos/components/Schedules';
import { saveEntidadesAction } from '../EntidadesAction';
import BotonNuevoComponent from './BotonNuevo';

const schedules = ['08:00 - 12:00', '14:00 - 18:00'];
const FormDatosEntidad = ({ openModal = () => {} }) => {
  const dispatch = Redux.useDispatch();
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const { addNotification } = useNotification();

  let activo = entidadesStore.activo;
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [entidad, setEntidad] = React.useState({
    description: '',
    mapUrl: '',
    aboutImages: [],
    deletedAboutImages: [],
    schedules: [
      { day: 'monday', schedules },
      { day: 'tuesday', schedules },
      { day: 'wednesday', schedules },
      { day: 'thursday', schedules },
      { day: 'friday', schedules },
      { day: 'saturday', schedules: ['08:00 - 14:00'] },
      { day: 'sunday', schedules: ['08:00 - 14:00'] },
      { day: 'holiday', schedules: ['08:00 - 14:00'] },
    ],
  });

  React.useEffect(() => {
    if (activo === null) return;
    // TODO: Agregar data para mostrar
    setEntidad({
      schedules: activo.schedules,
      description: activo.description,
      mapUrl: activo.mapUrl,
      aboutImages: activo.images.aboutImages,
      newAboutImages: [],
      deletedAboutImages: [],
    });
  }, [activo]);

  const _handleSubmit = async () => {
    let data = {
      schedules: entidad.schedules,
      description: entidad.description,
      mapUrl: entidad.mapUrl,
      newAboutImages: entidad.newAboutImages,
      deletedAboutImages: entidad.deletedAboutImages,
    };

    setLoadingSave(true);
    let res = await saveEntidadesAction(
      { dispatch, addNotification },
      data,
      {
        id: activo ? activo.id : null,
        isSU: true,
      },
      entidadesStore.params
    );
    openModal(!res);
    setLoadingSave(false);
  };

  return (
    <Box component="form">
      <Grid container spacing={2} sx={{ mt: loadingSave ? 2 : 0 }}>
        <LoadingComponent
          isLoading={loadingSave || loadingSave}
          text={activo ? 'Actualizando...' : 'Guardando...'}
        />
        <Grid item xs={12} md={6}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <Typography variant="body">Descripción</Typography>
            <TextareaAutosize
              size="small"
              type="time"
              value={entidad.description}
              minRows={3}
              onChange={(e) =>
                setEntidad({ ...entidad, description: e.target.value })
              }
              id="description"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <Typography variant="body">URL del mapa</Typography>
            <TextField
              size="small"
              value={entidad.mapUrl}
              onChange={(e) =>
                setEntidad({ ...entidad, mapUrl: e.target.value })
              }
              id="mapUrl"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <ButtonFileMultipleImages
            images={entidad.aboutImages}
            onChange={(files) => {
              setEntidad({ ...entidad, newAboutImages: files });
            }}
            onDelete={(image) => {
              setEntidad({
                ...entidad,
                deletedAboutImages: [...entidad.deletedAboutImages, image],
              });
            }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Schedules
            schedules={entidad.schedules}
            onChange={(e) => setEntidad({ ...entidad, schedules: e })}
          />
        </Grid>

        <Grid item md={12} align="center" sx={{ p: 2 }}>
          <BotonNuevoComponent click={_handleSubmit} morado text="GUARDAR" />
        </Grid>
      </Grid>
    </Box>
  );
};

FormDatosEntidad.propTypes = {
  openModal: PropTypes.func,
};

export default FormDatosEntidad;
