import * as React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { GENERO } from '../../constants/FieldsConst';
import { validFielddHelper } from '../../helpers/ValidFieldsHelper';

export const generosEnums = [
    { id: 'male', nombre: 'Masculino' },
    { id: 'female', nombre: 'Femenino' },
    { id: 'other', nombre: 'Otro' },
    { id: 'unspecified', nombre: 'Sin Especificar' },
]

const GenerosField = ({
    onChange,
    required,
    value,
    helperText,
    error,
    isSearch,
    disabled
}) => {
    const xName = "Género"

    const xSearch = <Autocomplete
        disablePortal
        id={GENERO}
        options={generosEnums}
        size="small"
        onChange={onChange}
        getOptionLabel={(option) => option.label}
        value={value}
        disabled={disabled}
        renderInput={(params) => <TextField
            fullWidth
            label={xName}
            helperText={helperText ?? error ? validFielddHelper(1) : null}
            error={error}
            required={required ?? true}
            {...params}
        />}
    />

    const xSelect = <FormControl
        fullWidth
        error={error}
        size="small"
        required
    >
        <InputLabel id={GENERO}>{xName}</InputLabel>
        <Select
            labelId={GENERO}
            id={GENERO}
            value={value}
            label={xName}
            disabled={disabled}
            onChange={onChange}
        >
            {
                generosEnums.map((item) => {
                    return (
                        <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>
                    )
                })
            }
        </Select>
        {
            error ?
                <FormHelperText>{helperText ?? error ? validFielddHelper(1) : null}</FormHelperText>
                : <></>
        }
    </FormControl>

    return isSearch ? xSearch : xSelect;
};

GenerosField.propTypes = {
    onChange: PropTypes.func.isRequired
};

export default GenerosField;