import {
  deleteReservacionesClasesService,
  getAllReservacionesClasesService,
  getOneReservacionesClasesService,
  saveReservacionesClasesService,
} from '../../../services';
import { tryCatchHelper } from '../../../helpers';
import {
  ACTIVO_RESERVACIONES_CLASES,
  GETALL_RESERVACIONES_CLASES,
  LOADING_GETALL_RESERVACIONES_CLASES,
} from './ReservacionesClasesReducer';

export const getAllReservacionesClasesAction = async (dispatch, params) => {
  try {
    dispatch({
      type: LOADING_GETALL_RESERVACIONES_CLASES,
      payload: true,
    });
    const res = await getAllReservacionesClasesService(params);
    if (res.status === 200) {
      dispatch({
        type: GETALL_RESERVACIONES_CLASES,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_RESERVACIONES_CLASES,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_RESERVACIONES_CLASES,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_RESERVACIONES_CLASES,
      payload: false,
    });
    let message =
      e.response?.data?.message ||
      e.message ||
      'Error al intentar, realizar el proceso';
    if (Array.isArray(e.message)) message = e.message.join(', ');
    if (Array.isArray(e.response?.data?.message)) {
      message = e.response?.data?.message.join(', ');
    }
    console.warn(message);
  }
};

export const saveReservacionesClasesAction = async (
  { dispatch, addNotification },
  params,
  id,
  data
) => {
  try {
    const res = await saveReservacionesClasesService(id, data);
    addNotification(
      res.message ?? id
        ? 'El tipo de documento actualizado.'
        : 'El tipo de documento registrado.'
    );
    await getAllReservacionesClasesAction(dispatch, params);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoReservacionesClasesAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_RESERVACIONES_CLASES,
    payload: data,
  });
};

export const getOneReservacionesClasesAction = async (dispatch, id) => {
  try {
    const res = await getOneReservacionesClasesService(id);
    if (res.status === 200) {
      await setActivoReservacionesClasesAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteReservacionesClasesAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteReservacionesClasesService(id);
    if (res.status === 200) {
      addNotification(res.data?.message ?? 'Tipo de documento eliminado.');
      await setActivoReservacionesClasesAction(dispatch, null);
      await getAllReservacionesClasesAction(dispatch, params);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
