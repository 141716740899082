import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../../modulos/modules.helpers';
import {
  getAllNotificacionesAction,
  setActivoNotificacionesAction,
} from './NotificacionesAction';
import FormNotificaciones from './components/FormNotificaciones';
import ListadoNotificaciones from './components/ListadoNotificaciones';

const NotificacionesView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const notificacionesStore = Redux.useSelector(
    (state) => state.notificaciones
  );

  const loginStore = Redux.useSelector((state) => state.login);
  const accessName = 'notifications';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [openModalForm, setOpenModalForm] = React.useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoNotificacionesAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        <BotonNuevoComponent
          click={async () => {
            await setActivoNotificacionesAction(dispatch, null);
            await getAllNotificacionesAction(
              dispatch,
              entidadesStore.activo?.id,
              null
            );
          }}
          disabled={
            notificacionesStore.loadingGetAll ||
            notificacionesStore.all.length === 0
          }
          white
          text={
            notificacionesStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'
          }
        />
      </Grid>
      <Grid item md={12}>
        <ListadoNotificaciones
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Grid>
      <Modal
        id="modalFormNotificaciones"
        title={`Enviar Notificación`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {notificacionesStore.activo && (
          <Typography variant="h6">
            {notificacionesStore.activo.nombres}{' '}
            {notificacionesStore.activo.apellidos}
          </Typography>
        )}
        <FormNotificaciones
          activo={notificacionesStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default NotificacionesView;
