import {
  SET_LOGOUT,
  GET_DASHBOARD,
  LOADING_DASHBOARD,
} from "../../store/Types";

const initialState = {
  dashboard: null,
  loading: false,
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload.data,
      };
    case LOADING_DASHBOARD:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
