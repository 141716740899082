import { Card, Grid, Paper, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../components';
import DevicesComponent from './components/Devices';
import ReservationUsedBlocks from '../../components/ReservationUsedBlocks';
import FormReservasEspeciales from './components/FormReservasEspeciales';

const PanelReservasEspecialesView = () => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();
  const [isMobile, setIsMobile] = React.useState(false);
  
  const [refresh, setRefresh] = React.useState(false);
  const [areaIds, setAreaIds] = React.useState([]);
  const [dates, setDates] = React.useState([]);

  // const entidadesStore = Redux.useSelector((state) => state.entidades);
  // const areasStore = Redux.useSelector((state) => state.areas);

  React.useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [openModalForm, setOpenModalForm] = React.useState(false);

  const tabPanel = (
    <Paper elevation={2}>
      <Grid container>
        <Grid item xs={12} md={12}></Grid>
      </Grid>
    </Paper>
  );

  return (
    <Grid container spacing={2} sx={{ marginTop: '1px' }}>
      <Grid item md={4}>
        <Card>
          <FormReservasEspeciales
            setIsMobile={() => setIsMobile(!isMobile)} 
            setOpenModalForm={(val) => {}}
            onRefresh={() => {
              setRefresh(true);
              setTimeout(() => {
                setRefresh(false);
              }, 100);
            }}
            onChangeAreas={(val) => setAreaIds(val)}
            onChangeDates={(val) => setDates(val)} 
          />
        </Card>
      </Grid>
      <Grid item md={8}>
        <Paper elevation={2} sx={{ p: 2 }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <ReservationUsedBlocks
                areaIds={areaIds}
                dates={dates}
                refresh={refresh}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Modal
        id="modalFormAreas"
        title="Nueva reserva"
        open={openModalForm}
        onClose={() => setOpenModalForm(!openModalForm)}
      ></Modal>
    </Grid>
  );
};

export default PanelReservasEspecialesView;
