import { Avatar } from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../router/Routes";
import BotonNuevoComponent from "../entidades/components/BotonNuevo";

const LayoutInfoComponent = ({ message, isReturn = true }) => {
  let history = useHistory();

  return (
    <center>
      <Box
        sx={{
          width: "30%",
        }}
      >
        <Avatar
          alt="On Pass"
          src="./img/logoon.png"
          sx={{ width: 150, height: 150, padding: "20px" }}
        />
        <Typography component="h1" variant="h3" sx={{ fontWeight: "700" }}>
          ON PASS
        </Typography>
        <Typography sx={{ mt: 2, mb: 2 }} component="h1" variant="h5">
          {message}
        </Typography>
        {
          isReturn && (
            <BotonNuevoComponent
              click={() => {
                history.push(ROUTES.login.to);
              }}
              text="VOLVER AL INICIO DE SESIÓN"
              morado
            />
          )
        }
      </Box>
    </center>
  );
};

export default LayoutInfoComponent;
