import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import PanelClasesView from './PanelClasesView';
import * as ROUTES from '../../../router/Routes';

const PanelClasesModule = () => {
  return (
    <ContainerTemplate active={ROUTES.panelClases} routes={[ROUTES.clases]}>
      <PanelClasesView />
    </ContainerTemplate>
  );
};

export default PanelClasesModule;
