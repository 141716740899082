import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const entidadBancosApi = `${baseApi}/entity-banks`;

export const getAllEntidadBancosService = async (params) => {
  const res = await axios.get(entidadBancosApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveEntidadBancosService = async (id, data) => {
  if (id) {
    return await axios.put(`${entidadBancosApi}/${id}`, data, {
      headers: await token({}),
    });
  }

  return await axios.post(`${entidadBancosApi}`, data, {
    headers: await token({}),
  });
};

export const deleteEntidadBancosService = async (id) => {
  const res = await axios.delete(`${entidadBancosApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};
