import {} from '../../../store/Types';
import {
  deleteClasesService,
  getAllClasesService,
  getOneClasesService,
  saveClasesService,
} from '../../../services';
import { tryCatchHelper } from '../../../helpers';
import {
  ACTIVO_CLASES,
  GETALL_CLASES,
  LOADING_GETALL_CLASES,
} from './ClasesReducer';

export const getAllClasesAction = async (dispatch, params) => {
  try {
    dispatch({ type: LOADING_GETALL_CLASES, payload: true });
    const res = await getAllClasesService(params);
    if (res.status === 200) {
      dispatch({ type: GETALL_CLASES, payload: res.data });
      dispatch({ type: LOADING_GETALL_CLASES, payload: false });

      return res.data;
    }
    dispatch({ type: LOADING_GETALL_CLASES, payload: false });
    return null;
  } catch (e) {
    dispatch({ type: LOADING_GETALL_CLASES, payload: false });
    tryCatchHelper(e);
  }
};

export const saveClasesAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res = await saveClasesService(id, data);
    if (id) {
      await setActivoClasesAction(dispatch, res);
    }
    addNotification(
      res.message ?? id ? 'Área actualizada.' : 'Área registrada.'
    );
    await getAllClasesAction(dispatch, params);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoClasesAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_CLASES,
    payload: data,
  });
};

export const getOneClasesAction = async (dispatch, id) => {
  try {
    dispatch({
      type: LOADING_GETALL_CLASES,
      payload: true,
    });
    const res = await getOneClasesService(id);
    if (res.status === 200) {
      await setActivoClasesAction(dispatch, res.data);
      dispatch({
        type: LOADING_GETALL_CLASES,
        payload: false,
      });
      return true;
    }
    await setActivoClasesAction(dispatch, null);
    dispatch({
      type: LOADING_GETALL_CLASES,
      payload: false,
    });
    return false;
  } catch (e) {
    await setActivoClasesAction(dispatch, null);
    dispatch({
      type: LOADING_GETALL_CLASES,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const deleteClasesAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteClasesService(id);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Área eliminada.');
      await setActivoClasesAction(dispatch, null);
      await getAllClasesAction(dispatch, params);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
