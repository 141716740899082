import React from "react";
import ContainerTemplate from "../../components/template/Container";
import ReservasView from "./ReservasView";
import * as ROUTES from "../../router/Routes";

const ReservasModule = () => {
  return (
    <ContainerTemplate active={ROUTES.reservas} routes={[]}>
      <ReservasView />
    </ContainerTemplate>
  );
};

export default ReservasModule;
