import { tryCatchHelper } from '../../../helpers';
import {
  deleteRestaurantesService,
  getAllRestaurantesService,
  getOneRestaurantesService,
  saveRestaurantesService,
} from '../../../services';
import {
  ACTIVO_RESTAURANTES,
  GETALL_RESTAURANTES,
  // GET_DATA_SEARCH_RESTAURANTES_RESERVAS_ESPECIALES,
  LOADING_GETALL_RESTAURANTES,
} from '../../../store/Types';

export const getAllRestaurantesAction = async (dispatch, params) => {
  try {
    dispatch({ type: LOADING_GETALL_RESTAURANTES, payload: true });
    const res = await getAllRestaurantesService(params);
    if (res.status === 200) {
      dispatch({ type: GETALL_RESTAURANTES, payload: res.data });
      dispatch({ type: LOADING_GETALL_RESTAURANTES, payload: false });
      // if (res.data.data.length > 0) {
      //   dispatch({
      //     type: GET_DATA_SEARCH_RESTAURANTES_RESERVAS_ESPECIALES,
      //     payload: res.data.data[0].id,
      //   });
      // }
      return res.data;
    }
    dispatch({ type: LOADING_GETALL_RESTAURANTES, payload: false });
    return null;
  } catch (e) {
    dispatch({ type: LOADING_GETALL_RESTAURANTES, payload: false });
    tryCatchHelper(e);
  }
};

export const saveRestaurantesAction = async (
  { dispatch, addNotification },
  id,
  data,
  params
) => {
  try {
    let res = await saveRestaurantesService(id, data);
    if (id) {
      await setActivoRestaurantesAction(dispatch, res);
    }
    addNotification(
      res.message ?? id ? 'Restaurante actualizado.' : 'Restaurante registrado.'
    );
    await getAllRestaurantesAction(dispatch, params);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoRestaurantesAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_RESTAURANTES,
    payload: data,
  });
};

export const getOneRestaurantesAction = async (dispatch, id) => {
  try {
    dispatch({
      type: LOADING_GETALL_RESTAURANTES,
      payload: true,
    });
    const res = await getOneRestaurantesService(id);
    if (res.status === 200) {
      await setActivoRestaurantesAction(dispatch, res.data);
      dispatch({
        type: LOADING_GETALL_RESTAURANTES,
        payload: false,
      });
      return true;
    }
    dispatch({
      type: LOADING_GETALL_RESTAURANTES,
      payload: false,
    });
    return false;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_RESTAURANTES,
      payload: false,
    });
    tryCatchHelper(e);
    return false;
  }
};

export const deleteRestaurantesAction = async (
  { dispatch, addNotification },
  id,
  params
) => {
  try {
    const res = await deleteRestaurantesService(id);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Restaurante eliminada.');
      await setActivoRestaurantesAction(dispatch, null);
      await getAllRestaurantesAction(dispatch, params);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
