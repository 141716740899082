import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const settingsApi = `${baseApi}/settings`;

export const getSettingsService = async () => {
  const res = await axios
    .get(settingsApi, {
      headers: await token({}),
    })
    .catch((e) => {
      throw e.response.data;
    });
  return res.data;
};

export const saveSettingsService = async (data) => {
  const res = await axios
    .put(settingsApi, data, {
      headers: await token({}),
    })
    .catch((e) => {
      throw e.response.data;
    });
  return res.data;
};
