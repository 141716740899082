import {
  GETALL_TIPOS_INVITACION,
  LOADING_GETALL_TIPOS_INVITACION,
  ACTIVO_TIPOS_INVITACION,
  SET_LOGOUT,
  CLEAR_ALL,
  PARAMS_TIPOS_INVITACION,
} from '../../store/Types';

const initialState = {
  all: [],
  loadingGetAll: false,
  activo: null,
  params: {},
};

export const tiposInvitacionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_TIPOS_INVITACION:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case ACTIVO_TIPOS_INVITACION:
      return {
        ...state,
        activo: action.payload,
      };
    case LOADING_GETALL_TIPOS_INVITACION:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case PARAMS_TIPOS_INVITACION:
      return {
        ...state,
        params: action.payload,
      };
    case SET_LOGOUT || CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
