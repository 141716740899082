import * as React from 'react';
import * as Redux from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import * as CONST from '../../../constants/FieldsConst';
import { getDateFormated } from '../../../helpers';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import LoadingComponent from '../../../components/Loading';
import { accessLogTypesLabel } from '../enum/access-type-allowed.enum';
import { accesos } from '../AccesosService';
import { downloadExcelFile } from '../../../helpers/exportHelper';
import { useNotification } from '../../../helpers/notification';

let intervalPage = null;
let intervalSearch = null;
const ListadoAccesos = ({ params, pagination, setPagination }) => {
  const { addNotification } = useNotification();
  const accesosStore = Redux.useSelector((state) => state.accesos);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const timezone = entidadesStore.activo.country.timezone;

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'user',
      label: 'Propietario',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {value.profile && (
                <Avatar
                  alt={value.profile.firstName}
                  sx={{ width: 30, height: 30, mr: 1 }}
                  src={value.profile.avatar}
                />
              )}
              <Typography variant="body">
                {value.profile
                  ? `${value.profile.firstName} ${value.profile.lastName}`
                  : 'ROOT'}
              </Typography>
            </Box>
          ) : (
            <Typography variant="body">ROOT</Typography>
          ),
      },
    },
    {
      name: 'guest',
      label: 'Invitado',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Avatar
                alt={value.firstName}
                sx={{ width: 30, height: 30, mr: 1 }}
                src={value.avatar}
              />
              <Typography variant="body">
                {value.firstName} {value.lastName}
              </Typography>
            </Box>
          ) : (
            <Typography variant="body"></Typography>
          ),
      },
    },
    {
      name: 'delivery',
      label: 'Delivery',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value?.description ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography variant="body">{value.description}</Typography>
            </Box>
          ) : (
            <Typography variant="body"></Typography>
          ),
      },
    },
    {
      name: 'entryTime',
      label: 'Entrada',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'accessDoor',
      label: 'Puerta',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography variant="body">{value.name}</Typography>
            </Box>
          ) : (
            <Typography variant="body"></Typography>
          ),
      },
    },
    {
      name: 'type',
      label: 'Tipo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body">{accessLogTypesLabel[value]}</Typography>
          );
        },
      },
    },
    {
      name: 'securityUser',
      label: 'Escaneado por',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = accesosStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );

          if (data?.securityUser) {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  alt={data.securityUser.profile.firstName}
                  sx={{ width: 30, height: 30, mr: 1 }}
                  src={data.securityUser.profile.avatar}
                />
                <Typography variant="body">
                  {data.securityUser.profile.firstName}{' '}
                  {data.securityUser.profile.lastName}
                </Typography>
              </Box>
            );
          } else if (data?.accessDoor?.iot) {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body">
                  {data.accessDoor.iot.name}
                </Typography>
              </Box>
            );
          } else {
            return <Typography variant="body"></Typography>;
          }
        },
      },
    },
  ];

  const access = accesosStore.all.map((item) => {
    return {
      ...item,
      entryTime: getDateFormated(
        item.entryTime,
        'DD-MM-YYYY hh:mm a',
        timezone
      ),
      exitTime: getDateFormated(item.exitTime, 'DD-MM-YYYY hh:mm a', timezone),
    };
  });

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },

    onDownload: () => {
      const url = `${accesos}/export`;
      const name = 'Aperturas de puertas';
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },

    serverSide: true,
    onChangePage: (currentPage) => {
      clearTimeout(intervalPage);
      intervalPage = setTimeout(() => {
        setPagination({
          ...pagination,
          page: currentPage + 1,
        });
      }, 500);
    },
    customSearch: () => false,
    onSearchChange: (searchQuery) => {
      clearTimeout(intervalSearch);
      intervalSearch = setTimeout(() => {
        setPagination({
          ...pagination,
          q: searchQuery,
          page: 1,
        });
      }, 500);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setPagination({
        ...pagination,
        limit: numberOfRows,
        page: 1,
      });
    },
    rowsPerPage: pagination.limit,
    count: pagination.total,
    page: pagination.page - 1,
    rowsPerPageOptions: [15, 30, 50],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <Grid container></Grid>;
    },
    selectableRowsHideCheckboxes: true,
    customSort: (data, colIndex, sortDirection, meta) => {
      return data;
    },
    onColumnSortChange: (sort, sortDirection) => {
      if (sort === 'user') sort = 'firstName'
      if (sort === 'guest') sort = 'guestFirstName'
      if (sort === 'delivery') sort = 'deliveryDescription'
      if (sort === 'accessDoor') sort = 'accessDoorName'
      if (sort === 'securityUser') sort = 'scannedBy'
      setPagination({
        ...pagination,
        page: 1,
        sort,
        sortDirection
      })
    }
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={accesosStore.loadingGetAll}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={access}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
};

export default ListadoAccesos;
