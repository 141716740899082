import { LoadingButton } from '@mui/lab';
import { Avatar, Grid, Paper, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import CopyrightTemplate from '../../../components/template/Copyright';
import { setUserDeletionRequests } from '../../../services';

const UserDeletionRequestView = () => {
  const [data, setData] = React.useState({
    email: null,
    description: null,
  });
  const [emailError, setEmailError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isSend, setIsSend] = React.useState(false);
  const [isAlreadyError, setIsAlreadyError] = React.useState(false);

  return (
    <center>
      <Box
        sx={{
          width: {
            xs: '90%',
            md: '30%',
          },
        }}
      >
        <Avatar
          alt="On Pass"
          src="./img/logoon.png"
          sx={{
            width: {
              xs: 100,
              md: 150,
            },
            height: {
              xs: 100,
              md: 150,
            },
            padding: '20px',
          }}
        />
        <Typography component="h1" variant="h3" sx={{ fontWeight: '700' }}>
          ON PASS
        </Typography>
        <Typography sx={{ mt: 2, mb: 2 }} component="h1" variant="h5">
          Solicitar eliminación de usuario
        </Typography>
        <Paper elevation={5} sx={{ p: 3 }}>
          {!isSend ? (
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} align="center">
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Correo electrónico"
                  name="email"
                  value={data.email}
                  autoComplete="email"
                  onChange={(e) => {
                    setData({
                      ...data,
                      email: e.target.value,
                    });
                  }}
                  autoFocus
                  error={emailError}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} md={12} align="center">
                <TextField
                  required
                  fullWidth
                  id="description"
                  label="Descripción"
                  name="description"
                  value={data.description}
                  autoComplete="description"
                  onChange={(e) => {
                    setData({
                      ...data,
                      description: e.target.value,
                    });
                  }}
                  autoFocus
                  error={emailError}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} md={12} align="center">
                <LoadingButton
                  fullWidth
                  variant="contained"
                  loading={loading}
                  sx={{ mt: 3 }}
                  onClick={async () => {
                    setEmailError(false);
                    if (data.email === undefined || data.email === null) {
                      return setEmailError(true);
                    }
                    setLoading(true);
                    try {
                      const res = await setUserDeletionRequests(data);
                      if (res.status >= 200 && res.status <= 203) {
                        setLoading(false);
                        setIsSend(true);
                      }
                    } catch (error) {
                      if (
                        error?.response?.data?.message?.includes(
                          'already exist'
                        )
                      ) {
                        setIsAlreadyError(true);
                        setIsSend(true);
                      }
                      setLoading(false);
                    }
                  }}
                >
                  ENVIAR
                </LoadingButton>
              </Grid>
            </Grid>
          ) : !isAlreadyError ? (
            <Typography sx={{ mt: 2, mb: 2 }} component="h1" variant="h5">
              Se ha enviado la solicitud
            </Typography>
          ) : (
            <Typography sx={{ mt: 2, mb: 2 }} component="h1" variant="h5">
              Ya ha enviado su solicitud de eliminación
            </Typography>
          )}
        </Paper>
        <CopyrightTemplate sx={{ mt: 5 }} />
      </Box>
    </center>
  );
};

export default UserDeletionRequestView;
