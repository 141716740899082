import { tryCatchHelper } from '../../../helpers';
import {
  deleteTiposServiciosService,
  getAllTiposServiciosService,
  getOneTiposServiciosService,
  saveTiposServiciosService,
} from '../../../services';
import {
  ACTIVO_TIPOS_SERVICIOS,
  GETALL_TIPOS_SERVICIOS,
  LOADING_GETALL_TIPOS_SERVICIOS,
} from './TiposServiciosReducer';

export const getAllTiposServiciosAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_GETALL_TIPOS_SERVICIOS,
      payload: true,
    });
    const res = await getAllTiposServiciosService({ entityId });
    if (res.status === 200) {
      dispatch({
        type: GETALL_TIPOS_SERVICIOS,
        payload: res.data,
      });
      dispatch({
        type: LOADING_GETALL_TIPOS_SERVICIOS,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_GETALL_TIPOS_SERVICIOS,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_GETALL_TIPOS_SERVICIOS,
      payload: false,
    });
    let message =
      e.response?.data?.message ||
      e.message ||
      'Error al intentar, realizar el proceso';
    if (Array.isArray(e.message)) message = e.message.join(', ');
    if (Array.isArray(e.response?.data?.message)) {
      message = e.response?.data?.message.join(', ');
    }
    console.warn(message);
  }
};

export const saveTiposServiciosAction = async (
  { dispatch, addNotification },
  entityId,
  id,
  data
) => {
  try {
    const res = await saveTiposServiciosService(id, data);
    addNotification(
      res.message ?? id
        ? 'El tipo de servicio actualizado.'
        : 'El tipo de servicio registrado.'
    );
    await getAllTiposServiciosAction(dispatch, entityId);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoTiposServiciosAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_TIPOS_SERVICIOS,
    payload: data,
  });
};

export const getOneTiposServiciosAction = async (dispatch, id) => {
  try {
    const res = await getOneTiposServiciosService(id);
    if (res.status === 200) {
      await setActivoTiposServiciosAction(dispatch, res.data);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper(e);
    return false;
  }
};

export const deleteTiposServiciosAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deleteTiposServiciosService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data?.message ?? 'Tipo de servicio eliminado.');
      await setActivoTiposServiciosAction(dispatch, null);
      await getAllTiposServiciosAction(dispatch, entityId);
      return true;
    }
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
