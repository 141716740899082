import * as React from 'react';
import * as Redux from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import { Modal } from '../../components';
import * as ROUTES from '../../router/Routes';
import { useHistory } from 'react-router-dom';
import { Paper } from '@mui/material';
import InfoUsuarioComponent from './components/InfoUsuarios';
import FormUsuarios from './components/FormUsuarios';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Tab } from '@mui/material';
import CodigoUsuarioComponent from './components/CodigoUsuarios';
import { setActivoUsuariosAction } from './UsuariosAction';
import { TYPE_ENTITY_ENUM } from '../../constants/enumsConst';

const InfoUsuariosView = () => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const usuariosStore = Redux.useSelector((state) => state.usuarios);

  const [openModalForm, setOpenModalForm] = React.useState(false);
  const [tab, setTab] = React.useState(1);

  React.useEffect(() => {
    if (usuariosStore.activo === null || usuariosStore.activo === undefined) {
      history.push(ROUTES.usuariosInfo.to);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    usuariosStore.all.map(async (el) => {
      if (el.id === usuariosStore.activo.id) {
        await setActivoUsuariosAction(dispatch, el);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuariosStore.all]);

  const tab1 = (
    <TabPanel value={1}>
      <CodigoUsuarioComponent />
    </TabPanel>
  );

  // const tab2 = (
  //   <TabPanel value={2}>
  //     <AccesosUsuarioComponent />
  //   </TabPanel>
  // );

  // const tab3 = (
  //   <TabPanel value={3}>
  //     <SeccionesUsuarioComponent />
  //   </TabPanel>
  // );

  const tabPanel = (
    <Paper elevation={2}>
      <Grid container xs={12} md={12}>
        <Grid item xs={12} md={12}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={(e, nv) => setTab(nv)} aria-label="tabs-users">
                <Tab
                  label={
                    entidadesStore.activo
                      ? TYPE_ENTITY_ENUM[entidadesStore.entityType.name]
                      : 'Código'
                  }
                  value={1}
                />
                {/* Valida Residencia  o accion  */}
                {/* <Tab label="Accesos" value={2} /> */}
                {/* <Tab label="Módulos" value={3} /> */}
              </TabList>
            </Box>
            {tab1}
            {/* {tab2} */}
            {/* {tab3} */}
          </TabContext>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Grid container spacing={2} sx={{ marginTop: '1px' }}>
      <Grid item xs={12} md={3}>
        <InfoUsuarioComponent
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        {tabPanel}
      </Grid>
      <Modal
        id="modalFormUsuarios"
        title={`${usuariosStore.activo ? 'Editar' : 'Nuevo'} usuario`}
        open={openModalForm}
        onClose={() => setOpenModalForm(!openModalForm)}
        maxWidth="lg"
      >
        {usuariosStore.activo ? (
          <Typography variant="h6">
            {usuariosStore.activo.nombres} {usuariosStore.activo.apellidos}
          </Typography>
        ) : null}
        <FormUsuarios
          activo={usuariosStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default InfoUsuariosView;
