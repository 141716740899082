import { CLEAR_ALL, SET_LOGOUT } from '../../../store/Types';

export const GETALL_TIPOS_SERVICIOS = 'GETALL_TIPOS_SERVICIOS';
export const ACTIVO_TIPOS_SERVICIOS = 'ACTIVO_TIPOS_SERVICIOS';
export const LOADING_GETALL_TIPOS_SERVICIOS = 'LOADING_GETALL_TIPOS_SERVICIOS';
export const PARAMS_TIPOS_SERVICIOS = 'PARAMS_TIPOS_SERVICIOS';

const initialState = {
  all: [],
  activo: null,
  loadingGetAll: false,
  params: {},
};

export const tiposServiciosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETALL_TIPOS_SERVICIOS:
      return {
        ...state,
        all: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    case ACTIVO_TIPOS_SERVICIOS:
      return {
        ...state,
        activo: action.payload,
      };
    case LOADING_GETALL_TIPOS_SERVICIOS:
      return {
        ...state,
        loadingGetAll: action.payload,
      };
    case PARAMS_TIPOS_SERVICIOS:
      return {
        ...state,
        params: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
