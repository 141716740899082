import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import TiposDocumentosView from './TiposDocumentosView';
import * as ROUTES from '../../../router/Routes';

const TiposDocumentosPage = () => {
  return (
    <ContainerTemplate active={ROUTES.tiposDocumentos} routes={[]}>
      <TiposDocumentosView />
    </ContainerTemplate>
  );
};

export default TiposDocumentosPage;
