import * as React from 'react';
import * as Redux from 'react-redux';
import { Grid, TextField, FormControl } from '@mui/material';
import * as CONST from '../../../constants/FieldsConst';
import Box from '@mui/material/Box';
import { saveSettingsAction } from '../SettingsAction';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import LoadingComponent from '../../../components/Loading';
import { FormControlLabel } from '@mui/material';
import { Checkbox } from '@mui/material';
import { useNotification } from '../../../helpers/notification';

const FormSettings = () => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const settingsStore = Redux.useSelector((state) => state.settings);
  const activo = settingsStore.activo;

  const initData = {
    [CONST.APP_VERSION]: activo?.versionApp ?? '',
    [CONST.APP_FORCE_UPDATE]: activo?.forceVersion ?? false,
    [CONST.APP_MAINTENANCE]: activo?.maintenance ?? false,
    [CONST.APP_NAME]: activo?.name ?? '',
    [CONST.APP_TESTING_VERSION]: activo?.versionTestingApp ?? '',
    [CONST.GOOGLE_SIGN_IN]: activo?.googleSignIn ?? false,
    playStoreUrl: activo?.playStoreUrl ?? '',
    appStoreUrl: activo?.appStoreUrl ?? '',
  };

  const [setting, setSetting] = React.useState(initData);
  const [dataError, setDataRequired] = React.useState({
    [CONST.APP_VERSION]: false,
    [CONST.APP_NAME]: false,
  });
  // const [file, setFile] = React.useState();
  // const [fileError, setFileError] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);

  React.useEffect(() => {
    if (activo) {
      setSetting(activo);
    }
  }, [activo]);

  const _handleSetDataField = (key, value) =>
    setSetting({ ...setting, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).map((key) => {
      const val = setting[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      return va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const _handleSubmit = async () => {
    const valid = _validDataForm();
    if (valid) {
      setLoadingSave(true);
      await saveSettingsAction({ dispatch, addNotification }, setting);
      setLoadingSave(false);
    }
  };

  return (
    <Box component="form" sx={{}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={activo ? 'Actualizando...' : 'Guardando...'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            id={CONST.APP_NAME}
            required
            label="Nombre de la app móvil"
            value={setting.appName}
            onChange={(e) =>
              _handleSetDataField(CONST.APP_NAME, e.target.value)
            }
            helperText={dataError.appName ? validFielddHelper(1) : null}
            error={dataError.appName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            id={CONST.APP_VERSION}
            required
            label="Version de la app móvil"
            value={setting.appVersion}
            onChange={(e) =>
              _handleSetDataField(CONST.APP_VERSION, e.target.value)
            }
            helperText={dataError.appVersion ? validFielddHelper(1) : null}
            error={dataError.appVersion}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={setting.appForceUpdate}
                  value={setting.appForceUpdate}
                  onChange={(e) =>
                    _handleSetDataField(
                      CONST.APP_FORCE_UPDATE,
                      e.target.checked
                    )
                  }
                  name={CONST.APP_FORCE_UPDATE}
                />
              }
              label="Forzar el cambio de versión de la app móvil"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={setting.appMaintenance}
                  value={setting.appMaintenance}
                  onChange={(e) =>
                    _handleSetDataField(CONST.APP_MAINTENANCE, e.target.checked)
                  }
                  name={CONST.APP_MAINTENANCE}
                />
              }
              label="App móvil en mantenimiento"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            id={CONST.APP_TESTING_VERSION}
            required
            label="Version de la app móvil (TESTING)"
            value={setting.appTestingVersion}
            onChange={(e) =>
              _handleSetDataField(CONST.APP_TESTING_VERSION, e.target.value)
            }
            helperText={
              dataError.appTestingVersion ? validFielddHelper(1) : null
            }
            error={dataError.appTestingVersion}
          />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={setting.googleSignIn}
                  value={setting.googleSignIn}
                  onChange={(e) =>
                    _handleSetDataField(
                      CONST.GOOGLE_SIGN_IN,
                      e.target.checked
                    )
                  }
                  name={CONST.GOOGLE_SIGN_IN}
                />
              }
              label="Habilitar inicio de sesión con Google"
            />
          </FormControl>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            id="playStoreUrl"
            required
            label="URL de la app en Play Store"
            value={setting.playStoreUrl}
            onChange={(e) =>
              _handleSetDataField('playStoreUrl', e.target.value)
            }
            helperText={
              dataError.playStoreUrl ? validFielddHelper(1) : null
            }
            error={dataError.playStoreUrl}
          />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            id="appStoreUrl"
            required
            label="URL de la app en App Store"
            value={setting.appStoreUrl}
            onChange={(e) =>
              _handleSetDataField('appStoreUrl', e.target.value)
            }
            helperText={
              dataError.appStoreUrl ? validFielddHelper(1) : null
            }
            error={dataError.appStoreUrl}
          />
        </Grid>
        <Grid item xs={12} md={12} align="center" sx={{ m: 2 }}>
          <BotonNuevoComponent
            click={_handleSubmit}
            text="GUARDAR"
            disabled={loadingSave}
            morado
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormSettings;
