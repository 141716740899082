import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const options = {
  title: {
    text: 'Gráfico de Líneas Simple',
  },
  series: [
    {
      data: [1, 2, 3, 4, 5],
    },
  ],
};

const LineChartPanelControl = () => (
  <HighchartsReact highcharts={Highcharts} options={options} />
);

export default LineChartPanelControl;
