import {
  Avatar,
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import ActionsList from '../../../components/ActionsList';
import LoadingComponent from '../../../components/Loading';
import { MORADO_COLOR, NEGRO_COLOR } from '../../../constants/ColorsConst';
import { SU } from '../../../constants/ConfigConst';
import * as CONST from '../../../constants/FieldsConst';
import { getCodeType } from '../../../constants/enumsConst';
import { getDateFormated, getDateTimezone } from '../../../helpers';
import { downloadExcelFile } from '../../../helpers/exportHelper';
import { useNotification } from '../../../helpers/notification';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import {
  deleteInvitadosAction,
  setActivoInvitadosAction,
} from '../InvitadosAction';
import { invitados } from '../InvitadosService';

export const textLabelsTable = {
  body: {
    noMatch: 'No se encontrarón registros',
    toolTip: 'Ordenar',
    columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
  },
  pagination: {
    next: 'Pág, Siguiente',
    previous: 'Pág, Anterior',
    rowsPerPage: 'Filas por página:',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Buscar',
    downloadCsv: 'Download CSV',
    print: 'Print',
    viewColumns: 'View Columns',
    filterTable: 'Filter Table',
  },
  filter: {
    all: 'TODOS',
    title: 'FILTROS',
    reset: 'REINICIAR',
  },
  viewColumns: {
    title: 'Show Columns',
    titleAria: 'Show/Hide Table Columns',
  },
  selectedRows: {
    text: 'row(s) selected',
    delete: 'Delete',
    deleteAria: 'Delete Selected Rows',
  },
};

const ListadoAreas = ({ getParams = () => {} }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const invitadosStore = Redux.useSelector((state) => state.invitados);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const loginStore = Redux.useSelector((state) => state.login);

  const [rows, setRows] = React.useState([]);
  const [isDelete, setIsDelete] = React.useState(false);

  const timezone = entidadesStore.activo.country.timezone;
  const isClub = entidadesStore.activo?.entityType?.id === 2;

  const isRoot = loginStore.roles.indexOf(SU) !== -1;

  const _handleDeleteClick = async (id) => {
    // setLoadingDelete(true);
    await deleteInvitadosAction({ dispatch, addNotification }, id, getParams());
    setIsDelete(false);
    // setLoadingDelete(false);
  };

  React.useEffect(() => {
    const rowsData = [];
    invitadosStore.all.forEach((el) => {
      rowsData.push({
        ...el,
        [CONST.AVATAR]: el.guest?.avatar,
        [CONST.NAME]: el.guest ? `${el.guest.firstName} ${el.guest.lastName}` : null,
        [CONST.DESCRIPTION]: el.event?.name || 'N/A',
        [CONST.IDENTIFICATION_NUMBER]: el.guest ? el.guest.identificationNumber || 'N/A' : null,
        [CONST.DATE_FROM]: getDateFormated(
          el.startDate,
          'DD-MM-YYYY HH:mm',
          timezone
        ),
        [CONST.DATE_TO]: getDateFormated(
          el.endDate,
          'DD-MM-YYYY HH:mm',
          timezone
        ),
      });
    });
    setRows(rowsData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitadosStore.all]);

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: CONST.AVATAR,
      label: 'Foto',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!value) return null;
          return (
            <Avatar alt={value} sx={{ width: 30, height: 30 }} src={value} />
          )
        },
      },
    },
    {
      name: CONST.IDENTIFICATION_NUMBER,
      label: 'C.I.',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.NAME,
      label: 'Nombre y apellido',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.DESCRIPTION,
      label: 'Motivo',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.DATE_FROM,
      label: 'Fecha',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.DATE_TO,
      label: 'Hasta',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'PROPIETARIO',
      label: 'Propietario',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = invitadosStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          if (!data) return null;
          const code = data.userCode.code;
          const profile = data.userCode.user.profile;
          const ownerName = `${profile.firstName} ${profile.lastName}`;
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '6px',
                marginBottom: '3px',
              }}
            >
              <Avatar
                alt={value}
                sx={{ width: 30, height: 30 }}
                src={data.userCode.avatar}
              />
              <Box>
                <NameComponent style={{ marginLeft: '10px' }}>
                  {ownerName}
                </NameComponent>
                <Chip
                  label={`${code.code} | ${getCodeType(
                    data.userCode.userType,
                    isClub
                  )}`}
                  variant="outlined"
                  size="small"
                  sx={{
                    color: NEGRO_COLOR,
                    borderColor: NEGRO_COLOR,
                    mr: 1,
                    mb: 1,
                    marginLeft: '10px',
                    '&:hover': {
                      borderColor: MORADO_COLOR,
                      color: MORADO_COLOR,
                    },
                  }}
                  onClick={() => {}}
                />
              </Box>
            </Box>
          );
        },
      },
    },
    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = invitadosStore.all.find(
            (el) => el.id === tableMeta.rowData[0]
          );
          const startDate = data
            ? getDateTimezone(data.startDate, timezone)
            : moment();

          const disabledDeleted = isRoot
            ? false
            : moment(startDate).startOf('day').isBefore(moment());
          return (
            <Box align="left" sx={{ width: '10%' }}>
              <ActionsList
                id={tableMeta.rowData[0]}
                onDelete={async () => {
                  await setActivoInvitadosAction(dispatch, data);
                  setIsDelete(true);
                }}
                disabledDeleted={disabledDeleted}
              />
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onDownload: () => {
      const url = `${invitados}/export`;
      const name = 'invitados';
      const params = getParams();
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const data = invitadosStore.all.find(
        (el) => el.id === rowData[0]
      );
      if (!data.event) return null;
      return (
        
        <TableRow>
          <TableCell colSpan={9}>

            <TableContainer component={Paper} sx={{ backgroundColor: '#f3f3f3' }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Avatar</TableCell>
                    <TableCell align="left">Nombre</TableCell>
                    <TableCell align="left">Teléfono</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Asistió</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    data.event.participants.map((item, index) => {
                      return (
                        <TableRow
                          key={`row_${index}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left">
                            <Avatar alt={item.avatar} src={item.avatar} />
                          </TableCell>
                          <TableCell align="left">{item.firstName} {item.lastName}</TableCell>
                          <TableCell align="left">{item.phone}</TableCell>
                          <TableCell align="left">{item.email}</TableCell>
                          <TableCell align="left">
                            <Chip
                              label={item.checked ? 'Sí' : 'No'}
                              style={{ color: '#fff', backgroundColor: item.attended ? '#4CAF50' : '#F44336' }}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </TableCell>
        </TableRow>
      );
    },
    selectableRowsHideCheckboxes: true,
    customSearch: (q, currentRow, columns) => {
      q = q.toLowerCase();
      const data = invitadosStore.all.find(({ id }) => id === currentRow[0]);
      if (!data) return true;
      const guestData =
        `${data.guest.firstName} ${data.guest.lastName} ${data.guest.identificationNumber}`.toLowerCase();
      const ownerData =
        `${data.userCode.user.profile.firstName} ${data.userCode.user.profile.lastName} ${data.userCode.code.code}`.toLowerCase();
      const dateData = `${currentRow[3]} ${currentRow[4]}}`.toLowerCase();

      return (
        guestData.includes(q) || ownerData.includes(q) || dateData.includes(q)
      );
    },
  };

  return (
    <Grid container>
      <LoadingComponent
        isLoading={invitadosStore.loadingGetAll}
        text="Cargando lista..."
      />
      <Grid item md={12}>
        <MUIDataTable
          title={'Listado'}
          data={invitadosStore.all.length > 0 ? rows : []}
          columns={columns}
          options={options}
        />
      </Grid>

      {invitadosStore.activo ? (
        <Modal
          id="modal-deleteasd"
          title={`Eliminar`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar esta invitación?
              <p>
                <strong>
                  {invitadosStore.activo.firstName}{' '}
                  {invitadosStore.activo.lastName}
                </strong>
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={async () => {
                await _handleDeleteClick(invitadosStore.activo.id);
              }}
            >
              ELIMINAR
            </Button>
            <Button
              sx={{ color: MORADO_COLOR }}
              onClick={() => setIsDelete(false)}
            >
              CANCELAR
            </Button>
          </DialogActions>
        </Modal>
      ) : null}
    </Grid>
  );
};

export default ListadoAreas;
