import React from 'react';

const LogOnComponent = ({ open, isLogo }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '80px',
        height: isLogo ? '90px' : '45px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLogo && <img alt="logo2" src="img/logo2.png" width={open ? 80 : 55} />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
      >
        <label
          style={{
            fontFamily: 'Roboto',
            fontSize: open ? '8pt' : '6pt',
            marginRight: '2px',
          }}
        >
          By
        </label>
        <img
          alt="Logo1"
          src="img/logo.svg"
          width={open ? 60 : 40}
          height={15}
        />
      </div>
    </div>
  );
};

export default LogOnComponent;
