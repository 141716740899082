import React from 'react';
import ContainerTemplate from '../../../components/template/Container';
import TiposContactosView from './TiposContactosView';
import * as ROUTES from '../../../router/Routes';

const TiposContactosPage = () => {
  return (
    <ContainerTemplate active={ROUTES.tiposContactos} routes={[]}>
      <TiposContactosView />
    </ContainerTemplate>
  );
};

export default TiposContactosPage;
