import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';
import { serialize } from 'object-to-formdata';

export const tiposImagenClasesApi = `${baseApi}/class-layout-images`;
export const getAllTiposImagenClasesService = async (params) => {
  // params.active = true;
  const res = await axios.get(tiposImagenClasesApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveTiposImagenClasesService = async (id, data) => {

  const isFile = data.image;

  if (!!isFile) {
    data = serialize(data, {
      nullsAsUndefineds: true,
      allowEmptyArrays: true,
    })
  }

  let res;
  if (id) {
    res = await axios.put(`${tiposImagenClasesApi}/${id}`, data, {
      headers: await token({
        isFormData: !!isFile,
      }),
    });
  } else {
    res = await axios.post(`${tiposImagenClasesApi}`, data, {
      headers: await token({
        isFormData: !!isFile,
      }),
    });
  }
  return res.data;
};

export const getOneTiposImagenClasesService = async (id) => {
  const res = await axios.get(`${tiposImagenClasesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteTiposImagenClasesService = async (id) => {
  const res = await axios.delete(`${tiposImagenClasesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};
