import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const options = {
  chart: {
    type: 'column',
  },
  title: {
    text: 'Reservas por mes',
  },
  plotOptions: {
    column: {
      dataLabels: {
        enabled: true, // Habilita las etiquetas de datos
        format: '{point.y}', // Formato de etiqueta (en este caso, muestra el valor y)
      },
    },
  },
  xAxis: {
    categories: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
  },
  yAxis: {
    title: {
      text: 'Reservas',
    },
  },
  series: [
    {
      name: 'Reservas',
      data: [70, 75, 90, 82, 71, 95, 67, 70, 95, 86, 95],
    },
    {
      name: 'Clases reservadas',
      data: [10, 15, 20, 22, 21, 25, 17, 20, 25, 26, 25],
    }
  ],
};

const MesesBarChartPanelControl = () => (
  <HighchartsReact highcharts={Highcharts} options={options} />
);

export default MesesBarChartPanelControl;
