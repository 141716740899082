import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import { dateformat, isSwitch } from '../../../helpers';
import FechasPersonalizada from '../../accesos/components/FechasPersonalizada';
import BotonFechas from '../../invitados/components/BotonFechas';
import SwitchFechaComponent from '../../invitados/components/SwitchFecha';
import { getAllReservacionesClasesAction } from './ReservacionesClasesAction';
import FormReservacionesClases from './components/FormReservacionesClases';
import ListadoReservacionesClases from './components/ListadoReservacionesClases';

const ReservacionesClasesView = () => {
  const dispatch = Redux.useDispatch();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const tiposClasesStore = Redux.useSelector((state) => state.tiposClases);

  const clasesStore = Redux.useSelector((state) => state.clases);

  const botonFechasRef = React.useRef(null);

  const [openModalForm, setOpenModalForm] = React.useState(false);
  const [isRango, setIsRango] = React.useState(false);
  const [isFuturo, setIsFuturo] = React.useState(true);
  const [startDate, setStartDate] = React.useState(
    dateformat(null, { full: true })
  );
  const [endDate, setEndDate] = React.useState(null);
  const [params, setParams] = React.useState({
    entityId: entidadesStore.activo?.id,
    classId: clasesStore.activo?.id,
  });

  React.useEffect(() => {
    botonFechasRef.current.handleMenuItemClick(null, 4);
  }, []);

  React.useEffect(() => {
    if (clasesStore.activo) {
      getAllReservacionesClasesAction(dispatch, params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  React.useEffect(() => {
    setParams((old) => ({
      ...old,
      startDate: startDate,
      endDate: endDate,
    }));
  }, [startDate, endDate]);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
          <Grid container spacing={1}>
            <Grid item md={isRango ? 3 : 6}>
              <Stack direction="row" alignItems="center">
                <BotonFechas
                  futuro={isFuturo}
                  ref={botonFechasRef}
                  dateRange={(start, end) => {
                    setStartDate(dateformat(start, { full: true }));
                    setEndDate(dateformat(end, { full: true }));
                  }}
                  setIsRango={(v) => setIsRango(v)}
                />
                {isSwitch(isFuturo, startDate, endDate) || isRango ? null : (
                  <Box sx={{ ml: 2, mt: 1 }}>
                    <SwitchFechaComponent
                      value={isFuturo}
                      onClickLeft={() => setIsFuturo(false)}
                      onClickRigth={() => setIsFuturo(true)}
                      onChange={(e) => setIsFuturo(e.target.checked)}
                    />
                  </Box>
                )}
              </Stack>
            </Grid>
            {isRango && (
              <FechasPersonalizada
                startDate={startDate}
                endDate={endDate}
                onStart={(value) => {
                  if (!value) return;
                  setParams({ ...params, startDate: value });
                  setStartDate(value);
                }}
                onEnd={(value) => {
                  if (!value) return;
                  setParams({ ...params, endDate: value });
                  setEndDate(value);
                }}
              />
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item md={12}>
        <ListadoReservacionesClases
          params={params}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Grid>
      <Modal
        id="modalFormReservacionesClases"
        title={`${tiposClasesStore.activo ? 'Editar' : 'Nuevo'} reserva`}
        open={openModalForm}
        maxWidth="sm"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {tiposClasesStore.activo && (
          <Typography variant="h6">
            {tiposClasesStore.activo.nombres}{' '}
            {tiposClasesStore.activo.apellidos}
          </Typography>
        )}
        <FormReservacionesClases
          params={params}
          activo={tiposClasesStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default ReservacionesClasesView;
