import axios from 'axios';
import { baseApi, token } from '../../../services/Apis';

export const eventosApi = `${baseApi}/events`;

export const getAllEventosService = async (params) => {
  const res = await axios.get(eventosApi, {
    headers: await token({}),
    params,
  });
  return res;
};

export const saveEventosService = async (id, data) => {
  if (data.image || data.flyer) {
    const formData = new FormData();

    if (data.dates && data.dates.length) {
      for (let i = 0; i < data.dates.length; i++) {
        const date = data.dates[i];
        for (const key in date) {
          formData.append(`dates[${i}][${key}]`, date[key]);
        }
      }
      delete data.dates;
    } else {
      formData.append('dates[]', '');
    }

    if (data.subareaIds && data.subareaIds.length) {
      for (let i = 0; i < data.subareaIds.length; i++) {
        formData.append('subareaIds[]', data.subareaIds[i]);
      }
      delete data.subareaIds;
    }

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    data = formData;
  } else {
    delete data.image;
    delete data.flyer;
  }
  let res;
  if (id) {
    res = await axios.put(`${eventosApi}/${id}`, data, {
      headers: await token({
        isFormData: !!data.image || !!data.flyer,
      }),
    });
  } else {
    res = await axios.post(`${eventosApi}`, data, {
      headers: await token({
        isFormData: !!data.image || !!data.flyer,
      }),
    });
  }
  return res;
};

export const deleteEventosService = async (id) => {
  const res = await axios.delete(`${eventosApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const bulkExcelEventosService = async (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  const res = await axios.post(`${eventosApi}/bulk`, formData, {
    headers: await token({ isFormData: true }),
  });
  return res;
};
