import React from "react";
import ContainerTemplate from "../../components/template/Container";
import TiposInvitacionView from "./TiposInvitacionView";
import * as ROUTES from "../../router/Routes";

const TiposInvitacionPage = () => {
  return (
    <ContainerTemplate active={ROUTES.tiposInvitacion} routes={[]}>
      <TiposInvitacionView />
    </ContainerTemplate>
  );
};

export default TiposInvitacionPage;
