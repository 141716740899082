import { Alert, Grid } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import LoadingComponent from '../../../components/Loading';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { checkModuleAccess } from '../../modulos/modules.helpers';
import { getAllEventosAction, setActivoEventosAction } from './EventosAction';
import CalendarioEventosComponent from './components/CalendarioEventos';
import FormEventos from './components/FormEventos';

export const statusEventoEnum = {
  ACTIVE: 'Activo',
  FINISHED: 'Finalizado',
};

const EventosView = () => {
  const dispatch = Redux.useDispatch();
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const eventosStore = Redux.useSelector((state) => state.eventos);
  const loginStore = Redux.useSelector((state) => state.login);

  const [openModalForm, setOpenModalForm] = React.useState(false);

  const accessName = 'events';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <BotonNuevoComponent
          click={async () => {
            await setActivoEventosAction(dispatch, null);
            setOpenModalForm(true);
          }}
          azul
          mr={2}
        />
        <BotonNuevoComponent
          click={async () => {
            await setActivoEventosAction(dispatch, null);
            await getAllEventosAction(dispatch, {
              entityId: entidadesStore.activo.id,
              ...eventosStore.params,
            });
          }}
          white
          disabled={eventosStore.loadingGetAll || eventosStore.all.length === 0}
          text={eventosStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'}
        />
      </Grid>
      <Grid item md={12}>
        <LoadingComponent
          isLoading={eventosStore.loadingGetAll}
          text="Cargando lista..."
        />
        <CalendarioEventosComponent
          setOpenModalForm={() => setOpenModalForm(true)}
        />
      </Grid>
      <Modal
        id="modalFormEventos"
        title={`${eventosStore.activo ? 'Editar' : 'Nuevo'} eventos`}
        open={openModalForm}
        maxWidth="lg"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {eventosStore.activo?.status === 'FINISHED' && (
          <Alert severity="warning">El evento se encuentra finalizado.</Alert>
        )}
        <FormEventos
          activo={eventosStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default EventosView;
