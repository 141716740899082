import {
  GETALL_PUERTAS,
  LOADING_UPDATE_PUERTAS,
  ACTIVO_PUERTAS,
} from '../../store/Types';
import {
  assignAllCodesService,
  deleteIotPuertaService,
  deletePuertasService,
  getAllPuertasService,
  getOnePuertasService,
  saveIotPuertaService,
  savePuertasService,
} from '../../services';
import { tryCatchHelper } from '../../helpers';

export const getAllPuertasAction = async (dispatch, entityId) => {
  try {
    dispatch({
      type: LOADING_UPDATE_PUERTAS,
      payload: true,
    });
    const res = await getAllPuertasService(entityId);
    if (res.status === 200) {
      dispatch({
        type: GETALL_PUERTAS,
        payload: res.data,
      });
      dispatch({
        type: LOADING_UPDATE_PUERTAS,
        payload: false,
      });
      return res.data;
    }
    dispatch({
      type: LOADING_UPDATE_PUERTAS,
      payload: false,
    });
    return null;
  } catch (e) {
    dispatch({
      type: LOADING_UPDATE_PUERTAS,
      payload: false,
    });
    tryCatchHelper(e);
  }
};

export const savePuertasAction = async (
  { dispatch, addNotification },
  data,
  { entityId, id }
) => {
  try {
    const res = await savePuertasService(id, data);
    addNotification(
      res.message ?? id ? 'La puerta actualizado.' : 'La puerta registrado.'
    );
    await getAllPuertasAction(dispatch, entityId, null);
    return res;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const setActivoPuertasAction = async (dispatch, data) => {
  dispatch({
    type: ACTIVO_PUERTAS,
    payload: data,
  });
};

export const getOnePuertasAction = async (dispatch, id) => {
  const res = await getOnePuertasService(id);
  if (res.status === 200) {
    await setActivoPuertasAction(dispatch, res.data);
    return true;
  }
  return false;
};

export const deletePuertasAction = async (
  { dispatch, addNotification },
  id,
  entityId
) => {
  try {
    const res = await deletePuertasService(id, entityId);
    if (res.status === 200) {
      addNotification(res.data.message ?? 'Puerta de acceso eliminada.');
      await setActivoPuertasAction(dispatch, null);
      await getAllPuertasAction(dispatch, entityId, null);
      return true;
    }
    addNotification('Error al momento de eliminar el puerta', { error: true });
    return false;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
  }
};

export const saveIotPuertaAction = async (
  { dispatch, addNotification },
  entityId,
  idPuerta,
  idIot
) => {
  try {
    const res = await saveIotPuertaService(idPuerta, idIot);
    addNotification(res.message ?? 'Iot asignado a la puerta', {
      horizontal: 'right',
    });
    await getAllPuertasAction(dispatch, entityId, null);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const deleteIotPuertaAction = async (
  { dispatch, addNotification },
  entityId,
  idPuerta,
  idIot
) => {
  try {
    dispatch({
      type: LOADING_UPDATE_PUERTAS,
      payload: true,
    });
    const res = await deleteIotPuertaService(idPuerta, idIot);
    addNotification(res.message ?? 'Iot eliminado de la puerta', {
      horizontal: 'right',
    });
    await getAllPuertasAction(dispatch, entityId, null);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};

export const assignAllCodesAction = async (
  { dispatch, addNotification },
  entityId,
  idPuerta
) => {
  try {
    dispatch({
      type: LOADING_UPDATE_PUERTAS,
      payload: true,
    });
    const res = await assignAllCodesService(idPuerta);
    addNotification(
      res.message ?? 'Se han asignado todos los códigos a la puerta',
      { horizontal: 'right' }
    );
    await getAllPuertasAction(dispatch, entityId, null);
    return true;
  } catch (e) {
    tryCatchHelper({ e, addNotification });
    return false;
  }
};
