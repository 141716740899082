import axios from 'axios';
import { baseApi, token } from '../../services/Apis';

export const iotTunelesApi = `${baseApi}/iot-tunnels`;

export const getAllIotTunelesService = async (entityId) => {
  const res = await axios.get(iotTunelesApi, {
    headers: await token({}),
    params: {
      entityId,
    },
  });
  return res;
};

export const saveIotTunelesService = async (id, data) => {
  let res;
  if (id) {
    res = await axios.put(`${iotTunelesApi}/${id}`, data, {
      headers: await token({}),
    });
  } else {
    res = await axios.post(`${iotTunelesApi}`, data, {
      headers: await token({}),
    });
  }
  return res;
};

export const getOneIotTunelesService = async (id) => {
  const res = await axios.get(`${iotTunelesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const deleteIotTunelesService = async (id) => {
  const res = await axios.delete(`${iotTunelesApi}/${id}`, {
    headers: await token({}),
  });
  return res;
};

export const setUpIotTunelesService = async (id, dto) => {
  try {
    return await axios
      .post(`${iotTunelesApi}/${id}/set-up`, dto, {
        headers: await token({}),
      })
      .then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};
