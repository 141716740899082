import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../components';
import { SU } from '../../constants/ConfigConst';
import { CLEAR_ALL } from '../../store/Types';
import {
  getAllEntidadesAction,
  setActivoEntidadesAction,
} from './EntidadesAction';
import BotonNuevoComponent from './components/BotonNuevo';
import FormEntidades from './components/FormEntidades';
import ListadoEntidades from './components/ListadoEntidades';

const EntidadesModule = () => {
  const dispatch = Redux.useDispatch();
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const loginStore = Redux.useSelector((state) => state.login);

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);

  const [openModalForm, setOpenModalForm] = React.useState(false);

  React.useEffect(() => {
    if (entidadesStore.all.length === 0) _handleGetAll();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    dispatch({
      type: CLEAR_ALL,
      payload: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entidadesStore.activo]);

  const _handleGetAll = async () => {
    await getAllEntidadesAction(dispatch, entidadesStore.params);
  };

  return (
    <Grid container spacing={1}>
      <Grid item md={12} xs={12}>
        {isRoot && (
          <BotonNuevoComponent
            click={async () => {
              await setActivoEntidadesAction(dispatch, null);
              setOpenModalForm(true);
            }}
            azul
            mr={2}
          />
        )}
        <BotonNuevoComponent
          click={async () => {
            await setActivoEntidadesAction(dispatch, null);
            _handleGetAll();
          }}
          white
          disabled={entidadesStore.loadingGetAll}
          text={entidadesStore.loadingGetAll ? 'Actualizando...' : 'Actualizar'}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ListadoEntidades setOpenModalForm={(val) => setOpenModalForm(val)} />
      </Grid>
      <Modal
        id="modalFormEntidades"
        title={`${entidadesStore.activo ? 'Editar' : 'Nueva'} entidad`}
        open={openModalForm}
        maxWidth="lg"
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {entidadesStore.activo ? (
          <Typography variant="h6">{entidadesStore.activo.nombre}</Typography>
        ) : null}
        <FormEntidades openModal={(val) => setOpenModalForm(val)} />
      </Modal>
    </Grid>
  );
};

export default EntidadesModule;
