import React from "react";
import ContainerTemplate from "../../components/template/Container";
import Recibosview from "./RecibosView";
import * as ROUTES from "../../router/Routes";

const RecibosModule = () => {
  return (
    <ContainerTemplate active={ROUTES.recibos} routes={[]}>
      <Recibosview />
    </ContainerTemplate>
  );
};

export default RecibosModule;
