import { LoadingButton } from '@mui/lab';
import { Avatar, Stack } from '@mui/material';
import { Paper } from '@mui/material';
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import CopyrightTemplate from '../../../components/template/Copyright';
import { resetPassService } from '../../../services';
import PasswordField from '../login/components/PasswordField';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import queryString from 'query-string';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { baseApi } from '../../../services/Apis';

const RecoverModule = () => {
  const [pass, setPass] = React.useState({
    password: '',
    confirm: '',
  });
  const location = useLocation();

  const queryParams = queryString.parse(location.search);
  const token = queryParams.token;

  const [passwordError, setPasswordError] = React.useState(false);
  const [confirmError, setConfirmError] = React.useState(false);
  const [checkPassword, setCheckPassword] = React.useState({
    upper: false,
    number: false,
    length: false,
    match: false,
  });
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isTokenValid, setIsTokenValid] = React.useState(true);
  const [passwordChanged, setPasswordChanged] = React.useState(false);
  const _handleSetDataLogin = (prop) => (event) => {
    setPass({ ...pass, [prop]: event.target.value });
  };

  const checkToken = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.get(`${baseApi}/auth/check-token/${token}`).then((res) => {
        });
        resolve(true);
      } catch (error) {
        reject(false);
      }
    });
  };

  React.useEffect(() => {
    checkToken(token)
      .then((res) => {
        setIsTokenValid(true);
      })
      .catch((err) => {
        setIsTokenValid(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    checkPass('password', pass.password);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pass.password]);

  React.useEffect(() => {
    checkPass('confirm', pass.confirm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pass.confirm]);

  function checkPass(type, value) {
    // var lowerCaseLetters = /[a-z]/g;
    var upperCaseLetters = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (type === 'password') {
      setCheckPassword({
        ...checkPassword,
        upper: value.match(upperCaseLetters),
        number: value.match(numbers),
        length: value.length >= 8,
        match: pass.password === pass.confirm,
      });
    }
    if (type === 'confirm') {
      setCheckPassword({
        ...checkPassword,
        match: pass.password === pass.confirm,
      });
    }
  }

  const checkText = (check, text) => (
    <Stack direction="row" spacing={1}>
      {check ? (
        <CheckIcon color="success" sx={{ fontSize: 15 }} />
      ) : (
        <CloseIcon sx={{ fontSize: 15 }} />
      )}
      <Typography
        sx={{
          color: check ? 'green' : 'gray',
          fontSize: 12,
        }}
      >
        {text}
      </Typography>
    </Stack>
  );

  return (
    <center>
      <Box
        sx={{
          width: {
            xs: '90%',
            md: '30%',
          },
        }}
      >
        <Avatar
          alt="On Pass"
          src="./img/logoon.png"
          sx={{
            width: {
              xs: 100,
              md: 150,
            },
            height: {
              xs: 100,
              md: 150,
            },
            padding: '20px',
          }}
        />
        <Typography component="h1" variant="h3" sx={{ fontWeight: '700' }}>
          ON PASS
        </Typography>
        <Typography sx={{ mt: 2, mb: 2 }} component="h1" variant="h5">
          Recuperar Contraseña
        </Typography>
        <Paper elevation={5} sx={{ p: 3 }}>
          {isTokenValid ? (
            !passwordChanged ? (
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} align="center">
                  <PasswordField
                    value={pass.password}
                    error={passwordError}
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    showPassword={showPassword}
                    onChange={_handleSetDataLogin('password')}
                  />
                </Grid>
                <Grid item xs={12} md={12} align="center">
                  <PasswordField
                    value={pass.confirm}
                    error={confirmError}
                    label="Confirmar contraseña"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    showPassword={showPassword}
                    onChange={_handleSetDataLogin('confirm')}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  {checkText(checkPassword.upper, 'Minímo una letra Mayúscula')}
                  {checkText(checkPassword.number, 'Minímo un Número')}
                  {checkText(checkPassword.length, 'Minímo 8 carácteres')}
                  {checkText(
                    checkPassword.match,
                    'Las contraseñas deben ser iguales'
                  )}
                </Grid>
                <Grid item xs={12} md={12} align="center">
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    loading={loading}
                    sx={{ mt: 3 }}
                    onClick={async () => {
                      setPasswordError(false);
                      setConfirmError(false);

                      if (
                        !checkPassword.upper ||
                        !checkPassword.number ||
                        !checkPassword.length
                      ) {
                        setPasswordError(true);
                        return;
                      }

                      if (!checkPassword.match) {
                        setConfirmError(true);
                        return;
                      }

                      if (!passwordError && !confirmError) {
                        setLoading(true);
                        const res = await resetPassService(
                          pass.password,
                          token
                        );
                        if (res.error) {
                          setLoading(false);
                          return alert(res.message);
                        }
                        if (res.status >= 200 && res.status <= 203) {
                          setLoading(false);
                          setPasswordChanged(true);
                          // alert("La contraseña reiniciado correctamente");
                          // history.push("/");
                        }
                      }
                    }}
                  >
                    ENVIAR
                  </LoadingButton>
                </Grid>
              </Grid>
            ) : (
              <Typography sx={{ mt: 2, mb: 2 }} component="h1" variant="h5">
                La contraseña reiniciado correctamente
              </Typography>
            )
          ) : (
            <Typography sx={{ mt: 2, mb: 2 }} component="h1" variant="h5">
              El token ha expirado o no es válido
            </Typography>
          )}
        </Paper>
        <CopyrightTemplate sx={{ mt: 5 }} />
      </Box>
    </center>
  );
};

export default RecoverModule;
